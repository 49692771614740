import DataGrid, { Column, Button as DataGridButton, FilterPanel, Scrolling, ColumnChooser, HeaderFilter, SearchPanel, FilterBuilderPopup, Pager, StateStoring, Toolbar, Item, ColumnChooserSearch, Search, Grouping, GroupPanel, } from 'devextreme-react/data-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Sorting } from 'devextreme-react/gantt';
import { useScreenSize } from '../../../utils/media-query';
import Header from '../../../components/header/Header';
import { useLocation } from "react-router";
import { confirm } from 'devextreme/ui/dialog';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, eventStatusDropDown, sortingMethodDate } from '../../../utils/common-methods';
import { EventGroupService } from '../../../api/services/eventgroupService';
import EventGroupPopup from './EventGroupPopup';
import { useNavigate } from 'react-router-dom';
import './EventGroup.scss';
import { Button, ScrollView, SelectBox } from 'devextreme-react';
import ListSkeletonFilter from '../../../layouts/templates/ListSkeletonFilter';
import { eRegistrationType } from '../../../api/services/registrationFormDesignerService';
import RegistrationFormDesigner from '../../registration-form/RegistrationFormDesigner';

const eventGroupService = new EventGroupService();
const EventGroupList = () => {
    const { isExSmall, isXXSmall } = useScreenSize();

    const { state } = useLocation();

    const { EventID, hideStatusFilter } = state || {};

    const [dsEventGroup, setDsEventGroup] = useState([]);
    const [filterVisible, setFilterVisible] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [showEventGroupPopup, setShowEventGroupPopup] = useState(false)
    const [primaryKey, setPrimaryKey] = useState(null)
    const [focusedRowKey, setfocusedRowKey] = useState(0);
    const [eventStatus, setEventStatus] = useState(1);
    const [status, setStatus] = useState({
        eStatus: null,
        primaryKeyID: primaryKey,
    });
    const [currentEventID, setCurrentEventID] = useState(0);
    const [currentGroupCategoryID, setCurrentGroupCategoryID] = useState(0);
    // Start For Group Registration Form Designer
    const [ShowGroupRegistrationFormDesignerPopup, setShowGroupRegistrationFormDesignerPopup] = useState(false);
    const [SelectedEventDetail, setSelectedEventDetail] = useState(null);
    const [eventListEventID, seteventListEventID] = useState();

    const [GroupStatus, setGroupStatus] = useState({
        eStatus: null
    });
    const [hideFilterSelectBox, setHideFilterSelectBox] = useState(false);


    // End For Group Registration Form Designer
    const DataGridRef = useRef(null);
    const NavigateTo = useNavigate();

    useEffect(() => {
        EventID && seteventListEventID(EventID);
        hideStatusFilter &&  setHideFilterSelectBox(hideStatusFilter) 
    }, [])


    useEffect(() => {
        eventListEventID && DataGridRef.current?.instance?.filter(["eventID", "=", eventListEventID]);
    })

    const GetEventGroupList = async () => {
        setShowSkeleton(true)

        const result = (eventStatus === null || hideStatusFilter) ? await eventGroupService.GetListRecord() : await eventGroupService.GetListRecordWithFilter(eventStatus);
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")
            setShowSkeleton(false);
        } else {
            const EventGroupData = result?.data?.map(item => {
                const createdOn = item.createdOn ? GetFormattedDateTimeUTCString(item.createdOn) : null;
                const updatedOn = item.updatedOn ? GetFormattedDateTimeUTCString(item.updatedOn) : null;
                return {
                    ...item,
                    createdOn: createdOn ? createdOn : null,
                    updatedOn: updatedOn ? updatedOn : null
                };
            });
            setDsEventGroup(EventGroupData);
            setShowSkeleton(false);
        }
    }

    useEffect(() => {
        GetEventGroupList();
    }, [eventStatus])


    const onCloseEventGroupPopup = () => {
        setShowEventGroupPopup(false);
    }

    useEffect(() => {
        if (status.eStatus !== null && status.eStatus !== eCRUDStatus.None) {
            const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
            UpdateListByID(PrimaryKeyID);
            setStatus({ eStatus: null })
        } else if (status.eStatus !== null && status.eStatus === eCRUDStatus.None) {
            setPrimaryKey(null);
            setStatus({ eStatus: null })
        }
    }, [status])


    const UpdateListByID = async (primaryKey) => {
        setShowSkeleton(true);
        GetEventGroupList();
    };

    // Start For Group Registration Form Designer

    useEffect(() => {
        if (GroupStatus.eStatus !== null && GroupStatus.eStatus !== eCRUDStatus.None) {
            setSelectedEventDetail(null);
            GetEventGroupList();
            setGroupStatus({ eStatus: null })
        } else if (GroupStatus.eStatus !== null && GroupStatus.eStatus === eCRUDStatus.None) {
            setSelectedEventDetail(null);
            setGroupStatus({ eStatus: null })
        }
    }, [GroupStatus]);

    const onShowGroupRegistrationFormDesignerPopup = (e) => {
        setSelectedEventDetail({
            EventID: e[0]?.eventID,
            EventName: e[0]?.eventName,
            EventGroupCategoryID: e[0]?.eventGroupCategoryID,
            RegistrationFormID: e[0]?.groupRegistrationFormID,
            RegistrationTypeID: eRegistrationType.Group
        })
        setShowGroupRegistrationFormDesignerPopup(!ShowGroupRegistrationFormDesignerPopup);
    }

    const onCloseGroupRegistrationFormDesignerPopup = () => {
        setShowGroupRegistrationFormDesignerPopup(!ShowGroupRegistrationFormDesignerPopup);
    }

    // End For Group Registration Form Designer

    const onOptionChange = (e) => {
        if (e.fullName === "filterValue") {
            setFilterVisible(!!e.value);
        }
    };

    const handleAdd = (eventID, eventGroupCategoryID) => {
        eventID ? setCurrentEventID(eventID) : setCurrentEventID(null)
        eventGroupCategoryID ? setCurrentGroupCategoryID(eventGroupCategoryID) : setCurrentGroupCategoryID(null)
        setPrimaryKey(0);
        setShowEventGroupPopup(true)
    }

    const handleEdit = (e) => {
        setCurrentEventID(e?.data.eventID)
        setCurrentGroupCategoryID(e?.data.eventGroupCategoryID)
        setPrimaryKey(e?.data.eventGroupID)
        setShowEventGroupPopup(true)
    }

    const onDeleteButtonClick = (e) => {
        let res = confirm(
            `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
            "Run"
        );
        res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
    };

    const handleDelete = async (e) => {
        const Id = e.row.data.eventGroupID;
        const result = await eventGroupService.DeleteRecord(Id);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false)
        }
        else if (result.statusCode === 200) {
            setDsEventGroup(dsEventGroup.filter((x) => x.eventGroupID !== e.row.data.eventGroupID));
            setShowSkeleton(false)
        }
    }

    const onRowDbClick = (e) => {
        if (e?.rowType === "data") {
            setPrimaryKey(e.data.eventGroupID)
            setCurrentEventID(e?.data.eventID)
            setCurrentGroupCategoryID(e?.data.eventGroupCategoryID)
            setShowEventGroupPopup(true)
        }
    }

    const eventGroupName = (cellData) => {
        return <>
            <div><span className='text-decoration-underline pointer-link text-break' title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.eventGroupName}</span></div>
        </>
    }

    function onFocusedRowChanged(e) {
        setfocusedRowKey(e.component.option("focusedRowKey"));
    }

    const refreshDataGrid = () => {
        GetEventGroupList();
        DataGridRef.current?.instance.clearFilter();
    };

    const NavigateHome = () => {
        NavigateTo('/dashboard')
    }

    const EventIDRender = (e) => {
        const res = dsEventGroup.filter(item => item.eventID === e.value);
        const eventName = res[0]?.eventName;
        const eventID = res[0]?.eventID;
        return (
            <div className='row d-flex align-items-center'>
                <div className="d-flex align-items-center">
                    <div>{eventName}
                        <span className="vertical-line-inside-grid px-1">|</span>
                    </div>
                    <Button
                        icon="plus"
                        className="rounded-5 Add-EventGrup-button mt-1"
                        id="Add"
                        onClick={() => handleAdd(eventID)}
                        stylingMode="outlined"
                        type="default"
                        hint="Add"
                    ></Button>
                </div>
            </div>
        );
    }

    const CategoryCellRender = (e) => {
        const res = dsEventGroup.filter(item => item.eventGroupCategoryID === e.value);
        const categoryName = res[0]?.eventGroupCategoryName;
        const eventGroupCategoryID = res[0]?.eventGroupCategoryID;
        const eventID = res[0]?.eventID;
        return (
            <div className='row d-flex align-items-center'>
                <div className="d-flex align-items-center">
                    <div>{categoryName}
                        <span className="vertical-line-inside-grid px-1">|</span>
                    </div>
                    <Button
                        icon="plus"
                        className="rounded-5 Add-EventGrup-button mt-1"
                        id="Add"
                        onClick={() => handleAdd(eventID, eventGroupCategoryID)}
                        stylingMode="outlined"
                        type="default"
                        hint="Add"
                    ></Button>
                    &nbsp; &nbsp;
                    <Button
                        className="rounded-5 Add-EventGrup-button mt-1"
                        stylingMode="outlined"
                        type="default"
                        name='editRegistrationfForm'
                        icon='EditRegistrationformIcon'
                        hint='Group Registration Form'
                        visible={true}
                        onClick={() => onShowGroupRegistrationFormDesignerPopup(res)}
                    />
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header
                title={"Groups"}
                handleAdd={handleAdd}
                dataGridRef={DataGridRef}
                refreshDataGrid={refreshDataGrid}
                resetStatus={setEventStatus}
                DefaultstatusValue={1}
                seteventListEventID={seteventListEventID}
                setHideFilterSelectBox ={setHideFilterSelectBox}
                eventListEventID={eventListEventID}
            />
            <ScrollView>
                <div className='ps-1'>
                    <div className={showSkeleton ? 'd-block' : 'd-none'}>
                        <ListSkeletonFilter />
                    </div>
                    <div className={showSkeleton ? 'd-none' : 'd-block list-section'}>
                        <DataGrid
                            ref={DataGridRef}
                            height={"100%"}
                            width={"100%"}
                            className='List_DataGrid'
                            dataSource={dsEventGroup}
                            keyExpr="eventGroupID"
                            showBorders={true}
                            showColumnLines={false}
                            showRowLines={true}
                            focusedRowKey={focusedRowKey}
                            focusedRowEnabled={true}
                            wordWrapEnabled={true}
                            hoverStateEnabled={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            autoNavigateToFocusedRow={true}
                            loadPanel={HideDatagridLoader}
                            filterSyncEnabled={true}
                            onOptionChanged={onOptionChange}
                            onRowDblClick={onRowDbClick}
                            noDataText='No Record Found'
                            onFocusedRowChanged={onFocusedRowChanged}
                        >
                            <Sorting mode={"multiple"} />
                            <FilterPanel visible={filterVisible} />
                            <FilterBuilderPopup visible={false} container={"#none"} />
                            <Scrolling mode={'virtual'} />
                            <ColumnChooser enabled={true} width={!isExSmall ? 320 : 'auto'}>
                                <ColumnChooserSearch
                                    enabled={true}
                                />
                            </ColumnChooser>
                            <HeaderFilter visible={true} >
                                <Search
                                    enabled={true}
                                />
                            </HeaderFilter>
                            <Grouping contextMenuEnabled={false} />
                            <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
                            <StateStoring enabled={true} type="localStorage" storageKey="GroupsList_Layout" />
                            <Pager
                                visible={true}
                                showInfo={true}
                                infoText={`{2} Rows`}
                            ></Pager>
                            <Toolbar>
                                <Item location={'before'} locateInMenu='auto'>
                                    {!hideFilterSelectBox ?
                                        <SelectBox
                                            labelMode='floating'
                                            label='Status'
                                            dataSource={eventStatusDropDown}
                                            width={240}
                                            className='mt-0'
                                            displayExpr="name"
                                            valueExpr="value"
                                            value={eventStatus}
                                            onValueChange={(e) => setEventStatus(e)}
                                        />
                                        :
                                        <div>
                                        </div>
                                    }
                                </Item>
                                <Item location={'after'} name="columnChooserButton" locateInMenu='auto' />
                                <Item location={'after'} name="searchPanel" locateInMenu='auto' />

                            </Toolbar>
                            <Column
                                dataField='eventID'
                                groupIndex={0}
                                minWidth={50}
                                width={100}
                                allowGrouping={false}
                                groupCellRender={EventIDRender}
                            />
                            <Column
                                dataField='eventName'
                                visible={false}
                                showInColumnChooser={false}
                            />
                            <Column
                                caption='Category'
                                dataField='eventGroupCategoryID'
                                minWidth={50}
                                width={100}
                                allowGrouping={false}
                                groupIndex={1}
                                groupCellRender={CategoryCellRender}
                            />
                            <Column
                                caption='Group Name'
                                cellRender={eventGroupName}
                                dataField='eventGroupName'
                                minWidth={120}
                                allowGrouping={false}
                                allowHiding={false}
                            />
                            <Column
                                caption='First Name'
                                dataField='firstName'
                                minWidth={120}
                                width={250}
                            />
                            <Column
                                caption='Last Name'
                                dataField='lastName'
                                minWidth={120}
                                width={250}
                            />
                            <Column
                                caption='Email ID'
                                dataField='emailID'
                                minWidth={120}
                                width={280}
                            />
                            <Column
                                caption='Mobile No.'
                                dataField='mobileNo'
                                minWidth={120}
                                width={200}
                            />
                            <Column
                                dataField="createdByUserName"
                                caption="Created By"
                                visible={false}
                                width={125}
                                minWidth={80}
                                alignment="left"
                            />
                            <Column
                                dataField="updatedByUserName"
                                caption="Updated By"
                                visible={false}
                                width={125}
                                minWidth={80}
                                alignment="left"
                            />
                            <Column
                                dataField="createdOn"
                                caption="Created On"
                                visible={false}
                                width={145}
                                minWidth={80}
                                alignment="left"
                                sortingMethod={sortingMethodDate}
                            />
                            <Column
                                dataField="updatedOn"
                                caption="Updated On"
                                visible={false}
                                width={145}
                                minWidth={80}
                                alignment="left"
                                sortingMethod={sortingMethodDate}
                            />
                            <Column type='buttons' fixed={false} allowResizing={false} width={100} allowReordering={false}>
                                <DataGridButton
                                    name='Dashboard'
                                    icon='home'
                                    hint='Dashboard'
                                    visible={true}
                                    cssClass='text-success'
                                    onClick={NavigateHome}
                                />
                                <DataGridButton
                                    name='delete'
                                    icon='trash'
                                    hint='Delete'
                                    visible={true}
                                    cssClass='text-danger'
                                    onClick={onDeleteButtonClick}
                                />
                                {/* <DataGridButton
                                    name='editRegistrationfForm'
                                    icon='EditRegistrationformIcon'
                                    hint='Group Registration Form'
                                    visible={true}
                                    onClick={onShowGroupRegistrationFormDesignerPopup}
                                /> */}

                            </Column>
                        </DataGrid>
                    </div>
                </div>
            </ScrollView>
            {showEventGroupPopup &&
                <EventGroupPopup
                    popupShow={showEventGroupPopup}
                    popupClose={onCloseEventGroupPopup}
                    primaryKey={primaryKey}
                    setStatus={setStatus}
                    currentEventID={currentEventID}
                    currentGroupCategoryID={currentGroupCategoryID}
                />
            }
            {ShowGroupRegistrationFormDesignerPopup &&
                <RegistrationFormDesigner
                    popupShow={ShowGroupRegistrationFormDesignerPopup}
                    popupClose={onCloseGroupRegistrationFormDesignerPopup}
                    EventDetail={SelectedEventDetail}
                    setStatus={setGroupStatus}
                />
            }
        </div>
    )
}

export default EventGroupList