import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './RegistrationPage.scss';

const RegistrationClosed = () => {

    const { state } = useLocation();

    const {  EventName, FormattedClosingMessage } = state || {};

    return (
        <div className='row registration-closed-page'>
            <div className='text-center mb-2'><i className='dx-icon-warning warning-icon'></i></div>
            <div className='text-center my-2 fw-semibold message'>{EventName}
            <br/>
            <div className={``} dangerouslySetInnerHTML={{ __html: FormattedClosingMessage }}>
                </div>
            </div>
          
        </div>
    )
}

export default RegistrationClosed;