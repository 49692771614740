import React, { useEffect, useRef, useState } from "react";
import "./home.scss";
import { Header } from "../../components";
import { useScreenSize } from "../../utils/media-query";
import {
  ApprovedIcon,
  EmptyImage,
  EventCompleted,
  EventLive,
  GroupsIcon,
  MediaIcon,
  PrintedIcon,
  RegistrationClosedIcon,
  RegistrationOpenIcon,
  ResendEmailIcon,
  StartSoon,
  TickIcon,
  VisitorsIcon,
  pendingIcon,
  rejectedIcon,
} from "../../utils/base-64-Icons";
import TabPanel, { Item as TabItem } from "devextreme-react/tab-panel";
import DataGrid, {
  Column,
  Sorting,
  FilterPanel,
  Scrolling,
  HeaderFilter,
  SearchPanel,
  FilterBuilderPopup,
  Pager,
  Toolbar,
  Item,
  Search,
  Grouping,
  Selection,
  ColumnChooser,
  ColumnChooserSearch,
} from "devextreme-react/data-grid";
import {
  CustomUseEffect,
  GetListFormattedDateTime,
  HideDatagridLoader,
  ShowAlert,
  sortingMethodDate,
} from "../../utils/common-methods";
import { SelectBox, Button, Toast, LoadPanel } from "devextreme-react";
import DropDownButton from "devextreme-react/drop-down-button";
import { EventService } from "../../api/services/eventService";
import { ScrollView } from "devextreme-react";
import {
  CardSkeleton,
  LeftGridSkeleton,
  RightListSkeleton,
} from "./DashBoardSkeleton";
import { EventGroupService } from "../../api/services/eventgroupService";
import { RegistrationFormDesignerService } from "../../api/services/registrationFormDesignerService";
import { RegistrationAuthorizedService, eState } from "../../api/services/registrationAuthorizedService";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import EmailTemplate from "./EmailTemplate";
import SingleRejectionEmailTemplate from "./SingleRejectionEmailTemplate";
import { confirm } from "devextreme/ui/dialog";

const dropDownOptionsWidth = { width: 120 };

const DownloadItems = [
  { id: 1, name: "Photos" },
  { id: 2, name: "Passport Copies" },
  { id: 3, name: "QID Copies" },
  { id: 4, name: "AIP Copies" },
];

const exportToOptions = [
  { id: 1, name: "Excel" },
  { id: 2, name: "CSV" },
];

const RegistrationStatusItems = [
  { id: 1, name: "All" },
  { id: 2, name: "Pending" },
  { id: 3, name: "Approved" },
  { id: 4, name: "Printed" },
  { id: 5, name: "Rejected" },
];



const GridRowDownloadItems = [
  { id: 1, name: "Photo" },
  { id: 2, name: "Passport" },
  { id: 3, name: "QID" },
];

const eventService = new EventService();
const eventGroupService = new EventGroupService();
const registrationFormDesignerService = new RegistrationFormDesignerService();
const registrationAuthorizedService = new RegistrationAuthorizedService();

const Home = () => {
  const { isMedium, isLarge, isExLarge } = useScreenSize();

  const scrollViewRef = useRef(null);

  const setAsOption = {
    pending: false,
    approved: false,
    printed: false,
    rejected: false
  }


  const [focusCardIndex, setfocusCardIndex] = useState(0);
  const [showCardSkeleton, setShowCardSkeleton] = useState(false);
  const [showLeftGridSkeleton, setShowLeftGridSkeleton] = useState(false);
  const [showTopSection, setShowTopSection] = useState(true);
  const [eventStatus, setEventStatus] = useState(1);
  const [dsEventSummary, setDsEventSummary] = useState([]);
  const [dsGroupsData, setDsGroupData] = useState([]);
  const [dsGroupDashboard, setDsGroupDashboard] = useState([]);
  const [dsVisitor, setDsVisitor] = useState([]);
  const [dsMedia, setDsMedia] = useState([]);
  const [HideButton, setHideButton] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [filterVisible, setFilterVisible] = useState(false);
  const [GroupFormData, setGroupFormData] = useState(null);
  const [focusedRowKeyGroupsFilter, setFocusedRowKeyGroupsFilter] = useState(null);
  const [groupRegistrationFormID, setGroupRegistrationFormID] = useState(undefined);
  const [isEventGroupCategoryChange, setIsEventGroupCategoryChange] = useState(true);
  const [eventGroupCategoryName, setEventGroupCategoryName] = useState(null);
  const [GridState, setGridState] = useState(null)
  const [registrationStatus, setRegistrationStatus] = useState(1);
  const [visitorRegistrationStatus, setVisitorRegistrationStatus] = useState(1);
  const [mediaRegistrationStatus, setMediaRegistrationStatus] = useState(1);
  const [selectedRowKeys, setselectedRowKeys] = useState([]);
  const [visitorSelectedRowKeys, setVisitorSelectedRowKeys] = useState([]);
  const [mediaSelectedRowKeys, setMediaSelectedRowKeys] = useState([]);
  const [selectedRegistrationIds, setSelectedRegistrationIds] = useState([]);
  const [visitorSelectedRegistrationIds, setVisitorSelectedRegistrationIds] = useState([]);
  const [mediaSelectedRegistrationIds, setMediaSelectedRegistrationIds] = useState([]);
  const [disableButtons, setDisableButtons] = useState(true)
  const [selectedTab, setselectedTab] = useState(0);
  const [visitorDisableButtons, setVisitorDisableButtons] = useState(true)
  const [mediaDisableButtons, setMediaDisableButtons] = useState(true)
  const [groupComponents, setGroupComponents] = useState([]);
  const [selectedEventDetails, setSelectedEventDetails] = useState({
    eventID: null,
    eventName: null,
    eventLogo: null,
    visitorRegistrationFormID: null,
    mediaRegistrationFormID: null,
    autoEmailOnApproval: false,
    autoEmailOnRejection: false,
    autoEmailOnPrinted: false,
  });
  const [selectedRecordDetails, setSelectedRecordDetails] = useState({
    emailID: null,
    registrationID: null
  })
  const [isGroupColumns, setIsGroupColumn] = useState(false);
  const [hideTabPanel, setHideTabPanel] = useState(false);
  const [showEmailTemplateOnSingleRejection, setShowEmailTemplateOnSingleRejection] = useState(false);
  const [isRejectionVisibleLoadPanel, setIsRejectionVisibleLoadPanel] = useState(false);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);
  const [selectedGroupsRegistration, setSelectedGroupsRegistration] = useState();
  const [isLoadPanelVisible, setIsVisibleLoadPanel] = useState(false);
  const [rowFocused, setRowFocused] = useState(false);
  const [disableSetasOptions, setDisableSetasOptions] = useState(setAsOption);
  const [disableSetasOptionsVisitor, setDisableSetasOptionsVisitor] = useState(setAsOption);
  const [disableSetasOptionsMedia, setDisableSetasOptionsMedia] = useState(setAsOption);
  const [ToastStatus, setToastStatus] = useState({
    visibleToast: false,
    message: null,
    type: null
  })
  const [refreshCards, setRefreshCards] = useState(false);
  const [checkrefreshClick, setCheckrefreshClick] = useState(false);
  const { isExSmall, isXXSmall } = useScreenSize();

  const GroupFilterDataGridRef = useRef(null)
  const GroupRegisDataGridRef = useRef(null);
  const VisitorsDataGridRef = useRef(null);
  const MediaDataGridRef = useRef(null);

  const SetAsItems = [
    { id: 1, name: "Pending", disabled: disableSetasOptions.pending },
    { id: 2, name: "Approved", disabled: disableSetasOptions.approved },
    { id: 3, name: "Printed", disabled: disableSetasOptions.printed },
    { id: 4, name: "Rejected", disabled: disableSetasOptions.rejected },
  ];

  const SetAsItemsVisitors = [
    { id: 1, name: "Pending", disabled: disableSetasOptionsVisitor.pending },
    { id: 2, name: "Approved", disabled: disableSetasOptionsVisitor.approved },
    { id: 3, name: "Printed", disabled: disableSetasOptionsVisitor.printed },
    { id: 4, name: "Rejected", disabled: disableSetasOptionsVisitor.rejected },
  ];

  const SetAsItemsMedia = [
    { id: 1, name: "Pending", disabled: disableSetasOptionsMedia.pending },
    { id: 2, name: "Approved", disabled: disableSetasOptionsMedia.approved },
    { id: 3, name: "Printed", disabled: disableSetasOptionsMedia.printed },
    { id: 4, name: "Rejected", disabled: disableSetasOptionsMedia.rejected },
  ];

  const RowButtonsCellRender = (e) => {

    let regisID = e?.data.registrationID;
    let regisStatus = e?.data.registrationStatus;
    let emailID = e?.data.emailID

    let GridRowUpdateStatusItems;

    if (regisStatus === 0) {
      GridRowUpdateStatusItems = [
        { id: 1, name: "Approved" },
        { id: 2, name: "Set as Printed" },
        { id: 3, name: "Rejected" },
      ]
    } else if (regisStatus === 1) {
      GridRowUpdateStatusItems = [
        { id: 1, name: "Pending" },
        { id: 2, name: "Set as Printed" },
        { id: 3, name: "Rejected" },
      ]
    } else if (regisStatus === 2) {
      GridRowUpdateStatusItems = [
        { id: 1, name: "Pending" },
        { id: 2, name: "Approved" },
        { id: 3, name: "Rejected" },
      ]
    } else if (regisStatus === 100) {
      GridRowUpdateStatusItems = [
        { id: 1, name: "Pending" },
        { id: 2, name: "Approved" },
        { id: 3, name: "Set as Printed" },
      ]
    } else {
      GridRowUpdateStatusItems = [
        { id: 1, name: "Pending" },
        { id: 2, name: "Approved" },
        { id: 3, name: "Set as Printed" },
        { id: 4, name: "Rejected" }
      ]
    }

    const ResendEmail = () =>{
      if(regisStatus === 100){
        SendRejectionEmail(regisID, emailID)
      }else{
        SendEmailOnStatusChange(regisID, true)
      }
    }

    return (
      <>
        <DropDownButton
          text="Update Status"
          displayExpr="name"
          keyExpr="id"
          className="DownloadDropdownButton GridRowDropdown pe-1 DropdownButtonOrange"
          items={GridRowUpdateStatusItems}
          onItemClick={(e) => onUpdateStatusByRowItemClick(e, regisID, emailID)}
        />{" "}
        {
          regisStatus !== 0 &&
        <Button
          hint="Resend Email"
          displayExpr="name"
          icon={ResendEmailIcon}
          keyExpr="id"
          type="normal"
          className="rounded-3 registration-deleteBtn"
          onClick={ResendEmail}
          stylingMode="text"
        />
        }
        {
          regisStatus === 0 &&
          <Button
            hint="Delete"
            icon="trash"
            type="danger"
            stylingMode="text"
            className="rounded-3 registration-deleteBtn"
            onClick={() => onDeleteButtonClick(regisID)}
          />
        }
      </>
    );
  };


  const convertToSummaryStatus = (data) => {
    const newData = data.map((event) => {
      if (event.summaryStatus.length === 0) {
        event.summaryStatus = Array.from({ length: 3 }, (_, i) => ({
          eventID: event.eventID,
          registrationTypeID: i + 1,
          pending: 0,
          approved: 0,
          printed: 0,
          rejected: 0,
          total: 0,
        }));
      } else {
        const newSummaryStatus = [];
        for (let i = 0; i < 3; i++) {
          // Assuming there are always 3 registration types
          const registrationType = event.summaryStatus.find(
            (status) => status.registrationTypeID === i + 1
          );
          if (registrationType) {
            newSummaryStatus.push({
              eventID: registrationType.eventID,
              registrationTypeID: i + 1,
              pending: registrationType.pending || 0,
              approved: registrationType.approved || 0,
              printed: registrationType.printed || 0,
              rejected: registrationType.rejected || 0,
              total: registrationType.total || 0,
            });
          } else {
            newSummaryStatus.push({
              eventID: event.eventID,
              registrationTypeID: i + 1,
              pending: 0,
              approved: 0,
              printed: 0,
              rejected: 0,
              total: 0,
            });
          }
        }
        event.summaryStatus = newSummaryStatus;
      }
      return event;
    });
    return newData;
  };

  const GetEventSummryDataByID = async () => {
    const result = await eventService.GetEventSummaryByID(eventStatus, selectedEventDetails.eventID);

    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setShowCardSkeleton(false);
    } else {
      setDsEventSummary(
        dsEventSummary.map((i) => {
          if (i.eventID === selectedEventDetails.eventID) {
            const updatedEvent = convertToSummaryStatus(result.data)[0];
            updatedEvent.eventLogo = i.eventLogo; // previous eventLogo
            return updatedEvent;
          } else {
            return i;
          }
        })
      );
    }
  };

  const GetEventSummaryData = async (focusCardIndex) => {
    if(selectedTab === 0){
      setShowSkeleton(true);
    }
    setShowCardSkeleton(true);
    setShowLeftGridSkeleton(true);
    setHideTabPanel(false)
    const result = await eventService.GetEventSummary(eventStatus);
    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setShowCardSkeleton(false);
    } else if (result.data.length > 0) {
      const newData = convertToSummaryStatus(result.data);
      if (focusCardIndex) {
        setSelectedEventDetails({
          eventID: result.data[focusCardIndex]?.eventID,
          eventName: result.data[focusCardIndex]?.eventName,
          eventLogo: null,
          visitorRegistrationFormID: result.data[focusCardIndex]?.visitorRegistrationFormID,
          mediaRegistrationFormID: result.data[focusCardIndex]?.mediaRegistrationFormID,
          autoEmailOnApproval: result.data[focusCardIndex]?.autoEmailOnApproval,
          autoEmailOnRejection: result.data[focusCardIndex]?.autoEmailOnRejection,
          autoEmailOnPrinted: result.data[focusCardIndex]?.autoEmailOnPrinted

        });
      } else {
        setSelectedEventDetails({
          eventID: result.data[0]?.eventID,
          eventName: result.data[0]?.eventName,
          eventLogo: null,
          visitorRegistrationFormID: result.data[0]?.visitorRegistrationFormID,
          mediaRegistrationFormID: result.data[0]?.mediaRegistrationFormID,
          autoEmailOnApproval: result.data[0]?.autoEmailOnApproval,
          autoEmailOnRejection: result.data[0]?.autoEmailOnRejection,
          autoEmailOnPrinted: result.data[0]?.autoEmailOnPrinted

        });
      }
      setShowCardSkeleton(false);
      setDsEventSummary(newData)
      const updatedData = await Promise.all(newData.map(async event => {
        const res = await GetEventLogoThumbnailData(event.eventID);
        event.eventLogo = res?.size > 0 ? window.URL.createObjectURL(res) : null;
        return event;
      }));
      setDsEventSummary(updatedData)
      if(focusCardIndex){
        scrollViewRef.current.instance.scrollToElement(document.querySelector(`#card-${focusCardIndex}`));
      }
    } else {
      setShowCardSkeleton(false);
      setShowLeftGridSkeleton(false);
      setDsEventSummary(result.data)
      setHideTabPanel(true)
    }
  };


  const GetGroupListRecordWithFilterData = async () => {

    const result = await eventGroupService.GetListRecordWithFilter(
      eventStatus
    );

    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setShowLeftGridSkeleton(false);
    } else {
      var NewRowForAllGroups = { eventGroupID: 0, eventGroupName: "All Groups", eventGroupCategoryName: "", groupRegistrationFormID: 0 }
      var dsGroup = result?.data.filter((x) => x.eventID === selectedEventDetails.eventID)
      if (dsGroup.length > 0) {
        setDsGroupData([NewRowForAllGroups, ...dsGroup]);
        setFocusedRowKeyGroupsFilter(checkrefreshClick ? focusedRowKeyGroupsFilter : 0)
        setGroupRegistrationFormID(checkrefreshClick ? groupRegistrationFormID : 0)
        setEventGroupCategoryName(0)
        setRowFocused(!rowFocused)
        setCheckrefreshClick(false)
      }else{
        setFocusedRowKeyGroupsFilter(null)
        setGroupRegistrationFormID(null)
        setEventGroupCategoryName(null)
        setDsGroupData([]);
        setIsGroupColumn(false)
      }
      setShowLeftGridSkeleton(false);
      // setFocusedRowKeyGroupsFilter(dsGroup[0]?.eventGroupID)
    }
  };

  const GetEventLogoThumbnailData = async (eventID) => {
    const result = await eventService.GetEventLogoThumbnail(eventID)
    let response = result.data
    if (response) {
      return response.blob()
    } else {
      return;
    }
  }

  const refreshCard = () => {
    setRefreshCards(!refreshCards)
    setCheckrefreshClick(true)
    setRowFocused(false)
    GetEventSummaryData(focusCardIndex);
  }

  CustomUseEffect(() => {
    if (selectedEventDetails.eventID) {
      setShowLeftGridSkeleton(true);
      GetGroupListRecordWithFilterData();
    }
  }, [selectedEventDetails.eventID, refreshCards]);

  useEffect(() => {
    GetEventSummaryData();
  }, [eventStatus]);

  const onMultipleDownloadItemClick = (e) => {
    if (e.itemData.id === 1) {
      DownloadPhotos(e);
    } else if (e.itemData.id === 2) {
      DownloadPassPortCopies(e)
    } else if (e.itemData.id === 3) {
      DownloadQIDCopies(e)
    } else if (e.itemData.id === 4) {
      DownloadAIPCopies(e)
    }
  };


  const onUpdateStatusByRowItemClick = (e, regisID, emailID) => {

    if (e.itemData.name && regisID) {
      UpdateStatusByRow(e.itemData.name, regisID, emailID);
    }
  };

  const onSetAsItemClick = (e) => {
    var selectedRows;
    var selectedRowData;
    if (selectedTab === 0) {
      selectedRows = GroupRegisDataGridRef.current?.instance.getSelectedRowKeys();
      selectedRowData = GroupRegisDataGridRef.current?.instance.getSelectedRowsData();
    } else if (selectedTab === 1) {
      selectedRows = VisitorsDataGridRef.current?.instance.getSelectedRowKeys();
      selectedRowData = VisitorsDataGridRef.current?.instance.getSelectedRowsData();
    } else if (selectedTab === 2) {
      selectedRows = MediaDataGridRef.current?.instance.getSelectedRowKeys();
      selectedRowData = MediaDataGridRef.current?.instance.getSelectedRowsData();
    }

    if (selectedRows && selectedRows.length >= 1) {
      var filteredData = selectedRowData.filter(i => i.registrationStatusName !== e.itemData.name)
      setSelectedGroupsRegistration(filteredData)
    }
    if (e.itemData.id) {
      UpdateStatus(e.itemData.id);
    }
  }

  const FocusCard = (data, index) => {
    setfocusCardIndex(index);
    setIsEventGroupCategoryChange(true)
    if (data?.eventID !== selectedEventDetails.eventID && selectedTab === 0) {
      setShowSkeleton(true)
    }
    setSelectedEventDetails({
      eventID: data?.eventID,
      eventName: data?.eventName,
      eventLogo: null,
      visitorRegistrationFormID: data?.visitorRegistrationFormID,
      mediaRegistrationFormID: data?.mediaRegistrationFormID,
      autoEmailOnApproval: data?.autoEmailOnApproval,
      autoEmailOnRejection: data?.autoEmailOnRejection,
      autoEmailOnPrinted: data?.autoEmailOnPrinted,
    });
  };

  const GroupCell = (options) => <div>{options.value}</div>;

  function onFocusedRowChangedGroupsFilter(e) {
    let newEventGroupCategory = e.row.data.eventGroupCategoryName;

    setEventGroupCategoryName(newEventGroupCategory)
    if (eventGroupCategoryName === newEventGroupCategory) {
      setIsEventGroupCategoryChange(false)
    } else {
      setIsEventGroupCategoryChange(true)
    }
    if (e.row.data?.groupRegistrationFormID === 0) {
      setIsGroupColumn(true)
    } else {
      setIsGroupColumn(false)
    }
    setGroupRegistrationFormID(e.row.data?.groupRegistrationFormID)
    setFocusedRowKeyGroupsFilter(e.component.option("focusedRowKey"));
  }

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };


  const GetFormDesignerData = async (showLoadPanel) => {
    if (groupRegistrationFormID === null || groupRegistrationFormID === undefined) {
      return
    }

    if (!showLoadPanel) {
      setShowSkeleton(true);
    }

    const result =
      await registrationFormDesignerService.GetRecord(groupRegistrationFormID);
    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setShowSkeleton(false);
    } else {
      setGroupFormData(result.data);
      GetListWithFilter(result.data.components);
    }
  };

  const GetAllGroupRegistrationFormDesignData = async (showLoadPanel) => {
    if (groupRegistrationFormID === null || groupRegistrationFormID === undefined) {
      return
    }

    if (!showLoadPanel) {
      setShowSkeleton(true);
    }

    const result =
      await registrationFormDesignerService.GetAllGroupRegistrationFormDesign(selectedEventDetails.eventID);
    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setShowSkeleton(false);
    } else {
      // setGroupFormData(result.data);
      setGroupComponents(result.data)
      GetListWithFilter(result.data);
    }
  };

  useEffect(() => {
    if(groupRegistrationFormID !== undefined && selectedTab === 0 && groupRegistrationFormID !== null ){
      if(groupRegistrationFormID === 0){
        GetAllGroupRegistrationFormDesignData();
      } else {
        if (isEventGroupCategoryChange){
          GetFormDesignerData();
        } else {
          GetListWithFilter(GroupFormData?.components)
        }
      }
    } else if (groupRegistrationFormID === null) {
      setGridState(eState.None)
    }
  }, [groupRegistrationFormID, focusedRowKeyGroupsFilter, registrationStatus, selectedTab, rowFocused]);

  const GetListWithFilter = async (Components, showLoadPanel) => {
    if (!showLoadPanel) {
      setShowSkeleton(true)
    }
    let regStatus;
    if (registrationStatus === 1) {
      regStatus = null;
    } else if (registrationStatus === 2) {
      regStatus = 0;
    } else if (registrationStatus === 3) {
      regStatus = 1
    } else if (registrationStatus === 4) {
      regStatus = 2
    } else if (registrationStatus === 5) {
      regStatus = 100
    }

    const result = await registrationAuthorizedService.GetListWithFilter(
      selectedEventDetails.eventID,
      3,
      focusedRowKeyGroupsFilter,
      null,
      regStatus
    );
    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setShowSkeleton(false);
    } else {
      const inst = GroupRegisDataGridRef.current?.instance;
      let dataForColumns = [];

      // If Form Designer created
      if (Components && Components.length > 0 && result.data.length === 0) {
        inst.beginUpdate();
        Components?.map((r) => {
          let dataToPush = {
            registrationID: 0,
            registrationStatusName: null,
            index: r.index,
            componentID: r.componentID,
            arrivalDateTime: null,
            departureDateTime: null,
            passportExpiryDate: null,
            passportIssueDate: null,
            dob: null,
          };

          if (r.componentID === 1) {
            dataToPush.firstName = null;
          }
          if (r.componentID === 2) {
            dataToPush.middleName = null;
          }
          if (r.componentID === 3) {
            dataToPush.lastName = null;
          }
          if (r.componentID === 4) {
            dataToPush.fullName = null;
          }
          if (r.componentID === 5) {
            dataToPush.function = null;
          }
          if (r.componentID === 6) {
            dataToPush.club = null;
          }
          if (r.componentID === 7) {
            dataToPush.comments = null;
          }
          if (r.componentID === 8) {
            dataToPush.company = null;
          }
          if (r.componentID === 9) {
            dataToPush.nationalityName = null;
          }
          if (r.componentID === 10) {
            dataToPush.gender = null;
          }
          if (r.componentID === 11) {
            dataToPush.dob = null;
          }
          if (r.componentID === 12) {
            dataToPush.photo = null;
          }
          if (r.componentID === 13) {
            dataToPush.passportNo = null;
          }
          if (r.componentID === 14) {
            dataToPush.passportCopy = null;
          }
          if (r.componentID === 15) {
            dataToPush.passportIssueDate = null;
          }
          if (r.componentID === 16) {
            dataToPush.passportExpiryDate = null;
          }
          if (r.componentID === 17) {
            dataToPush.qidNo = null;
          }
          if (r.componentID === 18) {
            dataToPush.qidCopy = null;
          }
          if (r.componentID === 19) {
            dataToPush.emailID = null;
          }
          if (r.componentID === 20) {
            dataToPush.mobileNo = null;
          }
          if (r.componentID === 21) {
            dataToPush.whatsappNo = null;
          }
          if (r.componentID === 22) {
            dataToPush.aipNo = null;
          }
          if (r.componentID === 23) {
            dataToPush.aipCopy = null;
          }
          if (r.componentID === 24) {
            dataToPush.mediaTypeName = null;
          }
          if (r.componentID === 25) {
            dataToPush.mediaName = null;
          }
          if (r.componentID === 26) {
            dataToPush.mediaFunctionName = null;
          }
          if (r.componentID === 27) {
            dataToPush.mediaAddress = null;
          }
          if (r.componentID === 28) {
            dataToPush.arrivalDateTime = null;
          }
          if (r.componentID === 29) {
            dataToPush.arrivalFlightNo = null;
          }
          if (r.componentID === 30) {
            dataToPush.departureDateTime = null;
          }
          if (r.componentID === 31) {
            dataToPush.departureFlightNo = null;
          }
          dataForColumns.push(dataToPush);
        });
        inst.endUpdate();
        setHideButton(true);
        setTimeout(() => {
          setGridState(eState.FormDesign);
        }, 500)


      } else if (
        ((Components && Components.length === 0) || Components === undefined) &&
        result.data.length === 0
      ) {
        // If no form designer is created
        let dataToPush = {
          registrationID: 0,
          arrivalDateTime: null,
          departureDateTime: null,
          passportExpiryDate: null,
          passportIssueDate: null,
          dob: null,
        };
        dataForColumns.push(dataToPush);
        setHideButton(false);
        setTimeout(() => {
          setGridState(eState.None)
        }, 500)


      }
      // If Registration form is created

      if (result.data.length > 0) {
        result.data.map((i) => {
          const arrivalDateTime = i.arrivalDateTime ? GetListFormattedDateTime(i.arrivalDateTime) : null;
          const departureDateTime = i.departureDateTime ? GetListFormattedDateTime(i.departureDateTime) : null;
          const passportExpiryDate = i.passportExpiryDate ? GetListFormattedDateTime(i.passportExpiryDate) : null;
          const passportIssueDate = i.passportIssueDate ? GetListFormattedDateTime(i.passportIssueDate) : null;
          const dob = i.dob ? GetListFormattedDateTime(i.dob) : null;
          dataForColumns.push({
            ...i,
            arrivalDateTime: arrivalDateTime ? arrivalDateTime : null,
            departureDateTime: departureDateTime ? departureDateTime : null,
            passportExpiryDate: passportExpiryDate ? passportExpiryDate : null,
            passportIssueDate: passportIssueDate ? passportIssueDate : null,
            dob: dob ? dob : null,
          })
        });
        setHideButton(true);
        setTimeout(() => {
          setGridState(eState.FormCreated);
        }, 500)

      }
      setDsGroupDashboard(dataForColumns);
    }
  };

  useEffect(() => {

    if (GridState !== null) {
      const inst = GroupRegisDataGridRef.current?.instance;
      if (GridState === eState.None) {

        inst.beginUpdate();
        //  if (dsGroupDashboard.length > 0) {
        setDsGroupDashboard([]);
        const allColumns = inst.option('columns').filter(r => !r.name.includes('default'))
        allColumns.forEach(function (column) {
          // Check if either the name or dataField exists in the list of objects                     
          if (column.name) {
            inst.columnOption(column.name, "visible", false);
            inst.columnOption(column.name, "showInColumnChooser", false);
          }
        });
        //  }
        inst.endUpdate();
      }
      else if (GridState === eState.FormDesign) {
        if (dsGroupDashboard.length > 0) {
          inst.beginUpdate();
          const allColumns = inst.option('columns').filter(r => !r.name.includes('default'))

          allColumns.forEach(function (column) {

            if (column.name) {
              inst.columnOption(column.name, "visible", false);
              inst.columnOption(column.name, "showInColumnChooser", false);
            }
          });

          allColumns.forEach(function (column) {
            // Check if either the name or dataField exists in the list of objects
            dsGroupDashboard.map(function (obj) {

              if (column.name && obj.componentID === parseInt(column.name)) {
                inst.columnOption(column.name, "visibleIndex", obj.index);
                inst.columnOption(column.name, "visible", true);
                inst.columnOption(column.name, "showInColumnChooser", true);
              }
            });
          });

          setDsGroupDashboard([]);
          inst.endUpdate();

        }
      }
      else if (GridState === eState.FormCreated) {
        let component;
        if (groupRegistrationFormID === 0) {
          component = groupComponents
        } else {
          component = GroupFormData?.components
        }
        inst.beginUpdate();
        UpdateComponentValue(component, inst);

        inst.endUpdate();

        if (isLoadPanelVisible) {
          setIsVisibleLoadPanel(false)
          setShowEmailTemplate(!showEmailTemplate)
        }
      }

      setGridState(null);
      if (dsGroupDashboard.length > 0) {
        inst.getDataSource().reload()
      }

      setShowSkeleton(false);

      GroupRegisDataGridRef?.current?.instance.repaint()

    }
  }, [GridState])

  const UpdateComponentValue = (Components, gridInstance) => {

    const allColumns = gridInstance.option('columns').filter(r => !r.name?.includes('default'));
    allColumns.forEach(function (column) {
      if (column.name) {
        gridInstance.columnOption(column.name, "visible", false);
        gridInstance.columnOption(column.name, "showInColumnChooser", false);
      }
    });

    allColumns.forEach(function (column) {
      Components.map(function (obj) {
        if (column.name && obj.componentID === parseInt(column.name)) {
          gridInstance.columnOption(column.name, "visibleIndex", obj.index);
          gridInstance.columnOption(column.name, "visible", true);
          gridInstance.columnOption(column.name, "showInColumnChooser", true);
        }
      });
    });

  }

  const RegistrationTypeCellRender = (e) => {
    if (e.value === 1) {
      return <span className="fw-medium">Visitor</span>;
    } else if (e.value === 2) {
      return <span className="fw-medium">Media</span>;
    } else if (e.value === 3) {
      return <span className="fw-medium">Groups</span>;
    }
  };

  const onExportItemClick = (e, dataGridRef, fileName) => {

    if (e.itemData.id === 1) {
      var workbook = new Workbook();
      var worksheet = workbook.addWorksheet("SheetName");
      exportDataGrid({
        component: dataGridRef.current?.instance,
        worksheet: worksheet,
      }).then(function () {
        //var fileName = camelCase(props.headerTitle);
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName + ".xlsx"
          );
        });
      });
    }
    else if (e.itemData.id === 2) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Report");
      exportDataGrid({
        component: dataGridRef.current?.instance,
        worksheet: worksheet,
      }).then(function () {
        //var fileName = camelCase(props.headerTitle);
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName + ".csv"
          );
        });
      });
    }

  };

  const onRegistrationValueChange = (e) => {
    setRegistrationStatus(e)

  }
  const onVisitorRegistrationValueChange = (e) => {
    setVisitorRegistrationStatus(e)

  }

  const onMediaRegistrationValueChange = (e) => {
    setMediaRegistrationStatus(e)

  }

  const DownloadPhotos = async (e) => {
    let RegistrationIDs;
    if (selectedTab === 0) {
      RegistrationIDs = selectedRegistrationIds;
    } else if (selectedTab === 1) {
      RegistrationIDs = visitorSelectedRegistrationIds;
    } else if (selectedTab === 2) {
      RegistrationIDs = mediaSelectedRegistrationIds;
    }
    const result = await registrationAuthorizedService.DownloadPhotosByIDs(RegistrationIDs);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    } else if (result.statusCode === 204) {
      setToastStatus({
        visibleToast: true,
        message: "No content to download",
        type: 'warning'
      })
    } else {
      const response = await result.data.blob();
      let FileExtension = result.fileExtension;
      let fileName;
      if (result.fileExtension.includes('octet-stream')) {
        fileName = "Photos.zip";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        fileName = "Photos.docx";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        fileName = "Photos.xlsx";
      }
      else {
        fileName = "Photos." + FileExtension.split('/')[1];
      }
      download(response, fileName)
    }
  }

  const DownloadSinglePhoto = async (regisID, PhotoFileName) => {

    const result = await registrationAuthorizedService.DownloadPhoto(regisID);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    } else if (result.statusCode === 204) {
      setToastStatus({
        visibleToast: true,
        message: "No content to download",
        type: 'warning'
      })
    } else {
      const response = await result.data;
      let FileExtension = result.fileExtension;
      let fileName;
      if (result.fileExtension.includes('octet-stream')) {
        fileName = PhotoFileName + ".zip";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        fileName = PhotoFileName + ".docx";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        fileName = PhotoFileName + ".xlsx";
      }
      else {
        fileName = PhotoFileName + "." + FileExtension.split('/')[1];
      }
      download(response, fileName)
    }
  }


  const DownloadPassPortCopies = async (e) => {
    let RegistrationIDs;
    if (selectedTab === 0) {
      RegistrationIDs = selectedRegistrationIds;
    } else if (selectedTab === 1) {
      RegistrationIDs = visitorSelectedRegistrationIds;
    } else if (selectedTab === 2) {
      RegistrationIDs = mediaSelectedRegistrationIds;
    }
    const result = await registrationAuthorizedService.DownloadPassportCopiesByIDs(RegistrationIDs);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    } else if (result.statusCode === 204) {
      setToastStatus({
        visibleToast: true,
        message: "No content to download",
        type: 'warning'
      })
    } else {
      const response = await result.data.blob();
      let FileExtension = result.fileExtension;
      let fileName;
      if (result.fileExtension.includes('octet-stream')) {
        fileName = "PassportCopies.zip";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        fileName = "PassportCopies.docx";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        fileName = "PassportCopies.xlsx";
      }
      else {
        fileName = "PassportCopies." + FileExtension.split('/')[1];
      }
      download(response, fileName)
    }
  }

  const DownloadSignlePassPortCopy = async (regisID, passportFileName) => {
    const result = await registrationAuthorizedService.DownloadPassportCopy(regisID);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    } else if (result.statusCode === 204) {
      setToastStatus({
        visibleToast: true,
        message: "No content to download",
        type: 'warning'
      })
    } else {
      const response = await result.data;
      let FileExtension = result.fileExtension;
      let fileName;
      if (result.fileExtension.includes('octet-stream')) {
        fileName = passportFileName + ".zip";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        fileName = passportFileName + ".docx";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        fileName = passportFileName + ".xlsx";
      }
      else {
        fileName = passportFileName + "." + FileExtension.split('/')[1];
      }
      download(response, fileName)
    }
  }

  const DownloadQIDCopies = async (e) => {
    let RegistrationIDs;
    if (selectedTab === 0) {
      RegistrationIDs = selectedRegistrationIds;
    } else if (selectedTab === 1) {
      RegistrationIDs = visitorSelectedRegistrationIds;
    } else if (selectedTab === 2) {
      RegistrationIDs = mediaSelectedRegistrationIds;
    }
    const result = await registrationAuthorizedService.DownloadQIDCopiesByIDs(RegistrationIDs);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    } else if (result.statusCode === 204) {
      setToastStatus({
        visibleToast: true,
        message: "No content to download",
        type: 'warning'
      })
    } else {
      const response = await result.data.blob();
      let FileExtension = result.fileExtension;
      let fileName;
      if (result.fileExtension.includes('octet-stream')) {
        fileName = "QIDCopies.zip";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        fileName = "QIDCopies.docx";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        fileName = "QIDCopies.xlsx";
      }
      else {
        fileName = "QIDCopies." + FileExtension.split('/')[1];
      }
      download(response, fileName)
    }
  }

  const DownloadSignleQIDCopy = async (regisID, QIDFileName) => {
    const result = await registrationAuthorizedService.DownloadQIDCopy(regisID);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    } else if (result.statusCode === 204) {
      setToastStatus({
        visibleToast: true,
        message: "No content to download",
        type: 'warning'
      })
    } else {
      const response = await result.data;
      let FileExtension = result.fileExtension;
      let fileName;
      if (result.fileExtension.includes('octet-stream')) {
        fileName = QIDFileName + ".zip";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        fileName = QIDFileName + ".docx";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        fileName = QIDFileName + ".xlsx";
      }
      else {
        fileName = QIDFileName + "." + FileExtension.split('/')[1];
      }
      download(response, fileName)
    }
  }

  const DownloadAIPCopies = async (e) => {
    let RegistrationIDs;
    if (selectedTab === 0) {
      RegistrationIDs = selectedRegistrationIds;
    } else if (selectedTab === 1) {
      RegistrationIDs = visitorSelectedRegistrationIds;
    } else if (selectedTab === 2) {
      RegistrationIDs = mediaSelectedRegistrationIds;
    }
    const result = await registrationAuthorizedService.DownloadAIPCopiesByIDs(RegistrationIDs);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    } else if (result.statusCode === 204) {
      setToastStatus({
        visibleToast: true,
        message: "No content to download",
        type: 'warning'
      })
    } else {
      const response = await result.data.blob();
      let FileExtension = result.fileExtension;
      let fileName;
      if (result.fileExtension.includes('octet-stream')) {
        fileName = "AIPCopies.zip";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        fileName = "AIPCopies.docx";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        fileName = "AIPCopies.xlsx";
      }
      else {
        fileName = "AIPCopies." + FileExtension.split('/')[1];
      }
      download(response, fileName)
    }
  }

  const DownloadSignleAIPCopy = async (regisID, AIPFileName) => {
    const result = await registrationAuthorizedService.DownloadAIPCopy(regisID);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    } else if (result.statusCode === 204) {
      setToastStatus({
        visibleToast: true,
        message: "No content to download",
        type: 'warning'
      })
    } else {
      const response = await result.data;
      let FileExtension = result.fileExtension;
      let fileName;
      if (result?.fileExtension?.includes('octet-stream')) {
        fileName = AIPFileName + ".zip";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        fileName = AIPFileName + ".docx";
      } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        fileName = AIPFileName + ".xlsx";
      }
      else {
        fileName = AIPFileName + "." + FileExtension.split('/')[1];
      }
      download(response, fileName)
    }
  }

  const UpdateStatus = async (itemID) => {
    let newStatus;
    if (itemID === 1) {
      newStatus = 0 //for pending
    } else if (itemID === 2) {
      newStatus = 1 //for approved
    } else if (itemID === 3) {
      newStatus = 2 // for printed
    } else if (itemID === 4) {
      newStatus = 100 //for Rejected
    }

    let selectedRegisIds;
    if (selectedTab === 0) {
      selectedRegisIds = selectedRegistrationIds;
    } else if (selectedTab === 1) {
      selectedRegisIds = visitorSelectedRegistrationIds;
    } else if (selectedTab === 2) {
      selectedRegisIds = mediaSelectedRegistrationIds;
    }

    let DataToSend = {
      registrationID: selectedRegisIds,
      newRegistrationStatus: newStatus
    }
    const result = await registrationAuthorizedService.UpdateStatus(DataToSend)
    if (result.statusCode === 400) {
      ShowAlert(result.data)
    } else {

      if (selectedTab === 0) {
        setDisableSetasOptions(setAsOption);
        setSelectedRegistrationIds([]);
        setselectedRowKeys([]);
        if ((itemID === 2 && selectedEventDetails.autoEmailOnApproval)
          || (itemID === 3 && selectedEventDetails.autoEmailOnPrinted)
          || (itemID === 4 && selectedEventDetails.autoEmailOnRejection)
        ) {
          setIsVisibleLoadPanel(true);
          GetListWithFilter(GroupFormData?.components, true)
        } else {
          GetListWithFilter(GroupFormData?.components, false)
        }
      } else if (selectedTab === 1) {
        setVisitorSelectedRowKeys([]);
        setVisitorSelectedRegistrationIds([]);
        setDisableSetasOptionsVisitor(setAsOption);
        if ((itemID === 2 && selectedEventDetails.autoEmailOnApproval)
          || (itemID === 3 && selectedEventDetails.autoEmailOnPrinted)
          || (itemID === 4 && selectedEventDetails.autoEmailOnRejection)
        ) {
          setIsVisibleLoadPanel(true);
          GetVisitorListWithFilter(visitorFormData?.components, true)
        } else {
          GetVisitorListWithFilter(visitorFormData?.components, false)
        }
      } else if (selectedTab === 2) {
        setMediaSelectedRowKeys([]);
        setMediaSelectedRegistrationIds([]);
        setDisableSetasOptionsMedia(setAsOption);
        if ((itemID === 2 && selectedEventDetails.autoEmailOnApproval)
          || (itemID === 3 && selectedEventDetails.autoEmailOnPrinted)
          || (itemID === 4 && selectedEventDetails.autoEmailOnRejection)
        ) {
          setIsVisibleLoadPanel(true);
          GetMediaListWithFilter(mediaFormData?.components, true)
        } else {
          GetMediaListWithFilter(mediaFormData?.components, false)
        }
      }
      GetEventSummryDataByID();

    }
  }

  const SendRejectionEmail = (regisID,emailID) =>{
    setSelectedRecordDetails({
      emailID: emailID,
      registrationID: regisID
    })
    setShowEmailTemplateOnSingleRejection(!showEmailTemplateOnSingleRejection)
  }

  const SendEmailOnStatusChange = async (regisID, isResendEmail) => {
    if(isResendEmail){
      setIsVisibleLoadPanel(true)
    }
    const result = await registrationAuthorizedService.SendEmailOnStatusChange(regisID)
    if (result.statusCode === 400) {
      setIsVisibleLoadPanel(false)
      setToastStatus({
        visibleToast: true,
        message: result.data,
        type: 'warning'
      })
    }else if(result.statusCode === 500 ){
      setIsVisibleLoadPanel(false)
        setToastStatus({
          visibleToast: true,
          message: "Something went wrong while sending email, contact your administrator.",
          type: 'warning'
        })
    }
    else if(result.isOk){
      setIsVisibleLoadPanel(false)
        setToastStatus({
          visibleToast: true,
          message: "Email sent successfully",
          type: 'success'
        })
     
    }
  }

  const UpdateStatusByRow = async (itemName, regisID, emailID) => {
    let newStatus;

    if (itemName === "Approved") {
      newStatus = 1 //for approved
    } else if (itemName === "Set as Printed") {
      newStatus = 2 // for Printed
    } else if (itemName === "Rejected") {
      newStatus = 100 //for Rejected
      setIsRejectionVisibleLoadPanel(true)
    } else if (itemName === "Pending") {
      newStatus = 0 //for Pending
    }

    let DataToSend = {
      registrationID: [regisID],
      newRegistrationStatus: newStatus
    }
    const result = await registrationAuthorizedService.UpdateStatus(DataToSend)
    if (result.statusCode === 400) {
      ShowAlert(result.data)
    } else {
      if (itemName === "Rejected") {
        setSelectedRecordDetails({
          emailID: emailID,
          registrationID: regisID
        })
      } else if (itemName !== "Pending") {
        if ((itemName === "Approved" && selectedEventDetails.autoEmailOnApproval)
          || (itemName === "Set as Printed" && selectedEventDetails.autoEmailOnPrinted)
          || (itemName === "Rejected" && selectedEventDetails.autoEmailOnRejection)
        ) {
          SendEmailOnStatusChange(regisID);
        }
      }
      if (itemName === "Rejected") {
        setIsRejectionVisibleLoadPanel(false)
        setShowEmailTemplateOnSingleRejection(!showEmailTemplateOnSingleRejection)
      }

      if (selectedTab === 0) {
        const updatedDsGroupDashboard = dsGroupDashboard.map(item => {
          if (item.registrationID === regisID) {
            return { ...item, registrationStatusName: itemName === "Set as Printed" ? "Printed" : itemName, registrationStatus: newStatus };
          } else {
            return item;
          }
        });
        setDsGroupDashboard(updatedDsGroupDashboard)
      } else if (selectedTab === 1) {
        const updatedDsVisitor = dsVisitor.map(item => {
          if (item.registrationID === regisID) {
            return { ...item, registrationStatusName: itemName === "Set as Printed" ? "Printed" : itemName, registrationStatus: newStatus };
          } else {
            return item;
          }
        });
        setDsVisitor(updatedDsVisitor)
      } else if (selectedTab === 2) {
        const updatedDsMedia = dsMedia.map(item => {
          if (item.registrationID === regisID) {
            return { ...item, registrationStatusName: itemName === "Set as Printed" ? "Printed" : itemName, registrationStatus: newStatus };
          } else {
            return item;
          }
        });
        setDsMedia(updatedDsMedia)
      }
      GetEventSummryDataByID();
    }
  }



  const onSelectedChanged = (e) => {

    var rowData = e.selectedRowsData;

    setSelectedRegistrationIds(rowData?.map(item => item.registrationID))
    setselectedRowKeys(e.selectedRowKeys);

    if (rowData?.every(item => item.registrationStatusName === "Rejected")) {
      setDisableSetasOptions({ ...disableSetasOptions, rejected: true })
    } else if (rowData?.every(item => item.registrationStatusName === "Approved")) {
      setDisableSetasOptions({ ...disableSetasOptions, approved: true })
    } else if (rowData?.every(item => item.registrationStatusName === "Printed")) {
      setDisableSetasOptions({ ...disableSetasOptions, printed: true })
    } else if (rowData?.every(item => item.registrationStatusName === "Pending")) {
      setDisableSetasOptions({ ...disableSetasOptions, pending: true })
    } else {
      setDisableSetasOptions({
        rejected: false,
        approved: false,
        printed: false,
        pending: false
      })
    }

    if (e.selectedRowKeys?.length > 0) {
      setDisableButtons(false)
    } else {
      setDisableButtons(true)
    }
  }

  const onVisitorSelectedChanged = (e) => {
    var rowData = e.selectedRowsData;
    setVisitorSelectedRegistrationIds(rowData.map(item => item.registrationID))
    setVisitorSelectedRowKeys(e.selectedRowKeys);

    if (rowData?.every(item => item.registrationStatusName === "Rejected")) {
      setDisableSetasOptionsVisitor({ ...disableSetasOptionsVisitor, rejected: true })
    } else if (rowData?.every(item => item.registrationStatusName === "Approved")) {
      setDisableSetasOptionsVisitor({ ...disableSetasOptionsVisitor, approved: true })
    } else if (rowData?.every(item => item.registrationStatusName === "Printed")) {
      setDisableSetasOptionsVisitor({ ...disableSetasOptionsVisitor, printed: true })
    } else if (rowData?.every(item => item.registrationStatusName === "Pending")) {
      setDisableSetasOptionsVisitor({ ...disableSetasOptionsVisitor, pending: true })
    } else {
      setDisableSetasOptionsVisitor({
        rejected: false,
        approved: false,
        printed: false,
        pending: false
      })
    }

    if (e.selectedRowKeys?.length > 0) {
      setVisitorDisableButtons(false)
    } else {
      setVisitorDisableButtons(true)
    }
  }

  const onMediaSelectedChanged = (e) => {

    var rowData = e.selectedRowsData;

    setMediaSelectedRegistrationIds(rowData.map(item => item.registrationID))
    setMediaSelectedRowKeys(e.selectedRowKeys);

    if (rowData?.every(item => item.registrationStatusName === "Rejected")) {
      setDisableSetasOptionsMedia({ ...disableSetasOptionsMedia, rejected: true })
    } else if (rowData?.every(item => item.registrationStatusName === "Approved")) {
      setDisableSetasOptionsMedia({ ...disableSetasOptionsMedia, approved: true })
    } else if (rowData?.every(item => item.registrationStatusName === "Printed")) {
      setDisableSetasOptionsMedia({ ...disableSetasOptionsMedia, printed: true })
    } else if (rowData?.every(item => item.registrationStatusName === "Pending")) {
      setDisableSetasOptionsMedia({ ...disableSetasOptionsMedia, pending: true })
    } else {
      setDisableSetasOptionsMedia({
        rejected: false,
        approved: false,
        printed: false,
        pending: false
      })
    }


    if (e.selectedRowKeys?.length > 0) {
      setMediaDisableButtons(false)
    } else {
      setMediaDisableButtons(true)
    }
  }

  const download = (blob, fileName) => {
    const url = blob?.size > 0 ? window.URL.createObjectURL(blob) : null;
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName ? fileName : 'Image';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onHidingToast = () => {
    setToastStatus({
      ...ToastStatus,
      visibleToast: false
    })
  }

  // visitor integration

  const [visitorListSkeleton, setVisitorListSkeleton] = useState(false)
  const [visitorFormData, setVisitorFormData] = useState(null)
  const [visitorGridState, setVisitorGridState] = useState(null)
  const [visitorHideButton, setVisitorHideButton] = useState(null)

  const GetVisitorFormDesignerData = async (showLoadPanel) => {

    if (!showLoadPanel) {
      setVisitorListSkeleton(true);
    }


    const result =
      await registrationFormDesignerService.GetRecord(selectedEventDetails.visitorRegistrationFormID);
    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setVisitorListSkeleton(false);
    } else if (result.statusCode === 204) {
      setVisitorGridState(eState.None)
      return;
    } else {
      setVisitorFormData(result.data);
      GetVisitorListWithFilter(result.data.components);
    }
  };

  useEffect(() => {

    if (selectedEventDetails.eventID && selectedTab === 1) {
      GetVisitorFormDesignerData();
    }
  }, [selectedEventDetails, selectedTab, visitorRegistrationStatus]);

  const GetVisitorListWithFilter = async (Components, showLoadPanel) => {
    if (!showLoadPanel) {
      setVisitorListSkeleton(true);
    }
    let regStatus;
    if (visitorRegistrationStatus === 1) {
      regStatus = null;
    } else if (visitorRegistrationStatus === 2) {
      regStatus = 0;
    } else if (visitorRegistrationStatus === 3) {
      regStatus = 1
    } else if (visitorRegistrationStatus === 4) {
      regStatus = 2
    } else if (visitorRegistrationStatus === 5) {
      regStatus = 100
    }

    const result = await registrationAuthorizedService.GetListWithFilter(
      selectedEventDetails.eventID,
      1,
      null,
      null,
      regStatus
    );
    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setVisitorListSkeleton(false);
    } else {
      const inst = VisitorsDataGridRef.current?.instance;
      let dataForColumns = [];

      // If Form Designer created
      if (Components && Components.length > 0 && result.data.length === 0) {
        inst.beginUpdate();
        Components?.map((r) => {
          let dataToPush = {
            registrationID: 0,
            registrationStatusName: null,
            index: r.index,
            componentID: r.componentID,
            arrivalDateTime: null,
            departureDateTime: null,
            passportExpiryDate: null,
            passportIssueDate: null,
            dob: null,
          };

          if (r.componentID === 1) {
            dataToPush.firstName = null;
          }
          if (r.componentID === 2) {
            dataToPush.middleName = null;
          }
          if (r.componentID === 3) {
            dataToPush.lastName = null;
          }
          if (r.componentID === 4) {
            dataToPush.fullName = null;
          }
          if (r.componentID === 5) {
            dataToPush.function = null;
          }
          if (r.componentID === 6) {
            dataToPush.club = null;
          }
          if (r.componentID === 7) {
            dataToPush.comments = null;
          }
          if (r.componentID === 8) {
            dataToPush.company = null;
          }
          if (r.componentID === 9) {
            dataToPush.nationalityName = null;
          }
          if (r.componentID === 10) {
            dataToPush.gender = null;
          }
          if (r.componentID === 11) {
            dataToPush.dob = null;
          }
          if (r.componentID === 12) {
            dataToPush.photo = null;
          }
          if (r.componentID === 13) {
            dataToPush.passportNo = null;
          }
          if (r.componentID === 14) {
            dataToPush.passportCopy = null;
          }
          if (r.componentID === 15) {
            dataToPush.passportIssueDate = null;
          }
          if (r.componentID === 16) {
            dataToPush.passportExpiryDate = null;
          }
          if (r.componentID === 17) {
            dataToPush.qidNo = null;
          }
          if (r.componentID === 18) {
            dataToPush.qidCopy = null;
          }
          if (r.componentID === 19) {
            dataToPush.emailID = null;
          }
          if (r.componentID === 20) {
            dataToPush.mobileNo = null;
          }
          if (r.componentID === 21) {
            dataToPush.whatsappNo = null;
          }
          if (r.componentID === 22) {
            dataToPush.aipNo = null;
          }
          if (r.componentID === 23) {
            dataToPush.aipCopy = null;
          }
          if (r.componentID === 24) {
            dataToPush.mediaTypeName = null;
          }
          if (r.componentID === 25) {
            dataToPush.mediaName = null;
          }
          if (r.componentID === 26) {
            dataToPush.mediaFunctionName = null;
          }
          if (r.componentID === 27) {
            dataToPush.mediaAddress = null;
          }
          if (r.componentID === 28) {
            dataToPush.arrivalDateTime = null;
          }
          if (r.componentID === 29) {
            dataToPush.arrivalFlightNo = null;
          }
          if (r.componentID === 30) {
            dataToPush.departureDateTime = null;
          }
          if (r.componentID === 31) {
            dataToPush.departureFlightNo = null;
          }
          dataForColumns.push(dataToPush);
        });
        inst.endUpdate();
        setVisitorHideButton(true);
        setTimeout(() => {
          setVisitorGridState(eState.FormDesign);
        }, 500)

      } else if (
        ((Components && Components.length === 0) || Components === undefined) &&
        result.data.length === 0
      ) {
        // If no form designer is created
        let dataToPush = {
          registrationID: 0,
          arrivalDateTime: null,
          departureDateTime: null,
          passportExpiryDate: null,
          passportIssueDate: null,
          dob: null,
        };
        dataForColumns.push(dataToPush);
        setVisitorHideButton(false);
        setTimeout(() => {
          setVisitorGridState(eState.None)
        }, 500)


      }
      // If Registration form is created

      if (result.data.length > 0) {
        result.data.map((i) => {
          const arrivalDateTime = i.arrivalDateTime ? GetListFormattedDateTime(i.arrivalDateTime) : null;
          const departureDateTime = i.departureDateTime ? GetListFormattedDateTime(i.departureDateTime) : null;
          const passportExpiryDate = i.passportExpiryDate ? GetListFormattedDateTime(i.passportExpiryDate) : null;
          const passportIssueDate = i.passportIssueDate ? GetListFormattedDateTime(i.passportIssueDate) : null;
          const dob = i.dob ? GetListFormattedDateTime(i.dob) : null;
          dataForColumns.push({
            ...i,
            arrivalDateTime: arrivalDateTime ? arrivalDateTime : null,
            departureDateTime: departureDateTime ? departureDateTime : null,
            passportExpiryDate: passportExpiryDate ? passportExpiryDate : null,
            passportIssueDate: passportIssueDate ? passportIssueDate : null,
            dob: dob ? dob : null,
          })
        });
        setVisitorHideButton(true);
        setTimeout(() => {
          setVisitorGridState(eState.FormCreated);
        }, 500)

      }
      setDsVisitor(dataForColumns);
    }
  };

  useEffect(() => {

    if (visitorGridState !== null) {
      const inst = VisitorsDataGridRef.current?.instance;
      if (visitorGridState === eState.None) {

        inst.beginUpdate();
        //  if (dsVisitor.length > 0) {
        setDsVisitor([]);
        const allColumns = inst.option('columns').filter(r => !r.name.includes('default'))
        allColumns.forEach(function (column) {
          // Check if either the name or dataField exists in the list of objects                     
          if (column.name) {
            inst.columnOption(column.name, "visible", false);
            inst.columnOption(column.name, "showInColumnChooser", false);
          }
        });
        //  }
        inst.endUpdate();
      }
      else if (visitorGridState === eState.FormDesign) {
        if (dsVisitor.length > 0) {
          inst.beginUpdate();
          const allColumns = inst.option('columns').filter(r => !r.name.includes('default'))

          allColumns.forEach(function (column) {

            if (column.name) {
              inst.columnOption(column.name, "visible", false);
              inst.columnOption(column.name, "showInColumnChooser", false);
            }
          });

          allColumns.forEach(function (column) {
            // Check if either the name or dataField exists in the list of objects
            dsVisitor.map(function (obj) {

              if (column.name && obj.componentID === parseInt(column.name)) {
                inst.columnOption(column.name, "visibleIndex", obj.index);
                inst.columnOption(column.name, "visible", true);
                inst.columnOption(column.name, "showInColumnChooser", true);
              }
            });
          });

          setDsVisitor([]);
          inst.endUpdate();
        }
      }
      else if (visitorGridState === eState.FormCreated) {
        inst.beginUpdate();
        UpdateVisitorComponentValue(visitorFormData?.components, inst);


        if (isLoadPanelVisible) {
          setIsVisibleLoadPanel(false)
          setShowEmailTemplate(!showEmailTemplate)
        }
        if (isRejectionVisibleLoadPanel) {
          setIsRejectionVisibleLoadPanel(false)
          setShowEmailTemplateOnSingleRejection(!showEmailTemplateOnSingleRejection)
        }

        inst.endUpdate();
      }

      setVisitorGridState(null);
      if (dsVisitor.length > 0) {
        inst.getDataSource().reload()
      }

      setVisitorListSkeleton(false);

      VisitorsDataGridRef?.current?.instance.repaint()

    }
  }, [visitorGridState])

  const UpdateVisitorComponentValue = (Components, gridInstance) => {

    const allColumns = gridInstance.option('columns').filter(r => !r.name?.includes('default'));
    allColumns.forEach(function (column) {
      if (column.name) {
        gridInstance.columnOption(column.name, "visible", false);
        gridInstance.columnOption(column.name, "showInColumnChooser", false);
      }
    });

    allColumns.forEach(function (column) {
      Components.map(function (obj) {
        if (column.name && obj.componentID === parseInt(column.name)) {
          gridInstance.columnOption(column.name, "visibleIndex", obj.index);
          gridInstance.columnOption(column.name, "visible", true);
          gridInstance.columnOption(column.name, "showInColumnChooser", true);
        }
      });
    });

  }



  // Media integration

  const [mediaListSkeleton, setMediaListSkeleton] = useState(false)
  const [mediaFormData, setMediaFormData] = useState(null)
  const [mediaGridState, setMediaGridState] = useState(null)
  const [mediaHideButton, setMediaHideButton] = useState(null)

  const GetMediaFormDesignerData = async (showLoadPanel) => {

    if (!showLoadPanel) {
      setMediaListSkeleton(true);
    }


    const result =
      await registrationFormDesignerService.GetRecord(selectedEventDetails.mediaRegistrationFormID);
    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setMediaListSkeleton(false);
    } else if (result.statusCode === 204) {
      setMediaGridState(eState.None)
      return;
    } else {
      setMediaFormData(result.data);
      GetMediaListWithFilter(result.data.components);
    }
  };

  useEffect(() => {

    if (selectedEventDetails.eventID && selectedTab === 2) {
      GetMediaFormDesignerData();
    }
  }, [selectedEventDetails, selectedTab, mediaRegistrationStatus]);

  const GetMediaListWithFilter = async (Components, showLoadPanel) => {
    if (!showLoadPanel) {
      setMediaListSkeleton(true);
    }
    let regStatus;
    if (mediaRegistrationStatus === 1) {
      regStatus = null;
    } else if (mediaRegistrationStatus === 2) {
      regStatus = 0;
    } else if (mediaRegistrationStatus === 3) {
      regStatus = 1
    } else if (mediaRegistrationStatus === 4) {
      regStatus = 2
    } else if (mediaRegistrationStatus === 5) {
      regStatus = 100
    }

    const result = await registrationAuthorizedService.GetListWithFilter(
      selectedEventDetails.eventID,
      2,
      null,
      null,
      regStatus
    );
    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setMediaListSkeleton(false);
    } else {
      const inst = MediaDataGridRef.current?.instance;
      let dataForColumns = [];

      // If Form Designer created
      if (Components && Components.length > 0 && result.data.length === 0) {
        inst.beginUpdate();
        Components?.map((r) => {
          let dataToPush = {
            registrationID: 0,
            registrationStatusName: null,
            index: r.index,
            componentID: r.componentID,
            arrivalDateTime: null,
            departureDateTime: null,
            passportExpiryDate: null,
            passportIssueDate: null,
            dob: null,
          };

          if (r.componentID === 1) {
            dataToPush.firstName = null;
          }
          if (r.componentID === 2) {
            dataToPush.middleName = null;
          }
          if (r.componentID === 3) {
            dataToPush.lastName = null;
          }
          if (r.componentID === 4) {
            dataToPush.fullName = null;
          }
          if (r.componentID === 5) {
            dataToPush.function = null;
          }
          if (r.componentID === 6) {
            dataToPush.club = null;
          }
          if (r.componentID === 7) {
            dataToPush.comments = null;
          }
          if (r.componentID === 8) {
            dataToPush.company = null;
          }
          if (r.componentID === 9) {
            dataToPush.nationalityName = null;
          }
          if (r.componentID === 10) {
            dataToPush.gender = null;
          }
          if (r.componentID === 11) {
            dataToPush.dob = null;
          }
          if (r.componentID === 12) {
            dataToPush.photo = null;
          }
          if (r.componentID === 13) {
            dataToPush.passportNo = null;
          }
          if (r.componentID === 14) {
            dataToPush.passportCopy = null;
          }
          if (r.componentID === 15) {
            dataToPush.passportIssueDate = null;
          }
          if (r.componentID === 16) {
            dataToPush.passportExpiryDate = null;
          }
          if (r.componentID === 17) {
            dataToPush.qidNo = null;
          }
          if (r.componentID === 18) {
            dataToPush.qidCopy = null;
          }
          if (r.componentID === 19) {
            dataToPush.emailID = null;
          }
          if (r.componentID === 20) {
            dataToPush.mobileNo = null;
          }
          if (r.componentID === 21) {
            dataToPush.whatsappNo = null;
          }
          if (r.componentID === 22) {
            dataToPush.aipNo = null;
          }
          if (r.componentID === 23) {
            dataToPush.aipCopy = null;
          }
          if (r.componentID === 24) {
            dataToPush.mediaTypeName = null;
          }
          if (r.componentID === 25) {
            dataToPush.mediaName = null;
          }
          if (r.componentID === 26) {
            dataToPush.mediaFunctionName = null;
          }
          if (r.componentID === 27) {
            dataToPush.mediaAddress = null;
          }
          if (r.componentID === 28) {
            dataToPush.arrivalDateTime = null;
          }
          if (r.componentID === 29) {
            dataToPush.arrivalFlightNo = null;
          }
          if (r.componentID === 30) {
            dataToPush.departureDateTime = null;
          }
          if (r.componentID === 31) {
            dataToPush.departureFlightNo = null;
          }
          dataForColumns.push(dataToPush);
        });
        inst.endUpdate();
        setMediaHideButton(true);
        setTimeout(() => {
          setMediaGridState(eState.FormDesign);
        }, 500)


      } else if (
        ((Components && Components.length === 0) || Components === undefined) &&
        result.data.length === 0
      ) {
        // If no form designer is created
        let dataToPush = {
          registrationID: 0,
          arrivalDateTime: null,
          departureDateTime: null,
          passportExpiryDate: null,
          passportIssueDate: null,
          dob: null,
        };
        dataForColumns.push(dataToPush);
        setMediaHideButton(false);
        setTimeout(() => {
          setMediaGridState(eState.None)
        }, 500)


      }
      // If Registration form is created

      if (result.data.length > 0) {
        result.data.map((i) => {
          const arrivalDateTime = i.arrivalDateTime ? GetListFormattedDateTime(i.arrivalDateTime) : null;
          const departureDateTime = i.departureDateTime ? GetListFormattedDateTime(i.departureDateTime) : null;
          const passportExpiryDate = i.passportExpiryDate ? GetListFormattedDateTime(i.passportExpiryDate) : null;
          const passportIssueDate = i.passportIssueDate ? GetListFormattedDateTime(i.passportIssueDate) : null;
          const dob = i.dob ? GetListFormattedDateTime(i.dob) : null;
          dataForColumns.push({
            ...i,
            arrivalDateTime: arrivalDateTime ? arrivalDateTime : null,
            departureDateTime: departureDateTime ? departureDateTime : null,
            passportExpiryDate: passportExpiryDate ? passportExpiryDate : null,
            passportIssueDate: passportIssueDate ? passportIssueDate : null,
            dob: dob ? dob : null,
          })
        });
        setMediaHideButton(true);
        setTimeout(() => {
          setMediaGridState(eState.FormCreated);
        }, 500)

      }
      setDsMedia(dataForColumns);
    }
  };

  useEffect(() => {

    if (mediaGridState !== null) {
      const inst = MediaDataGridRef.current?.instance;
      if (mediaGridState === eState.None) {

        inst.beginUpdate();
        //  if (dsMedia.length > 0) {
        setDsMedia([]);
        const allColumns = inst.option('columns').filter(r => !r.name.includes('default'))
        allColumns.forEach(function (column) {
          // Check if either the name or dataField exists in the list of objects                     
          if (column.name) {
            inst.columnOption(column.name, "visible", false);
            inst.columnOption(column.name, "showInColumnChooser", false);
          }
        });
        //  }
        inst.endUpdate();
      }
      else if (mediaGridState === eState.FormDesign) {
        if (dsMedia.length > 0) {
          inst.beginUpdate();
          const allColumns = inst.option('columns').filter(r => !r.name.includes('default'))

          allColumns.forEach(function (column) {

            if (column.name) {
              inst.columnOption(column.name, "visible", false);
              inst.columnOption(column.name, "showInColumnChooser", false);
            }
          });

          allColumns.forEach(function (column) {
            // Check if either the name or dataField exists in the list of objects
            dsMedia.map(function (obj) {

              if (column.name && obj.componentID === parseInt(column.name)) {
                inst.columnOption(column.name, "visibleIndex", obj.index);
                inst.columnOption(column.name, "visible", true);
                inst.columnOption(column.name, "showInColumnChooser", true);
              }
            });
          });

          setDsMedia([]);
          inst.endUpdate();

        }
      }
      else if (mediaGridState === eState.FormCreated) {

        inst.beginUpdate();
        UpdateMediaComponentValue(mediaFormData.components, inst);

        if (isLoadPanelVisible) {
          setIsVisibleLoadPanel(false)
          setShowEmailTemplate(!showEmailTemplate)
        }
        if (isRejectionVisibleLoadPanel) {
          setIsRejectionVisibleLoadPanel(false)
          setShowEmailTemplateOnSingleRejection(!showEmailTemplateOnSingleRejection)
        }

        inst.endUpdate();
      }

      setMediaGridState(null);
      if (dsMedia.length > 0) {
        inst.getDataSource().reload()
      }
      setMediaListSkeleton(false);


      MediaDataGridRef?.current?.instance.repaint()

    }
  }, [mediaGridState])


  const UpdateMediaComponentValue = (Components, gridInstance) => {

    const allColumns = gridInstance.option('columns').filter(r => !r.name?.includes('default'));
    allColumns.forEach(function (column) {
      if (column.name) {
        gridInstance.columnOption(column.name, "visible", false);
        gridInstance.columnOption(column.name, "showInColumnChooser", false);
      }
    });

    allColumns.forEach(function (column) {
      Components.map(function (obj) {
        if (column.name && obj.componentID === parseInt(column.name)) {
          gridInstance.columnOption(column.name, "visibleIndex", obj.index);
          gridInstance.columnOption(column.name, "visible", true);
          gridInstance.columnOption(column.name, "showInColumnChooser", true);
        }
      });
    });

  }


  const onTitleClick = (e) => {
    setselectedTab(e.itemIndex)
  }

  const PhotoCellRender = (cellInfo) => {
    let regisID = cellInfo.data.registrationID;
    let fileName = "";

    if (cellInfo.data.firstName) {
      fileName += cellInfo.data.firstName;
    }

    if (cellInfo.data.middleName) {
      fileName += cellInfo.data.middleName;
    }

    if (cellInfo.data.lastName) {
      fileName += cellInfo.data.lastName;
    }

    if (fileName !== "") {
      fileName = "Photo_" + fileName;
    } else {
      fileName = "Photo"
    }

    if (cellInfo.data.hasPhoto) {
      return (
        <>
          <Button text="Photo" className="photo-row-btn" stylingMode="text" hoverStateEnabled={false} onClick={() => DownloadSinglePhoto(regisID, fileName)} />
        </>
      )
    } else {
      return <>

      </>
    }
  }

  const PassportCopyCellRender = (cellInfo) => {
    let regisID = cellInfo.data.registrationID;
    let fileName = "";

    if (cellInfo.data.firstName) {
      fileName += cellInfo.data.firstName;
    }

    if (cellInfo.data.middleName) {
      fileName += cellInfo.data.middleName;
    }

    if (cellInfo.data.lastName) {
      fileName += cellInfo.data.lastName;
    }

    if (fileName !== "") {
      fileName = "Passport_" + fileName;
    } else {
      fileName = "Passport"
    }
    if (cellInfo.data.hasPassport) {
      return (
        <>
          <Button text="Passport Copy" className="photo-row-btn" stylingMode="text" hoverStateEnabled={false} onClick={() => DownloadSignlePassPortCopy(regisID, fileName)} />
        </>
      )
    } else {
      return <>

      </>
    }
  }

  const QIDCopyCellRender = (cellInfo) => {
    let regisID = cellInfo.data.registrationID;
    let fileName = "";

    if (cellInfo.data.firstName) {
      fileName += cellInfo.data.firstName;
    }

    if (cellInfo.data.middleName) {
      fileName += cellInfo.data.middleName;
    }

    if (cellInfo.data.lastName) {
      fileName += cellInfo.data.lastName;
    }

    if (fileName !== "") {
      fileName = "QID_" + fileName;
    } else {
      fileName = "QID"
    }
    if (cellInfo.data.hasQID) {
      return (
        <>
          <Button text="QID Copy" className="photo-row-btn" stylingMode="text" hoverStateEnabled={false} onClick={() => DownloadSignleQIDCopy(regisID, fileName)} />
        </>
      )
    } else {
      return <>

      </>
    }
  }

  const AIPCopyCellRender = (cellInfo) => {
    let regisID = cellInfo.data.registrationID;
    let fileName = "";

    if (cellInfo.data.firstName) {
      fileName += cellInfo.data.firstName;
    }

    if (cellInfo.data.middleName) {
      fileName += cellInfo.data.middleName;
    }

    if (cellInfo.data.lastName) {
      fileName += cellInfo.data.lastName;
    }

    if (fileName !== "") {
      fileName = "AIP_" + fileName;
    } else {
      fileName = "AIP"
    }
    if (cellInfo.data.hasAIP) {
      return (
        <>
          <Button text="AIP Copy" className="photo-row-btn" stylingMode="text" hoverStateEnabled={false} onClick={() => DownloadSignleAIPCopy(regisID, fileName)} />
        </>
      )
    } else {
      return <>

      </>
    }
  }

  const GroupCellRender = (e) => {
    return e.value
  }

  const RegistrationStatusCellRender = (e) => {
    return (
      <>
        {
          e.value === "Rejected" ?
            <span><img src={rejectedIcon} alt="Rejected" width={22} className="pe-1" /> Rejected</span> :
            e.value === "Approved" ?
              <span><img src={ApprovedIcon} alt="Approved" width={22} className="pe-1" /> Approved</span> :
              e.value === "Printed" ?
                <span><img src={PrintedIcon} alt="Printed" width={22} className="pe-1" /> Printed</span> :
                e.value === "Pending" ?
                  <span><img src={pendingIcon} alt="Pending" width={22} className="pe-1" /> Pending</span> : ""

        }
      </>
    )
  }


  const HideEmailTemplate = () => {
    setShowEmailTemplate(!showEmailTemplate)
  }
  const HideSingleRejectionEmailTemplate = () => {
    setShowEmailTemplateOnSingleRejection(!showEmailTemplateOnSingleRejection)
  }

  const onDeleteButtonClick = (regisID) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Run"
    );
    res.then((dialogResult) => (dialogResult ? handleDelete(regisID) : ""));
  };

  const handleDelete = async (regisID) => {

    const Id = regisID;
    const result = await registrationAuthorizedService.DeleteRecord(Id);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    }
    else if (result.statusCode === 200) {
      if (selectedTab === 0) {
        setDsGroupDashboard(
          dsGroupDashboard.filter((x) => x.registrationID !== regisID)
        );
      } else if (selectedTab === 1) {
        setDsVisitor(
          dsVisitor.filter((x) => x.registrationID !== regisID)
        );
      } else if (selectedTab === 2) {
        setDsMedia(
          dsMedia.filter((x) => x.registrationID !== regisID)
        );
      }
    }
  }

  return (
    <React.Fragment>
      <Header
        title={"Dashboard"}
        showEventStatusFilter={true}
        setfocusCardIndex={setfocusCardIndex}
        hideDefaultButtons={true}
        selectedEventDetails={selectedEventDetails}
        showEventDetails={!showTopSection}
        eventStatus={eventStatus}
        setEventStatus={setEventStatus}
        setRowFocused={setRowFocused}
        refreshDataGrid={refreshCard}
        showRefreshButton={true}
        disableRefreshButton={(showSkeleton || visitorListSkeleton || mediaListSkeleton) ? true : false}
      />
      {
        !hideTabPanel && (
          <div>
            <ScrollView direction="horizontal"  disabled={!showTopSection}  ref={scrollViewRef}>
              <div
                className={`d-flex ${showTopSection ? "left-section-expand" : "left-section-collapse"
                  }`}
              >
                {showCardSkeleton ? (
                  <CardSkeleton />
                ) : (
                  dsEventSummary.map((data, index) => {
                    return (
                      <>
                        <div
                          id={`card-${index}`}
                          key={index}
                          className={`admin-dashboard-cards  mx-2 mt-2 mb-3  ${focusCardIndex === index && "focused-card"
                            }`}
                          onClick={() => FocusCard(data, index)}
                        >
                          <div className="font-medium-18 px-2 py-2 border-bottom card-title">
                            {data.eventStatusID !== null &&
                              data.eventStatusID !== undefined && (
                                <img
                                  src={
                                    data.eventStatusID === 1
                                      ? RegistrationOpenIcon
                                      : data.eventStatusID === 2
                                        ? RegistrationClosedIcon
                                        : data.eventStatusID === 3
                                          ? EventLive
                                          : data.eventStatusID === 4
                                            ? EventCompleted
                                            : data.eventStatusID === 0
                                              ? StartSoon
                                              : ""
                                  }
                                  alt="event status"
                                  title={
                                    data.eventStatusID === 1
                                      ? "Registrations are open"
                                      : data.eventStatusID === 2
                                        ? "Registrations are closed"
                                        : data.eventStatusID === 3
                                          ? "Event is live"
                                          : data.eventStatusID === 4
                                            ? "Event is completed"
                                            : data.eventStatusID === 0
                                              ? "Registration will start soon"
                                              : ""
                                  }
                                  width={20}
                                  className="mb-1 me-2"
                                />
                              )}
                            {data.eventName?.length > 40 ? (
                              <span title={data.eventName}>
                                {data.eventName?.substring(0, 40) + "..."}
                              </span>
                            ) : (
                              data.eventName
                            )}
                          </div>
                          <div className="row py-2 px-3">
                            
                              <div className="col-auto d-flex justify-content-center align-items-center">
                              {data.eventLogo ? 
                                <img src={data.eventLogo} alt="logo" width={58} /> :
                                <img src={EmptyImage} alt="logo" width={58}/>
                              }
                              </div>
                            <div className="col">
                              <DataGrid
                                height={138}
                                showBorders={true}
                                dataSource={data.summaryStatus}
                                showRowLines={true}
                              >
                                <Column
                                  dataField="registrationTypeID"
                                  cellRender={RegistrationTypeCellRender}
                                  caption=""
                                  width={65}
                                />
                                <Column
                                  dataField="pending"
                                  caption="Pending"
                                  width={70}
                                  alignment="left"
                                />
                                <Column
                                  alignment="left"
                                  dataField="approved"
                                  caption="Approved"
                                  width={80}
                                />
                                <Column
                                  alignment="left"
                                  dataField="printed"
                                  caption="Printed"
                                  width={65}
                                />
                                <Column
                                  alignment="left"
                                  dataField="rejected"
                                  caption="Rejected"
                                  width={75}
                                />
                                <Column
                                  alignment="left"
                                  dataField="total"
                                  caption="Total"
                                  width={70}
                                />
                              </DataGrid>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </div>
            </ScrollView>

            <div className="DashboardTabSection position-relative">
              <div className="expandCollapseBtn">
                <Button
                  icon={showTopSection ? "chevronup" : "chevrondown"}
                  onClick={() => setShowTopSection(!showTopSection)}
                  className={
                    showTopSection ? "collapse-btn-left" : "expand-btn-left"
                  }
                  focusStateEnabled={false}
                  stylingMode="text"
                />
              </div>
              <LoadPanel
                visible={isLoadPanelVisible || isRejectionVisibleLoadPanel}
              />
              <Toast
                visible={ToastStatus.visibleToast}
                message={ToastStatus.message}
                type={ToastStatus.type}
                onHiding={onHidingToast}
                displayTime={900}
                width={450}
                position={"bottom"}
              />
              <TabPanel
                focusStateEnabled={false}
                activeStateEnabled={false}
                hoverStateEnabled={false}
                stylingMode={"secondary"}
                scrollingEnabled={true}
                swipeEnabled={false}
                scrollByContent={true}
                className="bg-white"
                onTitleClick={onTitleClick}
              >
                <TabItem title="Groups" icon={GroupsIcon}>
                  <div
                    className={`row ${isExLarge && showTopSection
                      ? "admin-dashboard-list-section"
                      : isExLarge && !showTopSection
                        ? "top-section-close" : !isExLarge && !showTopSection ? "top-section-close-sm"
                        : "admin-dashboard-tab-sm"
                      }`}
                  >
                    <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto col-12 pe-xxl-0 pe-xl-0 pe-lg-0 pe-3">
                      {showLeftGridSkeleton ? (
                        <LeftGridSkeleton />
                      ) : (
                        <DataGrid
                          height={
                            isExLarge && showTopSection
                              ? "600px"
                              : isExLarge && !showTopSection
                                ? "850px"
                                : "600px"
                          }
                          width={
                            isExLarge || isLarge || isMedium ? "300px" : "100%"
                          }
                          ref={GroupFilterDataGridRef}
                          dataSource={dsGroupsData}
                          className="List_DataGrid Dashboard_Group_Filter_Grid"
                          showBorders={true}
                          showColumnLines={false}
                          showRowLines={true}
                          focusedRowEnabled={true}
                          wordWrapEnabled={true}
                          hoverStateEnabled={true}
                          allowColumnReordering={true}
                          allowColumnResizing={true}
                          autoNavigateToFocusedRow={true}
                          loadPanel={HideDatagridLoader}
                          filterSyncEnabled={true}
                          keyExpr={"eventGroupID"}
                          focusedRowKey={focusedRowKeyGroupsFilter}
                          onFocusedRowChanged={onFocusedRowChangedGroupsFilter}
                        >
                          <SearchPanel visible={true} width={289} />
                          <Grouping contextMenuEnabled={false} />
                          <Scrolling mode="virtual" />
                          <Pager
                            visible={true}
                            showInfo={true}
                            infoText={`{2} Rows`}
                          ></Pager>
                          <Toolbar>
                            <Item
                              location={"before"}
                              name="searchPanel"
                              locateInMenu="never"
                            />
                          </Toolbar>
                          <Column
                            caption="Groups"
                            dataField="eventGroupName"
                            cssClass="GroupsText"
                          />
                          <Column
                            caption="Category"
                            dataField="eventGroupCategoryName"
                            cssClass="GroupsText"
                            groupIndex={0}
                            groupCellRender={GroupCell}
                          />
                        </DataGrid>
                      )}
                    </div>
                    <div className="col-xxl col-xl col-lg col-md  grid-min-width">
                      <div className={showSkeleton ? "d-block" : "d-none"}>
                        <RightListSkeleton />
                      </div>

                      <div
                        className={showSkeleton  ? "d-none" : "d-block "}
                      >
                        <DataGrid
                          ref={GroupRegisDataGridRef}
                          height={
                            isExLarge && showTopSection
                              ? "600px"
                              : isExLarge && !showTopSection
                                ? "850px"
                                : "600px"
                          }
                          width={"100%"}
                          className="List_DataGrid Dashboard_Grid"
                          dataSource={dsGroupDashboard}
                          keyExpr="registrationID"
                          showBorders={true}
                          showColumnLines={false}
                          showRowLines={true}
                          focusedRowEnabled={true}
                          wordWrapEnabled={true}
                          hoverStateEnabled={true}
                          allowColumnReordering={true}
                          allowColumnResizing={true}
                          autoNavigateToFocusedRow={true}
                          loadPanel={HideDatagridLoader}
                          filterSyncEnabled={true}
                          onOptionChanged={onOptionChange}
                          noDataText={dsGroupDashboard.length === 0 ? "No Record Found" : ""}
                          selectedRowKeys={selectedRowKeys}
                          onSelectionChanged={onSelectedChanged}

                        >
                          <Sorting mode={"multiple"} />
                          <FilterPanel visible={filterVisible} />
                          <FilterBuilderPopup visible={false} container={"#none"} />
                          <Scrolling mode={"virtual"} />
                          <ColumnChooser
                            enabled={true}
                            width={!isExSmall ? 320 : "auto"}
                          >
                            <ColumnChooserSearch enabled={true} />
                          </ColumnChooser>
                          <HeaderFilter visible={true}>
                            <Search enabled={true} />
                          </HeaderFilter>
                          <SearchPanel
                            visible={true}
                            width={isExSmall || isXXSmall ? 200 : 400}
                          />
                          <Selection mode="multiple" showCheckBoxesMode="always" />
                          <Pager
                            visible={true}
                            showInfo={true}
                            infoText={`{2} Rows`}
                          ></Pager>
                          <Toolbar>
                            <Item
                              location={"before"}
                              name="searchPanel"
                              locateInMenu="auto"
                            />
                            <Item location={"before"} locateInMenu="auto">
                              <SelectBox
                                label="Registration Status"
                                labelMode="floating"
                                dataSource={RegistrationStatusItems}
                                displayExpr={"name"}
                                valueExpr={"id"}
                                className="mb-2"
                                width={180}
                                value={registrationStatus}
                                onValueChange={onRegistrationValueChange}
                              />
                            </Item>
                            <Item location={"after"} locateInMenu="auto">
                              <DropDownButton
                                text="Set as"
                                displayExpr="name"
                                keyExpr="id"
                                className="DownloadDropdownButton ButtonPurple me-1 "
                                dropDownOptions={dropDownOptionsWidth}
                                items={SetAsItems}
                                onItemClick={onSetAsItemClick}
                                disabled={disableButtons}
                              />
                            </Item>
                            <Item location={"after"} locateInMenu="auto">
                              <DropDownButton
                                text="Download"
                                displayExpr="name"
                                keyExpr="id"
                                className="DownloadDropdownButton ButtonTeal me-1 "
                                items={DownloadItems}
                                dropDownOptions={dropDownOptionsWidth}
                                onItemClick={onMultipleDownloadItemClick}
                                disabled={disableButtons}
                              />
                            </Item>
                            <Item location={"after"} locateInMenu="auto">
                              <DropDownButton
                                text="Export List To"
                                displayExpr="name"
                                keyExpr="id"
                                className="DownloadDropdownButton ButtonGreen me-1 "
                                items={exportToOptions}
                                dropDownOptions={dropDownOptionsWidth}
                                onItemClick={(e) => onExportItemClick(e, GroupRegisDataGridRef, "Groups")}
                              />
                            </Item>
                          </Toolbar>

                          <Column
                            name='default_status'
                            caption='Status'
                            dataField='registrationStatusName'
                            minWidth={100}
                            width={120}
                            fixed={true}
                            allowReordering={false}
                            fixedPosition="left"
                            cellRender={RegistrationStatusCellRender}
                            visible={dsGroupDashboard.length > 0}
                          />
  
                          <Column
                            name="default_GroupName"
                            dataField="eventGroupName"
                            groupIndex={(groupRegistrationFormID === 0) ? 1 : -1}
                            groupCellRender={GroupCellRender}
                            visible={isGroupColumns}
                            />
                            <Column
                            name="default_CategoryName"
                            dataField="eventGroupCategoryName"
                            groupIndex={(groupRegistrationFormID === 0) ? 0  : -1}
                            groupCellRender={GroupCellRender}
                            visible={isGroupColumns}
                            />
                          <Column
                            name="1"
                            caption="First Name"
                            dataField="firstName"
                            minWidth={120}
                          />
                          <Column
                            name="2"
                            caption="Middle Name"
                            dataField="middleName"
                            minWidth={120}
                          />
                          <Column
                            name="3"
                            caption="Last Name"
                            dataField="lastName"
                            minWidth={120}
                          />
                          <Column
                            name="4"
                            caption="Full Name"
                            dataField="fullName"
                            minWidth={120}
                          />
                          <Column
                            name="5"
                            caption="Function"
                            dataField="function"
                            minWidth={120}
                          />
                          <Column
                            name="6"
                            caption="Club"
                            dataField="club"
                            minWidth={120}
                          />

                          <Column
                            name="7"
                            caption="Comments/Remarks"
                            dataField="comments"
                            minWidth={180}
                          />

                          <Column
                            name="8"
                            caption="Company/Department"
                            dataField="company"
                            minWidth={180}
                          />
                          <Column
                            name="9"
                            caption="Nationality"
                            dataField="nationalityName"
                            minWidth={120}
                          />

                          <Column
                            name="10"
                            caption="Gender"
                            dataField="gender"
                            minWidth={120}
                          />

                          <Column
                            name="11"
                            caption="Date of Birth"
                            dataField="dob"
                            minWidth={120}
                          // dataType="date"
                          // format={"shortDate"}
                          sortingMethod={sortingMethodDate}
                          />
                          <Column
                            name="12"
                            caption="Photo"
                            dataField="photo"
                            minWidth={120}
                            cellRender={PhotoCellRender}
                          />
                          <Column
                            name="13"
                            caption="Passport Number"
                            dataField="passportNo"
                            minWidth={120}
                          />
                          <Column
                            name="14"
                            caption="Passport Copy"
                            dataField="passportCopy"
                            minWidth={120}
                            cellRender={PassportCopyCellRender}
                          />
                          <Column
                            name="15"
                            caption="Passport Issue Date"
                            dataField="passportIssueDate"
                            minWidth={120}
                          // dataType="date"
                          // format={"shortDate"}
                          sortingMethod={sortingMethodDate}
                          />
                          <Column
                            name="16"
                            caption="Passport Expiry Date"
                            dataField="passportExpiryDate"
                            minWidth={120}
                          // dataType="date"
                          // format={"shortDate"}
                          sortingMethod={sortingMethodDate}
                          />
                          <Column
                            name="17"
                            caption="QID Number"
                            dataField="qidNo"
                            minWidth={120}
                          />
                          <Column
                            name="18"
                            caption="QID Copy"
                            dataField="qidCopy"
                            minWidth={120}
                            cellRender={QIDCopyCellRender}
                          />

                          <Column
                            name="19"
                            caption="Email"
                            dataField="emailID"
                            minWidth={120}
                          />
                          <Column
                            name="20"
                            caption="Mobile No"
                            dataField="mobileNo"
                            minWidth={120}
                          />

                          <Column
                            name="21"
                            caption="Whatsapp No"
                            dataField="whatsappNo"
                            minWidth={120}
                          />

                          <Column
                            name="22"
                            caption="AIP No"
                            dataField="aipNo"
                            minWidth={120}
                          />

                          <Column
                            name="23"
                            caption="AIP Copy"
                            dataField="aipCopy"
                            minWidth={120}
                            cellRender={AIPCopyCellRender}
                          />

                          <Column
                            name="24"
                            caption="Media Type"
                            dataField="mediaTypeName"
                            minWidth={120}
                          />

                          <Column
                            name="25"
                            caption="Media Name"
                            dataField="mediaName"
                            minWidth={120}
                          />

                          <Column
                            name="26"
                            caption="Media Function"
                            dataField="mediaFunctionName"
                            minWidth={120}
                          />
                          <Column
                            name="27"
                            caption="Media Address"
                            dataField="mediaAddress"
                            minWidth={120}
                          />

                          <Column
                            name="28"
                            caption="Arrival Date & Time"
                            dataField="arrivalDateTime"
                            minWidth={120}
                          // dataType='datetime'
                          // format={"shortDateShortTime"}
                          sortingMethod={sortingMethodDate}
                          />
                          <Column
                            name="29"
                            caption="Arrival Flight No"
                            dataField="arrivalFlightNo"
                            minWidth={120}
                          />
                          <Column
                            name="30"
                            caption="Departure Date & Time"
                            dataField="departureDateTime"
                            minWidth={120}
                          // dataType='datetime'
                          // format={"shortDateShortTime"}
                          sortingMethod={sortingMethodDate}
                          />
                          <Column
                            name="31"
                            caption="Departure Flight No"
                            dataField="departureFlightNo"
                            minWidth={120}
                          />

                          {HideButton && (
                            <Column
                              name='default_buttons'
                              cellRender={RowButtonsCellRender}
                              allowResizing={false}
                              allowReordering={false}
                              width={175}
                            ></Column>
                          )}
                        </DataGrid>

                      </div>
                    </div>
                  </div>
                </TabItem>
                <TabItem title="Visitors" icon={VisitorsIcon}>
                  <div className={visitorListSkeleton ? 'd-block' : 'd-none'}>
                    <RightListSkeleton />
                  </div>
                  <div className={visitorListSkeleton ? 'd-none' : 'd-block'}>
                    <div
                      className={
                        isExLarge && showTopSection
                          ? "admin-dashboard-list-section"
                          : isExLarge && !showTopSection
                            ? "top-section-close"
                            : "admin-dashboard-tab-sm"
                      }
                    >
                      <DataGrid
                        height={
                          isExLarge && showTopSection
                            ? "600px"
                            : isExLarge && !showTopSection
                              ? "850px"
                              : "600px"
                        }
                        ref={VisitorsDataGridRef}
                        width={"100%"}
                        dataSource={dsVisitor}
                        keyExpr="registrationID"
                        className="List_DataGrid Dashboard_Grid"

                        showBorders={true}
                        showColumnLines={false}
                        showRowLines={true}
                        focusedRowEnabled={true}
                        wordWrapEnabled={true}
                        hoverStateEnabled={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        autoNavigateToFocusedRow={true}
                        loadPanel={HideDatagridLoader}
                        filterSyncEnabled={true}
                        noDataText="No Record Found"
                        selectedRowKeys={visitorSelectedRowKeys}
                        onSelectionChanged={onVisitorSelectedChanged}
                      >
                        <Sorting mode={"multiple"} />
                        <FilterPanel visible={filterVisible} />
                        <FilterBuilderPopup visible={false} container={"#none"} />
                        <Scrolling mode={"virtual"} />
                        <ColumnChooser
                          enabled={true}
                          width={!isExSmall ? 320 : "auto"}
                        >
                          <ColumnChooserSearch enabled={true} />
                        </ColumnChooser>
                        <HeaderFilter visible={true}>
                          <Search enabled={true} />
                        </HeaderFilter>
                        <SearchPanel
                          visible={true}
                          width={isExSmall || isXXSmall ? 200 : 400}
                        />
                        <Selection mode="multiple" showCheckBoxesMode="always" />
                        <Pager
                          visible={true}
                          showInfo={true}
                          infoText={`{2} Rows`}
                        ></Pager>
                        <Toolbar>
                          <Item
                            location={"before"}
                            name="searchPanel"
                            locateInMenu="auto"
                          />
                          <Item location={"before"} locateInMenu="auto">
                            <SelectBox
                              label="Registration Status"
                              labelMode="floating"
                              dataSource={RegistrationStatusItems}
                              displayExpr={"name"}
                              valueExpr={"id"}
                              className="mb-2"
                              width={180}
                              value={visitorRegistrationStatus}
                              onValueChange={onVisitorRegistrationValueChange}
                            />
                          </Item>
                          <Item location={"after"} locateInMenu="auto">
                            <DropDownButton
                              text="Set as"
                              displayExpr="name"
                              keyExpr="id"
                              className="DownloadDropdownButton ButtonPurple me-1 "
                              dropDownOptions={dropDownOptionsWidth}
                              items={SetAsItemsVisitors}
                              onItemClick={onSetAsItemClick}
                              disabled={visitorDisableButtons}
                            />
                          </Item>
                          <Item location={"after"} locateInMenu="auto">
                            <DropDownButton
                              text="Download"
                              displayExpr="name"
                              keyExpr="id"
                              className="DownloadDropdownButton ButtonTeal me-1 "
                              items={DownloadItems}
                              dropDownOptions={dropDownOptionsWidth}
                              onItemClick={onMultipleDownloadItemClick}
                              disabled={visitorDisableButtons}
                            />
                          </Item>
                          <Item location={"after"} locateInMenu="auto">
                            <DropDownButton
                              text="Export List To"
                              displayExpr="name"
                              keyExpr="id"
                              className="DownloadDropdownButton ButtonGreen me-1 "
                              items={exportToOptions}
                              dropDownOptions={dropDownOptionsWidth}
                              onItemClick={(e) => onExportItemClick(e, VisitorsDataGridRef, "Visitors")}
                            />
                          </Item>
                        </Toolbar>

                        <Column
                          name='default_status'
                          caption={dsVisitor.length > 0 ? 'Status' : ''}
                          allowHeaderFiltering={dsVisitor.length > 0 ? true : false}
                          dataField='registrationStatusName'
                          minWidth={120}
                          fixed={true}
                          allowReordering={false}
                          fixedPosition="left"
                          cellRender={RegistrationStatusCellRender}
                        />
                        <Column
                          name="1"
                          caption="First Name"
                          dataField="firstName"
                          minWidth={120}
                        />
                        <Column
                          name="2"
                          caption="Middle Name"
                          dataField="middleName"
                          minWidth={120}
                        />
                        <Column
                          name="3"
                          caption="Last Name"
                          dataField="lastName"
                          minWidth={120}
                        />
                        <Column
                          name="4"
                          caption="Full Name"
                          dataField="fullName"
                          minWidth={120}
                        />
                        <Column
                          name="5"
                          caption="Function"
                          dataField="function"
                          minWidth={120}
                        />
                        <Column
                          name="6"
                          caption="Club"
                          dataField="club"
                          minWidth={120}
                        />

                        <Column
                          name="7"
                          caption="Comments/Remarks"
                          dataField="comments"
                          minWidth={180}
                        />

                        <Column
                          name="8"
                          caption="Company/Department"
                          dataField="company"
                          minWidth={180}
                        />
                        <Column
                          name="9"
                          caption="Nationality"
                          dataField="nationalityName"
                          minWidth={120}
                        />

                        <Column
                          name="10"
                          caption="Gender"
                          dataField="gender"
                          minWidth={120}
                        />

                        <Column
                          name="11"
                          caption="Date of Birth"
                          dataField="dob"
                          minWidth={120}
                          sortingMethod={sortingMethodDate}
                        // dataType='datetime'
                        // format={"shortDateShortTime"}
                        />
                        <Column
                          name="12"
                          caption="Photo"
                          dataField="photo"
                          minWidth={120}
                          cellRender={PhotoCellRender}
                        />
                        <Column
                          name="13"
                          caption="Passport Number"
                          dataField="passportNo"
                          minWidth={120}
                        />
                        <Column
                          name="14"
                          caption="Passport Copy"
                          dataField="passportCopy"
                          minWidth={120}
                          cellRender={PassportCopyCellRender}
                        />
                        <Column
                          name="15"
                          caption="Passport Issue Date"
                          dataField="passportIssueDate"
                          minWidth={120}
                          // dataType='datetime'
                          // format={"shortDateShortTime"}
                          sortingMethod={sortingMethodDate}
                        />
                        <Column
                          name="16"
                          caption="Passport Expiry Date"
                          dataField="passportExpiryDate"
                          minWidth={120}
                          // dataType='datetime'
                          // format={"shortDateShortTime"}
                          sortingMethod={sortingMethodDate}
                        />
                        <Column
                          name="17"
                          caption="QID Number"
                          dataField="qidNo"
                          minWidth={120}
                        />
                        <Column
                          name="18"
                          caption="QID Copy"
                          dataField="qidCopy"
                          minWidth={120}
                          cellRender={QIDCopyCellRender}
                        />

                        <Column
                          name="19"
                          caption="Email"
                          dataField="emailID"
                          minWidth={120}
                        />
                        <Column
                          name="20"
                          caption="Mobile No"
                          dataField="mobileNo"
                          minWidth={120}
                        />

                        <Column
                          name="21"
                          caption="Whatsapp No"
                          dataField="whatsappNo"
                          minWidth={120}
                        />

                        <Column
                          name="22"
                          caption="AIP No"
                          dataField="aipNo"
                          minWidth={120}
                        />

                        <Column
                          name="23"
                          caption="AIP Copy"
                          dataField="aipCopy"
                          minWidth={120}
                          cellRender={AIPCopyCellRender}
                        />

                        <Column
                          name="24"
                          caption="Media Type"
                          dataField="mediaTypeName"
                          minWidth={120}
                        />

                        <Column
                          name="25"
                          caption="Media Name"
                          dataField="mediaName"
                          minWidth={120}
                        />

                        <Column
                          name="26"
                          caption="Media Function"
                          dataField="mediaFunctionName"
                          minWidth={120}
                        />
                        <Column
                          name="27"
                          caption="Media Address"
                          dataField="mediaAddress"
                          minWidth={120}
                        />

                        <Column
                          name="28"
                          caption="Arrival Date & Time"
                          dataField="arrivalDateTime"
                          minWidth={120}
                          // dataType='datetime'
                          // format={"shortDateShortTime"}
                          sortingMethod={sortingMethodDate}
                        />
                        <Column
                          name="29"
                          caption="Arrival Flight No"
                          dataField="arrivalFlightNo"
                          minWidth={120}
                        />
                        <Column
                          name="30"
                          caption="Departure Date & Time"
                          dataField="departureDateTime"
                          minWidth={120}
                          // dataType='datetime'
                          // format={"shortDateShortTime"}
                          sortingMethod={sortingMethodDate}
                        />
                        <Column
                          name="31"
                          caption="Departure Flight No"
                          dataField="departureFlightNo"
                          minWidth={120}
                        />

                        {visitorHideButton && (
                          <Column
                            name='default_buttons'
                            cellRender={RowButtonsCellRender}
                            allowResizing={false}
                            allowReordering={false}
                            width={175}
                          ></Column>
                        )}
                      </DataGrid>
                    </div>
                  </div>


                </TabItem>
                <TabItem title="Media" icon={MediaIcon}>
                  <div className={mediaListSkeleton ? 'd-block' : 'd-none'}>
                    <RightListSkeleton />
                  </div>
                  <div className={mediaListSkeleton ? 'd-none' : 'd-block'}>
                    <div
                      className={
                        isExLarge && showTopSection
                          ? "admin-dashboard-list-section"
                          : isExLarge && !showTopSection
                            ? "top-section-close"
                            : "admin-dashboard-tab-sm"
                      }
                    >
                      <DataGrid
                        height={
                          isExLarge && showTopSection
                            ? "600px"
                            : isExLarge && !showTopSection
                              ? "850px"
                              : "600px"
                        }
                        ref={MediaDataGridRef}
                        width={"100%"}
                        dataSource={dsMedia}
                        keyExpr="registrationID"
                        className="List_DataGrid Dashboard_Grid"

                        showBorders={true}
                        showColumnLines={false}
                        showRowLines={true}
                        focusedRowEnabled={true}
                        wordWrapEnabled={true}
                        hoverStateEnabled={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        autoNavigateToFocusedRow={true}
                        loadPanel={HideDatagridLoader}
                        filterSyncEnabled={true}
                        noDataText="No Record Found"
                        selectedRowKeys={mediaSelectedRowKeys}
                        onSelectionChanged={onMediaSelectedChanged}
                      >
                        <Sorting mode={"multiple"} />
                        <FilterPanel visible={filterVisible} />
                        <FilterBuilderPopup visible={false} container={"#none"} />
                        <Scrolling mode={"virtual"} />
                        <ColumnChooser
                          enabled={true}
                          width={!isExSmall ? 320 : "auto"}
                        >
                          <ColumnChooserSearch enabled={true} />
                        </ColumnChooser>
                        <HeaderFilter visible={true}>
                          <Search enabled={true} />
                        </HeaderFilter>
                        <SearchPanel
                          visible={true}
                          width={isExSmall || isXXSmall ? 200 : 400}
                        />
                        <Selection mode="multiple" showCheckBoxesMode="always" />
                        <Pager
                          visible={true}
                          showInfo={true}
                          infoText={`{2} Rows`}
                        ></Pager>
                        <Toolbar>
                          <Item
                            location={"before"}
                            name="searchPanel"
                            locateInMenu="auto"
                          />
                          <Item location={"before"} locateInMenu="auto">
                            <SelectBox
                              label="Registration Status"
                              labelMode="floating"
                              dataSource={RegistrationStatusItems}
                              displayExpr={"name"}
                              valueExpr={"id"}
                              className="mb-2"
                              width={180}
                              value={mediaRegistrationStatus}
                              onValueChange={onMediaRegistrationValueChange}
                            />
                          </Item>
                          <Item location={"after"} locateInMenu="auto">
                            <DropDownButton
                              text="Set as"
                              displayExpr="name"
                              keyExpr="id"
                              className="DownloadDropdownButton ButtonPurple me-1 "
                              dropDownOptions={dropDownOptionsWidth}
                              items={SetAsItemsMedia}
                              onItemClick={onSetAsItemClick}
                              disabled={mediaDisableButtons}
                            />
                          </Item>
                          <Item location={"after"} locateInMenu="auto">
                            <DropDownButton
                              text="Download"
                              displayExpr="name"
                              keyExpr="id"
                              className="DownloadDropdownButton ButtonTeal me-1 "
                              items={DownloadItems}
                              dropDownOptions={dropDownOptionsWidth}
                              onItemClick={onMultipleDownloadItemClick}
                              disabled={mediaDisableButtons}
                            />
                          </Item>
                          <Item location={"after"} locateInMenu="auto">
                            <DropDownButton
                              text="Export List To"
                              displayExpr="name"
                              keyExpr="id"
                              className="DownloadDropdownButton ButtonGreen me-1 "
                              items={exportToOptions}
                              dropDownOptions={dropDownOptionsWidth}
                              onItemClick={(e) => onExportItemClick(e, MediaDataGridRef, "Media")}
                            />
                          </Item>
                        </Toolbar>

                        <Column
                          name='default_status'
                          caption={dsMedia.length > 0 ? 'Status' : ''}
                          allowHeaderFiltering={dsMedia.length > 0 ? true : false}
                          dataField='registrationStatusName'
                          minWidth={120}
                          fixed={true}
                          allowReordering={false}
                          fixedPosition="left"
                          cssClass="status_bg"
                          cellRender={RegistrationStatusCellRender}
                        />
                        <Column
                          name="1"
                          caption="First Name"
                          dataField="firstName"
                          minWidth={120}
                        />
                        <Column
                          name="2"
                          caption="Middle Name"
                          dataField="middleName"
                          minWidth={120}
                        />
                        <Column
                          name="3"
                          caption="Last Name"
                          dataField="lastName"
                          minWidth={120}
                        />
                        <Column
                          name="4"
                          caption="Full Name"
                          dataField="fullName"
                          minWidth={120}
                        />
                        <Column
                          name="5"
                          caption="Function"
                          dataField="function"
                          minWidth={120}
                        />
                        <Column
                          name="6"
                          caption="Club"
                          dataField="club"
                          minWidth={120}
                        />

                        <Column
                          name="7"
                          caption="Comments/Remarks"
                          dataField="comments"
                          minWidth={180}
                        />

                        <Column
                          name="8"
                          caption="Company/Department"
                          dataField="company"
                          minWidth={180}
                        />
                        <Column
                          name="9"
                          caption="Nationality"
                          dataField="nationalityName"
                          minWidth={120}
                        />

                        <Column
                          name="10"
                          caption="Gender"
                          dataField="gender"
                          minWidth={120}
                        />

                        <Column
                          name="11"
                          caption="Date of Birth"
                          dataField="dob"
                          minWidth={120}
                        // dataType='datetime'
                        // format={"shortDateShortTime"}
                        sortingMethod={sortingMethodDate}
                        />
                        <Column
                          name="12"
                          caption="Photo"
                          dataField="photo"
                          minWidth={120}
                          cellRender={PhotoCellRender}
                        />
                        <Column
                          name="13"
                          caption="Passport Number"
                          dataField="passportNo"
                          minWidth={120}
                        />
                        <Column
                          name="14"
                          caption="Passport Copy"
                          dataField="passportCopy"
                          minWidth={120}
                          cellRender={PassportCopyCellRender}
                        />
                        <Column
                          name="15"
                          caption="Passport Issue Date"
                          dataField="passportIssueDate"
                          minWidth={120}
                        // dataType='datetime'
                        // format={"shortDateShortTime"}
                        sortingMethod={sortingMethodDate}
                        />
                        <Column
                          name="16"
                          caption="Passport Expiry Date"
                          dataField="passportExpiryDate"
                          minWidth={120}
                        // dataType='datetime'
                        // format={"shortDateShortTime"}
                        sortingMethod={sortingMethodDate}
                        />
                        <Column
                          name="17"
                          caption="QID Number"
                          dataField="qidNo"
                          minWidth={120}
                        />
                        <Column
                          name="18"
                          caption="QID Copy"
                          dataField="qidCopy"
                          minWidth={120}
                          cellRender={QIDCopyCellRender}
                        />

                        <Column
                          name="19"
                          caption="Email"
                          dataField="emailID"
                          minWidth={120}
                        />
                        <Column
                          name="20"
                          caption="Mobile No"
                          dataField="mobileNo"
                          minWidth={120}
                        />

                        <Column
                          name="21"
                          caption="Whatsapp No"
                          dataField="whatsappNo"
                          minWidth={120}
                        />

                        <Column
                          name="22"
                          caption="AIP No"
                          dataField="aipNo"
                          minWidth={120}
                        />

                        <Column
                          name="23"
                          caption="AIP Copy"
                          dataField="aipCopy"
                          minWidth={120}
                          cellRender={AIPCopyCellRender}
                        />

                        <Column
                          name="24"
                          caption="Media Type"
                          dataField="mediaTypeName"
                          minWidth={120}
                        />

                        <Column
                          name="25"
                          caption="Media Name"
                          dataField="mediaName"
                          minWidth={120}
                        />

                        <Column
                          name="26"
                          caption="Media Function"
                          dataField="mediaFunctionName"
                          minWidth={120}
                        />
                        <Column
                          name="27"
                          caption="Media Address"
                          dataField="mediaAddress"
                          minWidth={120}
                        />

                        <Column
                          name="28"
                          caption="Arrival Date & Time"
                          dataField="arrivalDateTime"
                          minWidth={120}
                        // dataType='datetime'
                        // format={"shortDateShortTime"}
                        sortingMethod={sortingMethodDate}
                        />
                        <Column
                          name="29"
                          caption="Arrival Flight No"
                          dataField="arrivalFlightNo"
                          minWidth={120}
                        />
                        <Column
                          name="30"
                          caption="Departure Date & Time"
                          dataField="departureDateTime"
                          minWidth={120}
                        // dataType='datetime'
                        // format={"shortDateShortTime"}
                        sortingMethod={sortingMethodDate}
                        />
                        <Column
                          name="31"
                          caption="Departure Flight No"
                          dataField="departureFlightNo"
                          minWidth={120}
                        />

                        {mediaHideButton && (
                          <Column
                            name='default_buttons'
                            cellRender={RowButtonsCellRender}
                            allowResizing={false}
                            allowReordering={false}
                            width={175}
                          ></Column>
                        )}
                      </DataGrid>
                    </div>
                  </div>
                </TabItem>
              </TabPanel>
            </div>
          </div>
  )
}
{
  showEmailTemplate &&
    <EmailTemplate
      groupDataGridRef={GroupRegisDataGridRef}
      selectedGroupRegisData={selectedGroupsRegistration}
      showEmailTemplate={showEmailTemplate}
      HideEmailTemplate={HideEmailTemplate}
    />
}
{
  showEmailTemplateOnSingleRejection &&
    <SingleRejectionEmailTemplate
      showEmailTemplateOnSingleRejection={showEmailTemplateOnSingleRejection}
      HideEmailTemplate={HideSingleRejectionEmailTemplate}
      eventId={selectedEventDetails.eventID}
      eventName={selectedEventDetails.eventName}
      selectedRecordDetails={selectedRecordDetails}
    />
}
    </React.Fragment >
  );
};

export default Home;
