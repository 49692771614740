import React, { useEffect, useRef, useState } from 'react';
import { SelectBox, Button as SelectBoxButton } from 'devextreme-react/select-box';
import {
  Validator,
  RequiredRule,
} from "devextreme-react/validator";
import { ShowAlert, eCRUDStatus } from '../../../utils/common-methods';
import { EventGroupCategoryService } from '../../../api/services/eventGroupCategoryService';
import EventGroupCategoryPopup from './EventGroupCategoryPopup';

const eventGroupCategoryService = new EventGroupCategoryService();
const EventGroupCategoryDropDown = (props) => {
  const SelectBoxRef = useRef(null);

  const [openPopup, setOpenPopup] = useState(false);
  const [dsEventGroupCategory, setDsEventGroupCategory] = useState([]);
  let [primaryKey, setPrimaryKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: null,
    primaryKeyID: primaryKey,
  });

  useEffect(() => {
    GeteventGroupLookupList();
  }, [props.eventID])

  useEffect(() => {
    openPopup === false && props.setDropDownClick && props.setDropDownClick(false);
  }, [openPopup])


  const GeteventGroupLookupList = async () => {
    const result = props.eventID ? props.callEventIDFilter && await eventGroupCategoryService.GetLookupListByIDRecord(props.eventID) : !props.callEventIDFilter && await eventGroupCategoryService.GetLookupListRecord();
    if (result?.isOk) {
      setDsEventGroupCategory(result?.data)
    }
  }

  useEffect(() => {
    if (status.eStatus !== null && status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
      UpdateListByID(PrimaryKeyID);
      setStatus({ eStatus: null })
    } else if (status.eStatus !== null && status.eStatus === eCRUDStatus.None) {
      setPrimaryKey(null);
      setStatus({ eStatus: null })
    }
  }, [status])


  const UpdateListByID = async (primaryKey) => {
    
    const response = await eventGroupCategoryService.GetRecord(primaryKey)
    if(response.data.eventID === props.eventID){
      const result = await eventGroupCategoryService.GetListByIDRecord(primaryKey);
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Run")
      } else {
        if (status.eStatus === eCRUDStatus.Inserted) {
          setDsEventGroupCategory([result.data[0], ...dsEventGroupCategory]);
          setPrimaryKey(0);
        } else if (status.eStatus === eCRUDStatus.Updated) {
          setDsEventGroupCategory(dsEventGroupCategory.map((i) => i.eventGroupCategoryID === primaryKey ? result.data[0] : i));
        }
      }
      props.setAddedEventGroupCategoryID && props.setAddedEventGroupCategoryID(primaryKey);
    }
  };

  const AddButton = {
    icon: 'plus',
    stylingMode: 'text',
    onClick: () => {
      props.setDropDownClick && props.setDropDownClick(true);
      setOpenPopup(!openPopup)
    }
  }

  const DownArrow = {
    icon: 'spindown',
    stylingMode: 'text',
    onClick: (e) => {

      var selectBoxInstance = SelectBoxRef.current?.instance;
      var isOpened = selectBoxInstance.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    }
  }

  const onCloseEventGroupCategoryPopup = () => {
    setOpenPopup(false);
  }


  return (
    <div>
      <SelectBox
        label={props.label ? props.label : "Group Category"}
        labelMode='floating'
        searchEnabled={true}
        ref={SelectBoxRef}
        dataSource={dsEventGroupCategory}
        valueExpr="eventGroupCategoryID"
        displayExpr="eventGroupCategoryName"
        value={props.value}
        onValueChange={props.onValueChange}
        dropDownOptions={{
          height: props.dropdownHeight ? props.dropdownHeight : 120,
          // hideOnParentScroll:false,
          // container:"#AttitudeFactor_DropDown"
        }}
      >
        <SelectBoxButton
          name="add"
          location="after"
          options={AddButton}
        />
        <SelectBoxButton
          name="dropdown"
          location="after"
          options={DownArrow}
        />
        {props.validationRequired && (
          <Validator validationGroup={props.validationGroup} elementAttr={{ class: 'ForValidationAstrikMark' }}>
            <RequiredRule message={props.validationMessage ? props.validationMessage : "Group Category is required"} />
          </Validator>
        )}
      </SelectBox>
      {openPopup === true &&
        <EventGroupCategoryPopup
          popupShow={openPopup}
          popupClose={onCloseEventGroupCategoryPopup}
          primaryKey={primaryKey}
          setStatus={setStatus}
          currentEventID={props.eventID}
        />}
    </div>
  )
}
export default EventGroupCategoryDropDown;
