import DataGrid, { Column, Button as DataGridButton, FilterPanel, Sorting, Scrolling, ColumnChooser, HeaderFilter, SearchPanel, FilterBuilderPopup, Pager, StateStoring, Toolbar, Item, ColumnChooserSearch, Search, } from 'devextreme-react/data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '../../../utils/media-query';
import { Header } from '../../../components';
import { confirm } from 'devextreme/ui/dialog';
import { GetFormattedDateTime, GetFormattedDateTimeUTCString, GetListFormattedDateTime, HideDatagridLoader, ShowAlert, eCRUDStatus, eventStatusDropDown, sortingMethodDate } from '../../../utils/common-methods';
import { EventService } from '../../../api/services/eventService';
import { EventCompleted, EventLive, RegistrationClosedIcon, RegistrationOpenIcon, StartSoon, } from '../../../utils/base-64-Icons';
import EventPopup from './EventPopup';
import { SelectBox } from 'devextreme-react';
import ListSkeletonFilter from '../../../layouts/templates/ListSkeletonFilter';
import EmailTemplatePopup from './EmailTemplatePopup';
import { eRegistrationType } from '../../../api/services/registrationFormDesignerService';
import RegistrationFormDesigner from '../../registration-form/RegistrationFormDesigner';

const eventService = new EventService();
const EventList = () => {

    const { isExSmall, isXXSmall } = useScreenSize();
    const [dsEvent, setDsEvent] = useState([]);
    const [filterVisible, setFilterVisible] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true)

    const [showEventPopup, setShowEventPopup] = useState(false)
    const [primaryKey, setPrimaryKey] = useState(null)
    const [focusedRowKey, setfocusedRowKey] = useState(0);
    const [status, setStatus] = useState({
        eStatus: null,
        primaryKeyID: primaryKey,
    });
    const [eventStatus, setEventStatus] = useState(1);
    const [showEmailTemplatePopup, setShowEmailTemplatePopup] = useState(false)
    const [eventname, setEventName] = useState();

    const DataGridRef = useRef(null);
    const navigateTo = useNavigate();

    // Start For Registration Form Designer
    const [ShowRegistrationFormDesignerPopup, setShowRegistrationFormDesignerPopup] = useState(false);
    const [SelectedEventDetail, setSelectedEventDetail] = useState(null);

    const [GroupStatus, setGroupStatus] = useState({
        eStatus: null
    });

    useEffect(() => {
        if (GroupStatus.eStatus !== null && GroupStatus.eStatus !== eCRUDStatus.None) {
            setSelectedEventDetail(null);
            GetEventList();
            setGroupStatus({ eStatus: null })
        } else if (GroupStatus.eStatus !== null && GroupStatus.eStatus === eCRUDStatus.None) {
            setSelectedEventDetail(null);
            setGroupStatus({ eStatus: null })
        }
    }, [GroupStatus]);

    const onOpenVisitorRegistrationFormDesignerPopup = (e) => {
        setSelectedEventDetail({
            EventID: e.row.data.eventID,
            EventName: e.row.data.eventName,
            EventGroupCategoryID: null,
            RegistrationFormID: e.row.data.visitorRegistrationFormID,
            RegistrationTypeID: eRegistrationType.Visitor
        })
        setShowRegistrationFormDesignerPopup(!ShowRegistrationFormDesignerPopup);
    }

    const onOpenMediaRegistrationFormDesignerPopup = (e) => {
        setSelectedEventDetail({
            EventID: e.row.data.eventID,
            EventName: e.row.data.eventName,
            EventGroupCategoryID: null,
            RegistrationFormID: e.row.data.mediaRegistrationFormID,
            RegistrationTypeID: eRegistrationType.Media
        })
        setShowRegistrationFormDesignerPopup(!ShowRegistrationFormDesignerPopup);
    }

    const onCloseRegistrationFormDesignerPopup = () => {
        setShowRegistrationFormDesignerPopup(!ShowRegistrationFormDesignerPopup);
    }

    // End For Registration Form Designer


    const GetEventList = async () => {
        setShowSkeleton(true)
        const result = eventStatus === null ? await eventService.GetListRecord() : await eventService.GetListRecordWithFilter(eventStatus);
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")
            setShowSkeleton(false);
        } else {
            const EventData = result?.data?.map(item => {
                const eventFrom = item.eventFrom ? GetListFormattedDateTime(item.eventFrom) : null;
                const eventTo = item.eventTo ? GetListFormattedDateTime(item.eventTo) : null;
                const registrationFrom = item.registrationFrom ? GetListFormattedDateTime(item.registrationFrom) : null;
                const registrationTo = item.registrationTo ? GetListFormattedDateTime(item.registrationTo) : null;
                const createdOn = item.createdOn ? GetFormattedDateTimeUTCString(item.createdOn) : null;
                const updatedOn = item.updatedOn ? GetFormattedDateTimeUTCString(item.updatedOn) : null;
                return {
                    ...item,
                    eventFrom: eventFrom ? eventFrom : null,
                    eventTo: eventTo ? eventTo : null,
                    registrationFrom: registrationFrom ? registrationFrom : null,
                    registrationTo: registrationTo ? registrationTo : null,
                    createdOn: createdOn ? createdOn : null,
                    updatedOn: updatedOn ? updatedOn : null
                };
            });
            setDsEvent(EventData);
            setShowSkeleton(false);
        }
    }

    useEffect(() => {
        GetEventList();
    }, [eventStatus])

    const onCloseEventPopup = () => {
        setShowEventPopup(false);
    }

    const onCloseEmailTemplatePopup = () => {
        setShowEmailTemplatePopup(false);
    }

    useEffect(() => {
        if (status.eStatus !== null && status.eStatus !== eCRUDStatus.None) {
            const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
            UpdateListByID(PrimaryKeyID);
            setStatus({ eStatus: null })
        } else if (status.eStatus !== null && status.eStatus === eCRUDStatus.None) {
            setPrimaryKey(null);
            setStatus({ eStatus: null })
        }
    }, [status])

    const UpdateListByID = async (primaryKey) => {
        setShowSkeleton(true);
        GetEventList();
    };

    const onOptionChange = (e) => {
        if (e.fullName === "filterValue") {
            setFilterVisible(!!e.value);
        }
    };

    const handleAdd = () => {
        setPrimaryKey(0);
        setShowEventPopup(true)
    }

    const handleEdit = (e) => {
        setPrimaryKey(e.data.eventID)
        setShowEventPopup(true)
    }

    const onDeleteButtonClick = (e) => {
        let res = confirm(
            `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
            "Run"
        );
        res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
    };

    const handleDelete = async (e) => {
        const Id = e.row.data.eventID;
        const result = await eventService.DeleteRecord(Id);

        if (result.statusCode === 400) {
            var deleteMessage;
            if (result.data === "Deleting record exists in event group category.") {
                deleteMessage = "Deleting record exist in categories."
            } else {
                deleteMessage = result.data
            }
            ShowAlert(deleteMessage, "Run")
            setShowSkeleton(false)
        }
        else if (result.statusCode === 200) {
            setDsEvent(dsEvent.filter((x) => x.eventID !== e.row.data.eventID));

            // also delete event logo
            const deleteResult = await eventService.DeleteEventLogo(Id)
            if (deleteResult.statusCode === 400 || deleteResult.statusCode === 409) {
                ShowAlert("Cannot delete this event logo", "Run")
            } else {
                return;
            }

            setShowSkeleton(false)
        }
    }

    const onRowDbClick = (e) => {
        if (e?.rowType === "data") {
            setPrimaryKey(e.data.eventID)
            setShowEventPopup(true)
        }
    }


    const EventName = (cellData) => {
        return <>
            <div><span className='text-decoration-underline pointer-link text-break' title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.eventName}</span></div>
        </>
    }

    function onFocusedRowChanged(e) {
        setfocusedRowKey(e.component.option("focusedRowKey"));
    }

    const refreshDataGrid = () => {
        GetEventList();
        DataGridRef.current?.instance.clearFilter();
    };

    const onEmailTemplateClick = (e) => {
        setPrimaryKey(e.row.data.eventID)
        setEventName(e.row.data.eventName)
        setShowEmailTemplatePopup(true);
    }

    const onDashboardClick = () => {
        navigateTo('/dashboard')
    }

    const onGroupsClick =(e)=>{
        navigateTo('/Groups/List',{
            state: {
                EventID: e?.row.data.eventID,
                hideStatusFilter: true,
              },
        })
    }

    const StatusCellRender = (e) => {
        let eventStatus = e?.data?.eventStatusID;
        let RegStartSoon;
        let RegOpen;
        let EvenIsLive;
        let EventIsCompleted;
        let RegistrationClosed;
        if (eventStatus === 0) {
            RegStartSoon = true;
        } else if (eventStatus === 1) {
            RegOpen = true;
        } else if (eventStatus === 3) {
            EvenIsLive = true;
        } else if (eventStatus === 4) {
            EventIsCompleted = true;
        } else if (eventStatus === 2) {
            RegistrationClosed = true;
        }


        return (
            <>
                {
                    RegStartSoon ? <span title="Registration will start soon"><img src={StartSoon} alt='start soon' width={22} /></span>
                        : RegOpen ? <span title="Registrations are open"><img src={RegistrationOpenIcon} alt='registration open' width={24} /></span>
                            : EvenIsLive ? <span title="Event is live"><img src={EventLive} alt='event live' width={24} /></span>
                                : EventIsCompleted ? <span title="Event is completed"><img src={EventCompleted} alt='event is completed' width={24} /></span>
                                    : RegistrationClosed ? <span title="Registrations are closed"><img src={RegistrationClosedIcon} alt='event is completed' width={24} /></span> : <span></span>
                }
            </>
        )
    }

    return (
        <div>
            <Header
                title={"Events"}
                handleAdd={handleAdd}
                dataGridRef={DataGridRef}
                refreshDataGrid={refreshDataGrid}
                resetStatus={setEventStatus}
                DefaultstatusValue={1}
            />
            <div className={showSkeleton ? 'd-block' : 'd-none'}>
                <ListSkeletonFilter />
            </div>
            <div className={showSkeleton ? 'd-none' : 'd-block list-section'}>
                <DataGrid
                    ref={DataGridRef}
                    height={"100%"}
                    width={"100%"}
                    className='List_DataGrid'
                    dataSource={dsEvent}
                    keyExpr="eventID"
                    showBorders={true}
                    showColumnLines={false}
                    showRowLines={true}
                    focusedRowKey={focusedRowKey}
                    focusedRowEnabled={true}
                    wordWrapEnabled={true}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    autoNavigateToFocusedRow={true}
                    loadPanel={HideDatagridLoader}
                    filterSyncEnabled={true}
                    onOptionChanged={onOptionChange}
                    onRowDblClick={onRowDbClick}
                    noDataText='No Record Found'
                    onFocusedRowChanged={onFocusedRowChanged}
                >
                    <Sorting mode={"multiple"} />
                    <FilterPanel visible={filterVisible} />
                    <FilterBuilderPopup visible={false} container={"#none"} />
                    <Scrolling mode={'virtual'} />
                    <ColumnChooser enabled={true} width={!isExSmall ? 320 : 'auto'}>
                        <ColumnChooserSearch
                            enabled={true}
                        />
                    </ColumnChooser>
                    <HeaderFilter visible={true} >
                        <Search
                            enabled={true}
                        />
                    </HeaderFilter>
                    <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
                    <StateStoring enabled={true} type="localStorage" storageKey="event_Layout" />
                    <Pager
                        visible={true}
                        showInfo={true}
                        infoText={`{2} Rows`}
                    ></Pager>
                    <Toolbar>
                        <Item location={'before'} locateInMenu='auto'>
                            <SelectBox
                                labelMode='floating'
                                label='Status'
                                dataSource={eventStatusDropDown}
                                width={240}
                                className='mt-0'
                                displayExpr="name"
                                valueExpr="value"
                                value={eventStatus}
                                onValueChange={(e) => setEventStatus(e)}
                            />
                        </Item>
                        <Item location={'after'} name="columnChooserButton" locateInMenu='auto' />
                        <Item location={'after'} name="searchPanel" locateInMenu='auto' />

                    </Toolbar>
                    <Column
                        caption='Status'
                        dataField='eventStatusID'
                        minWidth={90}
                        width={90}
                        alignment='left'
                        allowHiding={false}
                        cellRender={StatusCellRender}
                        allowHeaderFiltering={false}
                    />
                    <Column
                        caption='Event Name'
                        cellRender={EventName}
                        dataField='eventName'
                        minWidth={120}
                        allowHiding={false}
                    />
                    <Column
                        caption='Event Code'
                        dataField='eventCode'
                        minWidth={100}
                        width={120}
                        allowHiding={false}
                    />
                    <Column
                        caption='Event From'
                        dataField='eventFrom'
                        minWidth={70}
                        width={160}
                        sortingMethod={sortingMethodDate}
                        allowHiding={false}
                    />
                    <Column
                        caption='Event To'
                        dataField='eventTo'
                        sortingMethod={sortingMethodDate}
                        minWidth={70}
                        width={160}
                        allowHiding={false}
                    />
                    <Column
                        caption='Registration From'
                        sortingMethod={sortingMethodDate}
                        dataField='registrationFrom'
                        minWidth={90}
                        width={160}
                        allowHiding={false}
                    />
                    <Column
                        caption='Registration To'
                        sortingMethod={sortingMethodDate}
                        dataField='registrationTo'
                        minWidth={70}
                        width={160}
                        allowHiding={false}
                    />
                    <Column
                        caption='Event Closing Message'
                        dataField='eventClosingMessagePlainText'
                        minWidth={120}
                        allowHiding={false}
                    />

                    <Column
                        dataField="createdByUserName"
                        caption="Created By"
                        visible={false}
                        width={125}
                        minWidth={80}
                        alignment="left"
                    />
                    <Column
                        dataField="updatedByUserName"
                        caption="Updated By"
                        visible={false}
                        width={125}
                        minWidth={80}
                        alignment="left"
                    />
                    <Column
                        dataField="createdOn"
                        caption="Created On"
                        visible={false}
                        width={135}
                        minWidth={80}
                        alignment="left"
                        sortingMethod={sortingMethodDate}
                    />
                    <Column
                        dataField="updatedOn"
                        caption="Updated On"
                        visible={false}
                        width={137}
                        minWidth={80}
                        alignment="left"
                        sortingMethod={sortingMethodDate}
                    />
                    <Column type='buttons' fixed={false} allowResizing={false} width={193} allowReordering={false}>

                        <DataGridButton
                            name='Groups'
                            icon='group'
                            hint='Groups'
                            visible={true}
                            cssClass='text-accent'
                            onClick={onGroupsClick}
                        />
                        <DataGridButton
                            name='emailTemplate'
                            icon='EmailTemplateIcon'
                            hint='Email Templates'
                            visible={true}
                            onClick={onEmailTemplateClick}
                        />
                        <DataGridButton
                            name='editVisitorRegistrationfForm'
                            icon='EditRegistrationformIcon'
                            hint='Visitor Registration Form'
                            visible={true}
                            onClick={onOpenVisitorRegistrationFormDesignerPopup}
                        />

                        <DataGridButton
                            name='editMediaRegistrationfForm'
                            icon='MediaRegistrationformIcon'
                            hint='Media Registration Form'
                            visible={true}
                            onClick={onOpenMediaRegistrationFormDesignerPopup}
                        />
                        <DataGridButton
                            name='Dashboard'
                            icon='home'
                            hint='Dashboard'
                            visible={true}
                            cssClass='text-success'
                            onClick={onDashboardClick}
                        />
                        <DataGridButton
                            name='delete'
                            icon='trash'
                            hint='Delete'
                            visible={true}
                            cssClass='text-danger'
                            onClick={onDeleteButtonClick}
                        />
                    </Column>
                </DataGrid>

            </div>
            {showEventPopup &&
                <EventPopup
                    popupShow={showEventPopup}
                    popupClose={onCloseEventPopup}
                    primaryKey={primaryKey}
                    setStatus={setStatus}
                />
            }
            {showEmailTemplatePopup &&
                <EmailTemplatePopup
                    popupShow={showEmailTemplatePopup}
                    popupClose={onCloseEmailTemplatePopup}
                    primaryKey={primaryKey}
                    currentEventName={eventname}
                />
            }
            {ShowRegistrationFormDesignerPopup &&
                <RegistrationFormDesigner
                    popupShow={ShowRegistrationFormDesignerPopup}
                    popupClose={onCloseRegistrationFormDesignerPopup}
                    EventDetail={SelectedEventDetail}
                    setStatus={setGroupStatus}
                />
            }
        </div>
    )
}

export default EventList