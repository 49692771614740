import { RunAEMSAPIService } from "../RunAEMSAPIService";
import { fetchWrapper } from "../authHeader";

const baseURL = process.env.REACT_APP_BASEURL;

const url = `${baseURL}UserManagement/User`;

export class UserService extends RunAEMSAPIService {

    constructor() {
        // call the super class constructor and pass in the name parameter
        super(url);
    }

    CheckDuplicateEmailID = async (EmailID, ExcludeID) => {
        EmailID = encodeURIComponent(EmailID);
        const result = await fetchWrapper.get(url + `/CheckDuplicateEmailID?EmailID=${EmailID}&ExcludeID=${ExcludeID}`);
        try {
            
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    message: "Failed To Load Data",
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }


    GetUserImage = async (ID) =>{
        const result = await fetchWrapper.get(url + '/GetUserProfileImage?userID=' + ID);
    try {
       
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            data: "Failed To Load Data",
            statusCode: result.status
        };
    }
    }

    UploadUserProfileImage = async (params,ID) =>{
        const formData = new FormData();
        formData.append("UserID", params.userID);
        formData.append("attachments", params.attachments);
        const result = await fetchWrapper.postwithFile(url + '/UpdateUserProfileImage/' + ID, formData);
    try {
        
        if (result.status && result.status === 200) {
            return {
                isOk: result.ok,
                data: await result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: result.ok,
                data: await result.text(),
                stautsCode: result.status && result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
    }


    DeleteUserImage = async (ID) =>{
        const result = await fetchWrapper.delete(url + '/DeleteUserProfileImage?UserID=' + ID);
    try {
        

        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: result.status
        };
    }
    }

}


