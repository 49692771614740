import { Button } from 'devextreme-react'
import React from 'react'

const PopupFooter = ({ ValidationGroupName, handleCancel, createUpdateStatus }) => {

  const onCancelClick = (e) => {
    if (e.validationGroup) {
      e.validationGroup.reset();
    }
    handleCancel()
  }

  return (
    <div>
      <hr className='mb-0' />
      <div className='row m-2 py-1'>
        <div className='col pt-xxl-2 pt-xl-2 pt-lg-2 pt-0 mt-xxl-2 mt-xl-2 mt-lg-2 mt-0'>
          {
            createUpdateStatus &&
            (
              createUpdateStatus.updatedByUserName ?
                <span className='footer-text'>Edited by <span className='footer-accent-text'>{
                  createUpdateStatus.updatedByUserName
                }</span> at <span className='footer-accent-text'>{new Date(new Date(createUpdateStatus.updatedOn + "Z").toUTCString()).toLocaleTimeString()}</span> on <span className='footer-accent-text'>{new Date(createUpdateStatus.updatedOn).toLocaleDateString()}</span></span>
                :
                createUpdateStatus.createdByUserName &&
                <span className='footer-text'>Created By <span className='footer-accent-text'>{
                  createUpdateStatus.createdByUserName
                }</span> at <span className='footer-accent-text'>{new Date(new Date(createUpdateStatus.createdOn + "Z").toUTCString()).toLocaleTimeString()}</span> on <span className='footer-accent-text'>{new Date(createUpdateStatus.createdOn).toLocaleDateString()}</span></span>
            )
          }


        </div>
        <div className='col-auto pe-1'>
          <Button
            text='Save'
            className='px-1 rounded-3 '
            useSubmitBehavior={true}
            width={85}
            height={34}
            type='default'
            validationGroup={ValidationGroupName}
          >
          </Button>
        </div>
        {handleCancel &&
          <div className='col-auto pe-1'>
            <Button
              text='Cancel'
              className=' px-1 rounded-3'
              onClick={onCancelClick}
              width={85}
              height={34}
              type='danger'
              validationGroup={ValidationGroupName}
            >
            </Button>
          </div>
        }
      </div>
    </div>
  )
}

export default PopupFooter;