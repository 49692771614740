import { Button, DataGrid, Popup, ScrollView } from 'devextreme-react'
import React, { useEffect, useRef, useState } from 'react'
import { HideDatagridLoader, eEmailStatus } from '../../utils/common-methods'
import { Column,Grouping, HeaderFilter, Pager, Scrolling, Search, SearchPanel, Sorting } from 'devextreme-react/cjs/data-grid'
import { useScreenSize } from '../../utils/media-query'
import { PopupHeader } from '../../layouts'
import { RegistrationAuthorizedService } from '../../api/services/registrationAuthorizedService'
import { TickIcon, rejectedIcon } from '../../utils/base-64-Icons'
import CircleLoader from '../../images/CircleLoader.gif';


const registrationAuthorizedService = new RegistrationAuthorizedService();

const EmailTemplate = (props) => {
    const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall } = useScreenSize();
    const dataGridRef = useRef(null);

    const [disableEmailButton, setDisableEmailButton] = useState(false);

    const onClosePopup = () =>{
        props.HideEmailTemplate();
    }

    const PopupTitle = (e) => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    showCloseButton={disableEmailButton}
                    title={[
                        "Email",
                    ]}
                />
            </>
        );
    };


    const [dsGroupRegistration, setDsGroupRegistration] = useState([]);

    const SendEmails = async (groupRegisIDs) => {
        
        for (let i = 0; i < groupRegisIDs.length; i++) {
            dsGroupRegistration.map(r => {
                if (r.registrationID === groupRegisIDs[i]) {
                    r.emailStatus = eEmailStatus.showLoader;
                    r.emailResult = "Loading...";
                }
            })
            const result = await registrationAuthorizedService.SendEmailOnStatusChange(groupRegisIDs[i])
                if(result.statusCode === 400){
                    dsGroupRegistration.map(r => {
                        if (r.registrationID === groupRegisIDs[i]) {
                            r.emailStatus = eEmailStatus.Error;
                            r.emailResult = result.data;
                        }
                    })
                }else if(result.statusCode === 500){
                    dsGroupRegistration.map(r => {
                        if (r.registrationID === groupRegisIDs[i]) {
                            r.emailStatus = eEmailStatus.Error;
                            r.emailResult = "Something went wrong, contact your administrator.";
                        }
                    })
                }
                // else if(result.isOk){
                //     return result.data;
                // }

            if (result.isOk === undefined && result !== null) {
                dsGroupRegistration.map(r => {
                    if (r.registrationID === groupRegisIDs[i]) {
                        r.emailStatus = eEmailStatus.Error;
                        r.emailResult = "Failed to send email";
                    }
                })
                dataGridRef.current?.instance.refresh();
            } else if (result && result.isOk) {
                
                dsGroupRegistration.map(r => {
                    if (r.registrationID === groupRegisIDs[i]) {
                        r.emailStatus = eEmailStatus.Success;
                        r.emailResult = "Email sent successfully";
                    }
                })
                dataGridRef.current?.instance.refresh();
            }
            if (groupRegisIDs.length === i + 1) {
                setDisableEmailButton(false);
            }
        };
    }

    useEffect(() => {
        
        if (props.selectedGroupRegisData && props.selectedGroupRegisData.length > 0) {
            let listData = [];
            props.selectedGroupRegisData.map(i => {
                i.emailStatus = eEmailStatus.InProgress;
                i.emailResult = "";
                listData.push(i)
            })
            setDsGroupRegistration(listData)
        } else {
            setDsGroupRegistration([])
        }

    }, [props.selectedGroupRegisData]);

    useEffect(()=>{
        if(dsGroupRegistration.length > 0){
            setDisableEmailButton(true)
            const groupRegisIDs = dsGroupRegistration?.filter(r => r.emailStatus !== eEmailStatus.Success).map(r => r.registrationID);
                SendEmails(groupRegisIDs, dsGroupRegistration)
        }
        },[dsGroupRegistration])


    const EmailStatusCellRender = (cellInfo) => {
        if (cellInfo.data.emailStatus === eEmailStatus.showLoader) {
            return (
                <div>
                    <>
                        <img src={CircleLoader} alt="" width={24} title={cellInfo.data.emailResult}/>
                    </>
                </div>
            );
        }else if (cellInfo.data.emailStatus === eEmailStatus.Success) {
            return (
                <div>
                    <>
                        <img src={TickIcon} alt="" width={24} title={cellInfo.data.emailResult}/>
                    </>
                </div>
            );
        }
        else if (cellInfo.data.emailStatus === eEmailStatus.Error) {
            return (
                <div>
                    <>
                        <img src={rejectedIcon} alt="" width={20} title={cellInfo.data.emailResult} className='rejected-icon-email-status'/>
                    </>
                </div>
            );
        }
    }
  return (
    <>
    
        <Popup
            visible={props.showEmailTemplate}
            onHiding={props.HideEmailTemplate}
            width={(isXSmall || isXXSmall || isExSmall) ? "95%" : isMedium || isSmall ? "60%" : "40%"}
            height={"auto"}
            maxHeight={"90%"}
            titleRender={PopupTitle}
            wrapperAttr={{ class: "CustomPopup" }}
            >
                
                <ScrollView width={'100%'} height={'100%'}>
                    <form >
                    <DataGrid
                       height={"480px"}
                       width={"100%"}
                       ref={dataGridRef}
                       className='List_DataGrid'
                       dataSource={dsGroupRegistration}
                       keyExpr="registrationID"
                       showBorders={true}
                       showColumnLines={false}
                       showRowLines={true}
                       focusedRowEnabled={true}
                       wordWrapEnabled={true}
                       hoverStateEnabled={true}
                       allowColumnReordering={true}
                       allowColumnResizing={true}
                       autoNavigateToFocusedRow={true}
                       loadPanel={HideDatagridLoader}
                       filterSyncEnabled={true}
                       noDataText='No Record Found'
                    >
                            <Sorting mode={"multiple"} />
                            <Scrolling mode={'virtual'} />
                            
                            <HeaderFilter visible={true} >
                                <Search
                                    enabled={true}
                                />
                            </HeaderFilter>
                            <Grouping contextMenuEnabled={false} />
                            <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
                            <Pager
                                visible={true}
                                showInfo={true}
                                infoText={`{2} Rows`}
                            ></Pager>

                            <Column
                                dataField='emailStatus'
                                cellRender={EmailStatusCellRender}
                                caption=''
                                width={40}
                                minWidth={30}
                                allowHeaderFiltering={false}
                                />
                            <Column
                                dataField='firstName'
                                caption='First Name'
                                minWidth={100}
                                />
                            <Column
                                dataField='lastName'
                                caption='Last Name'
                                minWidth={100}
                                />
                                <Column
                                dataField='middleName'
                                caption='Middle Name'
                                minWidth={100}
                                />
                            <Column
                                dataField='fullName'
                                caption='Full Name'
                                minWidth={100}
                                />
                                
                    </DataGrid>
                    </form>
                </ScrollView>
            </Popup>
    </>
  )
}

export default EmailTemplate