
export const navigation = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: 'HomeLightIcon'
  },
  {
    text: 'Event Managment',
    icon: 'EventsLightIcon',
    items: [
      {
        text: 'Events',
        path: '/Event/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Groups',
        path: '/Groups/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Categories',
        path: '/Category/List',
        icon: 'BulletIcon'
      },
    ]
  },
  {
    text: 'Settings',
    icon: 'AppSettingIcon',
    items: [
      {
        text: 'Users',
        path: '/Users/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Titles',
        path: '/Titles/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Nationalities',
        path: '/Nationalities/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Media Types',
        path: '/MediaTypes/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Media Functions',
        path: '/MediaFunctions/List',
        icon: 'BulletIcon'
      },
      {
        text: 'Login Page Banner ',
        path: '/Login-Page-Banner',
        icon: 'BulletIcon'
      },
    ]
  }
];
