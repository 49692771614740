import React from 'react';
import { Link } from 'react-router-dom';
import './InvalidPage.scss';

const InvalidPage = (props) => {

  const eventCode = localStorage.getItem("isGroupUser")

  return (
    <div className='row Invalid-page'>
      <div className='text-center mb-2'><i className='dx-icon-warning warning-icon'></i></div>
      <div className='text-center my-2 fw-semibold message'>Page url is not valid</div>
      {
        props.showSignInBtn &&
        <div className='text-center mb-2'><Link to={window.location.pathname.includes("/glogin/") ? window.origin + "/glogin/" + eventCode : "/login"}>Sign In</Link></div>
      }


    </div>
  )
}

export default InvalidPage;