import { RunAEMSAPIService } from "../RunAEMSAPIService";
import { fetchWrapper } from "../authHeader";

const baseURL = process.env.REACT_APP_BASEURL;

const url = `${baseURL}EmailManagement/EmailTemplate`;

export class EmailTemplateService extends RunAEMSAPIService {

    constructor() {
        // call the super class constructor and pass in the name parameter
        super(url);
    }

    GetComponentListRecord = async () => {
        const result = await fetchWrapper.get(this.apiUrl + '/GetComponents');
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetEmailTemplateRecord = async (ID) => {
        const result = await fetchWrapper.get(this.apiUrl + '/GetEmailTemplate/' + ID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    UpdateEmailTemplateRecord = async (params) => {
        const result = await fetchWrapper.post(this.apiUrl +  '/UpdateEmailTemplate', params);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetRejectionEmailTemplateRecord = async (ID, EmailTemplateTypeID) => {
        let url = '/GetEmailTemplate/' + ID;
        
        if(EmailTemplateTypeID){
            url += '/'+ EmailTemplateTypeID
        }
        const result = await fetchWrapper.get(this.apiUrl + url);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.text(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    
}


