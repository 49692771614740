import './GroupRegistrationPopup.scss'
import React, { createRef, useEffect, useRef, useState } from 'react';
import { Button, FileUploader, Popup, ScrollView, TextArea, TextBox } from 'devextreme-react';
import { GetFormattedDateTime, ShowAlert, dateOutOfRangeMessage, eCRUDStatus, minDate, toCamelCase, maxDOBDate } from '../../utils/common-methods';
import { PopupFooter, PopupHeader, PopupSkeleton } from '../../layouts';
import { useScreenSize } from '../../utils/media-query';
import Validator, { EmailRule, RequiredRule } from 'devextreme-react/validator';
import { SelectBox, Button as SelectBoxButton } from 'devextreme-react/select-box';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import { RegistrationAuthorizedService, eFileUploadType } from '../../api/services/registrationAuthorizedService';
import { alert } from "devextreme/ui/dialog";

const registrationAuthorizedService = new RegistrationAuthorizedService();

const GroupRegistrationPopup = (props) => {

    const { isExSmall, isXSmall, isXXSmall } = useScreenSize();
    let PrimaryKeyID = props.EventDetail.RegistrationID;
    const isAddMode = !PrimaryKeyID;
    const maxImageSizeLength = 10 * 1024 * 1024;
    const allowedFileExtensions = [".jpg", ".jpeg", ".gif", ".png"];

    const [componentItems, setComponentItems] = useState([]);
    const ValidationGroupName = "GroupRegistrationPopupValidation";
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [createUpdateStatus, setCreateUpdateStatus] = useState(null)
    const [RefreshSection, setRefreshSection] = useState(false);
    const targetRef = useRef(null);

    const [genderData, setgenderData] = useState([]);
    const [NationalityData, setNationalityData] = useState([]);
    const [MediaTypeData, setMediaTypeData] = useState([]);
    const [MediaFunctionData, setMediaFunctionData] = useState([]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                // handle resize
            });
        });

        if (targetRef.current) {
            resizeObserver.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                resizeObserver.unobserve(targetRef.current);
            }
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        if (PrimaryKeyID) {
            setShowSkeleton(true);
            GetFormDesign();
        }
        else {
            setShowSkeleton(true);
            GetFormDesign();
        }
    }, [PrimaryKeyID]);

    const UpdateComponentValue = (dsComponent, response) => {
        dsComponent.map(function (obj) {
            const ResponseKeys = Object.keys(response);
            if (obj.componentField === "dOB" || obj.componentField === "DOB" || obj.componentField === "dob") {
                const hasField = ResponseKeys.includes("dob");
                if (hasField) {
                    const propName = response["dob"];
                    obj.componentValue = propName;
                }
            } else {
                const hasField = ResponseKeys.includes(obj.componentField);
                if (hasField) {
                    const propName = response[obj.componentField];
                    obj.componentValue = propName;
                }
            }
        });
    }

    const UpdateDataSourceForDropdown = async (dsComponent) => {
        const hasGenderField = dsComponent.filter(r => r.componentTypeID === 3 && r.componentID === 10);
        if (hasGenderField.length > 0) {
            setgenderData([
                { genderID: 0, genderName: "Male" },
                { genderID: 1, genderName: "Female" }
            ])
        }

        const hasNationalityField = dsComponent.filter(r => r.componentTypeID === 3 && r.componentID === 9);
        if (hasNationalityField.length > 0) {
            const result = await registrationAuthorizedService.GetNationalityLookupList();
            if (result.statusCode === 200) {
                setNationalityData(result.data)
            } else {
                setNationalityData([])
            }
        }

        const hasMediaTypeField = dsComponent.filter(r => r.componentTypeID === 3 && r.componentID === 24);
        if (hasMediaTypeField.length > 0) {
            const result = await registrationAuthorizedService.GetMediaTypeLookupList();
            if (result.statusCode === 200) {
                setMediaTypeData(result.data)
            } else {
                setMediaTypeData([])
            }
        }

        const hasMediaFunctionField = dsComponent.filter(r => r.componentTypeID === 3 && r.componentID === 26);
        if (hasMediaFunctionField.length > 0) {
            const result = await registrationAuthorizedService.GetMediaFunctionLookupList();
            if (result.statusCode === 200) {
                setMediaFunctionData(result.data)
            } else {
                setMediaFunctionData([])
            }
        }

    }

    const UpdateFileUploderValues = async (dsComponent, ModelResponse) => {
        const CheckFileUploaderValidation = dsComponent.filter(r => r.componentTypeID === 7);
        if (CheckFileUploaderValidation.length > 0) {
            // For Photo
            const CheckPhotoValidation = CheckFileUploaderValidation.filter(r => r.componentID === 12);
            if (CheckPhotoValidation.length > 0) {
                dsComponent.map(r => {
                    if (r.componentID === 12) {
                        r.hasFile = true;
                        r.componentFileName = ModelResponse.photoFileName;
                    }
                })

            }
            // For Passport Copy
            const CheckPassportCopyValidation = CheckFileUploaderValidation.filter(r => r.componentID === 14);
            if (CheckPassportCopyValidation.length > 0) {
                dsComponent.map(r => {
                    if (r.componentID === 14) {
                        r.hasFile = true;
                        r.componentFileName = ModelResponse.passportCopyFileName;
                    }
                })

            }

            // For QID Copy
            const CheckQIDCopyValidation = CheckFileUploaderValidation.filter(r => r.componentID === 18);
            if (CheckQIDCopyValidation.length > 0) {
                dsComponent.map(r => {
                    if (r.componentID === 18) {
                        r.hasFile = true;
                        r.componentFileName = ModelResponse.qidCopyFileName;
                    }
                })
            }

            // For AIP Copy
            const CheckAIPCopyValidation = CheckFileUploaderValidation.filter(r => r.componentID === 23);
            if (CheckAIPCopyValidation.length > 0) {
                dsComponent.map(r => {
                    if (r.componentID === 23) {                        
                        r.hasFile = true;
                        r.componentFileName = ModelResponse.aipCopyFileName;
                    }
                })

            }
            setTimeout(() => {
                setShowSkeleton(false);
            }, 700)
        } else {
            setShowSkeleton(false);
        }
    }

    const GetModelRecord = async (dsComponent) => {
        const result = await registrationAuthorizedService.GetRecord(PrimaryKeyID);
        if (result.statusCode === 200) {
            const response = result.data;
            UpdateComponentValue(dsComponent, response);
            setCreateUpdateStatus(
                {
                    createdByUserName: result?.data.createdByUserName,
                    createdOn: result?.data.createdOn,
                    updatedByUserName: result?.data.updatedByUserName,
                    updatedOn: result?.data.updatedOn
                }
            )
            UpdateDataSourceForDropdown(dsComponent);
            UpdateFileUploderValues(dsComponent, response);
            setComponentItems(dsComponent);

        }
        else if (result.statusCode === 400 || result.statusCode === 409) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false);
        }
    };

    const GetFormDesign = () => {
        const components = props.GroupFormData.components.sort(function (a, b) {
            return a.index - b.index;
        });;
        let dsComponents = [];
        components.map(item => {
            const captions = props.ComponentDataSource.filter(r => r.componentID === item.componentID);
            dsComponents.push({
                componentID: item.componentID,
                componentTypeID: item.componentTypeID,
                caption: captions[0]?.caption,
                componentRef: createRef(),
                isMandatory: item.mandatory,
                componentValue: null,
                componentField: toCamelCase(captions[0]?.fieldName)
            })
        })
        if (!isAddMode) {
            GetModelRecord(dsComponents)
        } else {
            UpdateDataSourceForDropdown(dsComponents);
            setComponentItems(dsComponents);
            setShowSkeleton(false);
        }
    }

    const onComponentValueChange = (e, item) => {
        setRefreshSection(true);
        componentItems.map(r => {
            if (item.componentID === r.componentID) {
                if (item.componentID === 28 || item.componentID === 30) {
                    r.componentValue = GetFormattedDateTime(e)
                } else {
                    r.componentValue = e
                }
            }
        })
        setTimeout(() => {
            setRefreshSection(false);
        }, 100)
    }


    const DownloadFile = async (e, item) => {
        if (item.componentID === 12) {
            let fileData = item.componentFile;
            if (fileData) {
                const file = new Blob([fileData]);
                const fileURL = URL.createObjectURL(file);
                const downloadLink = document.createElement("a");
                downloadLink.href = fileURL;
                downloadLink.download = item.componentFileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(fileURL);
            } else {
                const result = await registrationAuthorizedService.DownloadPhoto(PrimaryKeyID);
                if (result.statusCode === 200) {
                    const response = await result.data;
                    const file = new File([response], "Image");
                    componentItems.map(r => {
                        if (r.componentID === 12) {
                            r.componentFile = file;
                            r.componentValue = response.size > 0 ? response : null
                        }
                    })
                    const fileURL = URL.createObjectURL(file);
                    const downloadLink = document.createElement("a");
                    downloadLink.href = fileURL;
                    downloadLink.download = item.componentFileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(fileURL);
                }
            }
        }
        else if (item.componentID === 14) {
            let fileData = item.componentFile;
            if (fileData) {
                const file = new Blob([fileData]);
                const fileURL = URL.createObjectURL(file);
                const downloadLink = document.createElement("a");
                downloadLink.href = fileURL;
                downloadLink.download = item.componentFileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(fileURL);
            } else {
                const result = await registrationAuthorizedService.DownloadPassportCopy(PrimaryKeyID);
                if (result.statusCode === 200) {
                    const response = await result.data;
                    const file = new File([response], "Image");
                    componentItems.map(r => {
                        if (r.componentID === 14) {
                            r.componentFile = file;
                            r.componentValue = response.size > 0 ? response : null
                        }
                    })
                    const fileURL = URL.createObjectURL(file);
                    const downloadLink = document.createElement("a");
                    downloadLink.href = fileURL;
                    downloadLink.download = item.componentFileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(fileURL);
                }
            }
        }
        else if (item.componentID === 18) {
            let fileData = item.componentFile;
            if (fileData) {
                const file = new Blob([fileData]);
                const fileURL = URL.createObjectURL(file);
                const downloadLink = document.createElement("a");
                downloadLink.href = fileURL;
                downloadLink.download = item.componentFileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(fileURL);
            } else {
                const result = await registrationAuthorizedService.DownloadQIDCopy(PrimaryKeyID);
                if (result.statusCode === 200) {
                    const response = await result.data;
                    const file = new File([response], "Image");
                    componentItems.map(r => {
                        if (r.componentID === 18) {
                            r.componentFile = file;
                            r.componentValue = response.size > 0 ? response : null
                        }
                    })
                    const fileURL = URL.createObjectURL(file);
                    const downloadLink = document.createElement("a");
                    downloadLink.href = fileURL;
                    downloadLink.download = item.componentFileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(fileURL);
                }
            }
        }
        else if (item.componentID === 23) {
            let fileData = item.componentFile;
            if (fileData) {
                const file = new Blob([fileData]);
                const fileURL = URL.createObjectURL(file);
                const downloadLink = document.createElement("a");
                downloadLink.href = fileURL;
                downloadLink.download = item.componentFileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(fileURL);
            } else {
                const result = await registrationAuthorizedService.DownloadAIPCopy(PrimaryKeyID);
                if (result.statusCode === 200) {
                    const response = await result.data;
                    const file = new File([response], "Image");
                    componentItems.map(r => {
                        if (r.componentID === 23) {
                            r.componentFile = file;
                            r.componentValue = response.size > 0 ? response : null
                        }
                    })
                    const fileURL = URL.createObjectURL(file);
                    const downloadLink = document.createElement("a");
                    downloadLink.href = fileURL;
                    downloadLink.download = item.componentFileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(fileURL);
                }
            }
        }
    }

    const onDeleteFile = async (e, item) => {
        setRefreshSection(true);
        if (item.hasFile) {
            componentItems.map(r => {
                if (r.componentID === item.componentID) {
                    r.isFileDeleted = true;
                    r.componentFileName = null;
                    r.componentFile = null;
                    r.componentValue = null;
                    r.isMandatory = item.isMandatory;
                }
            })
        } else {
            componentItems.map(r => {
                if (r.componentID === item.componentID) {
                    r.componentFileName = null;
                    r.componentFile = null;
                    r.componentValue = null;
                    r.isMandatory = item.isMandatory;
                }
            })
        }
        setTimeout(() => {
            item.componentRef.current?.instance.reset();
            setRefreshSection(false);
        }, 100)
    }

    const onFileUploaderValueChanged = (e, item) => {
        const file = e[0];
        if (file) {
            if (file?.type === "application/x-zip-compressed" || file?.type === "image/avif") {
                alert(
                    `<div class="row align-items-center"><i class="dx-icon-warning alerticon col-auto text-warning pe-0 fs-2" > </i><b class="alertText col">This file is not accepted.</b></div>`,
                    "Run"
                );
                item.componentRef?.current?.instance.reset();
                return;
            }
            if (file?.size > maxImageSizeLength) {
                alert(
                    `<div class="row align-items-center"><i class="dx-icon-warning alerticon col-auto text-warning fs-2 pe-0"> </i><b class="alertText col">Image of more than 10 MB size cannot be uploaded.</b></div>`,
                    "Run"
                );
                item.componentRef?.current?.instance.reset();
                return;
            }

            //if (file["type"]?.split("/")[0] === "image") {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setRefreshSection(true);
                componentItems.map(r => {
                    if (item.componentID === r.componentID) {
                        r.fileUploaded = true; // check is file uploaded or not
                        r.componentFileName = file.name;
                        r.componentValue = file;
                        r.componentFile = file;
                    }
                })
                setTimeout(() => {
                    setRefreshSection(false);
                }, 100)

            };
            fileReader.readAsDataURL(file);

            //  }
        }
    };

    const PopupTitle = (e) => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    title={[
                        <span key={"header_title"} className="base-accent-text">
                            {!isAddMode ? "Edit" : "New"}
                        </span>,
                        " Group Registration For ",
                        <span key={"header_subtitle"} className="base-accent-text">
                            {props.EventDetail.EventName}
                        </span>
                    ]}
                />
            </>
        );
    };

    const ClearUIFields = () => {
        setComponentItems([]);
    }

    const onClosePopup = () => {
        ClearUIFields();
        props.setStatus({ eStatus: eCRUDStatus.None })
        props.popupClose();
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        if (componentItems.length === 0) {
            ShowAlert("There are no control to save", "Run");
            return;
        }
        if (isAddMode) {
            InsertData();
        } else {
            UpdateData();
        }
    }

    const InsertData = async () => {
        setShowSkeleton(true);
        let dataToSave = {
            eventID: props.EventDetail.EventID,
            registrationTypeID: props.EventDetail.RegistrationTypeID,
            eventGroupID: props.EventDetail.EventGroupID
        }

        componentItems.map(function (obj) {
            if (obj.componentID) {
                const fieldToAdd = obj.componentField;
                dataToSave[fieldToAdd] = obj.componentValue;
            }
        });

        const result = await registrationAuthorizedService.InsertRecord(dataToSave);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Run");
                setShowSkeleton(false);
            }
        } else {
            // For Photo
            const CheckPhotoValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 12 && r.componentFile && r.fileUploaded === true);
            if (CheckPhotoValidation.length > 0) {
                UploadFile(CheckPhotoValidation[0]?.componentFile, result.data?.responseData, eFileUploadType.Photo);
            }
            // For Passport Copy
            const CheckPassportCopyValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 14 && r.componentFile && r.fileUploaded === true);
            if (CheckPassportCopyValidation.length > 0) {
                UploadFile(CheckPassportCopyValidation[0]?.componentFile, result.data?.responseData, eFileUploadType.PassportCopy);
            }
            // For QID Copy
            const CheckQIDCopyValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 18 && r.componentFile && r.fileUploaded === true);
            if (CheckQIDCopyValidation.length > 0) {
                UploadFile(CheckQIDCopyValidation[0]?.componentFile, result.data?.responseData, eFileUploadType.QIDCopy);
            }
            // For AIP Copy
            const CheckAIPCopyValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 23 && r.componentFile && r.fileUploaded === true);
            if (CheckAIPCopyValidation.length > 0) {
                UploadFile(CheckAIPCopyValidation[0]?.componentFile, result.data?.responseData, eFileUploadType.AIPCopy);
            }
            ClearUIFields();
            props.setStatus({
                eStatus: eCRUDStatus.Inserted
            });
            props.popupClose();
            setShowSkeleton(false);
        }
    }

    const UpdateData = async () => {
        setShowSkeleton(true);
        let dataToSave = {
            updatedOn: createUpdateStatus.updatedOn,
            registrationID: props.EventDetail.RegistrationID,
            eventID: props.EventDetail.EventID,
            registrationTypeID: props.EventDetail.RegistrationTypeID,
            eventGroupID: props.EventDetail.EventGroupID
        }

        componentItems.map(function (obj) {
            if (obj.componentID) {
                const fieldToAdd = obj.componentField;
                dataToSave[fieldToAdd] = obj.componentValue;
            }
        });

        const result = await registrationAuthorizedService.UpdateRecord(dataToSave);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Run");
                setShowSkeleton(false);
            }
        } else {
            // For Photo
            const CheckPhotoValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 12);
            if (CheckPhotoValidation.length > 0) {
                if (CheckPhotoValidation[0]?.isFileDeleted) {
                    DeleteFile(props.EventDetail.RegistrationID, eFileUploadType.Photo);
                }
                if (CheckPhotoValidation[0]?.fileUploaded && CheckPhotoValidation[0]?.componentFile) {
                    UploadFile(CheckPhotoValidation[0]?.componentFile, props.EventDetail.RegistrationID, eFileUploadType.Photo);
                }
            }
            // For Passport Copy
            const CheckPassportValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 14);
            if (CheckPassportValidation.length > 0) {
                if (CheckPassportValidation[0]?.isFileDeleted) {
                    DeleteFile(props.EventDetail.RegistrationID, eFileUploadType.PassportCopy);
                }
                if (CheckPassportValidation[0]?.fileUploaded && CheckPassportValidation[0]?.componentFile) {
                    UploadFile(CheckPassportValidation[0]?.componentFile, props.EventDetail.RegistrationID, eFileUploadType.PassportCopy);
                }
            }

            // For QID Copy
            const CheckQIDValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 18);
            if (CheckQIDValidation.length > 0) {
                if (CheckQIDValidation[0]?.isFileDeleted) {
                    DeleteFile(props.EventDetail.RegistrationID, eFileUploadType.QIDCopy);
                }
                if (CheckQIDValidation[0]?.fileUploaded && CheckQIDValidation[0]?.componentFile) {
                    UploadFile(CheckQIDValidation[0]?.componentFile, props.EventDetail.RegistrationID, eFileUploadType.QIDCopy);
                }
            }

            // For AIP Copy
            const CheckAIPValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 23);
            if (CheckAIPValidation.length > 0) {
                if (CheckAIPValidation[0]?.isFileDeleted) {
                    DeleteFile(props.EventDetail.RegistrationID, eFileUploadType.AIPCopy);
                }
                if (CheckAIPValidation[0]?.fileUploaded && CheckAIPValidation[0]?.componentFile) {
                    UploadFile(CheckAIPValidation[0]?.componentFile, props.EventDetail.RegistrationID, eFileUploadType.AIPCopy);
                }
            }
            ClearUIFields();
            props.setStatus({
                eStatus: eCRUDStatus.Updated
            });
            props.popupClose();
            setShowSkeleton(false);
        }
    }

    const UploadFile = async (File, RegistrationID, FileUploadType) => {
        var Datatoupload = { attachments: File }
        if (FileUploadType === eFileUploadType.Photo) {
            const result = await registrationAuthorizedService.UploadPhoto(Datatoupload, RegistrationID);
            if (result.isOk) {
                return;
            } else { }
        }
        if (FileUploadType === eFileUploadType.PassportCopy) {
            const result = await registrationAuthorizedService.UploadPassportCopy(Datatoupload, RegistrationID);
            if (result.isOk) {
                return;
            } else { }
        }
        if (FileUploadType === eFileUploadType.QIDCopy) {
            const result = await registrationAuthorizedService.UploadQIDCopy(Datatoupload, RegistrationID);
            if (result.isOk) {
                return;
            } else { }
        }
        if (FileUploadType === eFileUploadType.AIPCopy) {
            const result = await registrationAuthorizedService.UploadAIPCopy(Datatoupload, RegistrationID);
            if (result.isOk) {
                return;
            } else { }
        }

    }

    const DeleteFile = async (RegistrationID, FileUploadType) => {
        if (FileUploadType === eFileUploadType.Photo) {
            const result = await registrationAuthorizedService.DeletePhoto(RegistrationID)
            if (result.isOk) {
                return;
            } else { }
        }
        if (FileUploadType === eFileUploadType.PassportCopy) {
            const result = await registrationAuthorizedService.DeletePassportCopy(RegistrationID)
            if (result.isOk) {
                return;
            } else { }
        }
        if (FileUploadType === eFileUploadType.QIDCopy) {
            const result = await registrationAuthorizedService.DeleteQIDCopy(RegistrationID)
            if (result.isOk) {
                return;
            } else { }
        }
        if (FileUploadType === eFileUploadType.AIPCopy) {
            const result = await registrationAuthorizedService.DeleteAIPCopy(RegistrationID)
            if (result.isOk) {
                return;
            } else { }
        }
    }

    return (
        <>
            {props.popupShow && (
                <div ref={targetRef}>
                    <Popup
                        visible={props.popupShow}
                        titleRender={PopupTitle}
                        width={(isXSmall || isXXSmall || isExSmall) ? "75%" : "40%"}
                        height={"auto"}
                        maxHeight={"97vh"}
                        wrapperAttr={{ class: "CustomPopup" }}
                    >
                        <ScrollView height="100%" width="100%">
                            <form onSubmit={onFormSubmit}>
                                <div className='overflow-hidden'>
                                    {showSkeleton &&
                                        <div className='p-2 px-3 pb-0'>
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                        </div>
                                    }
                                    {!showSkeleton &&
                                        <div className='row'>
                                            {RefreshSection && <div> </div>}
                                            {componentItems.map((item, index) => {
                                                return (
                                                    <>
                                                        <div key={index}>
                                                            {item.componentTypeID === 1 &&
                                                                <>
                                                                    <div className='py-1 px-3'>
                                                                        <TextBox
                                                                            key={item.componentID}
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            value={item.componentValue}
                                                                            onValueChange={(e) => onComponentValueChange(e, item)}
                                                                            maxLength={item.componentID === 19 ? 50 : item.componentID === 20 ? 15 : undefined}

                                                                        >
                                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                {item.isMandatory &&
                                                                                    <RequiredRule message={`${item.caption} is required`} />
                                                                                }
                                                                                {item.componentID === 19 &&
                                                                                    <EmailRule message='Email ID is invalid' />
                                                                                }
                                                                            </Validator>
                                                                        </TextBox>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item.componentTypeID === 2 &&
                                                                <>
                                                                    <div className='py-1 px-3'>
                                                                        <TextArea
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            value={item.componentValue}
                                                                            onValueChange={(e) => onComponentValueChange(e, item)}
                                                                            maxLength={200}
                                                                        >
                                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                {item.isMandatory &&
                                                                                    <RequiredRule message={`${item.caption} is required`} />
                                                                                }
                                                                            </Validator>
                                                                        </TextArea>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item.componentTypeID === 3 && item.componentID === 10 &&
                                                                <>
                                                                    <div className='py-1 px-3'>
                                                                        <SelectBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            value={item.componentValue}
                                                                            showDropDownButton={true}
                                                                            dataSource={genderData}
                                                                            displayExpr="genderName"
                                                                            valueExpr="genderID"
                                                                            searchEnabled={true}
                                                                            onValueChange={(e) => onComponentValueChange(e, item)}
                                                                        >
                                                                            <SelectBoxButton
                                                                                name="dropdown"
                                                                                location="after"
                                                                                options={{
                                                                                    icon: 'spindown',
                                                                                    stylingMode: 'text'
                                                                                }}
                                                                            />
                                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                {item.isMandatory &&
                                                                                    <RequiredRule message={`${item.caption} is required`} />
                                                                                }
                                                                            </Validator>
                                                                        </SelectBox>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item.componentTypeID === 3 && item.componentID === 9 &&
                                                                <>
                                                                    <div className='py-1 px-3'>
                                                                        <SelectBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            value={item.componentValue}
                                                                            showDropDownButton={true}
                                                                            dataSource={NationalityData}
                                                                            displayExpr="nationalityName"
                                                                            valueExpr="nationalityID"
                                                                            searchEnabled={true}
                                                                            onValueChange={(e) => onComponentValueChange(e, item)}
                                                                        >
                                                                            <SelectBoxButton
                                                                                name="dropdown"
                                                                                location="after"
                                                                                options={{
                                                                                    icon: 'spindown',
                                                                                    stylingMode: 'text'
                                                                                }}
                                                                            />
                                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                {item.isMandatory &&
                                                                                    <RequiredRule message={`${item.caption} is required`} />
                                                                                }
                                                                            </Validator>
                                                                        </SelectBox>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item.componentTypeID === 3 && item.componentID === 24 &&
                                                                <>
                                                                    <div className='py-1 px-3'>
                                                                        <SelectBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            value={item.componentValue}
                                                                            showDropDownButton={true}
                                                                            dataSource={MediaTypeData}
                                                                            displayExpr="mediaTypeName"
                                                                            valueExpr="mediaTypeID"
                                                                            searchEnabled={true}
                                                                            onValueChange={(e) => onComponentValueChange(e, item)}
                                                                        >
                                                                            <SelectBoxButton
                                                                                name="dropdown"
                                                                                location="after"
                                                                                options={{
                                                                                    icon: 'spindown',
                                                                                    stylingMode: 'text'
                                                                                }}
                                                                            />
                                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                {item.isMandatory &&
                                                                                    <RequiredRule message={`${item.caption} is required`} />
                                                                                }
                                                                            </Validator>
                                                                        </SelectBox>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item.componentTypeID === 3 && item.componentID === 26 &&
                                                                <>
                                                                    <div className='py-1 px-3'>
                                                                        <SelectBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            value={item.componentValue}
                                                                            showDropDownButton={true}
                                                                            dataSource={MediaFunctionData}
                                                                            displayExpr="mediaFunctionName"
                                                                            valueExpr="mediaFunctionID"
                                                                            searchEnabled={true}
                                                                            onValueChange={(e) => onComponentValueChange(e, item)}
                                                                        >
                                                                            <SelectBoxButton
                                                                                name="dropdown"
                                                                                location="after"
                                                                                options={{
                                                                                    icon: 'spindown',
                                                                                    stylingMode: 'text'
                                                                                }}
                                                                            />
                                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                {item.isMandatory &&
                                                                                    <RequiredRule message={`${item.caption} is required`} />
                                                                                }
                                                                            </Validator>
                                                                        </SelectBox>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item.componentTypeID === 4 &&
                                                                <>
                                                                    <div className='py-1 px-3'>
                                                                        <DateBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            value={item.componentValue}
                                                                            type="date"
                                                                            displayFormat={"dd/MM/yyyy"}
                                                                            useMaskBehavior={true}
                                                                            onValueChange={(e) => onComponentValueChange(e, item)}
                                                                            min={minDate}
                                                                            dateOutOfRangeMessage={dateOutOfRangeMessage}
                                                                            max={item.componentID === 11 ? maxDOBDate : undefined}
                                                                        >
                                                                            <DateBoxButton name="dropDown" />
                                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                {item.isMandatory &&
                                                                                    <RequiredRule message={`${item.caption} is required`} />
                                                                                }
                                                                            </Validator>
                                                                        </DateBox>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item.componentTypeID === 5 &&
                                                                <>
                                                                    <div className='py-1 px-3'>
                                                                        <DateBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            value={item.componentValue}
                                                                            type="time"
                                                                            onValueChange={(e) => onComponentValueChange(e, item)}
                                                                            useMaskBehavior={true}
                                                                            displayFormat="shortTime"
                                                                        >
                                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                {item.isMandatory &&
                                                                                    <RequiredRule message={`${item.caption} is required`} />
                                                                                }
                                                                            </Validator>
                                                                        </DateBox>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item.componentTypeID === 6 &&
                                                                <>
                                                                    <div className='py-1 px-3 d-flex'>
                                                                        <div className='col'>
                                                                            <DateBox
                                                                                id={item.componentID}
                                                                                name={item.caption}
                                                                                ref={item.componentRef}
                                                                                label={item.caption.split(" ")[0] + " Date"}
                                                                                labelMode='floating'
                                                                                value={item.componentValue}
                                                                                type="date"
                                                                                displayFormat={"dd/MM/yyyy"}
                                                                                pickerType="calendar"
                                                                                onValueChange={(e) => onComponentValueChange(e, item)}
                                                                                useMaskBehavior={true}
                                                                                min={minDate}
                                                                                dateOutOfRangeMessage={dateOutOfRangeMessage}
                                                                            >
                                                                                <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                    {item.isMandatory &&
                                                                                        <RequiredRule message={`${item.caption} is required`} />
                                                                                    }
                                                                                </Validator>
                                                                            </DateBox> </div> &nbsp;
                                                                        <div className='col'>
                                                                            <DateBox
                                                                                id={item.componentID}
                                                                                name={item.caption}
                                                                                ref={item.componentRef}
                                                                                label={item.caption.split(" ")[0] + " Time"}
                                                                                labelMode='floating'
                                                                                value={item.componentValue}
                                                                                type="time"
                                                                                onValueChange={(e) => onComponentValueChange(e, item)}
                                                                                useMaskBehavior={true}
                                                                                displayFormat="shortTime"
                                                                            >
                                                                                <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                    {item.isMandatory &&
                                                                                        <RequiredRule message={`${item.caption} is required`} />
                                                                                    }
                                                                                </Validator>
                                                                            </DateBox>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item.componentTypeID === 7 &&
                                                                <>
                                                                    <div className='py-1 px-3'>
                                                                        <div className='RegistrationPageFileUploader d-flex'>
                                                                            <div className='col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-12'>
                                                                                <FileUploader
                                                                                    ref={item.componentRef}
                                                                                    id={item.componentID}
                                                                                    accept="*"
                                                                                    selectButtonText={`Select ${item.caption}`}
                                                                                    maxFileSize={maxImageSizeLength}
                                                                                    multiple={false}

                                                                                    //allowedFileExtensions={allowedFileExtensions}
                                                                                    uploadMode="useForm"
                                                                                    onValueChange={(e) => onFileUploaderValueChanged(e, item)}
                                                                                    labelText=""
                                                                                    showFileList={false}
                                                                                >
                                                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                                                                        {item.isMandatory && (item.componentFileName === undefined || item.componentFileName === null) &&
                                                                                            <RequiredRule message={`${item.caption} is required`} />
                                                                                        }
                                                                                    </Validator>
                                                                                </FileUploader>
                                                                            </div>
                                                                            <div className='col-xxl-8 col-xl-7 col-lg-7 col-md-6 col-sm-12 pt-4'>
                                                                                {item.componentFileName && item.componentFileName !== null &&
                                                                                    <div className='d-flex'>
                                                                                        <span className='text-decoration-underline fw-semibold cursor-pointer' onClick={(e) => DownloadFile(e, item)}>
                                                                                            {item.componentFileName}
                                                                                        </span>

                                                                                        <Button
                                                                                            icon="trash"
                                                                                            hint='Remove File'
                                                                                            className={`removeFileButton`}
                                                                                            stylingMode="text"
                                                                                            type="danger"
                                                                                            onClick={(e) => onDeleteFile(e, item)}
                                                                                            hoverStateEnabled={false}
                                                                                            activeStateEnabled={false}
                                                                                            focusStateEnabled={false}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>
                                                    </>
                                                )
                                            })
                                            }

                                        </div>

                                    }
                                    {showSkeleton &&
                                        <div className='d-flex justify-content-end pb-2 pe-3'>
                                            <div className='skeleton-View Skeleton-popup-footer'></div>
                                        </div>
                                    }
                                    {!showSkeleton &&
                                        <PopupFooter
                                            ValidationGroupName={ValidationGroupName}
                                            handleCancel={onClosePopup}
                                            createUpdateStatus={createUpdateStatus}
                                        />
                                    }
                                </div>
                            </form>
                        </ScrollView>
                    </Popup>
                </div>
            )}
        </>
    );
}


export default GroupRegistrationPopup