import React from 'react';
import "./ListSkeleton.scss";
import { ScrollView } from "devextreme-react";

const ListSkeletonFilter = () => {
    return (
        <>
            <ScrollView width={'100%'} height={'100%'}>
                <div className="skeleton-section">
                    <div className='d-flex justify-content-between'>
                        <div className="d-flex skeleton-toolbar-height rounded list-toolbar-skeleton-left"></div>
                        <div className="d-flex skeleton-toolbar-height rounded list-toolbar-skeleton"></div>
                    </div>
                    <span className="custom-skeleton skeleton-header-height"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton"></span>
                    <span className="custom-skeleton skeleton-rows"></span>
                </div>
            </ScrollView>
        </>
    )
}

export default ListSkeletonFilter