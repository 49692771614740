import './home.scss';

export const CardSkeleton = () =>{
    return(
        <>
        <div className='d-flex'>
        <div className='card-skeleton mx-2'></div>
        <div className='card-skeleton mx-2'></div>
        <div className='card-skeleton mx-2'></div>
        </div>
        </>
    )
}

export const LeftGridSkeleton = () =>{
    return(
        <>
        <div className='left-grid-skeleton'>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        <div className='left-grid-skeleton-row mx-2'></div>
        </div>
        </>
    )
}

export const RightListSkeleton = () =>{
    return(
        <>
        <div className='pt-1 pe-3'>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        <div className='right-grid-skeleton mx-2'></div>
        </div>
        </>
    )
}