import DataGrid, { Column, Button as DataGridButton, FilterPanel, Scrolling, ColumnChooser, HeaderFilter, SearchPanel, FilterBuilderPopup, Pager, StateStoring, Toolbar, Sorting, Item, ColumnChooserSearch, Search, } from 'devextreme-react/data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { useScreenSize } from '../../../utils/media-query';
import { Header } from '../../../components';
import ListSkeleton from '../../../layouts/templates/ListSkeleton';
import { confirm } from 'devextreme/ui/dialog';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, eventStatusDropDown, sortingMethodDate } from '../../../utils/common-methods';
import { EventGroupCategoryService } from '../../../api/services/eventGroupCategoryService';
import EventGroupCategoryPopup from './EventGroupCategoryPopup';
import { SelectBox } from 'devextreme-react';
import ListSkeletonFilter from '../../../layouts/templates/ListSkeletonFilter';
import { eRegistrationType } from '../../../api/services/registrationFormDesignerService';
import RegistrationFormDesigner from '../../registration-form/RegistrationFormDesigner';

const eventGroupCategoryService = new EventGroupCategoryService();

const EventGroupCategoryList = () => {

    const { isExSmall, isXXSmall } = useScreenSize();
    const [dsEventGroupCategory, setDsEventGroupCategory] = useState([]);
    const [filterVisible, setFilterVisible] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true)

    const [showEventGroupCategoryPopup, setShowEventGroupCategoryPopup] = useState(false)
    const [primaryKey, setPrimaryKey] = useState(null)
    const [focusedRowKey, setfocusedRowKey] = useState(0);
    const [status, setStatus] = useState({
        eStatus: null,
        primaryKeyID: primaryKey,
    });
    const [eventStatus, setEventStatus] = useState(1);

    // Start For Group Registration Form Designer
    const [ShowGroupRegistrationFormDesignerPopup, setShowGroupRegistrationFormDesignerPopup] = useState(false);
    const [SelectedEventDetail, setSelectedEventDetail] = useState(null);

    const [GroupStatus, setGroupStatus] = useState({
        eStatus: null
    });

    // End For Group Registration Form Designer

    const DataGridRef = useRef(null);

    const GetEventGroupCategoryList = async () => {
        setShowSkeleton(true)
        const result = eventStatus === null ? await eventGroupCategoryService.GetListRecord() : await eventGroupCategoryService.GetListRecordWithFilter(eventStatus);
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")
            setShowSkeleton(false);
        } else {
            const EventGroupCategoryData = result?.data?.map(item => {
                const createdOn = item.createdOn ? GetFormattedDateTimeUTCString(item.createdOn) : null;
                const updatedOn = item.updatedOn ? GetFormattedDateTimeUTCString(item.updatedOn) : null; 
                return {
                    ...item,
                    createdOn: createdOn ? createdOn : null,
                    updatedOn: updatedOn ? updatedOn : null
                };
            });
            setDsEventGroupCategory(EventGroupCategoryData);
            setShowSkeleton(false);
        }
    }

    useEffect(() => {
        GetEventGroupCategoryList();
    }, [eventStatus])

    const onCloseEventGroupCategoryPopup = () => {
        setShowEventGroupCategoryPopup(!showEventGroupCategoryPopup);
    }

    useEffect(() => {
        if (status.eStatus !== null && status.eStatus !== eCRUDStatus.None) {
            const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
            UpdateListByID(PrimaryKeyID);
            setStatus({ eStatus: null })
        } else if (status.eStatus !== null && status.eStatus === eCRUDStatus.None) {
            setPrimaryKey(null);
            setStatus({ eStatus: null })
        }
    }, [status])

    const UpdateListByID = async (primaryKey) => {
        setShowSkeleton(true);
        GetEventGroupCategoryList();
        // const result = await eventGroupCategoryService.GetListByIDRecord(primaryKey);
        // if (result.statusCode === 400) {
        //     ShowAlert(result.data, "Run")
        //     setShowSkeleton(false)
        // } else {
        //     const EventGroupCategoryData = result?.data?.map(item => {
        //        const createdOn = item.createdOn ? GetFormattedDateTimeUTCString(item.createdOn) : null;
        //        const updatedOn = item.updatedOn ? GetFormattedDateTimeUTCString(item.updatedOn) : null;
        //         return {
        //             ...item,
        //             createdOn: createdOn ? createdOn : null,
        //             updatedOn: updatedOn ? updatedOn : null
        //         };
        //     });
        //     if (status.eStatus === eCRUDStatus.Inserted) {
        //         setDsEventGroupCategory([EventGroupCategoryData[0], ...dsEventGroupCategory]);
        //         setShowSkeleton(false)
        //     } else if (status.eStatus === eCRUDStatus.Updated) {
        //         setDsEventGroupCategory(dsEventGroupCategory.map((i) => i.eventGroupCategoryID === primaryKey ? EventGroupCategoryData[0] : i));
        //         setShowSkeleton(false)
        //     }
        // }
        // setfocusedRowKey(primaryKey);
        // setPrimaryKey(null);
    };

    // Start For Group Registration Form Designer

    useEffect(() => {
        if (GroupStatus.eStatus !== null && GroupStatus.eStatus !== eCRUDStatus.None) {
            setSelectedEventDetail(null);
            GetEventGroupCategoryList();
            setGroupStatus({ eStatus: null })
        } else if (GroupStatus.eStatus !== null && GroupStatus.eStatus === eCRUDStatus.None) {
            setSelectedEventDetail(null);
            setGroupStatus({ eStatus: null })
        }
    }, [GroupStatus]);

    const onShowGroupRegistrationFormDesignerPopup = (e) => {      
        setSelectedEventDetail({
            EventID: e.row.data.eventID,
            EventName:e.row.data.eventName,
            EventGroupCategoryID: e.row.data.eventGroupCategoryID,
            RegistrationFormID:e.row.data.groupRegistrationFormID,
            RegistrationTypeID:eRegistrationType.Group
        })
        setShowGroupRegistrationFormDesignerPopup(!ShowGroupRegistrationFormDesignerPopup);
    }

    const onCloseGroupRegistrationFormDesignerPopup = () => {
        setShowGroupRegistrationFormDesignerPopup(!ShowGroupRegistrationFormDesignerPopup);
    }

    // End For Group Registration Form Designer

    const onOptionChange = (e) => {
        if (e.fullName === "filterValue") {
            setFilterVisible(!!e.value);
        }
    };

    const handleAdd = () => {
        setPrimaryKey(0)
        setShowEventGroupCategoryPopup(!showEventGroupCategoryPopup)
    }

    const handleEdit = (e) => {        
        setPrimaryKey(e.data.eventGroupCategoryID)
        setShowEventGroupCategoryPopup(!showEventGroupCategoryPopup)
    }

    const onDeleteButtonClick = (e) => {
        let res = confirm(
            `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
            "Run"
        );
        res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
    };

    const handleDelete = async (e) => {
        const Id = e.row.data.eventGroupCategoryID;
        const result = await eventGroupCategoryService.DeleteRecord(Id);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false)
        }
        else if (result.statusCode === 200) {
            setDsEventGroupCategory(dsEventGroupCategory.filter((x) => x.eventGroupCategoryID !== e.row.data.eventGroupCategoryID));
            setShowSkeleton(false)
        }
    }

    const onRowDbClick = (e) => {
        if (e?.rowType === "data") {
            setPrimaryKey(e.data.eventGroupCategoryID)
            setShowEventGroupCategoryPopup(true)
        }
    }

    const eventGroupCategoryName = (cellData) => {
        return <>
            <div>
                <span className='text-decoration-underline pointer-link text-break' title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.eventGroupCategoryName}</span>
            </div>
        </>
    }

    function onFocusedRowChanged(e) {
        setfocusedRowKey(e.component.option("focusedRowKey"));
    }

    const refreshDataGrid = () => {
        GetEventGroupCategoryList();
        DataGridRef.current?.instance.clearFilter();
    };

    const GroupCell = (options) => <div>{options.value}</div>;

    return (
        <div>
            <Header
                title={"Categories"}
                handleAdd={handleAdd}
                dataGridRef={DataGridRef}
                refreshDataGrid={refreshDataGrid}
                resetStatus={setEventStatus}
                DefaultstatusValue={1}
            />
            <div className={showSkeleton ? 'd-block' : 'd-none'}>
                <ListSkeletonFilter />
            </div>
            <div className={showSkeleton ? 'd-none' : 'd-block list-section'}>
                <DataGrid
                    ref={DataGridRef}
                    height={"100%"}
                    width={"100%"}
                    className='List_DataGrid'
                    dataSource={dsEventGroupCategory}
                    keyExpr="eventGroupCategoryID"
                    showBorders={true}
                    showColumnLines={false}
                    showRowLines={true}
                    focusedRowKey={focusedRowKey}
                    focusedRowEnabled={true}
                    wordWrapEnabled={true}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    autoNavigateToFocusedRow={true}
                    loadPanel={HideDatagridLoader}
                    filterSyncEnabled={true}
                    onOptionChanged={onOptionChange}
                    onRowDblClick={onRowDbClick}
                    noDataText='No Record Found'
                    onFocusedRowChanged={onFocusedRowChanged}
                >
                    <Sorting mode={"multiple"} />
                    <FilterPanel visible={filterVisible} />
                    <FilterBuilderPopup visible={false} container={"#none"} />
                    <Scrolling mode={'virtual'} />
                    <ColumnChooser enabled={true} width={!isExSmall ? 320 : 'auto'}>
                        <ColumnChooserSearch
                            enabled={true}
                        />
                    </ColumnChooser>
                    <HeaderFilter visible={true} >
                        <Search
                            enabled={true}
                        />
                    </HeaderFilter>
                    <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
                    <StateStoring enabled={true} type="localStorage" storageKey="EventGroupCategory_Layout" />
                    <Pager
                        visible={true}
                        showInfo={true}
                        infoText={`{2} Rows`}
                    ></Pager>
                    <Toolbar>
                        <Item location={'before'} locateInMenu='auto'>
                            <SelectBox
                                labelMode='floating'
                                label='Status'
                                dataSource={eventStatusDropDown}
                                width={240}
                                className='mt-0'
                                displayExpr="name"
                                valueExpr="value"
                                value={eventStatus}
                                onValueChange={(e) => setEventStatus(e)}
                            />
                        </Item>
                        <Item location={'after'} name="columnChooserButton" locateInMenu='auto' />
                        <Item location={'after'} name="searchPanel" locateInMenu='auto' />

                    </Toolbar>
                    <Column
                        caption='Category'
                        cellRender={eventGroupCategoryName}
                        dataField='eventGroupCategoryName'
                        minWidth={200}
                        allowHiding={false}
                    />
                    <Column
                        caption='Event'
                        dataField='eventName'
                        minWidth={120}
                        groupIndex={0}
                        groupCellRender={GroupCell}
                    />
                    <Column
                        dataField="createdByUserName"
                        caption="Created By"
                        visible={false}
                        width={125}
                        minWidth={80}
                        alignment="left"
                    />
                    <Column
                        dataField="updatedByUserName"
                        caption="Updated By"
                        visible={false}
                        width={125}
                        minWidth={80}
                        alignment="left"
                    />
                    <Column
                        dataField="createdOn"
                        caption="Created On"
                        visible={false}
                        width={145}
                        minWidth={80}
                        alignment="left"
                        sortingMethod={sortingMethodDate}
                    />
                    <Column
                        dataField="updatedOn"
                        caption="Updated On"
                        visible={false}
                        width={145}
                        minWidth={80}
                        alignment="left"
                        sortingMethod={sortingMethodDate}
                    />
                    <Column type='buttons' fixed={false} allowResizing={false} width={80} allowReordering={false}>
                      
                        <DataGridButton
                            name='EditRegistration'
                            icon='EditRegistrationformIcon'
                            hint='Group Registration Form'
                            cssClass=''
                            visible={true}
                            onClick={onShowGroupRegistrationFormDesignerPopup}
                        />
                          <DataGridButton
                            name='delete'
                            icon='trash'
                            hint='Delete'
                            visible={true}
                            cssClass='text-danger'
                            onClick={onDeleteButtonClick}
                        />
                    </Column>
                </DataGrid>
            </div>
            {showEventGroupCategoryPopup &&
                <EventGroupCategoryPopup
                    popupShow={showEventGroupCategoryPopup}
                    popupClose={onCloseEventGroupCategoryPopup}
                    primaryKey={primaryKey}
                    setStatus={setStatus}
                />
            }
            {ShowGroupRegistrationFormDesignerPopup &&
                <RegistrationFormDesigner
                    popupShow={ShowGroupRegistrationFormDesignerPopup}
                    popupClose={onCloseGroupRegistrationFormDesignerPopup}
                    EventDetail={SelectedEventDetail}                    
                    setStatus={setGroupStatus}
                />
            }
        </div>
    )
}

export default EventGroupCategoryList