import React, { useEffect, useState } from 'react';
import { Button, LoadIndicator } from 'devextreme-react';
import Validator, { EmailRule, RequiredRule } from 'devextreme-react/validator';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import openeye from '../../../images/openeye.png';
import closeeyeIcon from '../../../images/closeeyeIcon .png';
import { useNavigate, useParams } from 'react-router-dom';
import './GroupLoginForm.scss';
import { onDragOver } from '../../../utils/common-methods';
import { useAuth } from '../../../contexts/auth';

const GroupLoginForm = (props) => {

    const { eventCode } = useParams();
    const { groupSignIn, GetEventDetails, GetEventLogo } = useAuth();

    const [showLoaderForLogo, setShowLoaderForLogo] = useState(false);
    const [PasswordMode, setPasswordMode] = useState("password");
    const [showPasswordIcon, setShowpasswordIcon] = useState(openeye)
    const [errorMessage, setErrorMessage] = useState(null);
    const [EventDetail, setEventDetail] = useState(null);
    const [eventLogo, setEventLogo] = useState(null);
    const [formData, setFormData] = useState({
        emailid: null,
        password: null,
    });

    const navigate = useNavigate();

    const ValidationGroupName = "groupLoginValidation"

    useEffect(() => {
        GetDetailsByEventCode();
    }, [])

    const PasswordOptions = {
        icon: showPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setPasswordMode(PasswordMode === 'text' ? 'password' : 'text');
            setShowpasswordIcon(showPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        props.setShowLoader(true);
        var signInCredentials = {
            eventCode: eventCode,
            emailID: formData.emailid,
            password: formData.password
        }
        const result = await groupSignIn(signInCredentials);

        if (!result?.isOk) {
            setErrorMessage(result.message)
            props.setShowLoader(false);
        }
        else {
            setErrorMessage("")
            props.setShowLoader(false);
        }
    }

    const GetDetailsByEventCode = async () => {
        setShowLoaderForLogo(true)
        const result = await GetEventDetails(eventCode);
        if (!result.isOk) {
            navigate('/glogin/invalid-page')
            setShowLoaderForLogo(false)
            props.setShowSignInBtn(false)
        } else {
            const currentDateTime = new Date();
            if (currentDateTime > new Date(result.data.eventTo)) {
                navigate('/registration-closed', {
                    state: {
                        EventID: result.data.eventID,
                        EventName: result.data.eventName,
                        PlainClosingMessage: result.data.eventClosingMessagePlainText,
                        FormattedClosingMessage: result.data.eventClosingMessageFormattedText
                    }
                })
            } else {
                setEventDetail(result.data);
                //   document.getElementById('titleSection').textContent = result.data.eventName + " Group Login";
                document.title = result?.data?.eventName + " - Group Registration" ;
                props.setShowSignInBtn(true)
                const logoResult = await GetEventLogo(eventCode)
                if (logoResult) {
                    setEventLogo(window.URL.createObjectURL(logoResult))
                    setShowLoaderForLogo(false)
                } else {
                    setEventLogo(null)
                    setShowLoaderForLogo(false)
                }
            }

        }
    }

    useEffect(() => {
        eventCode && localStorage.setItem("isGroupUser", eventCode)
    }, [])

    return (
        <div>
            {props.showLoader ?
                <div className='m-auto group-loaderCart-height text-center '>
                    <LoadIndicator className='group-indicator' id="small-indicator" height={50} width={50} visible={true} />
                    <div className='pt-1'>Please Wait.....</div>
                </div>
                :
                <form onSubmit={handleSubmit} className='px-3 group-login-form' onDragOver={onDragOver}>
                    {
                        <div className='gLogin_completeForm'>

                            <div className='text-center EventLogo_div m-auto'>
                                {
                                    showLoaderForLogo ?
                                        <div className='loader-for-logo mt-3'>
                                            <LoadIndicator id="small-indicator" height={30} width={30} visible={true} />
                                        </div>
                                        :
                                        eventLogo &&
                                        <img src={eventLogo} alt='event logo' className='mt-3 eventLogo_imageTag' />
                                }

                            </div>
                            {!showLoaderForLogo &&
                                <div className={'title pt-4 text-center w-70'}>{EventDetail?.eventName}</div>
                            }
                        </div>
                    }
                    <div className={`row py-2 mt-2`}>
                        <TextBox
                            label={'Email ID'}
                            mode='email'
                            labelMode='floating'
                            stylingMode='underlined'
                            className='py-1 px-0'
                            value={formData.emailid}
                            onValueChange={(value) => setFormData({ ...formData, emailid: value })}

                        >
                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                <RequiredRule message='Email ID is required' />
                                <EmailRule message='Email ID is not valid' />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className='row py-2 '>
                        <TextBox
                            label='Password'
                            mode={PasswordMode}
                            labelMode='floating'
                            stylingMode='underlined'
                            maxLength={16}
                            className='py-1 px-0'
                            value={formData.password}
                            onValueChange={(value) => setFormData({ ...formData, password: value })}
                        >
                            <TextBoxButton
                                name="password"
                                location="after"
                                options={PasswordOptions}
                            />
                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                <RequiredRule message='Password is required' />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className='text-danger text-center'>{errorMessage}</div>
                    <div className='row py-2 mt-2 justify-content-center'>

                        <Button
                            width="auto"
                            text='Sign In'
                            useSubmitBehavior={true}
                            type='default'
                            validationGroup={ValidationGroupName}
                        >
                        </Button>
                    </div>
                    <div className='row py-2 justify-content-center'>

                        <Button
                            width="auto"
                            text='Forgot Password?'
                            focusStateEnabled={false}
                            hoverStateEnabled={false}
                            activeStateEnabled={false}
                            className='border-0 forgot-password-button'
                            onClick={() => navigate('/glogin/forgot-password',{
                                state:{
                                    eventCode:eventCode
                                }
                            })}
                        >
                        </Button>


                    </div>
                </form>
            }
        </div >
    )
}

export default GroupLoginForm;