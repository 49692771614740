import { CheckBox, NumberBox, Popup, ScrollView, TextArea } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { PopupHeader, PopupFooter, PopupSkeleton, PopupSkeletonOneLine } from '../../../layouts';
import { useScreenSize } from "../../../utils/media-query";
import {
    ConflictPopup,
    ShowAlert,
    eCRUDStatus,
    onKeyDown,
} from "../../../utils/common-methods";
import {
    Validator,
    RequiredRule,
    AsyncRule,
    EmailRule,
    CompareRule,
    StringLengthRule,
} from "devextreme-react/validator";
import { EventGroupService } from "../../../api/services/eventgroupService";
import openeye from '../../../images/openeye.png'
import closeeyeIcon from '../../../images/closeeyeIcon .png'
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import EventGroupCategoryDropDown from "../event-group-category/EventGroupCategoryDropDown";
import EventDropDown from "../event/EventDropDown";

const eventGroupService = new EventGroupService();
export default function EventGroupPopup(props) {

    const { isExSmall, isXSmall, isXXSmall } = useScreenSize();
    const ValidationGroupName = "EventGroupPopupValidation";

    const FocusedFeild = useRef(null);
    const onShown = () => FocusedFeild.current?.instance.focus();

    const GroupData = {
        eventID: props.currentEventID,
        eventGroupID: 0,
        eventGroupName: "",
        eventGroupAdminID: 0,
        eventGroupCategoryID: props.currentGroupCategoryID ? props.currentGroupCategoryID : null,
        firstName: "",
        lastName: "",
        address: "",
        cityName: "",
        countryName: "",
        emailID: null,
        mobileNo: null,
        updatePassword: false,
        password: "",
        confirmPassword: "",
        updatedOn: null
    };

    const [newRecord, setNewRecord] = useState(GroupData);
    const [PasswordMode, setPasswordMode] = useState("password")
    const [showPasswordIcon, setShowpasswordIcon] = useState(openeye)
    const [confirmPasswordMode, setConfirmPasswordMode] = useState("password")
    const [showConfirmPasswordIcon, setShowConfirmpasswordIcon] = useState(openeye)
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [createUpdateStatus, setCreateUpdateStatus] = useState(null)
    const [dropDownClick, setDropDownClick] = useState(false);
    const [addedEventGroupCategoryID, setAddedEventGroupCategoryID] = useState(0);
    const [eventdropDownClick, setEventDropDownClick] = useState(false);
    const [addedEventID, setAddedEventID] = useState(0);

    let PrimaryKeyID = props.primaryKey;
    const isAddMode = !PrimaryKeyID;

    const PasswordOptions = {
        icon: showPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setPasswordMode(PasswordMode === 'text' ? 'password' : 'text');
            setShowpasswordIcon(showPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };

    const ConfirmPasswordOptions = {
        icon: showConfirmPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setConfirmPasswordMode(confirmPasswordMode === 'text' ? 'password' : 'text');
            setShowConfirmpasswordIcon(showConfirmPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };

    const passwordComparison = () => {
        return newRecord.password
    }


    useEffect(() => {
        if (addedEventGroupCategoryID !== 0) {
            setNewRecord({ ...newRecord, eventGroupCategoryID: addedEventGroupCategoryID });
            setAddedEventGroupCategoryID(0);
        }
    }, [addedEventGroupCategoryID]);

    useEffect(() => {
        if (addedEventID !== 0) {
            setNewRecord({ ...newRecord, eventID: addedEventID });
            setAddedEventID(0);
        }
    }, [addedEventID]);

    useEffect(() => {
        if (PrimaryKeyID) {
            GetModelRecord();
        }
        else {
            setShowSkeleton(false);
            setNewRecord(GroupData);
        }
    }, [PrimaryKeyID]);


    useEffect(() => {
        if (!newRecord.updatePassword && !isAddMode) {
            setNewRecord({
                ...newRecord,
                password: "",
                confirmPassword: ""
            })
        }
    }, [newRecord.updatePassword])


    useEffect(() => {
        !showSkeleton && FocusedFeild.current?.instance.focus()
    }, [showSkeleton])

    const GetModelRecord = async () => {
        const result = await eventGroupService.GetRecord(PrimaryKeyID);
        if (result.statusCode === 200) {
            const response = result.data;
            setNewRecord({ ...response, eventID: props.currentEventID, eventGroupCategoryID: props.currentGroupCategoryID });
            setCreateUpdateStatus(
                {
                    createdByUserName: result?.data.createdByUserName,
                    createdOn: result?.data.createdOn,
                    updatedByUserName: result?.data.updatedByUserName,
                    updatedOn: result?.data.updatedOn
                }
            )
            setShowSkeleton(false);
        }
        else if (result.statusCode === 400 || result.statusCode === 409) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false);
        }
    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        if (dropDownClick !== true && eventdropDownClick !== true) {
            if (isAddMode) {
                InsertData();
            } else {
                UpdateData();
            }
        }
    };

    const InsertData = async () => {
        setShowSkeleton(true);
        let dataToInsert = {
            eventID: newRecord.eventID,
            eventGroupName: newRecord.eventGroupName,
            eventGroupCategoryID: newRecord.eventGroupCategoryID,
            eventGroupAdminID: newRecord.eventGroupAdminID,
            firstName: newRecord.firstName,
            lastName: newRecord.lastName,
            address: newRecord.address,
            cityName: newRecord.cityName,
            countryName: newRecord.countryName,
            emailID: newRecord.emailID,
            mobileNo: newRecord.mobileNo,
            password: newRecord.password,
            confirmPassword: newRecord.confirmPassword,
        };

        const result = await eventGroupService.InsertRecord(dataToInsert);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Run");
                setShowSkeleton(false);
            }
        } else {
            props.setStatus({
                eStatus: eCRUDStatus.Inserted,
                primaryKeyID: result.data.responseData,
            });
            ClearUIFields();
            props.popupClose();
            setShowSkeleton(false);

        }
    };

    const UpdateData = async () => {
        setShowSkeleton(true);
        var dataToUpdate = {
            eventID: newRecord.eventID,
            updatedOn: newRecord.updatedOn,
            eventGroupID: newRecord.eventGroupID,
            eventGroupName: newRecord.eventGroupName,
            eventGroupAdminID: newRecord.eventGroupAdminID,
            eventGroupCategoryID: newRecord.eventGroupCategoryID,
            firstName: newRecord.firstName,
            lastName: newRecord.lastName,
            address: newRecord.address,
            cityName: newRecord.cityName,
            countryName: newRecord.countryName,
            emailID: newRecord.emailID,
            mobileNo: newRecord.mobileNo,
            updatePassword: newRecord.updatePassword,
            password: newRecord.password,
            confirmPassword: newRecord.confirmPassword,
        };
        const result = await eventGroupService.UpdateRecord(dataToUpdate);

        if (!result.isOk) {
            if (result.statusCode === 409) {
                ConflictPopup(result.data).then((res) => {
                    if (res) {
                        GetModelRecord();
                    } else {
                        return;
                    }
                });
                setShowSkeleton(false);
            } else if (result.statusCode === 400) {
                ShowAlert(result.data, "Run");
                setShowSkeleton(false);
            }
        } else {
            props.setStatus({
                eStatus: eCRUDStatus.Updated,
                primaryKeyID: result.data.responseData,
            });
            ClearUIFields();
            props.popupClose();
            setShowSkeleton(false);

        }
    };

    const ClearUIFields = () => {
        setNewRecord(GroupData);
    }

    const onClosePopup = () => {
        ClearUIFields();
        props.setStatus({ eStatus: eCRUDStatus.None })
        props.popupClose();
    }

    const onEventIDChange = (e) => {
        setNewRecord({ ...newRecord, eventID: e, eventGroupCategoryID: null })
    }

    const PopupTitle = (e) => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    title={[
                        <span key={"header_title"} className="base-accent-text">
                            {isAddMode ? "New" : "Edit"}
                        </span>,
                        " Group",
                    ]}
                />
            </>
        );
    };

    const asyncGroupNameValidation = async (e) => {
        const Name = e?.value;
        const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
        const EventCategoryID = newRecord.eventGroupCategoryID ? newRecord.eventGroupCategoryID : 0;
        const result = await eventGroupService.CheckDuplicateName(Name, ExcludeID , EventCategoryID);
        return new Promise((resolve) => {
            resolve(result.isOk !== false);
        });
    };

    const asyncEmailIDValidation = async (e) => {
        const EmailID = e?.value;
        const ExcludeID = newRecord.eventGroupAdminID ? newRecord.eventGroupAdminID : 0;
        const EventCategoryID = newRecord.eventGroupCategoryID ? newRecord.eventGroupCategoryID : 0;
        const result = await eventGroupService.CheckDuplicateEmail(EmailID, ExcludeID, EventCategoryID);
        return new Promise((resolve) => {
            resolve(result.isOk !== false);
        });
    }

    const asyncMobileValidation = async (e) => {
        const MobileNo = e?.value;
        const ExcludeID = newRecord.eventGroupAdminID ? newRecord.eventGroupAdminID : 0;
        const EventCategoryID = newRecord.eventGroupCategoryID
        const result = await eventGroupService.CheckDuplicateMobileNo(MobileNo, ExcludeID, EventCategoryID);
        return new Promise((resolve) => {
            resolve(result.isOk !== false);
        });
    }


    return (
        <>
            {props.popupShow && (
                <Popup
                    visible={props.popupShow}
                    titleRender={PopupTitle}
                    width={(isXSmall || isXXSmall || isExSmall) ? "95%" : "700px"}
                    height={"auto"}
                    maxHeight={"90%"}
                    wrapperAttr={{ class: "CustomPopup" }}
                    onShown={onShown}
                >
                    <ScrollView >
                        <form onSubmit={handleSubmit}>
                            <div className='overflow-hidden'>
                                <div className='row px-3 pt-2'>

                                    <div className='col-md'>
                                        {showSkeleton ?
                                            <div className=''>
                                                <PopupSkeleton />
                                                <PopupSkeleton />
                                                <PopupSkeleton />
                                                <PopupSkeletonOneLine />
                                            </div>
                                            :
                                            <>
                                                <div>
                                                    <TextBox
                                                        label='Group Name'
                                                        labelMode='floating'
                                                        value={newRecord.eventGroupName}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, eventGroupName: e })}
                                                        inputAttr={{
                                                            autocomplete: "new"
                                                        }}
                                                        maxLength={50}
                                                        ref={FocusedFeild}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                            <RequiredRule message='Group Name is required' />
                                                            <AsyncRule
                                                                reevaluate={false}
                                                                message="Can't accept duplicate Group Name"
                                                                validationCallback={asyncGroupNameValidation}
                                                            />
                                                        </Validator>
                                                    </TextBox>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className="col-md mt-1 mt-md-0">
                                                        <EventDropDown
                                                            value={newRecord.eventID}
                                                            onValueChange={onEventIDChange}
                                                            setDropDownClick={setEventDropDownClick}
                                                            setAddedEventID={setAddedEventID}
                                                            validationRequired={true}
                                                            dropdownHeight={180}
                                                            validationGroup={ValidationGroupName}
                                                        />
                                                    </div>
                                                    <div className='col-md mt-4 mt-md-0'>
                                                        <EventGroupCategoryDropDown
                                                            value={newRecord.eventGroupCategoryID}
                                                            onValueChange={(e) => setNewRecord({ ...newRecord, eventGroupCategoryID: e })}
                                                            setDropDownClick={setDropDownClick}
                                                            setAddedEventGroupCategoryID={setAddedEventGroupCategoryID}
                                                            validationRequired={true}
                                                            dropdownHeight={180}
                                                            validationGroup={ValidationGroupName}
                                                            eventID={newRecord.eventID}
                                                            callEventIDFilter={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        label='First Name'
                                                        maxLength={50}
                                                        labelMode='floating'
                                                        value={newRecord.firstName}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, firstName: e })}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                        }}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                            <RequiredRule message='First Name is required' />
                                                        </Validator>
                                                    </TextBox>
                                                </div>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        label='Last Name'
                                                        maxLength={50}
                                                        labelMode='floating'
                                                        value={newRecord.lastName}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, lastName: e })}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                        }}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                            <RequiredRule message='Last Name is required' />
                                                        </Validator>
                                                    </TextBox>
                                                </div>
                                                <div className='mt-4'>
                                                    <TextArea
                                                        label='Address'
                                                        maxLength={1000}
                                                        labelMode='floating'
                                                        value={newRecord.address}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, address: e })}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                        }}
                                                    >
                                                    </TextArea>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                {showSkeleton ?
                                    <div className='px-3'>
                                        <PopupSkeleton />
                                        <PopupSkeleton />
                                        {PrimaryKeyID && newRecord.updatePassword === true ? <PopupSkeleton /> : ""}
                                    </div>
                                    :
                                    <>

                                        <div className='row px-3'>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        label='Email ID'
                                                        labelMode='floating'
                                                        value={newRecord.emailID}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, emailID: e })}
                                                        inputAttr={{
                                                            autocomplete: "new"
                                                        }}
                                                        maxLength={50}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                            <EmailRule message='Email ID is invalid' />
                                                            <RequiredRule message='Email ID is required' />
                                                            {
                                                                newRecord.eventGroupCategoryID ?
                                                            <AsyncRule
                                                                reevaluate={false}
                                                                message="Can't accept duplicate Email ID"
                                                                validationCallback={asyncEmailIDValidation}
                                                            />:<></>
                                                            }
                                                        </Validator>
                                                    </TextBox>
                                                </div>

                                            </div>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <NumberBox
                                                        label='Mobile No.'
                                                        labelMode='floating'
                                                        format="#"
                                                        step={0}
                                                        onKeyDown={onKeyDown}
                                                        maxLength={15}
                                                        value={newRecord.mobileNo && parseInt(newRecord.mobileNo)}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                            maxLength: 15
                                                        }}
                                                        onValueChange={(value) => setNewRecord({ ...newRecord, mobileNo: value })}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName}>
                                                            <StringLengthRule message='Mobile No. must have 10 digits' min={10} ignoreEmptyValue={true} />
                                                            <AsyncRule
                                                                ignoreEmptyValue={true}
                                                                reevaluate={false}
                                                                message="Can't accept duplicate Mobile No."
                                                                validationCallback={asyncMobileValidation}
                                                            />
                                                        </Validator>
                                                    </NumberBox>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row px-3'>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        label='City'
                                                        labelMode='floating'
                                                        maxLength={50}
                                                        value={newRecord.cityName}
                                                        inputAttr={{
                                                            autocomplete: "new"
                                                        }}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, cityName: e })}
                                                    >
                                                    </TextBox>
                                                </div>

                                            </div>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        id="EventCountryName"
                                                        name='EventCountryName'
                                                        label='Country'
                                                        labelMode='floating'
                                                        maxLength={50}
                                                        value={newRecord.countryName}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, countryName: e })}
                                                        inputAttr={{
                                                            autocomplete: "country"
                                                        }}
                                                    >
                                                    </TextBox>
                                                </div>
                                            </div>
                                        </div>

                                        {PrimaryKeyID ?
                                            <div className='px-3'>
                                                <CheckBox
                                                    iconSize={15}
                                                    className='pe-2 mt-4'
                                                    value={newRecord.updatePassword}
                                                    onValueChange={(e) => setNewRecord({ ...newRecord, updatePassword: e })}
                                                >
                                                </CheckBox>
                                                <span className='font-regular-15 show_change_password fw-semibold'>Do you want to change password?</span>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                        {((PrimaryKeyID && newRecord.updatePassword === true) || !PrimaryKeyID) &&
                                            <div className='row px-3'>
                                                <div className='col-md'>
                                                    <div className='mt-4'>
                                                        <TextBox
                                                            name="Eventnewpassword"
                                                            label='Password'
                                                            mode={PasswordMode}
                                                            labelMode='floating'
                                                            maxLength={16}
                                                            value={newRecord.password}
                                                            onValueChange={(value) => setNewRecord({ ...newRecord, password: value?.replaceAll(" ", "") })}
                                                            inputAttr={{
                                                                autocomplete: "new-password"
                                                            }}
                                                        >
                                                            <TextBoxButton
                                                                name="password"
                                                                location="after"
                                                                options={PasswordOptions}
                                                            />
                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}  >
                                                                <RequiredRule message='Password is required' />
                                                                {/* <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} /> */}
                                                            </Validator>
                                                        </TextBox>
                                                    </div>
                                                </div>
                                                <div className='col-md'>
                                                    <div className='mt-4'>
                                                        <TextBox
                                                            label="Confirm Password"
                                                            mode={confirmPasswordMode}
                                                            labelMode='floating'
                                                            maxLength={16}
                                                            value={newRecord.confirmPassword}
                                                            onValueChange={(value) => setNewRecord({ ...newRecord, confirmPassword: value?.replaceAll(" ", "") })}
                                                            inputAttr={{
                                                                autocomplete: "new",
                                                            }}
                                                        >
                                                            <TextBoxButton
                                                                name="confirmPassword"
                                                                location="after"
                                                                options={ConfirmPasswordOptions}
                                                            />
                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                                <RequiredRule message='Confirm Password is required' />
                                                                {/* <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} /> */}
                                                                {newRecord.password && <CompareRule
                                                                    message="Password and Confirm Password does not match"
                                                                    comparisonTarget={passwordComparison}
                                                                />
                                                                }
                                                            </Validator>
                                                        </TextBox>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                {showSkeleton ?
                                    <div className='d-flex justify-content-end pb-3 pe-3 mt-3'>
                                        <div className='skeleton-View Skeleton-popup-footer'></div>
                                    </div>
                                    :
                                    <PopupFooter
                                        ValidationGroupName={ValidationGroupName}
                                        handleCancel={onClosePopup}
                                        createUpdateStatus={createUpdateStatus}
                                    />
                                }
                            </div>
                        </form>
                    </ScrollView>
                </Popup>
            )}
        </>
    );
}
