import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../../components';
import DataGrid, { Column, Button as DataGridButton, ColumnChooser, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Pager, Scrolling, SearchPanel, Sorting, StateStoring, Toolbar, ColumnChooserSearch, Search } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../../utils/media-query';
import './User.scss'
import { ListSkeleton } from '../../../layouts';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, sortingMethodDate } from '../../../utils/common-methods';
import UserPopup from './UserPopup';
import { confirm } from 'devextreme/ui/dialog';
import { UserService } from '../../../api/services/userService';

const userService = new UserService();

const UserList = () => {


  const { isExSmall, isXXSmall } = useScreenSize();

  const [dsUser, setDsUser] = useState([])
  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [showUserPopup, setShowUserPopup] = useState(false)
  const [primaryKey, setPrimaryKey] = useState(null)
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: null,
    primaryKeyID: primaryKey,
  })

  const DataGridRef = useRef(null);

  const GetUserList = async () => {
    setShowSkeleton(true)
    const result = await userService.GetListRecord();
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Run")
      }
      else if (result.statusCode === 409) {
        ShowAlert(result.data, "Run")
      }
      setShowSkeleton(false);
    }
    else {

      const UserData = result?.data?.map(item => {
        const createdOn = item.createdOn ? GetFormattedDateTimeUTCString(item.createdOn) : null;
        const updatedOn = item.updatedOn ? GetFormattedDateTimeUTCString(item.updatedOn) : null; 
        return {
          ...item,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null
        };
      })

      setDsUser(UserData)
      setShowSkeleton(false)
    }
  }

  useEffect(() => {
    GetUserList();
  }, [])

  const onCloseUserPopup = () => {
    setShowUserPopup(false);
  }

  useEffect(() => {

    if (status.eStatus !== null && status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
      UpdateListByID(PrimaryKeyID);
      setStatus({ eStatus: null })
    } else if (status.eStatus !== null && status.eStatus === eCRUDStatus.None) {
      setPrimaryKey(null);
      setStatus({ eStatus: null })
    }
  }, [status])

  const UpdateListByID = async (primaryKey) => {
    setShowSkeleton(true);
    const result = await userService.GetListByIDRecord(primaryKey);
    // let userData = result.data[0]
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Run")
      setShowSkeleton(false)
    }
    else {
      const UserData = result?.data?.map(item => {
        const createdOn = item.createdOn ? GetFormattedDateTimeUTCString(item.createdOn) : null;
        const updatedOn = item.updatedOn ? GetFormattedDateTimeUTCString(item.updatedOn) : null; 
        return {
          ...item,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsUser([UserData[0], ...dsUser]);
        setShowSkeleton(false)
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setDsUser(dsUser.map((i) => i.userID === primaryKey ? UserData[0] : i));
        setShowSkeleton(false)
      }
    }
    setfocusedRowKey(primaryKey);
    setPrimaryKey(null);

  }



  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const handleAdd = () => {
    setPrimaryKey(0)
    setShowUserPopup(true)
  }

  const handleEdit = (e) => {
    setPrimaryKey(e.row?.data?.userID)
    setShowUserPopup(true)
  }

  const onRowDbClick = (e) => {
    if (e?.rowType === "data") {
      setPrimaryKey(e.data?.userID)
      setShowUserPopup(true)
    }
  }

  const onDeleteButtonClick = (e) => {
    let result = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Run"
    );
    result.then(dialogResult =>
      dialogResult ? handleDelete(e) : ""
    )
  }

  const handleDelete = async (e) => {
    const Id = e.row.data.userID;
    // setShowSkeleton(true)
    const result = await userService.DeleteRecord(Id)

    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Run")
      setShowSkeleton(false)
    }
    else if (result.statusCode === 200) {
      setDsUser(dsUser.filter((x) => x.userID !== e.row.data.userID));


      // also delete profile picture
      const deleteResult = await userService.DeleteUserImage(Id)
      if (deleteResult.statusCode === 400 || deleteResult.statusCode === 409) {
        ShowAlert("Cannot delete this user profile", "Run")
      } else {
        return;
      }
    }
  }


  const UserFullName = (cellData) => {
    return <>
      <div><span className='text-decoration-underline pointer-link text-break' title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.fullName}</span></div>
    </>
  }

  function onFocusedRowChanged(e) {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }

  const refreshDataGrid = () => {
    GetUserList();
    DataGridRef.current?.instance.clearFilter();
  };

  return (
    <div className='User'>
      <Header
        title={"Users"}
        handleAdd={handleAdd}
        dataGridRef={DataGridRef}
        refreshDataGrid={refreshDataGrid}
      />
      <div className={showSkeleton ? 'd-block' : 'd-none'}>
        <ListSkeleton />
      </div>
      <div className={showSkeleton ? 'd-none' : 'd-block list-section'}>
        <DataGrid
          ref={DataGridRef}
          dataSource={dsUser}
          showBorders={true}
          showColumnLines={false}
          showRowLines={true}
          focusedRowEnabled={true}
          focusedRowKey={focusedRowKey}
          wordWrapEnabled={true}
          hoverStateEnabled={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          autoNavigateToFocusedRow={true}
          keyExpr="userID"
          height={'100%'}
          width={"100%"}
          filterSyncEnabled={true}
          onOptionChanged={onOptionChange}
          className='List_DataGrid'
          loadPanel={HideDatagridLoader}
          onRowDblClick={onRowDbClick}
          noDataText='No Record Found'
          onFocusedRowChanged={onFocusedRowChanged}
        >
          <Sorting mode={"multiple"} />
          <FilterPanel visible={filterVisible} />
          <FilterBuilderPopup visible={false} container={"#none"} />
          <Scrolling mode={'virtual'} />
          <ColumnChooser enabled={true}>
            <ColumnChooserSearch
              enabled={true}
            />
          </ColumnChooser>
          <HeaderFilter visible={true} >
            <Search
              enabled={true}
            />
          </HeaderFilter>
          <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
          <StateStoring enabled={true} type="localStorage" storageKey="user_layout" />
          <Pager
            visible={true}
            showInfo={true}
            infoText={`{2} Rows`}
          ></Pager>
          <Toolbar>
            <Item location={'after'} name="columnChooserButton" />
            <Item location={'after'} name="searchPanel" />
          </Toolbar>

          <Column caption='Name' cellRender={UserFullName} dataField='fullName' minWidth={120} width={200} alignment='left' allowHiding={false} />
          <Column dataField='address' minWidth={150} alignment='left' width={250} />
          <Column caption='Email' dataField='emailID' minWidth={140} width={250} alignment='left' />
          <Column caption='Mobile No.' dataField='mobileNo' minWidth={100} width={160} alignment='left' />
          <Column caption='City' dataField='cityName' minWidth={120} width={150} alignment='left' />
          <Column caption='Country' dataField='countryName' minWidth={100} width={120} alignment='left' />
          <Column
            dataField="createdByUserName"
            caption="Created By"
            visible={false}
            width={125}
            minWidth={80}
            alignment="left"
          />

          <Column
            dataField="updatedByUserName"
            caption="Updated By"
            visible={false}
            width={125}
            minWidth={80}
            alignment="left"
          />
          <Column
            dataField="createdOn"
            caption="Created On"
            visible={false}
            width={145}
            minWidth={80}
            alignment="left"
            sortingMethod={sortingMethodDate}
          />
          <Column
            dataField="updatedOn"
            caption="Updated On"
            visible={false}
            width={145}
            minWidth={80}
            alignment="left"
            sortingMethod={sortingMethodDate}
          />
          <Column type='buttons' name="delete_button_column" fixed={false} allowResizing={false} width={43} allowReordering={false}>
            <DataGridButton
              name='delete'
              icon='trash'
              hint='Delete'
              visible={true}
              onClick={onDeleteButtonClick}
              cssClass='text-danger'
            />
          </Column>
        </DataGrid>
      </div>

      {showUserPopup === true &&
        <UserPopup
          popupShow={showUserPopup}
          popupClose={onCloseUserPopup}
          primaryKey={primaryKey}
          setStatus={setStatus}
        />
      }
    </div>
  )
}

export default UserList;