import "bootstrap/dist/css/bootstrap.css";
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from 'react';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import './utils/base-64-icons.scss';
import { BrowserRouter as Router } from "react-router-dom";
import GroupContent from "./GroupContent";


function App() {
  const { groupUser, user, loading, GroupSignOut } = useAuth();
  var isAdminUserUrl = window.location.pathname.includes("/login");
  var isGroupUserUrl = window.location.pathname.includes("/glogin");
  var isVisitorUrl = window.location.pathname.includes("/visitor/")
  var isMediaUrl = window.location.pathname.includes("/media/")

  var LSeventCode = localStorage.getItem("groupUserEventCode")
  var splitUrl = window.location.pathname.split("/")
  var urlEventCode = splitUrl[splitUrl.length - 1]
  
  if(isGroupUserUrl && LSeventCode !== urlEventCode){
    GroupSignOut();
  }


  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if(isVisitorUrl || isMediaUrl){
    return <UnauthenticatedContent />;
  }

  if( isAdminUserUrl && !user){
    return <UnauthenticatedContent />;
  }

  if((isGroupUserUrl || window.location.pathname.includes("/group/")) && !groupUser){
    return <UnauthenticatedContent />;
  }

  if((isGroupUserUrl || window.location.pathname.includes("/group/")) && groupUser){
    return <GroupContent />;
  }

  if(isGroupUserUrl && groupUser){
    return <GroupContent />;
  }

  if((isAdminUserUrl || !window.location.pathname.includes("/group/")) && !user){
    return <UnauthenticatedContent />;
  }

  if(isAdminUserUrl && user){
    return <Content />;
  }

  if (user) {
    return <Content />;
  }

  if (groupUser) {
    return <GroupContent />;
  }


  
  return <UnauthenticatedContent />;
}


export default function Root() {
  const screenSizeClass = useScreenSizeClass();
  return (
    <Router>
      <AuthProvider>      
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>        
      </AuthProvider>
    </Router>
  );
}
