// import React from 'react';
// import ScrollView from 'devextreme-react/scroll-view';
// import './single-card.scss';


// export default function SingleCard({ title, description, children }) {
//   return (
//     <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'}>
//       <div className={'dx-card content'}>
//         <div className={'header'}>
//           <div className={'title'}>{title}</div>
//           <div className={'description'}>{description}</div>
//         </div>
//         {children}
//       </div>
//     </ScrollView>
// )}

import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './single-card.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, LoadIndicator } from 'devextreme-react';


const SingleCard = ({ title, subtitle, showLoader, children }) => {

  const navigate = useNavigate();

  const location = useLocation();

  return (
    <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card '}>
      <div className=' dx-card p-xxl-4 p-xl-4 p-lg-4 p-md-4 p-2 shadow bg-white rounded'>
        <div className='form-card'>

          <div className={'header px-md-1'}>
            {
              <>
                <div id='titleSection' className={title && 'title text-center pb-2'}>{title}</div>
                {!showLoader &&
                  <div className={subtitle && 'subtitle pt-1 text-center w-70'}>{subtitle}</div>
                }
              </>

            }
          </div>

          {children}
        </div>
      </div>
    </ScrollView>
  )
}

export default SingleCard;
