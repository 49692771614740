import React, { useEffect, useRef, useState } from 'react'
import './GroupDashboard.scss';
import { ListSkeleton } from '../../layouts'
import { GetFormattedDateTimeUTCString, GetListFormattedDateTime, HideDatagridLoader, ShowAlert, camelCase, eCRUDStatus, sortingMethodDate } from '../../utils/common-methods'
import { useScreenSize } from '../../utils/media-query'
import DataGrid, { Column, Button as DataGridButton, FilterPanel, Scrolling, ColumnChooser, HeaderFilter, SearchPanel, FilterBuilderPopup, Pager, Toolbar, Item, ColumnChooserSearch, Search, Sorting } from 'devextreme-react/data-grid';
import GroupHeader from '../../components/group-admin/group-header/GroupHeader'
import { RegistrationAuthorizedService, eState } from '../../api/services/registrationAuthorizedService';
import { RegistrationFormDesignerService, eRegistrationType } from '../../api/services/registrationFormDesignerService';
import GroupRegistrationPopup from '../group-registration/GroupRegistrationPopup';
import { confirm } from 'devextreme/ui/dialog';
import { formatDate } from 'devextreme/localization';
import { Button, DropDownButton } from 'devextreme-react';
import { ApprovedIcon, PrintedIcon, pendingIcon, rejectedIcon } from '../../utils/base-64-Icons';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from "file-saver";
import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';

const dropDownOptions = {
    width: "85px",
  };

const registrationAuthorizedService = new RegistrationAuthorizedService()
const registrationFormDesignerService = new RegistrationFormDesignerService();
const GroupDashboard = () => {

    const { GetEventDetails, GetEventLogo } = useAuth();

    const groupEvent = localStorage.getItem("groupEvent") ? JSON.parse(localStorage.getItem("groupEvent")) : null;
    var eventCode = localStorage.getItem("isGroupUser")
    const DataGridRef = useRef(null);
    const [dsGroupDashboard, setDsGroupDashboard] = useState([])
    const { isExSmall, isXXSmall, isXSmall } = useScreenSize();
    const [filterVisible, setFilterVisible] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [focusedRowKey, setfocusedRowKey] = useState(0);
    const [HideButton, setHideButton] = useState(false)
    const [dsComponent, setDsComponent] = useState([]);
    const [GroupFormData, setGroupFormData] = useState(null);
    const [logoImage , setLogoImage] = useState(null);

    // Start For Group Registration Form 
    const [ShowGroupRegistrationPopup, setShowGroupRegistrationPopup] = useState(false);
    const [SelectedEventDetail, setSelectedEventDetail] = useState(null);

    const [GroupStatus, setGroupStatus] = useState({
        eStatus: null
    });

    // End For Group Registration Form 

    const navigate = useNavigate();

    useEffect(()=>{
        GetEventLogoImage();
    },[])

    const GetEventLogoImage = async () => {
        const logoResult = await GetEventLogo(eventCode)
        if (logoResult) {
            setLogoImage(window.URL.createObjectURL(logoResult))
        }
        else{
            setLogoImage(null)
        }
    }

    const dropdownItems = [
        { id: 1, name: "Excel", icon: "exportxlsx" },
        { id: 2, name: "CSV", icon: "xlsfile" },
      ];

    const GetComponents = async () => {
        const result = await registrationFormDesignerService.GetComponents();
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")

        } else {
            setDsComponent(result?.data);
        }
    }

    const GetFormDesignerData = async () => {
        setShowSkeleton(true);
        const result = await registrationFormDesignerService.GetGroupUserFormDesign();
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")
            setShowSkeleton(false);
        } else {
            setGroupFormData(result.data);
            GetListWithFilter(result.data.components);
        }
    }

    const [GridState, setGridState] = useState(null)

    const GetListWithFilter = async (Components) => {
        const result = await registrationAuthorizedService.GetListWithFilter(groupEvent?.eventID, 3, groupEvent?.eventGroupID, null, null);
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")
            setShowSkeleton(false);
        } else {
            const inst = DataGridRef.current?.instance;
            let dataForColumns = [];
            // If Form Designer created
            if (Components && Components.length > 0 && result.data.length === 0) {
                inst.beginUpdate();
                Components?.map(r => {
                    let dataToPush = {
                        registrationID: 0,
                        registrationStatusName: null,
                        createdByUserName: null,
                        arrivalDateTime: null,
                        departureDateTime: null,
                        passportExpiryDate: null,
                        passportIssueDate: null,
                        dob: null,
                        createdOn: null,
                        updatedByUserName: null,
                        updatedOn: null,
                        index: r.index,
                        componentID: r.componentID
                    }
                    if (r.componentID === 1) {
                        dataToPush.firstName = null;
                    }
                    if (r.componentID === 2) {
                        dataToPush.middleName = null;
                    }
                    if (r.componentID === 3) {
                        dataToPush.lastName = null;
                    }
                    if (r.componentID === 4) {
                        dataToPush.fullName = null;
                    }
                    if (r.componentID === 5) {
                        dataToPush.function = null;
                    }
                    if (r.componentID === 6) {
                        dataToPush.club = null;
                    }
                    if (r.componentID === 7) {
                        dataToPush.comments = null;
                    }
                    if (r.componentID === 8) {
                        dataToPush.company = null;
                    }
                    if (r.componentID === 9) {
                        dataToPush.nationalityName = null;
                    }
                    if (r.componentID === 10) {
                        dataToPush.gender = null;
                    }
                    if (r.componentID === 11) {
                        dataToPush.dob = null;
                    }
                    if (r.componentID === 12) {
                        dataToPush.photo = null;
                    }
                    if (r.componentID === 13) {
                        dataToPush.passportNo = null;
                    }
                    if (r.componentID === 14) {
                        dataToPush.passportCopy = null;
                    }
                    if (r.componentID === 15) {
                        dataToPush.passportIssueDate = null;
                    }
                    if (r.componentID === 16) {
                        dataToPush.passportExpiryDate = null;
                    }
                    if (r.componentID === 17) {
                        dataToPush.qidNo = null;
                    }
                    if (r.componentID === 18) {
                        dataToPush.qidCopy = null;
                    }
                    if (r.componentID === 19) {
                        dataToPush.emailID = null;
                    }
                    if (r.componentID === 20) {
                        dataToPush.mobileNo = null;
                    }
                    if (r.componentID === 21) {
                        dataToPush.whatsappNo = null;
                    }
                    if (r.componentID === 22) {
                        dataToPush.aipNo = null;
                    }
                    if (r.componentID === 23) {
                        dataToPush.aipCopy = null;
                    }
                    if (r.componentID === 24) {
                        dataToPush.mediaTypeName = null;
                    }
                    if (r.componentID === 25) {
                        dataToPush.mediaName = null;
                    }
                    if (r.componentID === 26) {
                        dataToPush.mediaFunctionName = null;
                    }
                    if (r.componentID === 27) {
                        dataToPush.mediaAddress = null;
                    }
                    if (r.componentID === 28) {
                        dataToPush.arrivalDateTime = null;
                    }
                    if (r.componentID === 29) {
                        dataToPush.arrivalFlightNo = null;
                    }
                    if (r.componentID === 30) {
                        dataToPush.departureDateTime = null;
                    }
                    if (r.componentID === 31) {
                        dataToPush.departureFlightNo = null;
                    }
                    dataForColumns.push(dataToPush)
                });
                inst.endUpdate();
                setHideButton(true)

                setGridState(eState.FormDesign)
            }
            else if (((Components && Components.length === 0) || Components === undefined) && result.data.length === 0) {
                // If no form designer is created
                let dataToPush = {
                    registrationID: 0,
                    createdByUserName: null,
                    createdOn: null,
                    updatedByUserName: null,
                    updatedOn: null,
                    arrivalDateTime: null,
                    departureDateTime: null,
                    passportExpiryDate: null,
                    passportIssueDate: null,
                    dob: null,
                }
                dataForColumns.push(dataToPush);
                setHideButton(false);
                setGridState(eState.None)
            }
            // If Registration form is created
            if (result.data.length > 0) {
                result.data.map(i => {
                    const createdOn = i.createdOn ? GetFormattedDateTimeUTCString(i.createdOn) : null;
                    const updatedOn = i.updatedOn ? GetFormattedDateTimeUTCString(i.updatedOn) : null;
                    const arrivalDateTime = i.arrivalDateTime ? GetListFormattedDateTime(i.arrivalDateTime) : null;
                    const departureDateTime = i.departureDateTime ? GetListFormattedDateTime(i.departureDateTime) : null;
                    const passportExpiryDate = i.passportExpiryDate ? GetListFormattedDateTime(i.passportExpiryDate) : null;
                    const passportIssueDate = i.passportIssueDate ? GetListFormattedDateTime(i.passportIssueDate) : null;
                    const dob = i.dob ? GetListFormattedDateTime(i.dob) : null;
                    dataForColumns.push({
                        ...i,
                        arrivalDateTime: arrivalDateTime ? arrivalDateTime : null,
                        departureDateTime: departureDateTime ? departureDateTime : null,
                        passportExpiryDate: passportExpiryDate ? passportExpiryDate : null,
                        passportIssueDate: passportIssueDate ? passportIssueDate : null,
                        dob: dob ? dob : null,
                        createdOn: createdOn ? createdOn : null,
                        updatedOn: updatedOn ? updatedOn : null
                    })
                })
                setHideButton(true)
                setGridState(eState.FormCreated)
            }
            setDsGroupDashboard(dataForColumns);
        }
    }

    useEffect(() => {
        if (GridState !== null) {
            const inst = DataGridRef.current?.instance;
            if (GridState === eState.None) {
                inst.beginUpdate();
                if (dsGroupDashboard.length > 0) {
                    setDsGroupDashboard([]);
                    const allColumns = inst.option('columns')
                    allColumns.forEach(function (column) {
                        // Check if either the name or dataField exists in the list of objects                     
                        if (column.name) {
                            inst.columnOption(column.name, "visible", false);
                            inst.columnOption(column.name, "showInColumnChooser", false);
                        }
                    });
                }
                inst.endUpdate();
            }
            else if (GridState === eState.FormDesign) {
                if (dsGroupDashboard.length > 0) {
                    inst.beginUpdate();
                    const allColumns = inst.option('columns').filter(r => !r.name.includes('default'))

                    allColumns.forEach(function (column) {
                        if (column.name) {
                            inst.columnOption(column.name, "visible", false);
                            inst.columnOption(column.name, "showInColumnChooser", false);
                        }
                    });

                    allColumns.forEach(function (column) {
                        // Check if either the name or dataField exists in the list of objects
                        dsGroupDashboard.map(function (obj) {
                            if (column.name && obj.componentID === parseInt(column.name)) {
                                inst.columnOption(column.name, "visibleIndex", obj.index);
                                inst.columnOption(column.name, "visible", true);
                                inst.columnOption(column.name, "showInColumnChooser", true);
                            }
                        });
                    });
                    setDsGroupDashboard([]);
                    inst.endUpdate();
                }
            }
            else if (GridState === eState.FormCreated) {
                inst.beginUpdate();
                UpdateComponentValue(GroupFormData.components, inst);
                inst.endUpdate();
            }
            setGridState(null);
            if (dsGroupDashboard.length > 0) {
                inst.getDataSource().reload()
            }
            setShowSkeleton(false);
        }
    }, [GridState]);


    const PhotoCellRender = (cellInfo) => {
        let regisID = cellInfo.data.registrationID;
        let fileName = "";

        if (cellInfo.data.firstName) {
            fileName += cellInfo.data.firstName;
        }

        if (cellInfo.data.middleName) {
            fileName += cellInfo.data.middleName;
        }

        if (cellInfo.data.lastName) {
            fileName += cellInfo.data.lastName;
        }

        if (fileName !== "") {
            fileName = "Photo_" + fileName;
        } else {
            fileName = "Photo"
        }
        if (cellInfo.data.hasPhoto) {
            return (
                <>
                    <Button text="Photo" className="photo-row-btn" stylingMode="text" hoverStateEnabled={false} onClick={() => DownloadSinglePhoto(regisID, fileName)} />
                </>
            )
        } else {
            return <>

            </>
        }
    }

    const PassportCopyCellRender = (cellInfo) => {
        let regisID = cellInfo.data.registrationID;
        let fileName = "";

        if (cellInfo.data.firstName) {
            fileName += cellInfo.data.firstName;
        }

        if (cellInfo.data.middleName) {
            fileName += cellInfo.data.middleName;
        }

        if (cellInfo.data.lastName) {
            fileName += cellInfo.data.lastName;
        }

        if (fileName !== "") {
            fileName = "Passport_" + fileName;
        } else {
            fileName = "Passport"
        }
        if (cellInfo.data.hasPassport) {
            return (
                <>
                    <Button text="Passport Copy" className="photo-row-btn" stylingMode="text" hoverStateEnabled={false} onClick={() => DownloadSignlePassPortCopy(regisID, fileName)} />
                </>
            )
        } else {
            return <>

            </>
        }
    }

    const QIDCopyCellRender = (cellInfo) => {
        let regisID = cellInfo.data.registrationID;
        let fileName = "";

        if (cellInfo.data.firstName) {
            fileName += cellInfo.data.firstName;
        }

        if (cellInfo.data.middleName) {
            fileName += cellInfo.data.middleName;
        }

        if (cellInfo.data.lastName) {
            fileName += cellInfo.data.lastName;
        }

        if (fileName !== "") {
            fileName = "QID_" + fileName;
        } else {
            fileName = "QID"
        }
        if (cellInfo.data.hasQID) {
            return (
                <>
                    <Button text="QID Copy" className="photo-row-btn" stylingMode="text" hoverStateEnabled={false} onClick={() => DownloadSignleQIDCopy(regisID, fileName)} />
                </>
            )
        } else {
            return <>

            </>
        }
    }

    const AIPCopyCellRender = (cellInfo) => {
        let regisID = cellInfo.data.registrationID;
        let fileName = "";

        if (cellInfo.data.firstName) {
            fileName += cellInfo.data.firstName;
        }

        if (cellInfo.data.middleName) {
            fileName += cellInfo.data.middleName;
        }

        if (cellInfo.data.lastName) {
            fileName += cellInfo.data.lastName;
        }

        if (fileName !== "") {
            fileName = "AIP_" + fileName;
        } else {
            fileName = "AIP"
        }
        if (cellInfo.data.hasAIP) {
            return (
                <>
                    <Button text="AIP Copy" className="photo-row-btn" stylingMode="text" hoverStateEnabled={false} onClick={() => DownloadSignleAIPCopy(regisID, fileName)} />
                </>
            )
        } else {
            return <>

            </>
        }
    }

    const DownloadSignleQIDCopy = async (regisID, QIDFileName) => {
        const result = await registrationAuthorizedService.DownloadQIDCopy(regisID);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
        } else {
            const response = await result.data;
            let FileExtension = result.fileExtension;
            let fileName;
            if (result.fileExtension.includes('octet-stream')) {
                fileName = QIDFileName + ".zip";
            } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                fileName = QIDFileName + ".docx";
            } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                fileName = QIDFileName + ".xlsx";
            }
            else {
                fileName = QIDFileName + "." + FileExtension.split('/')[1];
            }
            download(response, fileName)
        }
    }


    const DownloadSignleAIPCopy = async (regisID, AIPFileName) => {
        const result = await registrationAuthorizedService.DownloadAIPCopy(regisID);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
        } else {
            const response = await result.data;
            let FileExtension = result.fileExtension;
            let fileName;
            if (result?.fileExtension?.includes('octet-stream')) {
                fileName = AIPFileName + ".zip";
            } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                fileName = AIPFileName + ".docx";
            } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                fileName = AIPFileName + ".xlsx";
            }
            else {
                fileName = AIPFileName + "." + FileExtension.split('/')[1];
            }
            download(response, fileName)
        }
    }

    const DownloadSignlePassPortCopy = async (regisID, passportFileName) => {
        const result = await registrationAuthorizedService.DownloadPassportCopy(regisID);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
        } else {
            const response = await result.data;
            let FileExtension = result.fileExtension;
            let fileName;
            if (result.fileExtension.includes('octet-stream')) {
                fileName = passportFileName + ".zip";
            } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                fileName = passportFileName + ".docx";
            } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                fileName = passportFileName + ".xlsx";
            }
            else {
                fileName = passportFileName + "." + FileExtension.split('/')[1];
            }
            download(response, fileName)
        }
    }


    const DownloadSinglePhoto = async (regisID, photoFileName) => {

        const result = await registrationAuthorizedService.DownloadPhoto(regisID);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
        } else {
            const response = await result.data;
            let FileExtension = result.fileExtension;
            let fileName;
            if (result.fileExtension.includes('octet-stream')) {
                fileName = photoFileName + ".zip";
            } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                fileName = photoFileName + ".docx";
            } else if (result?.fileExtension?.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                fileName = photoFileName + ".xlsx";
            }
            else {
                fileName = photoFileName + "." + FileExtension.split('/')[1];
            }
            download(response, fileName)
        }
    }

    const download = (blob, fileName) => {
        const url = blob?.size > 0 ? window.URL.createObjectURL(blob) : null;
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName ? fileName : 'Image';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const UpdateComponentValue = (Components, gridInstance) => {
        const allColumns = gridInstance.option('columns').filter(r => !r.name.includes('default'));
        allColumns.forEach(function (column) {
            if (column.name) {
                gridInstance.columnOption(column.name, "visible", false);
                gridInstance.columnOption(column.name, "showInColumnChooser", false);
            }
        });

        allColumns.forEach(function (column) {
            Components.map(function (obj) {
                if (column.name && obj.componentID === parseInt(column.name)) {
                    gridInstance.columnOption(column.name, "visibleIndex", obj.index);
                    gridInstance.columnOption(column.name, "visible", true);
                    gridInstance.columnOption(column.name, "showInColumnChooser", true);
                }
            });
        });

    }

    useEffect(() => {
        GetComponents();
        GetFormDesignerData();
        GetEventDetailByEventCode();
    }, [])

    const onOptionChange = (e) => {
        if (e.fullName === "filterValue") {
            setFilterVisible(!!e.value);
        }
    };

    const onRowDbClick = (e) => {
        if(e.data.registrationStatusName === "Printed"){
            return;
        }
        if (e?.rowType === "data") {
            setSelectedEventDetail({
                RegistrationID: e.data.registrationID,
                EventID: groupEvent.eventID,
                EventName: groupEvent.eventName,
                EventGroupID: groupEvent.eventGroupID,
                RegistrationTypeID: eRegistrationType.Group
            })
            setShowGroupRegistrationPopup(!ShowGroupRegistrationPopup);
        }
    }

    const onEditButtonClick = (e) => {
        setSelectedEventDetail({
            RegistrationID: e.row.data.registrationID,
            EventID: groupEvent.eventID,
            EventName: groupEvent.eventName,
            EventGroupID: groupEvent.eventGroupID,
            RegistrationTypeID: eRegistrationType.Group
        })
        setShowGroupRegistrationPopup(!ShowGroupRegistrationPopup);
    }

    const VisibleEditButton = (e) =>{
        if(e.row.data.registrationStatusName === "Printed"){
            return false;
        }else{
            return true;
        }
    }

    const VisibleDeleteButton = (e) =>{
        if(e.row.data.registrationStatusName !== "Pending"){
            return false;
        }else{
            return true;
        }
    }

    const onDeleteButtonClick = (e) => {
        let res = confirm(
            `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
            "Run"
        );
        res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
    };

    const handleDelete = async (e) => {
        setShowSkeleton(true)
        const Id = e.row.data.registrationID;
        const result = await registrationAuthorizedService.DeleteRecord(Id);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false)
        }
        else if (result.statusCode === 200) {
            GetFormDesignerData();
            // setShowSkeleton(false)
        }
    }

    useEffect(() => {
        if (GroupStatus.eStatus !== null && GroupStatus.eStatus !== eCRUDStatus.None) {
            setSelectedEventDetail(null);
            setTimeout(() => {
                GetFormDesignerData();
            }, 2200)
            setGroupStatus({ eStatus: null })
        } else if (GroupStatus.eStatus !== null && GroupStatus.eStatus === eCRUDStatus.None) {
            setSelectedEventDetail(null);
            setGroupStatus({ eStatus: null })
        }
    }, [GroupStatus]);


    const onCloseGroupRegistrationPopup = () => {
        setShowGroupRegistrationPopup(!ShowGroupRegistrationPopup);
    }



    function onFocusedRowChanged(e) {
        setfocusedRowKey(e.component.option("focusedRowKey"));
    }

    const refreshDataGrid = () => {
        GetFormDesignerData();
        DataGridRef.current?.instance.clearFilter();
    };

    const handleAdd = () => {
        setSelectedEventDetail({
            RegistrationID: 0,
            EventID: groupEvent.eventID,
            EventName: groupEvent.eventName,
            EventGroupID: groupEvent.eventGroupID,
            RegistrationTypeID: eRegistrationType.Group
        })
        setShowGroupRegistrationPopup(!ShowGroupRegistrationPopup);
    }

    const RegistrationStatusCellRender = (e) =>{
        return(
          <>
            {
              e.value === "Rejected" ?
                <span><img src={rejectedIcon} alt="Rejected" width={22} className="pe-1"/> Rejected</span> :
                e.value === "Approved" ?
                <span><img src={ApprovedIcon} alt="Approved" width={22} className="pe-1"/> Approved</span> :
                e.value === "Printed" ?
                <span><img src={PrintedIcon} alt="Printed" width={22} className="pe-1"/> Printed</span> :
                e.value === "Pending" ?
                <span><img src={pendingIcon} alt="Pending" width={22} className="pe-1"/> Pending</span> :""
      
            }
          </>
        )
      }

      const onItemClick = (e) => {
            var fileName = camelCase("GroupRegistrationList");
       
    
        if (e.itemData.id === 1) {
          var workbook = new Workbook();
          var worksheet = workbook.addWorksheet("SheetName");
          exportDataGrid({
            component: DataGridRef.current?.instance,
            worksheet: worksheet,
          }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
              saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                fileName + ".xlsx"
              );
            });
          });
        } else if (e.itemData.id === 2) {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet("Report");
          exportDataGrid({
            component: DataGridRef.current?.instance,
            worksheet: worksheet,
          }).then(function () {
            workbook.csv.writeBuffer().then(function (buffer) {
              saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                fileName + ".csv"
              );
            });
          });
        }
      };

      const [isEditMode, setIsEditMode] = useState(null);

      const GetEventDetailByEventCode = async () => {
        
        const result = await GetEventDetails(eventCode);
        if (!result.isOk) {
            navigate('/glogin/invalid-page')
        }else{
            document.title = result?.data?.eventName + " - Group Registration" ;
            var regisEndDate = new Date(result.data?.registrationTo);
            var currentDate = new Date();
            
            if(regisEndDate <= currentDate){
                setIsEditMode(false)
            }else{
                setIsEditMode(true)
            }
        }
      }

    return (
        <>
            <div className='w-100'>
                <GroupHeader
                    handleAdd={handleAdd}
                    hideDefaultButtons={!HideButton}
                    dataGridRef={DataGridRef}
                    title={[<span key={"header_title"} className="base-accent-text">{groupEvent?.eventGroupName} <span className='registration-for-text'>Registration For</span> {(isExSmall || isXXSmall || isXSmall)?<span title={groupEvent?.eventName}>{groupEvent?.eventName?.length > 25 ? groupEvent?.eventName?.substring(0, 25) +"..." : groupEvent?.eventName}</span> : groupEvent?.eventName}</span>]}
                    excelFileName={"GroupRegistrationList"}
                    refreshDataGrid={refreshDataGrid}
                    eventLogo={logoImage}
                />
                <div className={showSkeleton ? 'd-block' : 'd-none'}>
                    <ListSkeleton />
                </div>
                <div className={showSkeleton ? 'd-none' : 'd-block list-section group-dashboard-section'}>
                    <DataGrid
                        ref={DataGridRef}
                        height={"100%"}
                        width={"100%"}
                        className='List_DataGrid GroupDashboardDataGrid'
                        dataSource={dsGroupDashboard}
                        keyExpr="registrationID"
                        showBorders={true}
                        showColumnLines={false}
                        showRowLines={true}
                        focusedRowKey={focusedRowKey}
                        focusedRowEnabled={true}
                        wordWrapEnabled={true}
                        hoverStateEnabled={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        autoNavigateToFocusedRow={true}
                        loadPanel={HideDatagridLoader}
                        filterSyncEnabled={true}
                        onOptionChanged={onOptionChange}
                        onRowDblClick={onRowDbClick}
                        noDataText='No Record Found'
                        onFocusedRowChanged={onFocusedRowChanged}
                    >
                        <Sorting mode={"multiple"} />
                        <FilterPanel visible={filterVisible} />
                        <FilterBuilderPopup visible={false} container={"#none"} />
                        <Scrolling mode={'virtual'} />
                        <ColumnChooser enabled={true} width={!isExSmall ? 320 : 'auto'}>
                            <ColumnChooserSearch
                                enabled={true}
                            />
                        </ColumnChooser>
                        <HeaderFilter visible={true} >
                            <Search
                                enabled={true}
                            />
                        </HeaderFilter>
                        <SearchPanel visible={true} width={(isExSmall || isXXSmall ) ? 120 : isXSmall ? 200 : 400} />
                        <Pager
                            visible={true}
                            showInfo={true}
                            infoText={`{2} Rows`}
                        ></Pager>
                        <Toolbar>
                        
                                 
                        <Item location="before" locateInMenu="auto" visible={HideButton}>
                            
                            <Button
                            icon="plus"
                            className={`${(isXXSmall || isExSmall || isXSmall) ? 'green-add-button-sm' : 'green-add-button'} rounded-2`}
                            id="Add"
                            text={'Add'}
                            onClick={handleAdd}
                            stylingMode="contained"
                            type="default"
                            hint="Add"
                            visible={isEditMode}
                            ></Button>
                        </Item>
                        <Item location={(isXXSmall || isExSmall || isXSmall) ? "before":"after"} locateInMenu="auto" visible={HideButton}>
                            <DropDownButton
                            displayExpr={"name"}
                            items={dropdownItems}
                            id={"export"}
                            icon={"export"}
                            hint="Export"
                            dropDownOptions={dropDownOptions}
                            stylingMode="contained"
                            type="default"
                            text={'Export'}
                            className={`${(isXXSmall || isExSmall || isXSmall) ? 'dropdown-btn-with-text-sm' : 'dropdown-btn-with-text'} rounded-2 `}
                            elementAttr={{ role: "button" }}
                            showArrowIcon={false}
                            onItemClick={onItemClick}
                            ></DropDownButton>
                        </Item>
                            <Item location={'after'} name="columnChooserButton"  locateInMenu='auto'/>
                            <Item location={'after'} name="searchPanel"  locateInMenu='auto'/>
                        </Toolbar>

                        <Column
                            name='default_status'
                            caption='Status'
                            dataField='registrationStatusName'
                            minWidth={120}
                            fixed={true}
                            cellRender={RegistrationStatusCellRender}
                        />

                        <Column
                            name='1'
                            caption='First Name'
                            dataField='firstName'
                            minWidth={120}
                        />
                        <Column
                            name='2'
                            caption='Middle Name'
                            dataField='middleName'
                            minWidth={120}
                        />
                        <Column
                            name='3'
                            caption='Last Name'
                            dataField='lastName'
                            minWidth={120}
                        />
                        <Column
                            name='4'
                            caption='Full Name'
                            dataField='fullName'
                            minWidth={120}
                        />
                        <Column
                            name='5'
                            caption='Function'
                            dataField='function'
                            minWidth={120}
                        />
                        <Column
                            name='6'
                            caption='Club'
                            dataField='club'
                            minWidth={120}
                        />

                        <Column
                            name='7'
                            caption='Comments/Remarks'
                            dataField='comments'
                            minWidth={180}
                        />

                        <Column
                            name='8'
                            caption='Company/Department'
                            dataField='company'
                            minWidth={180}
                        />
                        <Column
                            name='9'
                            caption='Nationality'
                            dataField='nationalityName'
                            minWidth={120}
                        />

                        <Column
                            name='10'
                            caption='Gender'
                            dataField='gender'
                            minWidth={120}
                        />

                        <Column
                            name='11'
                            caption='Date of Birth'
                            dataField='dob'
                            minWidth={120}
                            // dataType="date"
                            // format={"shortDate"}
                            sortingMethod={sortingMethodDate}
                        />
                        <Column
                            name='12'
                            caption='Photo'
                            dataField='photo'
                            minWidth={120}
                            alignment='center'
                            cellRender={PhotoCellRender}
                        />
                        <Column
                            name='13'
                            caption='Passport Number'
                            dataField='passportNo'
                            minWidth={120}
                        />
                        <Column
                            name='14'
                            caption='Passport Copy'
                            dataField='passportCopy'
                            minWidth={120}
                            alignment='center'
                            cellRender={PassportCopyCellRender}
                        />
                        <Column
                            name='15'
                            caption='Passport Issue Date'
                            dataField='passportIssueDate'
                            minWidth={120}
                            // dataType="date"
                            // format={"shortDate"}
                            sortingMethod={sortingMethodDate}
                        />
                        <Column
                            name='16'
                            caption='Passport Expiry Date'
                            dataField='passportExpiryDate'
                            minWidth={120}
                            // dataType="date"
                            // format={"shortDate"}
                            sortingMethod={sortingMethodDate}
                        />
                        <Column
                            name='17'
                            caption='QID Number'
                            dataField='qidNo'
                            minWidth={120}
                        />
                        <Column
                            name='18'
                            caption='QID Copy'
                            dataField='qidCopy'
                            minWidth={120}
                            alignment='center'
                            cellRender={QIDCopyCellRender}
                        />
                        <Column
                            name='19'
                            caption='Email'
                            dataField='emailID'
                            minWidth={120}
                        />
                        <Column
                            name='20'
                            caption='Mobile No'
                            dataField='mobileNo'
                            minWidth={120}
                        />

                        <Column
                            name='21'
                            caption='Whatsapp No'
                            dataField='whatsappNo'
                            minWidth={120}
                        />


                        <Column
                            name='22'
                            caption='AIP No'
                            dataField='aipNo'
                            minWidth={120}
                        />

                        <Column
                            name='23'
                            caption='AIP Copy'
                            dataField='aipCopy'
                            minWidth={120}
                            alignment='center'
                            cellRender={AIPCopyCellRender}
                        />

                        <Column
                            name='24'
                            caption='Media Type'
                            dataField='mediaTypeName'
                            minWidth={120}
                        />

                        <Column
                            name='25'
                            caption='Media Name'
                            dataField='mediaName'
                            minWidth={120}
                        />

                        <Column
                            name='26'
                            caption='Media Function'
                            dataField='mediaFunctionName'
                            minWidth={120}
                        />
                        <Column
                            name='27'
                            caption='Media Address'
                            dataField='mediaAddress'
                            minWidth={120}
                        />

                        <Column
                            name='28'
                            caption='Arrival Date & Time'
                            dataField='arrivalDateTime'
                            minWidth={120}
                            // dataType='datetime'
                            // format={"shortDateShortTime"}
                            sortingMethod={sortingMethodDate}
                        />
                        <Column
                            name='29'
                            caption='Arrival Flight No'
                            dataField='arrivalFlightNo'
                            minWidth={120}
                        />
                        <Column
                            name='30'
                            caption='Departure Date & Time'
                            dataField='departureDateTime'
                            minWidth={120}
                            // dataType='datetime'
                            // format={"shortDateShortTime"}
                            sortingMethod={sortingMethodDate}
                        />
                        <Column
                            name='31'
                            caption='Departure Flight No'
                            dataField='departureFlightNo'
                            minWidth={120}
                        />



                        <Column
                            dataField="createdByUserName"
                            caption="Created By"
                            visible={false}
                            width={125}
                            minWidth={80}
                            alignment="left"
                            name='default_createdByUserName'
                        />
                        <Column
                            dataField="updatedByUserName"
                            caption="Updated By"
                            visible={false}
                            width={125}
                            minWidth={80}
                            alignment="left"
                            name='default_updatedByUserName'
                        />
                        <Column
                            dataField="createdOn"
                            caption="Created On"
                            visible={false}
                            width={135}
                            minWidth={80}
                            alignment="left"
                            name='default_createdOn'
                            // dataType='datetime'
                            // format={"shortDateShortTime"}
                            sortingMethod={sortingMethodDate}
                        />
                        <Column
                            dataField="updatedOn"
                            caption="Updated On"
                            visible={false}
                            width={137}
                            minWidth={80}
                            alignment="left"
                            name='default_updatedOn'
                            // dataType='datetime'
                            // format={"shortDateShortTime"}
                            sortingMethod={sortingMethodDate}
                        />
                        {HideButton &&
                            <Column name='default_buttons' visible={isEditMode} type='buttons' fixed={false} allowResizing={false} width={80} allowReordering={false}>
                                <DataGridButton
                                    name='edit'
                                    icon='edit'
                                    hint='Edit'
                                    visible={VisibleEditButton}
                                    // disabled={}
                                    cssClass='text-accent bg-transparent'
                                    onClick={onEditButtonClick}
                                />
                                <DataGridButton
                                    name='delete'
                                    icon='trash'
                                    hint='Delete'
                                    visible={VisibleDeleteButton}
                                    cssClass='text-danger bg-transparent'
                                    onClick={onDeleteButtonClick}
                                    // disabled={DisabledDeleteButton}
                                />
                            </Column>
                        }
                    </DataGrid>
                </div>

                {!showSkeleton && ShowGroupRegistrationPopup &&
                    <GroupRegistrationPopup
                        popupShow={ShowGroupRegistrationPopup}
                        popupClose={onCloseGroupRegistrationPopup}
                        ComponentDataSource={dsComponent}
                        GroupFormData={GroupFormData}
                        EventDetail={SelectedEventDetail}
                        setStatus={setGroupStatus}
                    />
                }
            </div>
        </>
    )
}

export default GroupDashboard