import DataGrid, { Column, Button as DataGridButton, FilterPanel, Scrolling, ColumnChooser, HeaderFilter, SearchPanel, FilterBuilderPopup, Pager, StateStoring, Toolbar, Sorting, Item, ColumnChooserSearch, Search, } from 'devextreme-react/data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { useScreenSize } from '../../../utils/media-query';
import { Header } from '../../../components';
import { ListSkeleton } from '../../../layouts';
import { confirm } from 'devextreme/ui/dialog';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, sortingMethodDate } from '../../../utils/common-methods';
import { TitleService } from '../../../api/services/titleService';
import TitlePopup from './TitlePopup';


const titleService = new TitleService();
const TitleList = () => {

    const { isExSmall, isXXSmall } = useScreenSize();
    const [dsTitle, setDsTitle] = useState([]);
    const [filterVisible, setFilterVisible] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true)

    const [showTitlePopup, setShowTitlePopup] = useState(false)
    const [primaryKey, setPrimaryKey] = useState(null)
    const [focusedRowKey, setfocusedRowKey] = useState(0);
    const [status, setStatus] = useState({
        eStatus: eCRUDStatus.None,
        primaryKeyID: primaryKey,
    });

    const DataGridRef = useRef(null);

    const GetTitleList = async () => {
        setShowSkeleton(true)
        const result = await titleService.GetListRecord();
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")
            setShowSkeleton(false);
        } else {
            const TitleData = result?.data?.map(item => {
                const createdOn = item.createdOn ? GetFormattedDateTimeUTCString(item.createdOn) : null;
                const updatedOn = item.updatedOn ? GetFormattedDateTimeUTCString(item.updatedOn) : null; 
                return {
                    ...item,
                    createdOn: createdOn ? createdOn : null,
                    updatedOn: updatedOn ? updatedOn : null
                };
            });
            setDsTitle(TitleData);
            setShowSkeleton(false);
        }
    }

    useEffect(() => {
        GetTitleList();
    }, [])

    const onCloseTitlePopup = () => {
        setShowTitlePopup(false);
    }

    useEffect(() => {
        if (status.eStatus !== null && status.eStatus !== eCRUDStatus.None) {
            const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
            UpdateListByID(PrimaryKeyID);
            setStatus({ eStatus: null })
        } else if (status.eStatus !== null && status.eStatus === eCRUDStatus.None) {
            setPrimaryKey(null);
            setStatus({ eStatus: null })
        }
    }, [status])

    const UpdateListByID = async (primaryKey) => {
        setShowSkeleton(true)
        const result = await titleService.GetListByIDRecord(primaryKey);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false)
        } else {
            const TitleData = result?.data?.map(item => {
                const createdOn = item.createdOn ? GetFormattedDateTimeUTCString(item.createdOn) : null;
                const updatedOn = item.updatedOn ? GetFormattedDateTimeUTCString(item.updatedOn) : null; 
                return {
                    ...item,
                    createdOn: createdOn ? createdOn : null,
                    updatedOn: updatedOn ? updatedOn : null
                };
            });
            if (status.eStatus === eCRUDStatus.Inserted) {
                setDsTitle([TitleData[0], ...dsTitle]);
                setShowSkeleton(false)
            } else if (status.eStatus === eCRUDStatus.Updated) {
                setDsTitle(dsTitle.map((i) => i.titleID === primaryKey ? TitleData[0] : i));
                setShowSkeleton(false)
            }
        }
        setfocusedRowKey(primaryKey);
        setPrimaryKey(null);
    };

    const onOptionChange = (e) => {
        if (e.fullName === "filterValue") {
            setFilterVisible(!!e.value);
        }
    };

    const handleAdd = () => {
        setPrimaryKey(0)
        setShowTitlePopup(true)
    }

    const handleEdit = (e) => {
        setPrimaryKey(e.data.titleID)
        setShowTitlePopup(true)
    }

    const onDeleteButtonClick = (e) => {
        let res = confirm(
            `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
            "Run"
        );
        res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
    };

    const handleDelete = async (e) => {
        const Id = e.row.data.titleID;
        const result = await titleService.DeleteRecord(Id);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false)
        }
        else if (result.statusCode === 200) {
            setDsTitle(dsTitle.filter((x) => x.titleID !== e.row.data.titleID));
            setShowSkeleton(false)
        }
    }

    const onRowDbClick = (e) => {
        if (e?.rowType === "data") {
            setPrimaryKey(e.data.titleID)
            setShowTitlePopup(true)
        }
    }


    const TitleName = (cellData) => {
        return <>
            <div><span className='text-decoration-underline pointer-link text-break' title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.titleName}</span></div>
        </>
    }

    function onFocusedRowChanged(e) {
        setfocusedRowKey(e.component.option("focusedRowKey"));
    }


    const refreshDataGrid = () => {
        GetTitleList();
        DataGridRef.current?.instance.clearFilter();
    };

    return (
        <div>
            <Header
                title={"Titles"}
                handleAdd={handleAdd}
                dataGridRef={DataGridRef}
                refreshDataGrid={refreshDataGrid}
            />
            <div className={showSkeleton ? 'd-block' : 'd-none'}>
                <ListSkeleton />
            </div>
            <div className={showSkeleton ? 'd-none' : 'd-block list-section'}>
                <DataGrid
                    ref={DataGridRef}
                    height={"100%"}
                    width={"100%"}
                    className='List_DataGrid'
                    dataSource={dsTitle}
                    keyExpr="titleID"
                    showBorders={true}
                    showColumnLines={false}
                    showRowLines={true}
                    focusedRowKey={focusedRowKey}
                    focusedRowEnabled={true}
                    wordWrapEnabled={true}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    autoNavigateToFocusedRow={true}
                    loadPanel={HideDatagridLoader}
                    filterSyncEnabled={true}
                    onOptionChanged={onOptionChange}
                    onRowDblClick={onRowDbClick}
                    noDataText='No Record Found'
                    onFocusedRowChanged={onFocusedRowChanged}
                >
                    <Sorting mode={"multiple"} />
                    <FilterPanel visible={filterVisible} />
                    <FilterBuilderPopup visible={false} container={"#none"} />
                    <Scrolling mode={'virtual'} />
                    <ColumnChooser enabled={true} width={!isExSmall ? 320 : 'auto'}>
                        <ColumnChooserSearch
                            enabled={true}
                        />
                    </ColumnChooser>
                    <HeaderFilter visible={true} >
                        <Search
                            enabled={true}
                        />
                    </HeaderFilter>
                    <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
                    <StateStoring enabled={true} type="localStorage" storageKey="Title_Layout" />
                    <Pager
                        visible={true}
                        showInfo={true}
                        infoText={`{2} Rows`}
                    ></Pager>
                    <Toolbar>
                        <Item location={'after'} name="columnChooserButton" />
                        <Item location={'after'} name="searchPanel" />

                    </Toolbar>
                    <Column caption='Title' cellRender={TitleName} dataField='titleName' minWidth={120} allowHiding={false} />
                    <Column
                        dataField="createdByUserName"
                        caption="Created By"
                        visible={false}
                        width={125}
                        minWidth={80}
                        alignment="left"
                    />
                    <Column
                        dataField="updatedByUserName"
                        caption="Updated By"
                        visible={false}
                        width={125}
                        minWidth={80}
                        alignment="left"
                    />
                    <Column
                        dataField="createdOn"
                        caption="Created On"
                        visible={false}
                        width={145}
                        minWidth={80}
                        alignment="left"
                        sortingMethod={sortingMethodDate}
                    />
                    <Column
                        dataField="updatedOn"
                        caption="Updated On"
                        visible={false}
                        width={145}
                        minWidth={80}
                        alignment="left"
                        sortingMethod={sortingMethodDate}
                    />
                    <Column type='buttons' fixed={false} allowResizing={false} width={43} allowReordering={false}>
                        <DataGridButton
                            name='delete'
                            icon='trash'
                            hint='Delete'
                            visible={true}
                            cssClass='text-danger'
                            onClick={onDeleteButtonClick}
                        />
                    </Column>
                </DataGrid>
            </div>
            {showTitlePopup &&
                <TitlePopup
                    popupShow={showTitlePopup}
                    popupClose={onCloseTitlePopup}
                    primaryKey={primaryKey}
                    setStatus={setStatus}
                />
            }
        </div>
    )
}

export default TitleList