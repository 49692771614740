import { Button, CheckBox, NumberBox, Popup, ScrollView, TextArea, Validator } from 'devextreme-react'
import React, { useEffect, useRef, useState } from 'react'
import UserUploader from './UserUploader'
import { RequiredRule, StringLengthRule } from 'devextreme-react/validator'
import { ShowAlert, onKeyDown } from '../../utils/common-methods'
import { useScreenSize } from '../../utils/media-query'
import openeye from '../../images/openeye.png'
import closeeyeIcon from '../../images/closeeyeIcon .png'
import { Button as TextBoxButton, TextBox } from 'devextreme-react/text-box';
import { CompareRule } from 'devextreme-react/data-grid'
import { PopupSkeleton } from '../../layouts';
import { CurrentUserService } from '../../api/services/currentUserService'
import { userIcon } from '../../utils/base-64-Icons'

const currentUserService = new CurrentUserService();

const UserProfile = (props) => {

    const User = {
        userID: 0,
        createdByUserName: "",
        createdOn: null,
        updatedByUserName: "",
        updatedOn: null,
        firstName: "",
        lastName: "",
        address: "",
        cityName: 0,
        countryName: 0,
        emailID: "",
        mobileNo: 0,
        password: "",
        confirmPassword: "",
        updatePassword: false,
        uploadedFile: { fileData: null },
        attachedFile: null,
    };
    const FocusedFeild = useRef(null);

    const { isSmall, isXSmall, isXXSmall, isExSmall } = useScreenSize();
    const ValidationGroupName = "UserDataValidation"
    const onShown = () => FocusedFeild.current?.instance.focus();
    const [userData, setUserData] = useState(User);
    const [PasswordMode, setPasswordMode] = useState("password");
    const [showPasswordIcon, setShowpasswordIcon] = useState(openeye);
    const [confirmPasswordMode, setConfirmPasswordMode] = useState("password");
    const [showConfirmPasswordIcon, setShowConfirmpasswordIcon] = useState(openeye);
    const [showSkeleton, setShowSkeleton] = useState(true)


    const PasswordOptions = {
        icon: showPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setPasswordMode(PasswordMode === 'text' ? 'password' : 'text');
            setShowpasswordIcon(showPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };
    const ConfirmPasswordOptions = {
        icon: showConfirmPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setConfirmPasswordMode(confirmPasswordMode === 'text' ? 'password' : 'text');
            setShowConfirmpasswordIcon(showConfirmPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };

    const passwordComparison = () => {
        return userData.password
    }

    const UploadUserProfile = async () => {
        var Datatoupload = { attachments: userData.attachedFile }
        const result = await currentUserService.UploadUserProfileImage(Datatoupload)
        if (result.isOk) {
            props.setUpdateImage && props.setUpdateImage(true)
            return;
        } else {
            ShowAlert(result.data)
        }
    }

    useEffect(() => {
        props.setShowView === false && props.setDropDownClick && props.setDropDownClick(false);
    }, [props.setShowView])

    useEffect(() => {
        props.setDropDownClick && props.setDropDownClick(true);
    })

    useEffect(() => {
        !showSkeleton && FocusedFeild.current?.instance.focus()
    }, [showSkeleton])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowSkeleton(true)
        const DataToInsert = {
            ...userData,
            password: userData.updatePassword === false ? undefined : userData.password,
            confirmPassword: userData.updatePassword === false ? undefined : userData.confirmPassword
        }
        UploadUserProfile();
        const result = await currentUserService.UpdateRecord(DataToInsert);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false);
        }
        else {
            document.getElementById("currentUserImage").src = (userData.uploadedFile.fileData ? userData.uploadedFile.fileData : userIcon)
            if (userData.attachedFile) {
                if (props.isGroupUserPanel) {
                    localStorage.setItem("groupUserImage", window.URL.createObjectURL(userData.attachedFile));
                    localStorage.setItem("groupUserImageData", userData.attachedFile);
                }
                else {
                    localStorage.setItem("userImage", window.URL.createObjectURL(userData.attachedFile));
                    localStorage.setItem("userImageData", userData.attachedFile);
                }
            }
            else {
                if (props.isGroupUserPanel) {
                    localStorage.setItem("groupUserImage", userIcon);
                    localStorage.setItem("groupUserImageData", '');
                }
                else {
                    localStorage.setItem("userImage", userIcon);
                    localStorage.setItem("userImageData", '');
                }
            }
            props.setShowView(false);
            setShowSkeleton(false);
        }
    }

    const GetCurrentUser = async () => {
        const result = await currentUserService.GetCurrentUserRecord();
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
        }
        else {
            GetUserProfileImage().then((res) => {
                setShowSkeleton(false)
                setUserData({
                    userID: result.data.userID,
                    createdByUserName: result.data.createdByUserName,
                    createdOn: result.data.createdOn,
                    updatedByUserName: result.data.updatedByUserName,
                    updatedOn: result.data.updatedOn,
                    firstName: result.data.firstName,
                    lastName: result.data.lastName,
                    address: result.data.address,
                    cityName: result.data.cityName,
                    countryName: result.data.countryName,
                    emailID: result.data.emailID,
                    mobileNo: result.data.mobileNo,
                    password: result.data.password,
                    confirmPassword: result.data.confirmPassword,
                    updatePassword: false,
                    uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
                    attachedFile: (res?.size > 0 ? res : null)
                });
            })


        }
    }

    const GetUserProfileImage = async () => {
        const result = await currentUserService.GetUserImage()
        let response = result.data
        if (response) {
            return response.blob()
        } else {
            return;
        }
    }

    useEffect(() => {
        GetCurrentUser();
    }, []);


    const CancelButton = () => {
        props.setShowView(false)
    }

    return (
        <>

            <Popup
                visible={props.showView}
                height={"auto"}
                showTitle={false}
                wrapperAttr={{ class: "CustomPopup" }}
                deferRendering={false}
                maxHeight={"90vh"}
                width={(!isExSmall && !isXSmall && !isXXSmall && !isSmall) ? 850 : '90%'}
                onShown={onShown}
            >
                <div className='shadow rounded Company_Profile_container m-auto'>
                    <ScrollView height={"100%"} width={"100%"}>
                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                {
                                    <div className='col-md-auto '>
                                        <div className={showSkeleton ? 'pt-4 pt-md-0' : 'w-100 h-100 pt-4 pt-md-0 profile-image-bg '}>
                                            <div className='py-5'>
                                                {showSkeleton ?
                                                    <>
                                                        <div className='d-flex justify-content-center px-4'>
                                                            <div className='skeleton-View image-Skeleton-userprofile'></div>
                                                        </div>
                                                        <div className='d-flex justify-content-center px-4'>
                                                            <div className="skeleton-View User-Skeleton my-3"></div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className='mx-auto w-100 p-2 '>
                                                        <UserUploader newRecord={userData} setNewRecord={setUserData} />
                                                        <div className='py-2 font-semiBold-18 text-white text-center text-break'>{userData.firstName} {userData.lastName}</div>
                                                        <div className='pb-4 font-regular-14 text-white text-center text-break'>{userData.emailID}</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='col-md bg-white'>
                                    {showSkeleton ?
                                        <div className="d-flex justify-content-start pt-3">
                                            <span className="w-50 skeleton-toolbar-height_common rounded list-toolbar-skeleton_common"></span>
                                        </div>
                                        :
                                        <h3 className='company-title fw-semibold ps-xxl-0 ps-xl-0 ps-md-0 ps-2 pt-3 pb-2'>User Profile</h3>
                                    }
                                    {showSkeleton ?
                                        <div className='p-2 ps-0'>
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            {userData.updatePassword === true && <PopupSkeleton />}
                                            <div className='d-flex justify-content-center pb-2 pe-2'>
                                                <div className='skeleton-View Skeleton-popup-footer'></div>
                                            </div>
                                        </div>
                                        :
                                        <div className='row ps-xxl-0 ps-xl-0 ps-md-0 ps-2 pe-xxl-4 pe-xl-4 pe-md-4 pe-2'>
                                            <div className='col'>
                                                <TextBox
                                                    className=""
                                                    label="First Name"
                                                    labelMode="floating"
                                                    maxLength={50}
                                                    ref={FocusedFeild}
                                                    value={userData.firstName}
                                                    onValueChange={(e) =>
                                                        setUserData({ ...userData, firstName: e })
                                                    }
                                                >
                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                        <RequiredRule message='First Name is required' />
                                                    </Validator>
                                                </TextBox>

                                                <TextBox
                                                    className="mt-4"
                                                    label="Last Name"
                                                    maxLength={50}
                                                    labelMode="floating"
                                                    value={userData.lastName}
                                                    onValueChange={(e) =>
                                                        setUserData({ ...userData, lastName: e })
                                                    }
                                                >
                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                        <RequiredRule message='Last Name is required' />
                                                    </Validator>
                                                </TextBox>

                                                <div className=' mx-0'>
                                                    <NumberBox
                                                        label='Mobile Number'
                                                        labelMode='floating'
                                                        format="#"
                                                        step={0}
                                                        className="mt-4"
                                                        onKeyDown={onKeyDown}
                                                        maxLength={15}
                                                        inputAttr={{ maxLength: 15 }}
                                                        value={userData.mobileNo ? parseInt(userData.mobileNo) : undefined}
                                                        onValueChange={(value) => setUserData({ ...userData, mobileNo: value })}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName} >
                                                            <StringLengthRule message='Mobile Number must have 10 digits' min={10} ignoreEmptyValue={true} />
                                                        </Validator>
                                                    </NumberBox>
                                                </div>
                                                <TextArea
                                                    className='mt-4'
                                                    label="Address"
                                                    maxLength={255}
                                                    labelMode="floating"
                                                    value={userData.address}
                                                    onValueChange={(e) =>
                                                        setUserData({ ...userData, address: e })
                                                    }
                                                />
                                                <div className='row'>
                                                    <div className='col-md mt-3 pe-md-3'>
                                                        <TextBox
                                                            label='City'
                                                            labelMode='floating'
                                                            maxLength={50}
                                                            value={userData.cityName}
                                                            onValueChange={(e) =>
                                                                setUserData({ ...userData, cityName: e })
                                                            }
                                                        />
                                                    </div>
                                                    <div className='col-md mt-3'>
                                                        <TextBox
                                                            label='Country'
                                                            labelMode='floating'
                                                            maxLength={50}
                                                            value={userData.countryName}
                                                            onValueChange={(e) =>
                                                                setUserData({ ...userData, countryName: e })
                                                            }
                                                        />
                                                    </div>
                                                </div>


                                                <div className='mt-4'>
                                                    <CheckBox
                                                        iconSize={15}
                                                        className=' pe-2'
                                                        value={userData.updatePassword}
                                                        onValueChange={(e) =>
                                                            setUserData({ ...userData, updatePassword: e })
                                                        }
                                                    >
                                                    </CheckBox>
                                                    <span className='font-regular-15 show_change_password fw-semibold'>Do you want to change password?</span>
                                                </div>
                                                {userData.updatePassword === true &&
                                                    <div className='row'>
                                                        <div className='col-md pe-md-3'>
                                                            <div className='mt-4 '>
                                                                <TextBox
                                                                    label='Password'
                                                                    mode={PasswordMode}
                                                                    labelMode='floating'
                                                                    maxLength={16}
                                                                    value={userData.password}
                                                                    onValueChange={(value) => setUserData({ ...userData, password: value?.replaceAll(" ", "") })}

                                                                >
                                                                    <TextBoxButton
                                                                        name="password"
                                                                        location="after"
                                                                        options={PasswordOptions}
                                                                    />
                                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}  >
                                                                        <RequiredRule message='Password is required' />
                                                                        {/* <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} /> */}
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                        </div>
                                                        <div className='col-md'>
                                                            <div className='mt-4'>
                                                                <TextBox
                                                                    label="Confirm Password"
                                                                    mode={confirmPasswordMode}
                                                                    labelMode='floating'
                                                                    maxLength={16}
                                                                    value={userData.confirmPassword}
                                                                    onValueChange={(value) => setUserData({ ...userData, confirmPassword: value?.replaceAll(" ", "") })}
                                                                >
                                                                    <TextBoxButton
                                                                        name="confirmPassword"
                                                                        location="after"
                                                                        options={ConfirmPasswordOptions}
                                                                    />
                                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                                        <RequiredRule message='Confirm Password is required' />
                                                                        {/* <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} /> */}
                                                                        {userData.password && <CompareRule
                                                                            message="Password and Confirm Password does not match"
                                                                            comparisonTarget={passwordComparison}
                                                                        />
                                                                        }
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='mt-4 pb-4 text-center justify-content-center'>
                                                    <hr />
                                                    <Button
                                                        text='Save'
                                                        stylingMode='contained'
                                                        type='default'
                                                        useSubmitBehavior={true}
                                                        validationGroup={ValidationGroupName}
                                                        className='mx-2 px-1 rounded-3 '
                                                    >
                                                    </Button>
                                                    <Button
                                                        text='Cancel'
                                                        stylingMode='contained'
                                                        type='danger'
                                                        className='mx-2 px-1 rounded-3 '
                                                        onClick={CancelButton}
                                                    >
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </ScrollView>
                </div>
            </Popup>
        </>
    )
}

export default UserProfile