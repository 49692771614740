import { Popup, TextBox } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { useScreenSize } from "../../../utils/media-query";
import {
    ConflictPopup,
    ShowAlert,
    eCRUDStatus,
} from "../../../utils/common-methods";
import {
    Validator,
    RequiredRule,
    AsyncRule,
} from "devextreme-react/validator";
import { PopupHeader, PopupFooter, PopupSkeleton } from '../../../layouts';
import { EventGroupCategoryService } from "../../../api/services/eventGroupCategoryService";
import EventDropDown from "../event/EventDropDown";


const eventGroupCategoryService = new EventGroupCategoryService();
const EventGroupCategoryPopup = (props) => {

    const ValidationGroupName = "EventGroupCategoryPopupValidation";

    const { isExSmall, isXSmall, isXXSmall,  } = useScreenSize();

    const FocusedFeild = useRef(null);
    const onShown = () => FocusedFeild.current?.instance.focus();

    const EventGroupCategoryData = {
        eventGroupCategoryID: 0,
        eventGroupCategoryName: "",
        eventID: props.currentEventID ? props.currentEventID : null,
        updatedOn: null
    };

    const [newRecord, setNewRecord] = useState(EventGroupCategoryData);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [dropDownClick, setDropDownClick] = useState(false);
    const [addedEventID, setAddedEventID] = useState(0);
    const [createUpdateStatus, setCreateUpdateStatus] = useState(null)
    let PrimaryKeyID = props.primaryKey;
    const isAddMode = !PrimaryKeyID;


    useEffect(() => {
        if (addedEventID !== 0) {
            setNewRecord({ ...newRecord, eventID: addedEventID });
            setAddedEventID(0);
        }
    }, [addedEventID]);

    useEffect(() => {
        if (PrimaryKeyID) {
            GetModelRecord();
        }
        else {
            setShowSkeleton(false);
            setNewRecord(EventGroupCategoryData);
        }
    }, [PrimaryKeyID]);

    useEffect(() => {
        !showSkeleton && FocusedFeild.current?.instance.focus()
    }, [showSkeleton])

    const GetModelRecord = async () => {
        const result = await eventGroupCategoryService.GetRecord(PrimaryKeyID);
        if (result.statusCode === 200) {
            const response = result.data;
            setNewRecord(response);
            setCreateUpdateStatus(
                {
                    createdByUserName: result?.data.createdByUserName,
                    createdOn: result?.data.createdOn,
                    updatedByUserName:result?.data.updatedByUserName,
                    updatedOn:result?.data.updatedOn
                }
            )
            setShowSkeleton(false);
        }
        else if (result.statusCode === 400 || result.statusCode === 409) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (dropDownClick !== true) {
            if (isAddMode) {
                InsertData();
            } else {
                UpdateData();
            }
        }
    };

    const InsertData = async () => {
        setShowSkeleton(true);
        let dataToInsert = {
            eventGroupCategoryName: newRecord.eventGroupCategoryName,
            eventID: newRecord.eventID,
        };

        const result = await eventGroupCategoryService.InsertRecord(dataToInsert);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Run");
                setShowSkeleton(false);
            }
        } else {
            props.setStatus({
                eStatus: eCRUDStatus.Inserted,
                primaryKeyID: result.data.responseData,
            });
            ClearUIFields();
            props.popupClose();
            setShowSkeleton(false);

        }
    };

    const UpdateData = async () => {
        setShowSkeleton(true);
        var dataToUpdate = {
            updatedOn: newRecord.updatedOn,
            eventID: newRecord.eventID,
            eventGroupCategoryID: newRecord.eventGroupCategoryID,
            eventGroupCategoryName: newRecord.eventGroupCategoryName,
        };
        const result = await eventGroupCategoryService.UpdateRecord(dataToUpdate);

        if (!result.isOk) {
            if (result.statusCode === 409) {
                ConflictPopup(result.data).then((res) => {
                    if (res) {
                        GetModelRecord();
                    } else {
                        return;
                    }
                });
                setShowSkeleton(false);
            } else if (result.statusCode === 400) {
                ShowAlert(result.data, "Run");
                setShowSkeleton(false);
            }
        } else {
            props.setStatus({
                eStatus: eCRUDStatus.Updated,
                primaryKeyID: result.data.responseData,
            });
            ClearUIFields();
            props.popupClose();
            setShowSkeleton(false);


        }
    };
    const ClearUIFields = () => {
        setNewRecord(EventGroupCategoryData);
    }


    const onClosePopup = () => {
        ClearUIFields();
        props.setStatus({ eStatus: eCRUDStatus.None })
        props.popupClose();
    }

    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    title={[
                        <span key={"header_title"} className="base-accent-text">
                            {PrimaryKeyID ? "Edit" : "New"}
                        </span>,
                        " Event Group Category",
                    ]}
              
                />
            </>
        );
    };


    const asynceventGroupCategoryNameValidation = async (e) => {
        const Name = e?.value;
        const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
        const EventID = newRecord.eventID ? newRecord.eventID : 0;
        const result = await eventGroupCategoryService.CheckDuplicateName(Name, ExcludeID, EventID);
        return new Promise((resolve) => {
            resolve(result.isOk !== false);
        });
    };

    return (
        <>
            {props.popupShow && (
                <Popup
                    visible={props.popupShow}
                    titleRender={PopupTitle}
                    width={`${(isXSmall || isXXSmall || isExSmall) ? '95%' : "650px"}`}
                    height={"auto"}
                    maxHeight={"90%"}
                    wrapperAttr={{ class: "CustomPopup" }}
                    onShown={onShown}
                >
                    <form onSubmit={handleSubmit}>
                        {showSkeleton ?
                            <div className='p-2 px-3 pb-0'>
                                <PopupSkeleton />
                                <PopupSkeleton />
                            </div>
                            :
                            <>
                                <div className="py-2 px-3">
                                    <TextBox
                                        label="Category Name"
                                        maxLength={50}
                                        value={newRecord.eventGroupCategoryName}
                                        onValueChange={(e) => {
                                            setNewRecord({ ...newRecord, eventGroupCategoryName: e });
                                        }}
                                        labelMode="floating"
                                        ref={FocusedFeild}
                                        inputAttr={{
                                            autocomplete: "new"
                                        }}
                                    >
                                        <Validator
                                            validationGroup={ValidationGroupName}
                                            elementAttr={{ class: "ForValidationAstrikMark" }}
                                        >
                                            <RequiredRule message="Category Name is required" />
                                            <AsyncRule
                                                reevaluate={false}
                                                message="Can't accept duplicate Category Name"
                                                validationCallback={asynceventGroupCategoryNameValidation}
                                            />
                                        </Validator>
                                    </TextBox>
                                </div>
                                <div className="py-2 px-3">
                                    <EventDropDown
                                        value={newRecord.eventID}
                                        onValueChange={(e) => setNewRecord({ ...newRecord, eventID: e })}
                                        setDropDownClick={setDropDownClick}
                                        setAddedEventID={setAddedEventID}
                                        validationRequired={true}
                                        dropdownHeight={180}
                                        validationGroup={ValidationGroupName}
                                    />
                                </div>
                            </>
                        }
                        {showSkeleton ?
                            <div className='d-flex justify-content-end pb-2 pe-3'>
                                <div className='skeleton-View Skeleton-popup-footer'></div>
                            </div>
                            :
                            <PopupFooter
                                ValidationGroupName={ValidationGroupName}
                                handleCancel={onClosePopup}
                                createUpdateStatus={createUpdateStatus}
                            />
                        }
                    </form>
                </Popup>
            )}
        </>
    );
}
export default EventGroupCategoryPopup;
