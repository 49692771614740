import React, { useEffect, useMemo, useState } from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './UserPanel.scss';
import { userIcon } from '../../utils/base-64-Icons';
import UserProfile from './UserProfile';
import { CurrentUserService } from '../../api/services/currentUserService';

const currentUserService = new CurrentUserService();

const UserPanel = ({ menuMode, userImage, setUserImage, setDropDownClick }) => {

  const { signOut } = useAuth();

  const [showView, setShowView] = useState(false);

  const menuItems = useMemo(() => ([
    {
      text: 'User Profile',
      icon: 'user',
      onClick: () => setShowView(true),
    },
    {
      text: 'Logout',
      icon: 'runner',
      onClick: signOut
    }
  ]), [signOut]);

  const GetModalList = async () => {
    GetUserProfileImage().then((res) => {
      if (res && res.size > 0) {
        localStorage.setItem("userImage", window.URL.createObjectURL(res));
        localStorage.setItem("userImageData", res);
      }
      else{
        localStorage.setItem("userImage", userIcon);
        localStorage.setItem("userImageData", '');
      }
      setUserImage({
        uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
        attachedFile: (res?.size > 0 ? res : null)
      })
    })
  }

  const GetUserProfileImage = async () => {
    const result = await currentUserService.GetUserImage()
    let response = result.data
    if (response) {
      return response.blob()
    } else {
      return;
    }
  }

  useEffect(() => {
      const userImage = localStorage.getItem("userImage");
      const userImageData = localStorage.getItem("userImageData");
      if (userImage) {
        setUserImage({
          uploadedFile: { fileData: userImage },
          attachedFile: userImageData
        })
      }
      else {
        GetModalList();
      }
  }, []);


  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          {userImage?.uploadedFile?.fileData ?
            <div className='userImageContainer'>
              <img id="currentUserImage" src={userImage.uploadedFile.fileData} alt='user' className='userImage_Profile' draggable={false} />
            </div>
            :
            <div className='userImageContainer'>
              <img id="currentUserImage" src={userIcon} alt='user' draggable={false} />
            </div>
          }

        </div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={180}
          cssClass={'user-menu'}
        >
          <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
      {
        showView &&
        <UserProfile
          showView={showView}
          setShowView={setShowView}
          setDropDownClick={setDropDownClick}
        />
      }

    </div>
  );
}

export default UserPanel;