import { Popup, ScrollView } from 'devextreme-react'
import React, { useEffect, useState } from 'react'
import { PopupFooter, PopupHeader } from '../../../layouts';
import { useScreenSize } from '../../../utils/media-query';
import LoginPageProfileUploader from './LoginPageProfileUploader';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginPageBannerService } from '../../../api/services/loginpagebannerService';
import { ShowAlert } from '../../../utils/common-methods';

const loginPageBannerService = new LoginPageBannerService();
const LogInPageBanner = () => {

    const { isXSmall, isXXSmall, isExSmall, isSmall } = useScreenSize();
    const [popupShow, setPopupShow] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [newRecord, setNewRecord] = useState({
        uploadedFile: { fileData: null },
        attachedFile: null,
    });

    const currentLocation = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentLocation.pathname === '/Login-Page-Banner') {
            setPopupShow(true)
        }
    }, [])

    useEffect(() => {
        GetLoginBannerImage();
    }, [])

    const GetLoginBannerImage = async () => {
        setShowSkeleton(true);
        GetImage().then((res) => {
            setShowSkeleton(false);
            setNewRecord({
                uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
                attachedFile: (res?.size > 0 ? res : null)
            })
        })
    }

    const GetImage = async () => {
        const result = await loginPageBannerService.GetLoginBanner()
        let response = result.data
        if (response) {
            return response.blob()
        } else {
            return;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowSkeleton(true);
        UploadBanner();
        onClosePopup();
    }

    const UploadBanner = async () => {
        var Datatoupload = {attachments: newRecord.attachedFile }
        const result = await loginPageBannerService.UploadLoginBanner(Datatoupload)
        if (result.isOk) {
         setPopupShow(false)
          return;
        }
    }

    const onClosePopup = () => {
        navigate('/Home')
    }


    const onHiding = () => {
        if (currentLocation.pathname === '/Login-Page-Banner') {
            navigate('/Home')
        }
    }


    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    title={[<span key={"header_title"} className="base-accent-text">{"Login Page"}</span>, " Banner"]}
                />
            </>
        )
    }



    return (
        <>
            {popupShow && (
                <Popup
                    visible={popupShow}
                    titleRender={PopupTitle}
                    width={(isXSmall || isXXSmall || isExSmall) ? 300 : 350}
                    height={"auto"}
                    maxHeight={"90%"}
                    deferRendering={false}
                    wrapperAttr={{ class: "CustomPopup" }}
                    onHiding={onHiding}
                >
                    <ScrollView >
                        <form onSubmit={handleSubmit}>
                            <div className='overflow-hidden'>
                                {showSkeleton ?
                                    <div className="row pt-md-2 pt-3">
                                        <div className='skeleton-View  m-auto  image-Skeleton-LoginBanner'></div>
                                    </div>
                                    :
                                    <LoginPageProfileUploader newRecord={newRecord} setNewRecord={setNewRecord} />
                                }
                            </div>
                            {showSkeleton ?
                                <div className='d-flex justify-content-end pt-3 pb-2 pe-2'>
                                    <div className='skeleton-View Skeleton-popup-footer'></div>
                                </div>
                                :
                                <PopupFooter
                                    handleCancel={onClosePopup}
                                />
                            }
                        </form>
                    </ScrollView>
                </Popup>
            )}
        </>
    )
}

export default LogInPageBanner