export const DragIcon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAABlSURBVEiJ7dMxDYBAEETRv9gAF7gAHycCAWgAJSACF6CDoaK8bC6hgLCv/cVUA19nudAOaw+aARlK29gtJf1W5bc1ATXQCJvLuzvwjOyAoQQcwK7TUmkP7xE/cMUPfiB+4IofuC7UOmXmfDoqbgAAAABJRU5ErkJggg=='



export const TextEdit = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACKklEQVR4nO2YT0sbQRjG30uthxYkuSjU3lR6UsFbD/YD5DN47UlEB0lSPHsolYytkKVVpOktSFIIFEVMYmOMO20jlV6KCNUmapRQE1BmT2+ZaIJ/Esj+0WxwfvAcd3l+s7PsvAtgU/ZGRx1ZQpJZQrb/jo93QTMRXFx3/PF6E1lC8CKZppEIx2Jt4egaiyzFtnbdnmJZIjM2drA/MvIM7Iwa8jjDK2vpz9EkikSWYj933e6KRJaQ32BXcB7a0A/fUpHX8bLAhUTlSWQISYCdy6MCKFJNYs/jTYgXG+xeHqtJrCQ3Q8uqE5qlPF6WsG35D+BABdK1ypfihx+/gk20bfBq+U18B067lmey/F0jV14vb9hZp0/VFijjRco0NBp/KoeHgYHaL6sCePSxF5X1/SrX86KPaSEf4z26y1PG82aKmy+vXRbJT2+cPqlb4HzlzZUXYXEvarOPapbPBfpRSR3Ue79g3QJmt005ha/deBzvR23uscGV1yrxMV7QIXD1YjAArrZ24upDLCXasoXvoaj3I0WN9rBCYOpV30z6UzvekNDxhaWNFBh++Xx70OXC74GOc4F4yw5+eTApzkC33oNaIDA09OKfkJia6HuLidanRu5BGylgBVQKMPkETEHlFmJyC5mC3uMtxC05zFkalZ/ULSCGiIYXZtdOo6q2oEOA91gx0FgXnqcpru/3upiAxBAhzuENW3XGC6XhSm95iUQikUgkEokE7oL/b1qVhrpVUOsAAAAASUVORK5CYII="


export const MemoEdit = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2klEQVR4nO2Vv0sCYRjH362lQXTp2iuaSmhvD6eG/oGgpUHU0nNsiKAh3mjQQYuuzUGlliL8kZ7KvZWStNQQ/TBNI6iG4KYn7sqw/JEH4r0H7xe+48H38z7P9x6EmJiYmKgUJjLQZMQACJsAsBXSIr1XBrMSk/avwbmLPTXu9wQMD4BZB2R9S8z1eIW4LlaJAWDWAZmeQ8b18feJGQDR/wZgdsgIuwNA1SHDrAOy8e7AsKcIa+KH+v38wgwsuWdhM7gC29K1MQAUL0Zf1O+d7jmYttngTOAATgbgNTUGJOkFf65ijA5sBFYhvzekhlf8nLSCHBiE2u4E+LNl+gGC0k1TePAj1e0gqAJQ/JYabQrfCYI6gNOkt2X4uquC9VcnqAPw5arwJEy1Bfg7CeoAtEIgGgGwBgjYQhYqAXCXEOBDBU0Q/QTARFYLWxMm/4M4vww5zFQC4C4mkd1fT0RjmUL4WLJQCYA7QKjh4xlQ3S2EXoIdZAIfIi3DxzNwcJQo3vG8WOJ5+iGyrcJ7PO+PLheUXK40ollSmLdEYmK+IfxFPfy3rxDtiiQSpkhcJF8vz/+ELzmdlbLdPo6MoNBh1nzr9aYbXr70sLw8oncuTbp3OMzKzitr0xj+E5qNfSU/M482AAAAAElFTkSuQmCC"

export const Dropdown = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAy0lEQVR4nO2UMQoCMRBFg4WHsVGS9R5aWngAsRdnIizaqFex3p3Ro1i7aiXewEiCxeIi7Jpslw8fAgPzAjN/hIiK8ldqOkM8DRQcR0rzpLaRzU8D3STSvpceusI1b9JY1wB8LIF3QiKNWwMg38VfzXU9gLUngF5K86JSA1oGArBRq3z9XUuANuEAyE8J+bz0+5lCfoQEGIl8TnQ2tbbvgDPg8kpeFHBRHXLTgOkGWwRciD5mrQVNIW/dqXAQyMOdCqSrTbE7FVFRwldve98x3yAcJ9IAAAAASUVORK5CYII="


export const DateEdit =
"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnUlEQVR4nO1YwUrDQBDdu/WgPdajelUihPYT9K7/YQ9Kgx+Ru/bWg21oUygkIMaL7dGuBzFbsr3o1YD6AysbtWhTa6aGborz4EHZwnvzsjMTCCEIxPxhe929ttd9lGxdXu+q1gEjMr3qiYhe70G1Dhgj0w+q1pkI/chZ0wzX0gz3dcdwxVeOG4//n5TtX3TevZ3m9snFBgEXX3HCSab6cSdmLM+gxUN0tIoTbhmdQuIA8sn/ZFwu7ceM5Rk0AFzHqUMCxNrmk9Xcasy4urQCDgDXcZ4TB5hmfJbLC7tuj0ztc1ucLufBAWbRIWkEkNfc0IuRoaSlF8Vh6WCmFmoAdVIJIAdNmssnKCl/zzrEZaBOKgFUkmAAY0FugDIuskiCARjegMhEC5m11jemfU4xAMMbEJluIYrvAY5baCpwiGsZH2Jz0V9kFLcQxy30v2fAxAAct9BUqF6X9M8z4PMX1cXScfpB8o+7fRY0lRfMYrQSB7i9H25Sxp8yULSQ7LMgvLkL1hMHiNpoMCj0fV5X2k5+5G2Bi0cgEAgyL7wB4x3jqUCAx1sAAAAASUVORK5CYII="

export const TimeEdit ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADiElEQVR4nO2YS08TURTHJz4WsvSx8vEdOlUeQenCmcJCXKgF7m3AjvUVQyQSjabGNBgVdkYe6b0Q9gTjI742LkxUTEzKuaD4AUBWQolL2HTMmbZapLQz05lpSTjJSZo0ufP/3XvOveccSXLQZEWbklVNL+Y+RfssVav51MgnEwAfpa1icla0tFVN3gaosG2ZEwhNTu4kbLqBMIgRDs8IFz8oh5XGjli6seNuGn9TBnPGfwxi4dFkfTyu79hsPTl43i8rmvCp2rxP0TTXhLePfD9MOQwQDouUC92KEwY/KRf94dHZQwXEr+TdXGlZifQ4Kjw0DgcoB04ZrFkVvsEZrBEuEoQn9/tVrc6nRn4bwhVtQlYj1wwAVUv7Ve26I+IpB0oZpMoW/v+JcLHsb76Y2XlFmwgE4rvwez41ciUHUVY4XebJ3ZSLMaeF0zwP9jA9EHk4E7oxuSf/29mT0DEn7IqvIUy8c1M8Xe9v8Zv4bTyJTDgZJ5O0tfMei9czuSHen4q/qsmJx9zwKReOWgZwO2xoEW+KPJjJiccEtyyejIpwpcTTbE74Wy6lbInvHPq6j3KxVGjhk1cf68fpPb1tcMqDUIIUXrGWAYx7fpNFUTwebcPZmx5BiBFL4vF1LPZItQ9+0RvO3TIg6s/06m1PXIZgsNbJk0es7P5AqUU9h+Ci35R4LLKydYpeTRCEwyIWjSUBsKq0srCnEAlRawIAYlYX9gqCcLhjBuC5ncW9gCBcPC0JgI2H3Q+4DUE4fDMDUFapnA/hb47qta3d+um+l05BLJkBKLtJQQh/MPp3FoQQzgDAqicA6HWt3f8GWsGoQ+EEpgAc6bYwbAyI5qiTObHkahIXcqyVsGZCiBNdfe4nMbF5jZaCaOq6r7f0jrt/jRIbD5lnzsTtkgA4dKq4UF7YwxyOmSrmKIeFSoulG8Nnvtg0b51h6Vp1AEw8MiXeTEPjvcNqV0IclKwYjvsqL1xknMGQZNVCY3N7N2vqPQ0dLpZtNfWZU5juqDQA5SIklWOUAavc7sOwVK5hH0o5vPAeAN4E4h+MCXXZhoNWHLh6tvMMXueGu46ZMeT14GYiHIYd2/lCRtlMuyu3ExO/yk5YS3NTJkbwgXEg1lfxnsdrW/La8HXMlB12aidYwPLA8gvrhhnTvISoxbkN1uzYeBidHZYjGU9RJmbxPyyJsao0XZhtm1Td9gfW2z1QzJSf6gAAAABJRU5ErkJggg=="

export const DateTimeEdit = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD6ElEQVR4nO2Y70sTcRzH728QeqIWPcjAZ0HeKZKN7kalwVTCyhACA2NlpUklPo4iMJxh+BOJskLXj/UoH2RpU5Iir3K0za1fqy4Ct7V9b/Yg4xPfU8/bzvvhnHPWPvCB48sX7v36fn58P3cEkba0Jc9mjNs3IYY8iWhqkGcoF6IpHvv88yCiqdqZ3XkbiVSzCF2QhWiyE9HkLM9QoOaIpv7wDDXwy5C/mUgFi9BUGc+QSEu43MlwZFe+aU3FIzr/9PyJisLC9ccgZL0NQcdbCH7n5tzxBkLWWxCuOyaLBmLIU2t28kgiHlWVQ3B0BAKBgKoHR4eBryqPgkh6JCIGKluaNujkUQj4fDKxBEEILgPx+QDVVkenk7EwM2kAiCF7pSe/lHhVAAHiM/BVZYuRYKju5LVKerHbBJ89VUwXVQAhnUYkqUTO4siqvfvB0GiJbWj0C/b7j+3FcQHgopMWrFq+awFgR5LCRjR1Qu3dNiz+yRgIPjTmiw+Aph4tvDB0986KAUIDt0SA14f3Q6OlR9FtC+LnfW69O3ze0nPvrKU3RxcAz5AeMX0cbxWFTU9PiwB+v185jRxvRIDPpbsVxZ9r6ZIB4DXJHn/TletZegDE7hPgOEVhHR0dIkBzc7NyFDhOBAjuKVQEqDA3yADwWtS+lu7+VQHIzMwEt9u99N5vX0WAwJ4digA7TQdkAEWmg7H7fmoD0JRbTwrhtGlra4Pc3FwBIicnB5xOpzyFJvWlUFHpQbj5cFAUf8M2CDtNh2T7llfE1tuaNy/HcWAwGBQhQv19uoq4wtwAleY6QTj2SnM9VBxviAugVmyjdeptNBYiIyMD7HZ7TButEQGs9WdUi7jC3CBEAjt+jilifQB4no+6yEae6IYYHx+PuciGF4dAYz5cvNSq2kYbdbgmgBAFhuwRL59KEwQ+fdQFoTZKjFQfWbH4Rr0A+AMGD2AiRG21IGg54qXDHO4+Fy5fTR4ANp7OK476AsPjtH1YUzyenaQnzxsLYMo6AKzLmxAnljsXxX7Q4NkGjwe4PQr3BMcJz3hNWrAL4j92XkuYeHa5ANjwx4g0nfR6eJ8Bpu4m7uTZeAGwhfdu28DTVCtiyN+a4o0FwDWdgckXLxMuno0XQIyGgcr+0NkOP07VQOBwOaCSIsHx84/TNfChqx0mn79YFeFsIgCwraY4NhUALH33ozzedTYNoGDpCPSleAqx672I2fUOYEl3IW86Av93CrHrvYjZlAdwekNrBuD0aP/Y0rIJl+feGkbAumKA1+/eb2Vd3ulki59wefyvHJ4tRCKMdbuzJpze/qSkk1N4hzVh4tOWtn/M/gI4HeOdnqm9vgAAAABJRU5ErkJggg=="

 export const SingleFileUploader = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADZElEQVR4nO2azU8TQRjG9+TBxLMx6tn/y4PGdAUPKgcwfFpKuiMcsFKKOy0t27QUUeLBmMChgBckeDLtAcS0aPzATjWRpJq8ZrYfln7tdrvTLbvzJk9CJszOPL999t0pVBB48eLFixcvXo6v4eH4OeSSpySX/AmJGIxKEnFBcuEUEuUh/w3/+TMDVhKxpxPjjWHIezN9gYvCWSgk4iO66aPUPsAJMaw/+WP4sJeGpyPLZQjvH90JXxJ6vVDprnVivlonP75BxPNcveZ0H95HN4OXBScBgBoIs3fDXzy3568ITgIAKoSvEJ5cVa/tG4j8lseWr0/dW7ggOAUAlJKgeF+Y2mB16og2d/qGsxQAnBD4++sYdl7tQGg8ATP9wa6CoBB0AyjkDmyjw3d7lSQ4EkAhd1BJgeMBIK3egGyeAM3egGwOYDcUrWhjdqm+NyAHAaCq6w2oywBIJgWL7gSEJxOQz6adBYBk0hAci1c2IY/EIJdJOQMAyaQAjxbN+4YSqujPFAiLJPQUAFJ15x8PxmF96xA2tj+C70GCWRJ6BgBpYD65+1kVSwg9AYC0MM8aguUAiA7zLCFYCoC0YZ4VBMsAEAPmWUCwBADpwLzZELoOgJhg3kwIXQew6C5umB5wqIFqQ+vbhzA3vAKBh2t1ZgPuNfCPrtbNUSGUDksRT6L3AYQnGwNY36J3s/i/AqpaAOXx2nnVAJY8K70PIJ9NQ3C8+WlPC0CzeaEJY0dlS5pgPvu/D5w675fAtALQaJ5R85YBKNQkQW1io8UmpgWgdl4n5i0FUChBoI2LPrtlE1oAms07kwAKLTbUCgCL9TiAEE8A6HoEEANFvc/afgSUqRVT96A7AYiBIm4FcqkNVVoAyr8XcUetAZArbYCV9AIwez0OINRGAsITihrBapJmjelJgJnrG0oAahBHs8b0ADBzfQ5ANJCAiDt6qnObOaYnAWaurx+AC/+kA9m3r231FvDdD6nX3fQrFfNvAkp5PVIFQF6lgzFvlCmEbgN4+aT4kXp+IAibc4pqHg8WoUiiHKsAmO5fuIZEOcfyQKT3DyLdkCTK3+u+tyjdkq8iEccll5y3KwDqjd55y760iTQACHYvxAFgngDEHwHMe0DS6U0QNZFg90Ii3mz+jsZJq/fHS3BY/QO4EI2SA9/YcwAAAABJRU5ErkJggg=="