import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest, groupSignIn as sendGroupSignInRequest, getGroupUser, GetEventDetails as GetEventDetailsRequest, GetEventLogo as GetEventLogoRequest } from '../api/auth';


function AuthProvider(props) {
  const [user, setUser] = useState();
  const [groupUser, setGroupUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const result = await getGroupUser();
      if (result.isOk) {
        setGroupUser(result.data);
      }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data);
    }
    return result;
  }, []);

  const groupSignIn = useCallback(async (email, password) => {
    const result = await sendGroupSignInRequest(email, password);
    if (result.isOk) {
      setGroupUser(result.data);
    }
    return result;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("authToken");
    localStorage.removeItem("userImage");
    localStorage.removeItem("userImageData");
    setUser(undefined);
  }, []);

  const GroupSignOut = useCallback(() => {
    localStorage.removeItem("groupUserEmail");
    localStorage.removeItem("groupUserAuthToken");
    localStorage.removeItem("groupUserEventCode");
    localStorage.removeItem("groupEvent");
    localStorage.removeItem("groupUserImage");
    localStorage.removeItem("groupUserImageData");
    setGroupUser(undefined);  
  }, []);

  const GetEventDetails = useCallback(async (evenCode) => {
    const result = await GetEventDetailsRequest(evenCode);
    return result;
  }, []);


  const GetEventLogo = useCallback(async (evenCode) => {
    const result = await GetEventLogoRequest(evenCode);
    let response = result.data
    if (response) {
      return response.blob()
    } else {
      return;
    }
  }, [])

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, setUser, groupUser, groupSignIn, GroupSignOut, GetEventDetails, GetEventLogo }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
