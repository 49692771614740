import React, { useEffect, useState } from 'react'
import "./Container.scss"
import { ScrollView } from 'devextreme-react'
import { useScreenSize } from '../../utils/media-query';
import { LoginPageBannerService } from '../../api/services/loginpagebannerService';

const loginPageBannerService = new LoginPageBannerService();
const Container = ({ children , hideImage }) => {

    const { isXSmall, isXXSmall, isExSmall , isSmall  } = useScreenSize();
    const [newRecord, setNewRecord] = useState({});

    useEffect(() => {
        GetLoginBannerImage();
    }, [])

    const GetLoginBannerImage = async () => {
        GetImage().then((res) => {
            setNewRecord({
                uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
                attachedFile: (res?.size > 0 ? res : null)
            })
        })
    }

    const GetImage = async () => {
        const result = await loginPageBannerService.GetLoginBanner()
        let response = result.data
        if (response) {
            return response.blob()
        } else {
            return;
        }
    }

    return (
        <>
            <ScrollView height={'100%'} width={'100%'} className={'with-footer container'} >
                <div className={`row ${hideImage ?  "w-auto"  : "main-container"} mainContainer-Colour p-xxl-4 p-xl-4 p-lg-4 p-md-4 p-0  rounded m-auto d-flex justify-content-center shadow-sm`}>
                   
                    {!hideImage && (!isXSmall && !isXXSmall && !isExSmall && !isSmall ) && newRecord.uploadedFile?.fileData && <div className='col-md-auto me-3'>
                        <div className=' mt-3 mb-0 design_image'><img className='image_width_set' src={newRecord.uploadedFile?.fileData} alt='Run'/></div>
                    </div>}
                    <div className='col-md-auto px-0'>
                        {children}
                    </div>
                </div>
            </ScrollView>
        </>
    )
}
export default Container 