import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard, Container } from './layouts';
import { LoginForm, ForgotPassword, RequestResetLink, ResetPasswordForm, ErrorPage, InvalidPage, SuccessFullSetPassword, RegistrationClosed, SucessfullyFormSubmitted, RegistrationPage, GroupLoginForm } from './components';
import { useEffect, useState } from 'react';

export default function UnauthenticatedContent() {

  const [showLoader, setShowLoader] = useState(false);
  const [showSignInBtn, setShowSignInBtn] = useState(true);

  const defaultLoginPage = () => {

    var Admin_UserType = localStorage.getItem("userType_AdminUser");
    var Group_UserType = localStorage.getItem("userType_GroupUser");
    var eventCode = localStorage.getItem("isGroupUser");

    if (Admin_UserType && !window.location.pathname.includes("/group/")) {
      return '/login';
    } else if (window.location.pathname.includes("/group/")) {
      return `/glogin/${eventCode}`
    } else {
      return '/login';
    }
  }


  return (
    <Routes>
      <Route
        path='/login'
        element={
          <Container>
            <SingleCard showLoader={showLoader} title="Login">
              <LoginForm showLoader={showLoader} setShowLoader={setShowLoader} />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path="/forgot-password"
        element={
          <Container >
            <SingleCard
              showLoader={showLoader}
              title="Forgot Password?"
              subtitle="Please enter the email address you'd like your password reset information sent to"
            >
              <ForgotPassword showLoader={showLoader} setShowLoader={setShowLoader} />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path="/glogin/forgot-password"
        element={
          <Container hideImage={true}>
            <SingleCard
              showLoader={showLoader}
              title="Forgot Password?"
              subtitle="Please enter the email address you'd like your password reset information sent to"
            >
              <ForgotPassword showLoader={showLoader} setShowLoader={setShowLoader} />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/request-reset-link'
        element={
          <Container>
            <SingleCard>
              <RequestResetLink />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/glogin/request-reset-link'
        element={
          <Container hideImage={true}>
            <SingleCard>
              <RequestResetLink />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/error-page'
        element={
          <Container>
            <SingleCard>
              <ErrorPage />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/glogin/error-page'
        element={
          <Container hideImage={true}>
            <SingleCard>
              <ErrorPage />
            </SingleCard>
          </Container>
        }
      />
      <Route
        path='/ResetPassword/:ResetPasswordCode'
        element={
          <Container>
            <SingleCard
              title="Reset Password"
              showLoader={showLoader}
            >
              <ResetPasswordForm showLoader={showLoader} setShowLoader={setShowLoader} />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/glogin/ResetPassword/:ResetPasswordCode'
        element={
          <Container hideImage={true}>
            <SingleCard
              title="Reset Password"
              showLoader={showLoader}
            >
              <ResetPasswordForm showLoader={showLoader} setShowLoader={setShowLoader} />
            </SingleCard>
          </Container>
        }
      />
      <Route
        path='/glogin/invalid-page'
        element={
          <Container hideImage={true}>
            <SingleCard>
              <InvalidPage showSignInBtn={showSignInBtn} />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/invalid-page'
        element={
          <Container>
            <SingleCard>
              <InvalidPage showSignInBtn={showSignInBtn} />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/successful-set-password-page'
        element={
          <Container>
            <SingleCard>
              <SuccessFullSetPassword />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/glogin/successful-set-password-page'
        element={
          <Container hideImage={true}>
            <SingleCard>
              <SuccessFullSetPassword />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/glogin/:eventCode'
        element={
          <Container hideImage={true}>
            <SingleCard
              showLoader={showLoader}
            >
              <GroupLoginForm showLoader={showLoader} setShowLoader={setShowLoader} setShowSignInBtn={setShowSignInBtn} />
            </SingleCard>
          </Container>
        }
      />

      <Route
        path='/visitor/:eventCode'
        element={
          <RegistrationPage />
        }
      />

      <Route
        path='/media/:eventCode'
        element={
          <RegistrationPage />
        }
      />

      <Route
        path='/visitor/form-submitted'
        element={
          <Container >
            <SingleCard>
              <SucessfullyFormSubmitted />
            </SingleCard>
          </Container>

        }
      />

      <Route
        path='/media/form-submitted'
        element={
          <Container >
            <SingleCard>
              <SucessfullyFormSubmitted />
            </SingleCard>
          </Container>

        }
      />

      <Route
        path='/registration-closed'
        element={
          <Container hideImage={true}>
            <SingleCard>
              <RegistrationClosed showSignInBtn={showSignInBtn} />
            </SingleCard>
          </Container>
        }
      />

      <Route path='*' element={<Navigate to={defaultLoginPage()} />}></Route>
    </Routes>
  );
}
