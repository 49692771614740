import React, { createRef, useEffect, useState } from "react";
import {
  TextBox,
  TextArea,
  FileUploader,
  Button,
  ScrollView,
} from "devextreme-react";
import "./RegistrationPage.scss";
import { useParams, useNavigate } from 'react-router-dom';
import { alert } from "devextreme/ui/dialog";
import Validator, { EmailRule, RequiredRule } from 'devextreme-react/validator';
import { SelectBox, Button as SelectBoxButton } from 'devextreme-react/select-box';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import { RegistrationFormDesignerService, eRegistrationType } from "../../api/services/registrationFormDesignerService";
import { GetFormattedDateTime, ShowAlert, dateOutOfRangeMessage, maxDOBDate, minDate, toCamelCase } from "../../utils/common-methods";
import { RegistrationAuthorizedService, eFileUploadType } from "../../api/services/registrationAuthorizedService";
import { PopupSkeleton } from "../../layouts";
import { useScreenSize } from "../../utils/media-query";
import { useAuth } from "../../contexts/auth";

const registrationFormDesignerService = new RegistrationFormDesignerService();
const registrationAuthorizedService = new RegistrationAuthorizedService();

const RegistrationPage = (props) => {
  const { GetEventLogo, GetEventDetails } = useAuth();
  const { eventCode } = useParams();
  const navigateTo = useNavigate();
  const { isXSmall, isXXSmall, isExSmall, isLarge, isExLarge, isMedium } = useScreenSize();
  const [EventDetail, setEventDetail] = useState(null);
  const [FormDesignMessage, setFormDesignMessage] = useState("")

  const maxImageSizeLength = 10 * 1024 * 1024;
  const allowedFileExtensions = [".jpg", ".jpeg", ".gif", ".png"];
  const ValidationGroupName = "RegistrationFormPopupValidation";

  const [EventLogo, setEventLogo] = useState(null);
  const [RefreshSection, setRefreshSection] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const [genderData, setgenderData] = useState([]);
  const [NationalityData, setNationalityData] = useState([]);
  const [MediaTypeData, setMediaTypeData] = useState([]);
  const [MediaFunctionData, setMediaFunctionData] = useState([]);

  const [componentItems, setComponentItems] = useState([]);
  const GetComponents = async () => {
    setShowSkeleton(true);
    const result = await registrationFormDesignerService.GetComponents();
    if (result.statusCode === 400) {
      ShowAlert(result?.data, "Run");
      setShowSkeleton(false);
    } else {
      GetFormDesign(result?.data)
    }
  }

  useEffect(() => {
    localStorage.removeItem('eventCode');
    GetEventDetail();
  }, []);

  const GetEventLogoImage = async () => {
    const logoResult = await GetEventLogo(eventCode)
    if (logoResult) {
      setEventLogo(window.URL.createObjectURL(logoResult))
    } else {
      setEventLogo(null)
    }
  }

  const GetEventDetail = async () => {
    setShowSkeleton(true);
    GetEventLogoImage();
    const result = await GetEventDetails(eventCode);
    if (!result.isOk) {
      localStorage.removeItem('eventCode')
      navigateTo('/invalid-page');
    } else {
      localStorage.setItem('eventCode', eventCode)
      const response = result.data;
      const currentDateTime = new Date();
      if (currentDateTime > new Date(response.eventTo)) {
        navigateTo('/registration-closed', {
          state: {
            EventID: response.eventID,
            EventName: response.eventName,
            PlainClosingMessage: response.eventClosingMessagePlainText,
            FormattedClosingMessage: response.eventClosingMessageFormattedText
          }
        })
      } else {
        const isVisitor = window.location.pathname.includes("/visitor/");
        const isMedia = window.location.pathname.includes("/media/");
        let registrationType = null;
        if (isVisitor && !isMedia) {
          registrationType = eRegistrationType.Visitor;
        } else if (!isVisitor && isMedia) {
          registrationType = eRegistrationType.Media;
        }
        setEventDetail({
          EventID: response.eventID,
          EventName: response.eventName,
          RegistrationType: registrationType
        })
        document.title =  isVisitor ? response?.eventName + " - Visitor Registration" : isMedia && response?.eventName + " - Media Registration" ;
        GetComponents();
      }
    }
  }

  const GetFormDesign = async (ComponentDataSource) => {
    const isVisitor = window.location.pathname.includes("/visitor/");
    const isMedia = window.location.pathname.includes("/media/");
    let registrationType = null;
    if (isVisitor && !isMedia) {
      registrationType = eRegistrationType.Visitor;
    } else if (!isVisitor && isMedia) {
      registrationType = eRegistrationType.Media;
    }
    const result = await registrationAuthorizedService.GetRegistrationForm(eventCode, registrationType);

    if (result.statusCode === 200) {
      setFormDesignMessage("")
      const response = result.data;

      const components = response.components?.sort(function (a, b) {
        return a.index - b.index;
      });;

      let dsComponents = [];
      components.map(item => {
        const captions = ComponentDataSource.filter(r => r.componentID === item.componentID);
        let CorrectCaption;
        if(captions[0]?.caption === "QID copy"){
          CorrectCaption = "QID Copy"
        }else{
          CorrectCaption = captions[0]?.caption
        }
        dsComponents.push({
          componentID: item.componentID,
          componentTypeID: item.componentTypeID,
          caption: CorrectCaption,
          componentRef: createRef(),
          isMandatory: item.mandatory,
          componentValue: null,
          componentField: toCamelCase(captions[0]?.fieldName)
        })
      })
      UpdateDataSourceForDropdown(dsComponents);
      setComponentItems(dsComponents);
      setShowSkeleton(false);
    }
    else if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Run")
      setShowSkeleton(false);
      setFormDesignMessage("")
    }
    else if (result.statusCode === 204) {
      setFormDesignMessage(`No form design for ${registrationType === 1 ? "Visitor" : "Media"}`)
      setShowSkeleton(false);
    }
  };

  const UpdateDataSourceForDropdown = async (dsComponent) => {
    const hasGenderField = dsComponent.filter(r => r.componentTypeID === 3 && r.componentID === 10);
    if (hasGenderField.length > 0) {
      setgenderData([
        { genderID: 0, genderName: "Male" },
        { genderID: 1, genderName: "Female" }
      ])
    }

    const hasNationalityField = dsComponent.filter(r => r.componentTypeID === 3 && r.componentID === 9);
    if (hasNationalityField.length > 0) {
      const result = await registrationAuthorizedService.GetNationalityLookupList();
      if (result.statusCode === 200) {
        setNationalityData(result.data)
      } else {
        setNationalityData([])
      }
    }

    const hasMediaTypeField = dsComponent.filter(r => r.componentTypeID === 3 && r.componentID === 24);
    if (hasMediaTypeField.length > 0) {
      const result = await registrationAuthorizedService.GetMediaTypeLookupList();
      if (result.statusCode === 200) {
        setMediaTypeData(result.data)
      } else {
        setMediaTypeData([])
      }
    }

    const hasMediaFunctionField = dsComponent.filter(r => r.componentTypeID === 3 && r.componentID === 26);
    if (hasMediaFunctionField.length > 0) {
      const result = await registrationAuthorizedService.GetMediaFunctionLookupList();
      if (result.statusCode === 200) {
        setMediaFunctionData(result.data)
      } else {
        setMediaFunctionData([])
      }
    }

  }

  const onComponentValueChange = (e, item) => {
    setRefreshSection(true);
    componentItems.map(r => {
      if (item.componentID === r.componentID) {
        if (item.componentID === 28 || item.componentID === 30) {
          r.componentValue = GetFormattedDateTime(e)
        } else {
          r.componentValue = e
        }
      }
    })
    setTimeout(() => {
      setRefreshSection(false);
    }, 100)
  }

  const DownloadFile = async (e, item) => {
    const fileData = item.componentFile;
    if (fileData) {
      const file = new Blob([fileData]);
      const fileURL = URL.createObjectURL(file);
      const downloadLink = document.createElement("a");
      downloadLink.href = fileURL;
      downloadLink.download = item.componentFileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(fileURL);
    }
  }

  const onDeleteFile = async (e, item) => {
    setRefreshSection(true);
    if (item.hasFile) {
      componentItems.map(r => {
        if (r.componentID === item.componentID) {
          r.isFileDeleted = true;
          r.componentFileName = null;
          r.componentFile = null;
          r.componentValue = null;
          r.isMandatory = item.isMandatory;
        }
      })
    } else {
      componentItems.map(r => {
        if (r.componentID === item.componentID) {
          r.componentFileName = null;
          r.componentFile = null;
          r.componentValue = null;
          r.isMandatory = item.isMandatory;
        }
      })
    }
    setTimeout(() => {
      item.componentRef.current?.instance.reset();
      setRefreshSection(false);
    }, 100)
  }

  const onFileUploaderValueChanged = (e, item) => {
    const file = e[0];
    if (file) {
      
      if (file?.type ==="application/x-zip-compressed" || file?.type === "image/avif") {
        alert(
          `<div class="row align-items-center"><i class="dx-icon-warning alerticon col-auto text-warning pe-0 fs-2" > </i><b class="alertText col">This file is not accepted.</b></div>`,
          "Run"
        );
        item.componentRef?.current?.instance.reset();
        return;
      }
      if (file?.size > maxImageSizeLength) {
        alert(
          `<div class="row align-items-center"><i class="dx-icon-warning alerticon col-auto text-warning fs-2 pe-0"> </i><b class="alertText col">Image of more than 10 MB size cannot be uploaded.</b></div>`,
          "Run"
        );
        item.componentRef?.current?.instance.reset();
        return;
      }

     // if (file["type"]?.split("/")[0] === "image") {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          setRefreshSection(true);
          componentItems.map(r => {
            if (item.componentID === r.componentID) {
              r.fileUploaded = true; // check is file uploaded or not
              r.componentFileName = file.name;
              r.componentValue = file;
              r.componentFile = file;
            }
          })
          setTimeout(() => {
            setRefreshSection(false);
          }, 100)

        };
        fileReader.readAsDataURL(file);

     // }
    }
  };

  const onSubmitVisitorForm = (e) => {
    e.preventDefault();
    if (componentItems.length === 0) {
      ShowAlert("There are no control to save", "Run");
      return;
    }
    InsertData();
  }

  const InsertData = async () => {
    setShowSkeleton(true);
    let dataToSave = {
      eventID: EventDetail.EventID,
      registrationTypeID: EventDetail.RegistrationType
    }

    componentItems.map(function (obj) {
      if (obj.componentID && obj.componentTypeID !== 7) {
        const fieldToAdd = obj.componentField;
        dataToSave[fieldToAdd] = obj.componentValue;
      }
    });

    const result = await registrationAuthorizedService.InsertRecordWithoutToken(dataToSave);    
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Run");
        setShowSkeleton(false);
      }
    } else {
      // For Photo
      const CheckPhotoValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 12 && r.componentFile && r.fileUploaded === true);
      if (CheckPhotoValidation.length > 0) {
        UploadFile(CheckPhotoValidation[0]?.componentFile, result.data?.responseData, eFileUploadType.Photo);
      }
      // For Passport Copy
      const CheckPassportCopyValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 14 && r.componentFile && r.fileUploaded === true);
      if (CheckPassportCopyValidation.length > 0) {
        UploadFile(CheckPassportCopyValidation[0]?.componentFile, result.data?.responseData, eFileUploadType.PassportCopy);
      }
      // For QID Copy
      const CheckQIDCopyValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 18 && r.componentFile && r.fileUploaded === true);
      if (CheckQIDCopyValidation.length > 0) {
        UploadFile(CheckQIDCopyValidation[0]?.componentFile, result.data?.responseData, eFileUploadType.QIDCopy);
      }
      // For AIP Copy
      const CheckAIPCopyValidation = componentItems.filter(r => r.componentTypeID === 7 && r.componentID === 23 && r.componentFile && r.fileUploaded === true);
      if (CheckAIPCopyValidation.length > 0) {
        UploadFile(CheckAIPCopyValidation[0]?.componentFile, result.data?.responseData, eFileUploadType.AIPCopy);
      }



      const isVisitor = window.location.pathname.includes("/visitor/");
      const isMedia = window.location.pathname.includes("/media/");
      if (isVisitor && !isMedia) {
        navigateTo('/visitor/form-submitted');
      } else if (!isVisitor && isMedia) {
        navigateTo('/media/form-submitted');
      }

      setRefreshSection(false);
      setComponentItems([]);
      setEventDetail(null);
      setShowSkeleton(false);
    }
  }

  const UploadFile = async (File, RegistrationID, FileUploadType) => {
    var Datatoupload = { attachments: File }
    if (FileUploadType === eFileUploadType.Photo) {
      const result = await registrationAuthorizedService.UploadPhoto(Datatoupload, RegistrationID);
      if (result.isOk) {
        return;
      } else { }
    }
    if (FileUploadType === eFileUploadType.PassportCopy) {
      const result = await registrationAuthorizedService.UploadPassportCopy(Datatoupload, RegistrationID);
      if (result.isOk) {
        return;
      } else { }
    }
    if (FileUploadType === eFileUploadType.QIDCopy) {
      const result = await registrationAuthorizedService.UploadQIDCopy(Datatoupload, RegistrationID);
      if (result.isOk) {
        return;
      } else { }
    }
    if (FileUploadType === eFileUploadType.AIPCopy) {
      const result = await registrationAuthorizedService.UploadAIPCopy(Datatoupload, RegistrationID);
      if (result.isOk) {
        return;
      } else { }
    }

  }

  return (
    <>
      <div className="d-flex justify-content-center my-4  vw-100 ">
        <div className="shadow-sm rounded visitor-section-width ">
          <form onSubmit={onSubmitVisitorForm}>
            {showSkeleton &&
              <div className='p-2 px-3 pb-0'>
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            }
            {!showSkeleton &&
              <>
                {EventLogo &&
                  <div className='text-center EventLogo_div m-auto'>
                  <img src={EventLogo} alt='event logo' className="pt-3 eventLogo_imageTag" />
                </div>
                }
                <div className='text-center'>
                  <div className="font-medium-22 px-3 py-2 text-accent">
                    {EventDetail?.EventName}
                  </div>
                  <div className="font-medium-22 px-3 py-2 border-bottom">
                    {EventDetail?.RegistrationType === eRegistrationType.Media ? "Media" : "Visitor"} Registration

                  </div>
                </div>
                <ScrollView height={"100%"} width={"100%"}>
                  <div className={`p-3 ${isExLarge ? 'heightForExlarge' : 'heightForLarge'}`}>
                    <div className="form-section">
                      {FormDesignMessage !== "" && <div className="text-center fw-bold">{FormDesignMessage} </div>}
                      {RefreshSection && <div> </div>}
                      {componentItems.map((item, index) => {
                        return (
                          <>
                            <div key={index}>
                              {item.componentTypeID === 1 &&
                                <>
                                  <div className='py-1'>
                                    <TextBox
                                      key={item.componentID}
                                      id={item.componentID}
                                      name={item.caption}
                                      ref={item.componentRef}
                                      label={item.caption}
                                      labelMode='floating'
                                      value={item.componentValue}
                                      onValueChange={(e) => onComponentValueChange(e, item)}
                                      maxLength={item.componentID === 19 ? 50 : item.componentID === 20 ? 15 : undefined}
                                    >
                                      <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                        {item.isMandatory &&
                                          <RequiredRule message={`${item.caption} is required`} />
                                        }
                                        {item.componentID === 19 &&
                                          <EmailRule message='Email ID is invalid' />
                                        }
                                      </Validator>
                                    </TextBox>
                                  </div>
                                </>
                              }
                              {item.componentTypeID === 2 &&
                                <>
                                  <div className='py-1'>
                                    <TextArea
                                      id={item.componentID}
                                      name={item.caption}
                                      ref={item.componentRef}
                                      label={item.caption}
                                      labelMode='floating'
                                      value={item.componentValue}
                                      onValueChange={(e) => onComponentValueChange(e, item)}
                                      maxLength={200}
                                    >
                                      <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                        {item.isMandatory &&
                                          <RequiredRule message={`${item.caption} is required`} />
                                        }
                                      </Validator>
                                    </TextArea>
                                  </div>
                                </>
                              }
                              {item.componentTypeID === 3 && item.componentID === 10 &&
                                <>
                                  <div className='py-1'>
                                    <SelectBox
                                      id={item.componentID}
                                      name={item.caption}
                                      ref={item.componentRef}
                                      label={item.caption}
                                      labelMode='floating'
                                      value={item.componentValue}
                                      showDropDownButton={true}
                                      dataSource={genderData}
                                      displayExpr="genderName"
                                      valueExpr="genderID"
                                      searchEnabled={true}
                                      onValueChange={(e) => onComponentValueChange(e, item)}
                                    >
                                      <SelectBoxButton
                                        name="dropdown"
                                        location="after"
                                        options={{
                                          icon: 'spindown',
                                          stylingMode: 'text'
                                        }}
                                      />
                                      <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                        {item.isMandatory &&
                                          <RequiredRule message={`${item.caption} is required`} />
                                        }
                                      </Validator>
                                    </SelectBox>
                                  </div>
                                </>
                              }
                              {item.componentTypeID === 3 && item.componentID === 9 &&
                                <>
                                  <div className='py-1'>
                                    <SelectBox
                                      id={item.componentID}
                                      name={item.caption}
                                      ref={item.componentRef}
                                      label={item.caption}
                                      labelMode='floating'
                                      value={item.componentValue}
                                      showDropDownButton={true}
                                      dataSource={NationalityData}
                                      displayExpr="nationalityName"
                                      valueExpr="nationalityID"
                                      searchEnabled={true}
                                      onValueChange={(e) => onComponentValueChange(e, item)}
                                    >
                                      <SelectBoxButton
                                        name="dropdown"
                                        location="after"
                                        options={{
                                          icon: 'spindown',
                                          stylingMode: 'text'
                                        }}
                                      />
                                      <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                        {item.isMandatory &&
                                          <RequiredRule message={`${item.caption} is required`} />
                                        }
                                      </Validator>
                                    </SelectBox>
                                  </div>
                                </>
                              }
                              {item.componentTypeID === 3 && item.componentID === 24 &&
                                <>
                                  <div className='py-1'>
                                    <SelectBox
                                      id={item.componentID}
                                      name={item.caption}
                                      ref={item.componentRef}
                                      label={item.caption}
                                      labelMode='floating'
                                      value={item.componentValue}
                                      showDropDownButton={true}
                                      dataSource={MediaTypeData}
                                      displayExpr="mediaTypeName"
                                      valueExpr="mediaTypeID"
                                      searchEnabled={true}
                                      onValueChange={(e) => onComponentValueChange(e, item)}
                                    >
                                      <SelectBoxButton
                                        name="dropdown"
                                        location="after"
                                        options={{
                                          icon: 'spindown',
                                          stylingMode: 'text'
                                        }}
                                      />
                                      <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                        {item.isMandatory &&
                                          <RequiredRule message={`${item.caption} is required`} />
                                        }
                                      </Validator>
                                    </SelectBox>
                                  </div>
                                </>
                              }
                              {item.componentTypeID === 3 && item.componentID === 26 &&
                                <>
                                  <div className='py-1'>
                                    <SelectBox
                                      id={item.componentID}
                                      name={item.caption}
                                      ref={item.componentRef}
                                      label={item.caption}
                                      labelMode='floating'
                                      value={item.componentValue}
                                      showDropDownButton={true}
                                      dataSource={MediaFunctionData}
                                      displayExpr="mediaFunctionName"
                                      valueExpr="mediaFunctionID"
                                      searchEnabled={true}
                                      onValueChange={(e) => onComponentValueChange(e, item)}
                                    >
                                      <SelectBoxButton
                                        name="dropdown"
                                        location="after"
                                        options={{
                                          icon: 'spindown',
                                          stylingMode: 'text'
                                        }}
                                      />
                                      <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                        {item.isMandatory &&
                                          <RequiredRule message={`${item.caption} is required`} />
                                        }
                                      </Validator>
                                    </SelectBox>
                                  </div>
                                </>
                              }
                              {item.componentTypeID === 4 &&
                                <>
                                  <div className='py-1'>
                                    <DateBox
                                      id={item.componentID}
                                      name={item.caption}
                                      ref={item.componentRef}
                                      label={item.caption}
                                      labelMode='floating'
                                      value={item.componentValue}
                                      type="date"
                                      displayFormat={"dd/MM/yyyy"}
                                      useMaskBehavior={true}
                                      onValueChange={(e) => onComponentValueChange(e, item)}
                                      min={minDate}
                                      dateOutOfRangeMessage={dateOutOfRangeMessage}
                                      max={item.componentID === 11 ? maxDOBDate : undefined}
                                    >
                                      <DateBoxButton name="dropDown" />
                                      <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                        {item.isMandatory &&
                                          <RequiredRule message={`${item.caption} is required`} />
                                        }
                                      </Validator>
                                    </DateBox>
                                  </div>
                                </>
                              }
                              {item.componentTypeID === 5 &&
                                <>
                                  <div className='py-1'>
                                    <DateBox
                                      id={item.componentID}
                                      name={item.caption}
                                      ref={item.componentRef}
                                      label={item.caption}
                                      labelMode='floating'
                                      value={item.componentValue}
                                      type="time"
                                      onValueChange={(e) => onComponentValueChange(e, item)}
                                      useMaskBehavior={true}
                                      displayFormat="shortTime"
                                    >
                                      <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                        {item.isMandatory &&
                                          <RequiredRule message={`${item.caption} is required`} />
                                        }
                                      </Validator>
                                    </DateBox>
                                  </div>
                                </>
                              }
                              {item.componentTypeID === 6 &&
                                <>
                                  <div className='py-1 d-flex'>
                                    <div className='col'>
                                      <DateBox
                                        id={item.componentID}
                                        name={item.caption}
                                        ref={item.componentRef}
                                        label={item.caption.split(" ")[0] + " Date"}
                                        labelMode='floating'
                                        value={item.componentValue}
                                        type="date"
                                        displayFormat={"dd/MM/yyyy"}
                                        pickerType="calendar"
                                        useMaskBehavior={true}
                                        onValueChange={(e) => onComponentValueChange(e, item)}
                                        min={minDate}
                                        dateOutOfRangeMessage={dateOutOfRangeMessage}
                                      >
                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                          {item.isMandatory &&
                                            <RequiredRule message={`${item.caption} is required`} />
                                          }
                                        </Validator>
                                      </DateBox> </div> &nbsp;
                                    <div className='col'>
                                      <DateBox
                                        id={item.componentID}
                                        name={item.caption}
                                        ref={item.componentRef}
                                        label={item.caption.split(" ")[0] + " Time"}
                                        labelMode='floating'
                                        value={item.componentValue}
                                        type="time"
                                        onValueChange={(e) => onComponentValueChange(e, item)}
                                        useMaskBehavior={true}
                                        displayFormat="shortTime"
                                      >
                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                          {item.isMandatory &&
                                            <RequiredRule message={`${item.caption} is required`} />
                                          }
                                        </Validator>
                                      </DateBox>
                                    </div>
                                  </div>
                                </>
                              }
                              {item.componentTypeID === 7 &&
                                <>
                                  <div className='py-1'>
                                    <div className='RegistrationPageFileUploader d-flex'>
                                      <div className='col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-12'>
                                        <FileUploader
                                          ref={item.componentRef}
                                          id={item.componentID}
                                          accept="*"
                                          selectButtonText={`Select ${item.caption}`}
                                          maxFileSize={maxImageSizeLength}
                                          multiple={false}                                        
                                          uploadMode="useForm"
                                          onValueChange={(e) => onFileUploaderValueChanged(e, item)}
                                          labelText=""
                                          showFileList={false}
                                        >
                                          <Validator validationGroup={ValidationGroupName} elementAttr={{ class: item.isMandatory && 'ForValidationAstrikMark' }}>
                                            {item.isMandatory && (item.componentFileName === undefined || item.componentFileName === null) &&
                                              <RequiredRule message={`${item.caption} is required`} />
                                            }
                                          </Validator>
                                        </FileUploader>
                                      </div>
                                      <div className='col-xxl-8 col-xl-7 col-lg-7 col-md-6 col-sm-12 pt-4'>
                                        {item.componentFileName && item.componentFileName !== null &&
                                          <div className='row'>
                                            <div className="col">
                                              <span className='text-decoration-underline fw-semibold cursor-pointer text-break' onClick={(e) => DownloadFile(e, item)}>
                                                {item.componentFileName}
                                              </span>
                                            </div>
                                            <div className="col-auto pe-3">   
                                            <Button
                                              icon="trash"
                                              hint='Remove File'
                                              className={`removeFileButton`}
                                              stylingMode="text"
                                              type="danger"
                                              onClick={(e) => onDeleteFile(e, item)}
                                              hoverStateEnabled={false}
                                              activeStateEnabled={false}
                                              focusStateEnabled={false}
                                            />
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                            </div>
                          </>
                        )
                      })

                      }

                    </div>
                  </div>
                </ScrollView>
                {FormDesignMessage === "" &&
                  <div className="text-end px-3 py-2 border-top">

                    <Button
                      text="Save"
                      type="default"
                      className="rounded"
                      width={90}
                      useSubmitBehavior={true}
                      validationGroup={ValidationGroupName}
                    />
                  </div>
                }
              </>
            }
          </form>
        </div>
      </div>
    </>
  );
};

export default RegistrationPage;
