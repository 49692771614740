import React, { useRef, useState } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import "./GroupHeader.scss";
import { useScreenSize } from "../../../utils/media-query";
import "../../../utils/base-64-icons.scss";
import { DropDownButton } from "devextreme-react";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
import GroupUserPanel from "../group-user-panel/GroupUserPanel";
import { camelCase } from "../../../utils/common-methods";

const dropDownOptions = {
  width: "85px",
};

const GroupHeader = (props) => {
  const { isSmall, isXSmall, isXXSmall, isExSmall } = useScreenSize();
  const [userImage, setUserImage] = useState({
    uploadedFile: { fileData: localStorage.getItem("groupUserImage") },
    attachedFile: localStorage.getItem("groupUserImageData")
});

  const HeaderToolbarRef = useRef(null);
  

  const DataGridRef = props.dataGridRef;

  const onAddButtonClick = () => {
    props.handleAdd();
  };

  const onRefreshButtonClick = async () => {
    props.refreshDataGrid && await props.refreshDataGrid();
    HeaderToolbarRef.current?.instance.repaint();
  };

 
  const dropdownItems = [
    { id: 1, name: "Excel", icon: "exportxlsx" },
    { id: 2, name: "CSV", icon: "xlsfile" },
  ];

  const onItemClick = (e) => {
    var fileName = camelCase(props.excelFileName ? props.excelFileName:props.title);
    if (props.exportFileName !== undefined && props.exportFileName !== null) {
      fileName = camelCase(props.exportFileName);
    }

    if (e.itemData.id === 1) {
      var workbook = new Workbook();
      var worksheet = workbook.addWorksheet("SheetName");
      exportDataGrid({
        component: DataGridRef.current?.instance,
        worksheet: worksheet,
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName + ".xlsx"
          );
        });
      });
    } else if (e.itemData.id === 2) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Report");
      exportDataGrid({
        component: DataGridRef.current?.instance,
        worksheet: worksheet,
      }).then(function () {
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName + ".csv"
          );
        });
      });
    }
  };

  return (
    <header className={"header-component"} id="Comman-Header">
      <Toolbar className={"header-toolbar"} ref={HeaderToolbarRef}>
        <Item
          location={"before"}
          cssClass={"header-title ps-2 pe-4"}
          visible={!!props.title}
        >
         <span className="d-flex align-items-center"><img src={props.eventLogo} width={60} height={29} alt="Event Logo"/> <div className={"ps-2"}>{props.title}</div> </span>
        </Item>
        
         {!props.hideDefaultButtons && (
          <Item location="after" locateInMenu="auto">
            <Button
              icon="refresh"
              className="mx-md-1 rounded-5 default-button"
              id="refresh"
              onClick={onRefreshButtonClick}
              stylingMode="outlined"
              type="default"
              hint="Refresh"
            ></Button>
          </Item>
        )}
        
        {
          !isExSmall && !isXSmall && !isXXSmall && !props.hideDefaultButtons &&
          <Item location={"after"}>
            <span className="vertical-line">|</span>
          </Item>
        }
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            height={"100%"}
            stylingMode={"text"}
          >
            <GroupUserPanel menuMode={"context"} userImage={userImage} setUserImage={setUserImage} setDropDownClick={props.setDropDownClick} />
          </Button>
        </Item>
      </Toolbar>


    </header>
  );
}

export default GroupHeader;