import { RunAEMSAPIService } from "../RunAEMSAPIService";
import { fetchWrapper } from "../authHeader";

const baseURL = process.env.REACT_APP_BASEURL;

const url = `${baseURL}EventManagement/EventGroup`;

export class EventGroupService extends RunAEMSAPIService {

    constructor() {
        // call the super class constructor and pass in the name parameter
        super(url);
    }

    CheckDuplicateName = async (Name, ExcludeID , EventCategoryID) => {
        Name = encodeURIComponent(Name);
        const result = await fetchWrapper.get(url + `/CheckDuplicateName?Name=${Name}&ExcludeID=${ExcludeID}&CategoryID=${EventCategoryID}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result,
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    CheckDuplicateEmail = async (EmailID, ExcludeID, EventCategoryID) => {
        const result = await fetchWrapper.get(url + `/CheckDuplicateEmailID?EmailID=${EmailID}&ExcludeID=${ExcludeID}&CategoryID=${EventCategoryID}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result,
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    CheckDuplicateMobileNo = async (MobileNo, ExcludeID, EventCategoryID) => {
        const result = await fetchWrapper.get(url + `/CheckDuplicateMobileNo?MobileNo=${MobileNo}&ExcludeID=${ExcludeID}&CategoryID=${EventCategoryID}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result,
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetListRecordWithFilter = async (eventStatus,eventID) => {
        var params = new URLSearchParams();
        
        if(eventID){
            params.append("ID",eventID)
        }
        if(eventStatus !== null && eventStatus !== undefined){
            params.append("eventStatus",eventStatus) 
        }
        const result = await fetchWrapper.get(this.apiUrl + `/GetList?${params}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }
}


