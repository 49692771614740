import defaultUser from '../utils/default-user';

const baseURL = process.env.REACT_APP_BASEURL;

export async function signIn(LoginData) {
  try {
    const loginOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(LoginData)
    };

    let data = await fetch(`${baseURL}UserManagement/AuthenticateUser`, loginOptions);

    if (data.status === 401) {
      return {
        isOk: false,
        message: "Incorrect Username or Password",
      };
    }

    let loginResponseData = await data.json();
    
    localStorage.setItem("authToken", loginResponseData.authToken);
    localStorage.setItem("userEmail", LoginData.emailid);
    localStorage.setItem("userType_AdminUser", "AdminUser")
    let userData = await getUser();
    return {
      isOk: true,
      data: userData.data,
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function groupSignIn(LoginData) {
  try {
    const loginOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(LoginData)
    };
    
    let data = await fetch(`${baseURL}UserManagement/AuthenticateGroupUser`, loginOptions);

    if (data.status === 401) {
      return {
        isOk: false,
        message: "Incorrect Username or Password",
      };
    }

    let loginResponseData = await data.json();    
    localStorage.setItem("groupEvent", JSON.stringify(loginResponseData));
    localStorage.setItem("groupUserAuthToken", loginResponseData.authToken);
    localStorage.setItem("groupUserEmail", LoginData.emailID);
    localStorage.setItem("groupUserEventCode", LoginData.eventCode);
    localStorage.setItem("userType_GroupUser", "GroupUser")
    let userData = await getGroupUser();
    return {
      isOk: true,
      data: userData.data,
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function GetEventDetails(eventCode) {
  try {
    const loginOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    
    let res = await fetch(`${baseURL}EventManagement/EventOpenAPI/Get/${eventCode}`, loginOptions);

    if (res.status !== 200) {
      return {
        isOk: false,
        message: "Invalid Url",
      };
    } 
    return {
      isOk: true,
      data: await res.json()
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function GetEventLogo(eventCode) {
  try {
    const loginOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    
    let res = await fetch(`${baseURL}EventManagement/EventOpenAPI/GetLogo/${eventCode}`, loginOptions);

    if (res.status !== 200) {
      return {
        isOk: false,
        message: "Failed to get logo",
      };
    }
    
    return {
      isOk: true,
      data: res,
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function getGroupUser() {  
  try {
    // Send request
    const userEmail = localStorage.getItem("groupUserEmail")
    if (userEmail === null) {
      return {
        isOk: false,
      };
    }
    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}



export async function getUser() {  
  try {
    // Send request
    const userEmail = localStorage.getItem("userEmail")
    if (userEmail === null) {
      return {
        isOk: false,
      };
    }
    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function forgotPassword(email,eventCode) {
  try {
    const ForgotPasswordOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    const data = eventCode ? await fetch(baseURL + 'UserManagement/ForgotPassword' + `/SendResetPasswordCode?EmailID=${email}&EventCode=${eventCode}`, ForgotPasswordOptions) : await fetch(baseURL + 'UserManagement/ForgotPassword' + `/SendResetPasswordCode?EmailID=${email}`, ForgotPasswordOptions) ;
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.text(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to Send Request Reset Link"
    }
  }
}

export async function validateResetPasswordCode(code) {
  try {
    const ResetPasswordOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    const data = await fetch(baseURL + 'UserManagement/ForgotPassword' + `/ValidateResetPasswordCode/${code}`, ResetPasswordOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.text(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to Validate Reset Password Code"
    }
  }
}


export async function resetPassword(DataObj) {
  try {
    const ResetPasswordOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(DataObj)
    };

    const data = await fetch(baseURL + 'UserManagement/ForgotPassword' + `/ResetPassword`, ResetPasswordOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.text(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
