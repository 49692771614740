import { RunAEMSAPIService } from "../RunAEMSAPIService";
import { fetchWrapper } from "../authHeader";

const baseURL = process.env.REACT_APP_BASEURL;

const url = `${baseURL}EventManagement/EventGroupCategory`;

export class EventGroupCategoryService extends RunAEMSAPIService {

    constructor() {
        // call the super class constructor and pass in the name parameter
        super(url);
    }

    CheckDuplicateName = async (Name, ExcludeID, EventID) => {
        Name = encodeURIComponent(Name);
        const result = await fetchWrapper.get(url + `/CheckDuplicateName?Name=${Name}&ExcludeID=${ExcludeID}&EventID=${EventID}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result,
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }
    

    GetListRecordWithFilter = async (eventStatus) => {
        const result = await fetchWrapper.get(this.apiUrl + `/GetList?ID&eventStatus=${eventStatus}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }
}


