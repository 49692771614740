import { Button, FileUploader } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { useScreenSize } from "../../../utils/media-query";
import { alert } from "devextreme/ui/dialog";
import './User.scss'

const UserProfileUploader = (props) => {
  const UploadCandidatePictureRef = useRef();
  const { isXSmall, isXXSmall, isExSmall } = useScreenSize();
  const maxImageSizeLength = 10 * 1024 * 1024;
  const allowedFileExtensions = [".jpg", ".jpeg", ".gif", ".png"];

  const [dropState, setDropState] = useState({
    isDropZoneActive: false,
    textVisible: true,
  });

  const onDropZoneEnter = (e) => {
    if (e.dropZoneElement.id === "dropzone-external") {
      setDropState({ ...dropState, isDropZoneActive: true });
    }
  };

  const onDropZoneLeave = (e) => {
    if (e.dropZoneElement.id === "dropzone-external") {
      setDropState({ ...dropState, isDropZoneActive: false });
    }
  };

  const onCandidatePictureValueChanged = (e) => {
    const file = e[0];
    if (file) {
      if (
        file["type"]?.split("/")[0] !== "image" ||
        file?.type === "image/avif"
      ) {
        alert(
          `<div class="row align-items-center"><i class="dx-icon-warning alerticon col-auto text-warning pe-0 fs-2" > </i><b class="alertText col">This file is not accepted.</b></div>`,
          "Vakency"
        );
        UploadCandidatePictureRef?.current?.instance.reset();
        return;
      }
      if (file?.size > maxImageSizeLength) {
        alert(
          `<div class="row align-items-center"><i class="dx-icon-warning alerticon col-auto text-warning fs-2 pe-0"> </i><b class="alertText col">Image of more than 10 MB size cannot be uploaded.</b></div>`,
          "Vakency"
        );
        UploadCandidatePictureRef?.current?.instance.reset();
        return;
      }

      if (file["type"]?.split("/")[0] === "image") {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          setDropState({
            ...dropState,
            textVisible: false,
            isDropZoneActive: false,
          });
          props.setNewRecord({
            ...props.newRecord,
            uploadedFile: { fileData: fileReader.result },
            attachedFile: file,
          });
        };
        fileReader.readAsDataURL(file);
        setDropState({ ...dropState });
      }
    }
  };

  const OnRemoveImage = (e) => {
    UploadCandidatePictureRef?.current?.instance.reset();
    setDropState({ ...dropState, textVisible: true, isDropZoneActive: false });
    props.setNewRecord({
      ...props.newRecord,
      uploadedFile: { fileData: null },
      attachedFile: null,
    });
  };


  useEffect(() => {
    if (props.newRecord.uploadedFile?.fileData) {
      setDropState({ ...dropState, textVisible: false });
    }
  }, [props.newRecord.uploadedFile?.fileData])



  return (
    <>
      <div className="col-md-auto pt-md-2 pt-3 User_PictureSection image-hover-section">
        <div className="editor-label row">
          <div className="d-flex justify-content-end">
            {props.newRecord.uploadedFile?.fileData &&
              < Button
                hint="Remove Image"
                icon="trash"
                className="rounded-5 user-profile-clear-btn z-1 position-absolute content-on-image"
                stylingMode="text"
                type="danger"
                hoverStateEnabled={false}
                onClick={OnRemoveImage}
                disabled={
                  !props.newRecord.uploadedFile?.fileData && dropState.textVisible
                }
              />
            }
          </div>
        </div>
        <div
          id="dropzone-external"
          className={`flex-box UserImageWidth ${dropState.isDropZoneActive
            ? "dx-theme-accent-as-border-color dropzone-active"
            : "dx-theme-border-color"
            }`}
        >
          {props.newRecord.uploadedFile?.fileData && !dropState.textVisible && (
            <>
              <img
                id="dropzone-image"
                src={props.newRecord.uploadedFile?.fileData}
                alt=""
                className="grey-BgColor border profile-image"
              />
            </>
          )}
          {dropState.textVisible && (
            <div
              id="dropzone-text"
              className="flex-box text-center  h-100 d-flex align-items-center"
            >
              <div className="p-2">
                {!isExSmall && !isXXSmall && !isXSmall ? (
                  <>
                    <span>Drag & Drop the desired file</span>
                    <span>…or click to browse for a file instead.</span>
                  </>
                ) : (
                  <div className="pe-2">Click to browse for the file.</div>
                )}
              </div>
            </div>
          )}
        </div>
        <FileUploader
          ref={UploadCandidatePictureRef}
          id="file-uploader"
          accept="image/*"
          maxFileSize={maxImageSizeLength}
          dialogTrigger={"#dropzone-external"}
          dropZone="#dropzone-external"
          multiple={false}
          allowedFileExtensions={allowedFileExtensions}
          uploadMode="useButtons"
          visible={false}
          onDropZoneEnter={onDropZoneEnter}
          onDropZoneLeave={onDropZoneLeave}
          onValueChange={onCandidatePictureValueChanged}
        ></FileUploader>
      </div>
    </>
  );
};

export default UserProfileUploader;
