import { fetchWrapper } from "./authHeader";

export class RunAEMSAPIService {

    constructor(url) {
        this.apiUrl = url;
    }

    GetListRecord = async () => {
        const result = await fetchWrapper.get(this.apiUrl + '/GetList');
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetListByIDRecord = async (ID) => {
        const result = await fetchWrapper.get(this.apiUrl + '/GetList/' + ID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetRecord = async (ID) => {
        const result = await fetchWrapper.get(this.apiUrl + '/GetModel/' + ID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }

    }

    InsertRecord = async (params) => {
        const result = await fetchWrapper.post(this.apiUrl + '/Insert', params);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    UpdateRecord = async (params) => {
        const result = await fetchWrapper.put(this.apiUrl + '/Update', params);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    DeleteRecord = async (ID) => {
        const result = await fetchWrapper.delete(this.apiUrl + '/Delete/' + ID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data:await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetLookupListRecord = async () => {
        const result = await fetchWrapper.get(this.apiUrl + '/GetLookupList');
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetLookupListByIDRecord = async (ID) => {
        const result = await fetchWrapper.get(this.apiUrl + '/GetLookupList/' + ID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    InsertRecordWithoutToken = async (params) => {
        const result = await fetchWrapper.postWithoutHeader(this.apiUrl + '/Insert', params);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }
}