import { CheckBox, DateBox, Popup, Switch, TextBox } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { PopupHeader, PopupFooter, PopupSkeleton } from '../../../layouts';
import { useScreenSize } from "../../../utils/media-query";
import {
  ConflictPopup,
  GetFormattedDateTime,
  ShowAlert,
  dateOutOfRangeMessage,
  eCRUDStatus,
  minDate,
} from "../../../utils/common-methods";
import {
  Validator,
  RequiredRule,
  AsyncRule,
  CustomRule,
  PatternRule,
} from "devextreme-react/validator";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import { EventService } from "../../../api/services/eventService";
import { LinkIcon, TickIcon } from "../../../utils/base-64-Icons";
import EventLogoUploader from "./EventLogoUploader";
import './Event.scss';

const eventService = new EventService();
export default function EventPopup(props) {
  const { isMedium, isLarge, isExLarge, isSmall } =
    useScreenSize();
  const ValidationGroupName = "EventPopupValidation";

  const FocusedBoxRef = useRef(null);
  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };

  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];

  const htmlEditorRef = useRef(null);

  const EventData = {
    eventID: 0,
    eventName: "",
    eventCode: "",
    registrationFromDate: null,
    registrationFromTime: null,
    registrationToDate: null,
    registrationToTime: null,
    eventFromDate: null,
    eventFromTime: null,
    eventToDate: null,
    eventToTime: null,
    enableVisitorPublicLink: true,
    visitorRegistrationFormID: null,
    mediaRegistrationFormID: null,
    totalRegistrations: 0,
    enableMediaPublicLink: true,
    eventClosingMessagePlainText: "",
    eventClosingMessageFormattedText: "",
    updatedOn: null,
    uploadedFile: { fileData: null },
    attachedFile: null,
    autoEmailOnApproval: false,
    autoEmailOnRejection: false,
    autoEmailOnPrinted: false,
    autoEmailOnGroupUserRegistration: false,
  };

  const [newRecord, setNewRecord] = useState(EventData);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [createUpdateStatus, setCreateUpdateStatus] = useState(null);
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [hideCopyButton, setHideCopyButton] = useState(false);
  let PrimaryKeyID = props.primaryKey;
  const isAddMode = !PrimaryKeyID;


  const CopyButton = [
    {
      name: "copy",
      location: "after",
      options: {
        icon: 'copy',
        stylingMode: 'text',
        type: "normal",
        disabled: false,
        hint: "Copy",
        onClick: () => { handleCopy() }
      }
    }]

  useEffect(() => {
    if (PrimaryKeyID) {
      setShowSkeleton(true);
      GetModelRecord();
    } else {
      setNewRecord(EventData);
    }
  }, [PrimaryKeyID]);

  useEffect(() => {
    if (newRecord.eventCode === null || newRecord.eventCode === undefined || newRecord.eventCode === "") {
      setHideCopyButton(true);
    }
    else {
      setHideCopyButton(false);
    }
  }, [newRecord.eventCode])

  useEffect(() => {
    !showSkeleton && FocusedBoxRef.current?.instance.focus();
  }, [showSkeleton]);

  const GetModelRecord = async () => {
    const result = await eventService.GetRecord(PrimaryKeyID);
    if (result.statusCode === 200) {
      const response = result.data;
      GetEventLogoImage(PrimaryKeyID).then((res) => {
        setShowSkeleton(false);
        setNewRecord({
          ...response,
          registrationFromDate: response.registrationFrom,
          registrationFromTime: response.registrationFrom,
          registrationToDate: response.registrationTo,
          registrationToTime: response.registrationTo,
          eventFromDate: response.eventFrom,
          eventFromTime: response.eventFrom,
          eventToDate: response.eventTo,
          eventToTime: response.eventTo,
          uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
          attachedFile: (res?.size > 0 ? res : null),
          autoEmailOnApproval: response.autoEmailOnApproval,
          autoEmailOnRejection: response.autoEmailOnRejection,
          autoEmailOnPrinted: response.autoEmailOnPrinted,
          autoEmailOnGroupUserRegistration: response.autoEmailOnGroupUserRegistration,
        })
        setCreateUpdateStatus(
          {
            createdByUserName: response.createdByUserName,
            createdOn: response.createdOn,
            updatedByUserName: response.updatedByUserName,
            updatedOn: response.updatedOn
          }
        )
      })
    }
  };

  const handleCopy = () => {
    var copyText = document.getElementById("CopyLink").querySelector("input");
    const copyCompleted = navigator.clipboard.writeText(copyText.value);
    if (copyCompleted) {
      copyText.value && setPopoverVisible(true);
      setTimeout(() => {
        setPopoverVisible(false);
      }, 2000);
    }
  }


  const GetEventLogoImage = async (primaryKeyID) => {
    const result = await eventService.GetEventLogo(primaryKeyID)
    let response = result.data
    if (response) {
      return response.blob()
    } else {
      return;
    }
  }

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (isAddMode) {
      InsertData();
    } else {
      UpdateData();
    }
  };

  const InsertData = async () => {

    setShowSkeleton(true);

    var descrText = htmlEditorRef.current?.instance.getQuillInstance();
    var FormattedText = htmlEditorRef.current?.instance.option("value");
    let updatedEventFromDateTime = new Date(new Date(newRecord.eventFromDate).toDateString() + " " + new Date(newRecord.eventFromTime).toTimeString().substring(0, 5));
    let updatedEventToDateTime = new Date(new Date(newRecord.eventToDate).toDateString() + " " + new Date(newRecord.eventToTime).toTimeString().substring(0, 5));
    let updatedRegistrationFromDateTime = new Date(new Date(newRecord.registrationFromDate).toDateString() + " " + new Date(newRecord.registrationFromTime).toTimeString().substring(0, 5));
    let updatedRegistrationToDateTime = new Date(new Date(newRecord.registrationToDate).toDateString() + " " + new Date(newRecord.registrationToTime).toTimeString().substring(0, 5));

    const descrPlainText = descrText.getText();

    let dataToInsert = {
      eventName: newRecord.eventName,
      eventCode: newRecord.eventCode,
      registrationFrom: GetFormattedDateTime(updatedRegistrationFromDateTime),
      registrationTo: GetFormattedDateTime(updatedRegistrationToDateTime),
      eventFrom: GetFormattedDateTime(updatedEventFromDateTime),
      eventTo: GetFormattedDateTime(updatedEventToDateTime),
      enableVisitorPublicLink: newRecord.enableVisitorPublicLink,
      totalRegistrations: 0,
      enableMediaPublicLink: newRecord.enableMediaPublicLink,
      eventClosingMessagePlainText: descrPlainText,
      eventClosingMessageFormattedText: FormattedText,
      autoEmailOnApproval: newRecord.autoEmailOnApproval,
      autoEmailOnRejection: newRecord.autoEmailOnRejection,
      autoEmailOnPrinted: newRecord.autoEmailOnPrinted,
      autoEmailOnGroupUserRegistration: newRecord.autoEmailOnGroupUserRegistration,
    };

    const result = await eventService.InsertRecord(dataToInsert);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Run");
        setShowSkeleton(false);
      }
    } else {
      if (newRecord.attachedFile) {
        UploadEventLogo(result.data.responseData)
      }
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData,
      });
      ClearUIFields();
      props.popupClose();
      setShowSkeleton(false);
    }
  };

  const UploadEventLogo = async (primaryKeyID) => {
    var Datatoupload = { eventID: primaryKeyID, attachments: newRecord.attachedFile }
    const result = await eventService.UploadEventLogo(Datatoupload, primaryKeyID)
    if (result.isOk) {
      return;
    } else {
      ShowAlert(result.data)
    }
  }

  const UpdateData = async () => {
    setShowSkeleton(true);
    let updatedEventFromDateTime = new Date(new Date(newRecord.eventFromDate).toDateString() + " " + new Date(newRecord.eventFromTime).toTimeString().substring(0, 5));
    let updatedEventToDateTime = new Date(new Date(newRecord.eventToDate).toDateString() + " " + new Date(newRecord.eventToTime).toTimeString().substring(0, 5));
    let updatedRegistrationFromDateTime = new Date(new Date(newRecord.registrationFromDate).toDateString() + " " + new Date(newRecord.registrationFromTime).toTimeString().substring(0, 5));
    let updatedRegistrationToDateTime = new Date(new Date(newRecord.registrationToDate).toDateString() + " " + new Date(newRecord.registrationToTime).toTimeString().substring(0, 5));
    var descrText = htmlEditorRef.current?.instance.getQuillInstance();
    var FormattedText = htmlEditorRef.current?.instance.option("value");
    const descrPlainText = descrText.getText();
    var dataToUpdate = {
      updatedOn: newRecord.updatedOn,
      eventID: newRecord.eventID,
      eventName: newRecord.eventName,
      eventCode: newRecord.eventCode,
      registrationFrom: GetFormattedDateTime(updatedRegistrationFromDateTime),
      registrationTo: GetFormattedDateTime(updatedRegistrationToDateTime),
      eventFrom: GetFormattedDateTime(updatedEventFromDateTime),
      eventTo: GetFormattedDateTime(updatedEventToDateTime),
      enableVisitorPublicLink: newRecord.enableVisitorPublicLink,
      totalRegistrations: 0,
      enableMediaPublicLink: newRecord.enableMediaPublicLink,
      eventClosingMessagePlainText:
        descrPlainText && descrPlainText?.trim() !== ""
          ? descrPlainText?.trim()
          : null,
      eventClosingMessageFormattedText: FormattedText?.trim(),
      autoEmailOnApproval: newRecord.autoEmailOnApproval,
      autoEmailOnRejection: newRecord.autoEmailOnRejection,
      autoEmailOnPrinted: newRecord.autoEmailOnPrinted,
      autoEmailOnGroupUserRegistration: newRecord.autoEmailOnGroupUserRegistration,
    };
    const result = await eventService.UpdateRecord(dataToUpdate);

    if (!result.isOk) {
      if (result.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
        setShowSkeleton(false);
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Run");
        setShowSkeleton(false);
      }
    } else {
      UploadEventLogo(newRecord.primaryKeyID)
      props.setStatus({
        eStatus: eCRUDStatus.Updated,
        primaryKeyID: result.data.responseData,
      });
      ClearUIFields();
      props.popupClose();
      setShowSkeleton(false);
    }
  };

  const ClearUIFields = () => {
    setNewRecord(EventData);
  };

  const onClosePopup = () => {
    ClearUIFields();
    props.setStatus({ eStatus: eCRUDStatus.None });
    props.popupClose();
  };

  const PopupTitle = (e) => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          title={[
            <span key={"header_title"} className="base-accent-text">
              {isAddMode ? "New" : "Edit"}
            </span>,
            " Event",
          ]}
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    );
  };

  const asyncEventNameValidation = async (e) => {
    const Name = e?.value;
    const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
    const result = await eventService.CheckDuplicateName(Name, ExcludeID);
    return new Promise((resolve) => {
      resolve(result.isOk !== false);
    });
  };

  const asyncEventCodeValidation = async (e) => {
    const EventCode = e?.value;
    const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
    const result = await eventService.CheckDuplicateEventCode(EventCode, ExcludeID);
    return new Promise((resolve) => {
      resolve(result.isOk !== false);
    });
  };

  const onShown = () => FocusedBoxRef.current?.instance.focus();

  const GroupRegistrationLoginLink = () => {
    if (newRecord.eventCode) {
      return window.origin + "/glogin/" + newRecord.eventCode;
    } else {
      return null;
    }
  };

  const RegistrationEndsOnValidation = (e) => {
    var DateFrom = new Date(new Date(newRecord.registrationFromDate).toDateString() + " " + new Date(newRecord.registrationFromTime).toTimeString().substring(0, 5));
    var DateTo = new Date(new Date(newRecord.registrationToDate).toDateString() + " " + new Date(newRecord.registrationToTime).toTimeString().substring(0, 5));
    var EventDateTo = new Date(new Date(newRecord.eventToDate).toDateString() + " " + new Date(newRecord.eventToTime).toTimeString().substring(0, 5));
    if (DateFrom && DateTo && DateFrom > DateTo) {
      e.rule.isValid = false;
      e.rule.message =
        "Registration end date should be greater than registration start date.";
      e.validator.validate();
      return false;
    } else if (DateTo && EventDateTo && DateTo > EventDateTo) {
      e.rule.isValid = false;
      e.rule.message =
        "Registration end date cannot be greater than event end date.";
      e.validator.validate();
    } else {
      return true;
    }
  };

  const RegistrationStartOnValidation = (e) => {
    var DateFrom = new Date(new Date(newRecord.registrationFromDate).toDateString() + " " + new Date(newRecord.registrationFromTime).toTimeString().substring(0, 5));
    var EventDateFrom = new Date(new Date(newRecord.eventFromDate).toDateString() + " " + new Date(newRecord.eventFromTime).toTimeString().substring(0, 5));
    if (DateFrom && EventDateFrom && DateFrom > EventDateFrom) {
      e.rule.isValid = false;
      e.rule.message =
        "Registration start date cannot be greater than event start date.";
      e.validator.validate();
    } else {
      return true;
    }
  };

  const EventEndsOnValidation = (e) => {
    var DateFrom = new Date(new Date(newRecord.eventFromDate).toDateString() + " " + new Date(newRecord.eventFromTime).toTimeString().substring(0, 5))
    var DateTo = new Date(new Date(newRecord.eventToDate).toDateString() + " " + new Date(newRecord.eventToTime).toTimeString().substring(0, 5));
    if (DateFrom && DateTo && DateFrom > DateTo) {
      e.rule.isValid = false;
      e.rule.message =
        "Event end date should be greater than event start date.";
      e.validator.validate();
      return false;
    } else {
      return true;
    }
  };

  const eventCodePattern = "^[a-zA-Z0-9]+$";


  return (
    <>
      {props.popupShow && (
        <Popup
          visible={props.popupShow}
          titleRender={PopupTitle}
          width={`${isExLarge
            ? "53%"
            : isMedium
              ? "97%" : isLarge ? "78%"
                : isSmall ? "90%" : "95%"
            }`}
          height={"auto"}
          maxHeight={"90%"}
          deferRendering={false}
          wrapperAttr={{ class: "CustomPopup" }}
          onShown={onShown}
        >
          <form onSubmit={handleSubmit}>
            {showSkeleton ? (
              <div className="py-2 px-3 pb-0">
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            ) : (
              <div className="py-2 px-3">
                <div className="row">
                  <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto col-12">
                    <EventLogoUploader
                      newRecord={newRecord}
                      setNewRecord={setNewRecord}
                    />
                  </div>
                  <div className="ps-3 col">
                    <div className="row">
                      <div className="col">
                        <TextBox
                          name="eventName"
                          maxLength={50}
                          value={newRecord.eventName}
                          onValueChange={(e) => {
                            setNewRecord({ ...newRecord, eventName: e });
                          }}
                          label="Event Name"
                          labelMode="floating"
                          ref={FocusedBoxRef}
                        >
                          <Validator
                            validationGroup={ValidationGroupName}
                            elementAttr={{ class: "ForValidationAstrikMark" }}
                          >
                            <RequiredRule message="Event Name is required" />
                            <AsyncRule
                              reevaluate={false}
                              message="Can't accept duplicate Event Name"
                              validationCallback={asyncEventNameValidation}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                      <div className="col-auto ps-0">
                        <TextBox
                          name="eventCode"
                          maxLength={10}
                          value={newRecord.eventCode}
                          onValueChange={(e) => {
                            setNewRecord({ ...newRecord, eventCode: e });
                          }}
                          label="Event Code"
                          labelMode="floating"
                          width={160}
                        >
                          <Validator
                            validationGroup={ValidationGroupName}
                            elementAttr={{ class: "ForValidationAstrikMark" }}
                          >
                            <RequiredRule message="Event Code is required" />
                            <AsyncRule
                              reevaluate={false}
                              message="Can't accept duplicate Event Code"
                              validationCallback={asyncEventCodeValidation}
                            />
                            <PatternRule
                              message="Space and special characters are not accepted"
                              pattern={eventCodePattern}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-lg">
                        <div className="row">
                          <div className="col">
                            <DateBox
                              label="Event Start From"
                              labelMode="floating"
                              value={newRecord.eventFromDate}
                              onValueChange={(e) => {
                                setNewRecord({ ...newRecord, eventFromDate: e });
                              }}
                              type="date"
                              displayFormat={"dd/MM/yyyy"}
                              useMaskBehavior={true}
                              min={minDate}
                              dateOutOfRangeMessage={dateOutOfRangeMessage}
                            >
                              <Validator
                                validationGroup={ValidationGroupName}
                                elementAttr={{
                                  class: "ForValidationAstrikMark",
                                }}
                              >
                                <RequiredRule message="Event start date is required" />
                              </Validator>
                            </DateBox>
                          </div>
                          <div className="col-auto ps-0">
                            <DateBox
                              label="Time"
                              labelMode="floating"
                              value={newRecord.eventFromTime}
                              onValueChange={(e) => {
                                setNewRecord({ ...newRecord, eventFromTime: e });
                              }}
                              type="time"
                              displayFormat="shortTime"
                              width={120}
                              useMaskBehavior={true}
                            >
                              <Validator
                                validationGroup={ValidationGroupName}
                                elementAttr={{
                                  class: "ForValidationAstrikMark",
                                }}
                              >
                                <RequiredRule message="Event start time is required" />
                              </Validator>
                            </DateBox>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="row">
                          <div className="col">
                            <DateBox
                              label="Event Ends On"
                              labelMode="floating"
                              value={newRecord.eventToDate}
                              onValueChange={(e) => {
                                setNewRecord({ ...newRecord, eventToDate: e });
                              }}
                              type="date"
                              displayFormat={"dd/MM/yyyy"}
                              useMaskBehavior={true}
                              min={minDate}
                              dateOutOfRangeMessage={dateOutOfRangeMessage}
                            >
                              <Validator
                                validationGroup={ValidationGroupName}
                                elementAttr={{
                                  class: "ForValidationAstrikMark",
                                }}
                              >
                                <RequiredRule message="Event end date is required" />
                                <CustomRule
                                  ignoreEmptyValue={true}
                                  validationCallback={EventEndsOnValidation}
                                />
                              </Validator>
                            </DateBox>
                          </div>
                          <div className="col-auto ps-0">
                            <DateBox
                              label="Time"
                              labelMode="floating"
                              value={newRecord.eventToTime}
                              onValueChange={(e) => {
                                setNewRecord({ ...newRecord, eventToTime: e });
                              }}
                              type="time"
                              width={120}
                              useMaskBehavior={true}
                            >
                              <Validator
                                validationGroup={ValidationGroupName}
                                elementAttr={{
                                  class: "ForValidationAstrikMark",
                                }}
                              >
                                <RequiredRule message="Event end time is required" />
                              </Validator>
                            </DateBox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-xxl col-xl col-lg col-12">
                        <div className="row">
                          <div className="col">
                            <DateBox
                              label="Registration Start From"
                              labelMode="floating"
                              value={newRecord.registrationFromDate}
                              onValueChange={(e) => {
                                setNewRecord({ ...newRecord, registrationFromDate: e });
                              }}
                              type="date"
                              displayFormat={"dd/MM/yyyy"}
                              useMaskBehavior={true}
                              min={minDate}
                              dateOutOfRangeMessage={dateOutOfRangeMessage}
                            >
                              <Validator
                                validationGroup={ValidationGroupName}
                                elementAttr={{ class: "ForValidationAstrikMark" }}
                              >
                                <RequiredRule message="Registration start date is required" />
                                <CustomRule
                                  ignoreEmptyValue={true}
                                  validationCallback={RegistrationStartOnValidation}
                                />
                              </Validator>
                            </DateBox>
                          </div>
                          <div className="col-auto ps-0">
                            <DateBox
                              label="Time"
                              labelMode="floating"
                              value={newRecord.registrationFromTime}
                              onValueChange={(e) => {
                                setNewRecord({ ...newRecord, registrationFromTime: e });
                              }}
                              type="time"
                              width={120}
                              useMaskBehavior={true}
                            >
                              <Validator
                                validationGroup={ValidationGroupName}
                                elementAttr={{ class: "ForValidationAstrikMark" }}
                              >
                                <RequiredRule message="Registration start time is required" />
                              </Validator>
                            </DateBox>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <DateBox
                              label="Registration Ends On"
                              labelMode="floating"
                              value={newRecord.registrationToDate}
                              onValueChange={(e) => {
                                setNewRecord({ ...newRecord, registrationToDate: e });
                              }}
                              type="date"
                               displayFormat={"dd/MM/yyyy"}
                              useMaskBehavior={true}
                              min={minDate}
                              dateOutOfRangeMessage={dateOutOfRangeMessage}
                            >
                              <Validator
                                validationGroup={ValidationGroupName}
                                elementAttr={{ class: "ForValidationAstrikMark" }}
                              >
                                <RequiredRule message="Registration end date is required" />
                                <CustomRule
                                  ignoreEmptyValue={true}
                                  validationCallback={RegistrationEndsOnValidation}
                                />
                              </Validator>
                            </DateBox>
                          </div>
                          <div className="col-auto ps-0">
                            <DateBox
                              label="Time"
                              labelMode="floating"
                              value={newRecord.registrationToTime}
                              onValueChange={(e) => {
                                setNewRecord({ ...newRecord, registrationToTime: e });
                              }}
                              type="time"
                              width={120}
                              useMaskBehavior={true}
                            >
                              <Validator
                                validationGroup={ValidationGroupName}
                                elementAttr={{ class: "ForValidationAstrikMark" }}
                              >
                                <RequiredRule message="Registration end time is required" />
                              </Validator>
                            </DateBox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-xxl col-xl col-lg  col-12 pt-2 mt-1">
                        <div className="row">
                          <div className="col-xxl col-xl col-lg col-md col-12">
                            <div className="checkbox-width">
                              <CheckBox
                                text="Enable visitor public link"
                                value={newRecord.enableVisitorPublicLink}
                                onValueChange={(e) => {
                                  setNewRecord({
                                    ...newRecord,
                                    enableVisitorPublicLink: e,
                                  });
                                }}
                                className="EventCheckBox"
                                iconSize={17}
                              />
                              {newRecord.enableVisitorPublicLink &&
                                newRecord.eventCode && (
                                  <a
                                    href={`${window.origin}/visitor/${newRecord.eventCode}`}
                                    target="_blank"
                                  >
                                    <img
                                      src={LinkIcon}
                                      alt="link"
                                      className="ms-1"
                                      width={18}
                                    />
                                  </a>
                                )}
                            </div>
                          </div>
                          <div className="col pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-2 pb-xxl-0 pb-xl-0 pb-lg-0 pb-3">
                            <div className="checkbox-width">
                              <CheckBox
                                text="Enable media public link"
                                value={newRecord.enableMediaPublicLink}
                                onValueChange={(e) => {
                                  setNewRecord({
                                    ...newRecord,
                                    enableMediaPublicLink: e,
                                  });
                                }}
                                className="EventCheckBox"
                                iconSize={17}
                              />
                              {newRecord.enableMediaPublicLink &&
                                newRecord.eventCode && (
                                  <a
                                    href={`${window.origin}/media/${newRecord.eventCode}`}
                                    target="_blank"
                                  >
                                    <img
                                      src={LinkIcon}
                                      alt="link"
                                      className="ms-1"
                                      width={18}
                                    />
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col position-relative z-0">
                        <TextBox
                          id="CopyLink"
                          name="groupRegistrationLink"
                          label="Group Registration Login Link"
                          labelMode="floating"
                          value={GroupRegistrationLoginLink()}
                          buttons={hideCopyButton ? [] : CopyButton}
                          readOnly={true}
                        >
                        </TextBox>
                        {isPopoverVisible ? <img src={TickIcon} alt="Copied" width={25} height={25} className="Copied-Event-icon position-absolute" /> : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-md-auto">
                    <div className="fw-semibold automattic-notification-on-caption">Automatic Notification On: </div>
                    </div>
                    <div className="d-flex col-md-auto ps-md-1 pt-1 pt-md-0">
                      <CheckBox
                        className="EventCheckBox"
                        text="Approved"
                        iconSize={17}
                        value={newRecord.autoEmailOnApproval}
                        onValueChange={(e) => setNewRecord({ ...newRecord, autoEmailOnApproval: e })}
                      />
                    </div>
                    <div className="col-md-auto pt-1 pt-md-0 ">
                      <CheckBox
                        className="EventCheckBox"
                        text="Rejected"
                        iconSize={17}
                        value={newRecord.autoEmailOnRejection}
                        onValueChange={(e) => setNewRecord({ ...newRecord, autoEmailOnRejection: e })}
                      />
                    </div>
                    <div className="col-md-auto pt-1 pt-md-0">
                      <CheckBox
                        className="EventCheckBox"
                        text="Printed"
                        iconSize={17}
                        value={newRecord.autoEmailOnPrinted}
                        onValueChange={(e) => setNewRecord({ ...newRecord, autoEmailOnPrinted: e })}
                      />
                    </div>
                    <div className="col-md-auto pt-1 pt-md-0 ">
                      <CheckBox
                        className="EventCheckBox"
                        text="User Registration"
                        iconSize={17}
                        value={newRecord.autoEmailOnGroupUserRegistration}
                        onValueChange={(e) => setNewRecord({ ...newRecord, autoEmailOnGroupUserRegistration: e })}
                      />
                    </div>
                  </div>
                  <div className="pt-2">
                    Closing Message:
                    <HtmlEditor
                      ref={htmlEditorRef}
                      id={"JobDescribtionHtmlEditor"}
                      elementAttr={htmlEditorSecondAttributes}
                      valueType="html"
                      height={200}
                      className="CustomizeHtmlEditor"
                      defaultValue={newRecord.eventClosingMessageFormattedText}
                    >
                      <HtmlEditorToolbar multiline={false}>
                        <HtmlEditorItem name="link"></HtmlEditorItem>
                        <HtmlEditorItem
                          name="font"
                          acceptedValues={fontValues}
                        />
                        <HtmlEditorItem
                          name="size"
                          acceptedValues={sizeValues}
                        />
                        <HtmlEditorItem name="bold" />
                        <HtmlEditorItem name="italic" />
                        <HtmlEditorItem name="underline" />
                        <HtmlEditorItem name="background" />
                        <HtmlEditorItem name="color" />
                        <HtmlEditorItem name="orderedList" />
                        <HtmlEditorItem name="bulletList" />
                        <HtmlEditorItem name="clear" />
                      </HtmlEditorToolbar>
                    </HtmlEditor>
                  </div>
                </div>



              </div>
            )}
            {showSkeleton ? (
              <div className='d-flex justify-content-end pb-2 pe-3'>
                <div className='skeleton-View Skeleton-popup-footer'></div>
              </div>
            ) : (
              <PopupFooter
                ValidationGroupName={ValidationGroupName}
                handleCancel={onClosePopup}
                createUpdateStatus={createUpdateStatus}
              />
            )}
          </form>
        </Popup>
      )}
    </>
  );
}
