import { alert, custom } from "devextreme/ui/dialog";
import { useEffect, useRef } from "react";

export const eCRUDStatus = {
  None: 0,
  Inserted: 1,
  Updated: 2,
  Deleted: 3
};

export const camelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, "");
};

export const onKeyDown_Withpoint = (e) => {
  if (e.event.key === "-" || e.event.key === "_") {
    e.event.preventDefault();
    e.event.stopImmediatePropagation();
  }
}

export const onKeyDown_Withpoint_withoutPlus = (e) => {
  if (e.event.key === "-" || e.event.key === "_" || e.event.key === "+") {
    e.event.preventDefault();
    e.event.stopImmediatePropagation();
  }
}

export const onKeyDown_Withpoint_withoutPlus_withoutSpecialCharacters = (e) => {
  if (e.event.key === "-" || e.event.key === "_" || e.event.key === "+" || e.event.key === "#" || e.event.key === "$" || e.event.key === "%" || e.event.key === "'" || e.event.key === "," || e.event.key === "₹") {
    e.event.preventDefault();
    e.event.stopImmediatePropagation();
  }
}

export const onKeyDown = (e) => {
  if (e.event.key === "-" || e.event.key === "." || e.event.key === "_") {
    e.event.preventDefault();
    e.event.stopImmediatePropagation();
  }
}

export const ShowAlert = (message, title) => {
  return (
    alert(`<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${message}</span></div>`, title ? title : "Run")
  )
}

export const GetFormattedDate = (inputString) => {

  const dateObject = new Date(inputString);

  // Get the individual components of the date
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = dateObject.getFullYear().toString(); // Get year

  // Create the formatted date string
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate
}

export const GetFormattedTime = (inputString) => {
  const dateObject = new Date(inputString);

  // Get the individual components of the time
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Create the formatted time string
  const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  return formattedTime
}


export const ConflictPopup = (conflicMessage) => {
  if (conflicMessage === "Record already modified by another user.") {
    let myDialog = custom({
      title: "Run",
      messageHtml: "This record has been modified by another user. <br/> Reload to see the latest version of the record. Data entered on this screen will be lost.",
      buttons: [{
        text: "Reload",
        type: "success",
        stylingMode: "contained",
        elementAttr: { class: "btnReload" },
        onClick: (e) => {
          return true;
        }
      },
      {
        text: "Cancel",
        type: "danger",
        stylingMode: "contained",
        elementAttr: { class: "btnCancel" },
        onClick: (e) => {
          return false;
        }
      },
      ]
    });

    return myDialog.show().then((dialogResult) => {
      if (dialogResult) {
        return true;
      }
      else {
        return false;
      }

    });
  } else {
    return;
  }
}

export const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const onDragOver = (e) => {
  e.preventDefault();
}
export const maxDOBDate = new Date().setDate(new Date().getDate() - 1);;
export const minDate = new Date(1901, 0, 1);
export const dateOutOfRangeMessage = "Invalid date";

export const HideDatagridLoader = {
  enabled: false,
};

export const eventStatusDropDown = [
  {
    name: "Upcoming Event",
    value: 0
  },
  {
    name: "Registration Open",
    value: 1
  },
  {
    name: "Registration Closed",
    value: 2
  },
  {
    name: "Event Live",
    value: 3
  },
  {
    name: "Event Completed",
    value: 4
  },
  {
    name: "All",
    value: null
  }
];


export const dashboardSetas = [
  {
    statusID: 1,
    statusName: 'Pending'
  },
  {
    statusID: 2,
    statusName: 'Approved'
  },
  {
    statusID: 3,
    statusName: 'Rejected'
  },
  {
    statusID: 4,
    statusName: 'Printed'
  }
]

export const GetFormattedDateTime = (dateString) => {
  if (dateString) {
    // Convert UTC datetime string to a Date object
    const utcDate = new Date(dateString);

    // Get local date components
    const year = utcDate.getFullYear();
    const month = pad(utcDate.getMonth() + 1); // Month is zero-based
    const day = pad(utcDate.getDate());


    // Get local time components
    let hours = utcDate.getHours();
    const minutes = pad(utcDate.getMinutes());
    const seconds = pad(utcDate.getSeconds());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format the date and time
    const localDateTimeString = `${month}/${day}/${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

    return localDateTimeString

  }
  return null;
}

export const GetListFormattedDateTime = (dateString) => {
  if (dateString) {
    // Convert UTC datetime string to a Date object
    const utcDate = new Date(dateString);

    // Get local date components
    const year = utcDate.getFullYear();
    const month = pad(utcDate.getMonth() + 1); // Month is zero-based
    const day = pad(utcDate.getDate());


    // Get local time components
    let hours = utcDate.getHours();
    const minutes = pad(utcDate.getMinutes());
    const seconds = pad(utcDate.getSeconds());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format the date and time
    const localDateTimeString = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

    return localDateTimeString

  }
  return null;
}

export function GetFormattedDateOnly(dateString) {
  if (dateString) {
    // Convert UTC datetime string to a Date object        
    const utcDate = new Date(dateString);
    const localDate = new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000));
    // Get local date components
    const year = localDate.getFullYear();
    const month = pad(localDate.getMonth() + 1);
    const day = pad(localDate.getDate());

    // Format the date
    const localDateString = `${month}/${day}/${year}`;
    return localDateString;
  }
  return null;
}

const pad = (num) => {
  return num < 10 ? '0' + num : num;
};


export const GetFormattedDateTimeUTCString = (dateString) => {
  if (dateString) {
    // Convert UTC datetime string to a Date object
    const utcDate = new Date(dateString + "Z");

    // Get local date components
    const year = utcDate.getFullYear();
    const month = pad(utcDate.getMonth() + 1); // Month is zero-based
    const day = pad(utcDate.getDate());

    // Get local time components
    let hours = utcDate.getHours();
    const minutes = pad(utcDate.getMinutes());
    const seconds = pad(utcDate.getSeconds());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format the date and time
    const localDateTimeString = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

    return localDateTimeString

  }
  return null;
}

export const sortingMethodDate = (v1, v2) => {
  // const value1 = new Date(v1);
  // const value2 = new Date(v2);

  const parts1 = v1?.split(/[/ ,:]+/);
  const parts2 = v2?.split(/[/ ,:]+/);

  const value1 = parts1 && new Date(`${parts1[1]}/${parts1[0]}/${parts1[2]} ${parts1[3]}:${parts1[4]}:${parts1[5]} ${parts1[6]}`);
  const value2 = parts2 && new Date(`${parts2[1]}/${parts2[0]}/${parts2[2]} ${parts2[3]}:${parts2[4]}:${parts2[5]} ${parts2[6]}`);

  if (!value1 && value2) return -1;
  if (!value1 && !value2) return 0;
  if (value1 && !value2) return 1;
  if (value1 === value2) return 0;

  return value1 > value2 ? 1 : -1;

}

export const toCamelCase = (str) => {
  if (str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }
  return ""
}

export const CustomUseEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export const eEmailStatus = {
  None: 0,
  InProgress: 1,
  Success: 2,
  Error: 3,
  showLoader: 4

}