import { withNavigationWatcher } from './contexts/navigation';
import { GroupDashBoard } from './group-pages';


const groupRoutes = [
    {
        path: 'group/dashboard',
        element: GroupDashBoard
    }
]

export default groupRoutes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});