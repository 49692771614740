import React from 'react';
import { Link } from 'react-router-dom';
import './SucessfullyFormSubmitted.scss';

const SucessfullyFormSubmitted = () => {
  const eventCode = localStorage.getItem('eventCode')
  const isVisitor = window.location.pathname.includes("/visitor/");
  const isMedia = window.location.pathname.includes("/media/");


  return (
    <>
      <div className='row successful-form-submit'>
        <div className=' text-center my-2'><i className='dx-icon-check check-icon fs-5 p-2 rounded-5'></i></div>
        <div className='text-center py-2 message fw-bold'> Registration Completed Successfully!</div>
        <div className='pb-2 text-center'>
          {isVisitor && !isMedia &&
            <>
              <Link to={`/visitor/${eventCode}`} className='sign-in-link w-auto'>
                Start New
              </Link>
            </>
          }
          {!isVisitor && isMedia &&
            <>
              <Link to={`/media/${eventCode}`} className='sign-in-link w-auto'>
                Start New
              </Link>
            </>

          }

        </div>
      </div>
    </>
  )
}

export default SucessfullyFormSubmitted