import React, { useEffect, useRef, useState } from 'react';
import { SelectBox, Button as SelectBoxButton } from 'devextreme-react/select-box';
import { Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/validator';
import { ShowAlert, eCRUDStatus } from '../../../utils/common-methods';
import { EventService } from '../../../api/services/eventService';
import EventPopup from './EventPopup';


const eventService = new EventService();

const EventDropDown = (props) => {
  const [showEventPopup, setShowEventPopup] = useState(false)
  const [eventList, setEventList] = useState([])
  let [primaryKey, setPrimaryKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: null,
    primaryKeyID: primaryKey,
  });

  const SelectBoxRef = useRef(null)

  useEffect(() => {
    showEventPopup === false && props.setDropDownClick && props.setDropDownClick(false);
  }, [showEventPopup])

  useEffect(() => {
    eventLookUpList();
  }, [])

  const eventLookUpList = async () => {
    const result = await eventService.GetLookupListRecord()
    const response = result.data;
    setEventList(response);
  }

  useEffect(() => {
    if (status.eStatus !== null && status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
      UpdateListByID(PrimaryKeyID);
      setStatus({ eStatus: null })
    } else if (status.eStatus !== null && status.eStatus === eCRUDStatus.None) {
      setPrimaryKey(null);
      setStatus({ eStatus: null })
    }
  }, [status])

  const UpdateListByID = async (primaryKey) => {
    const result = await eventService.GetListByIDRecord(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Run")
    } else {
      if (status.eStatus === eCRUDStatus.Inserted) {
        setEventList([result.data[0], ...eventList]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setEventList(eventList.map((i) => i.eventID === primaryKey ? result.data[0] : i));
      }
    }
    props.setAddedEventID && props.setAddedEventID(primaryKey);
  };

  const AddButton = {
    icon: 'plus',
    stylingMode: 'text',
    onClick: () => {
      props.setDropDownClick && props.setDropDownClick(true);
      setShowEventPopup(!showEventPopup)
    }
  }

  let isOpened = null

  const DownArrow = {
    icon: 'spindown',
    stylingMode: 'text',
    onClick: (e) => {

      var selectBoxInstance
      if(props.FocusedFeild){
        selectBoxInstance = props.FocusedFeild?.current?.instance;
      }else{
        selectBoxInstance = SelectBoxRef?.current?.instance;
      }
      isOpened = selectBoxInstance?.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    }
  }

  const onCloseEventPopup = () => {
    setShowEventPopup(false);
  }

  return (
    <div>
      <SelectBox
        label={props.label ? props.label : 'Event'}
        searchEnabled={true}
        dataSource={eventList}
        ref={props.FocusedFeild ? props.FocusedFeild : SelectBoxRef}
        labelMode='floating'
        value={props.value}
        onValueChange={props.onValueChange}
        displayExpr="eventName"
        valueExpr="eventID"
        dropDownOptions={{
          height: props.dropdownHeight ? props.dropdownHeight : 120,
          hideOnParentScroll: false
        }}

      >
        
        <SelectBoxButton
          name="add"
          location="after"
          options={AddButton}
        />
        <SelectBoxButton
          name="dropdown"
          location="after"
          options={DownArrow}
        />
        {props.validationRequired && (
          <Validator validationGroup={props.validationGroup} elementAttr={{ class: 'ForValidationAstrikMark' }}>
            <RequiredRule message={props.validationMessage ? props.validationMessage : "Event is required"} />
          </Validator>
        )}
      </SelectBox>
      {showEventPopup === true &&
        <EventPopup
          popupShow={showEventPopup}
          popupClose={onCloseEventPopup}
          primaryKey={primaryKey}
          setStatus={setStatus}
        />}
    </div>
  )
}

export default EventDropDown;