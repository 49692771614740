import React from "react";
import "./ListSkeleton.scss";
import { ScrollView } from "devextreme-react";

const ListSkeleton = () => {
  return (
    <>
    <ScrollView width={'100%'} height={'100%'}>
      <div className="skeleton-section">
        <div className="d-flex justify-content-end">
        <span className=" skeleton-toolbar-height rounded list-toolbar-skeleton"></span>
        </div>
        <span className="custom-skeleton skeleton-header-height"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton"></span>
        <span className="custom-skeleton skeleton-rows"></span>
      </div>
      </ScrollView>
    </>
  );
};

export default ListSkeleton;
