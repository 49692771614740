import { RunAEMSAPIService } from "../RunAEMSAPIService";
import { fetchWrapper } from "../authHeader";

const baseURL = process.env.REACT_APP_BASEURL;

const url = `${baseURL}RegistrationManagement/Registration`;

export class RegistrationAuthorizedService extends RunAEMSAPIService {

    constructor() {
        // call the super class constructor and pass in the name parameter
        super(url);
    }

    GetRegisrationByMobileNo = async (MobileNo, EventID) => {
        const result = await fetchWrapper.get(url + `/GetRegisrationByMobileNo?MobileNo=${MobileNo}&eventID=${EventID}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetRegisrationByEmailID = async (EmailID, EventID) => {
        const result = await fetchWrapper.get(url + `/GetRegisrationByEmailID?EmailID=${EmailID}&eventID=${EventID}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetListWithFilter = async (EventID, RegistrationTypeID, GroupID, ID, RegistrationStatusFilter) => {
        var params = new URLSearchParams();
        if (ID) {
            params.append('ID', ID);
        }
        if (EventID) {
            params.append('EventID', EventID);
        }
        if (RegistrationTypeID) {
            params.append('RegistrationTypeID', RegistrationTypeID);
        }
        if (GroupID) {
            params.append('GroupID', GroupID);
        }
        if (RegistrationStatusFilter || RegistrationStatusFilter === 0) {
            params.append('RegistrationStatusFilter', RegistrationStatusFilter);
        }

        const result = await fetchWrapper.get(this.apiUrl + `/GetList?${params}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetRegistrationForm = async (EventCode, RegistrationType) => {
        const result = await fetchWrapper.getWithoutHeader(url + `/GetRegistrationForm/?eventCode=${EventCode}&RegistrationType=${RegistrationType}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetNationalityLookupList = async () => {
        const result = await fetchWrapper.getWithoutHeader(url + `/GetNationalityLookupList`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetMediaTypeLookupList = async () => {
        const result = await fetchWrapper.getWithoutHeader(url + `/GetMediaTypeLookupList`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetMediaFunctionLookupList = async () => {
        const result = await fetchWrapper.getWithoutHeader(url + `/GetMediaFunctionLookupList`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    // Start Photos //

    DownloadPhoto = async (RegistrationID) => {
        const result = await fetchWrapper.get(url + '/DownloadPhoto/' + RegistrationID);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.blob(),
                    statusCode: result.status,
                    fileExtension: result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    UploadPhoto = async (params, RegistrationID) => {
        const formData = new FormData();
        formData.append("attachments", params.attachments);
        const result = await fetchWrapper.postwithFile(url + '/UploadPhoto/' + RegistrationID, formData);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: result.ok,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: result.ok,
                    data: await result.text(),
                    stautsCode: result.status && result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    DeletePhoto = async (RegistrationID) => {
        const result = await fetchWrapper.delete(url + '/DeletePhoto/' + RegistrationID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    DownloadPhotoThumbnail = async (RegistrationID) => {
        const result = await fetchWrapper.get(url + '/DownloadPhotoThumbnail/' + RegistrationID);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.blob(),
                    statusCode: result.status,
                    fileExtension: result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    DownloadPhotosByIDs = async (RegistrationIDs) => {
        var params = {
            registrationIds :RegistrationIDs
        };
        const result = await fetchWrapper.post(url + '/DownloadPhotos', params);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status,
                    fileExtension:result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    // End Photos //

    // Start Passport //

    DownloadPassportCopy = async (RegistrationID) => {
        const result = await fetchWrapper.get(url + '/DownloadPassportCopy/' + RegistrationID);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.blob(),
                    statusCode: result.status,
                    fileExtension: result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    UploadPassportCopy = async (params, RegistrationID) => {
        const formData = new FormData();
        formData.append("attachments", params.attachments);
        const result = await fetchWrapper.postwithFile(url + '/UploadPassportCopy/' + RegistrationID, formData);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: result.ok,
                    data: await result,
                    statusCode: result.status,
                    fileExtension:result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: result.ok,
                    data: await result.text(),
                    stautsCode: result.status && result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    DeletePassportCopy = async (RegistrationID) => {
        const result = await fetchWrapper.delete(url + '/DeletePassportCopy/' + RegistrationID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    DownloadPassportCopyThumbnail = async (RegistrationID) => {
        const result = await fetchWrapper.get(url + '/DownloadPassportCopyThumbnail/' + RegistrationID);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.blob(),
                    statusCode: result.status,
                    fileExtension: result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    DownloadPassportCopiesByIDs = async (RegistrationIDs) => {
        var params = {
            registrationIds :RegistrationIDs
        };
        const result = await fetchWrapper.post(url + '/DownloadPassportCopies', params);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status,
                    fileExtension:result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    // End Passport //

    // Start QID //

    DownloadQIDCopy = async (RegistrationID) => {
        const result = await fetchWrapper.get(url + '/DownloadQIDCopy/' + RegistrationID);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.blob(),
                    statusCode: result.status,
                    fileExtension: result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    UploadQIDCopy = async (params, RegistrationID) => {
        const formData = new FormData();
        formData.append("attachments", params.attachments);
        const result = await fetchWrapper.postwithFile(url + '/UploadQIDCopy/' + RegistrationID, formData);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: result.ok,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: result.ok,
                    data: await result.text(),
                    stautsCode: result.status && result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    DeleteQIDCopy = async (RegistrationID) => {
        const result = await fetchWrapper.delete(url + '/DeleteQIDCopy/' + RegistrationID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    DownloadQIDCopyThumbnail = async (RegistrationID) => {
        const result = await fetchWrapper.get(url + '/DownloadQIDCopyThumbnail/' + RegistrationID);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.blob(),
                    statusCode: result.status,
                    fileExtension: result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    DownloadQIDCopiesByIDs = async (RegistrationIDs) => {
        var params = {
            registrationIds :RegistrationIDs
        };
        const result = await fetchWrapper.post(url + '/DownloadQIDCopies', params);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status,
                    fileExtension:result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    // End QID //

    // Start AIP //

    DownloadAIPCopy = async (RegistrationID) => {
        const result = await fetchWrapper.get(url + '/DownloadAIPCopy/' + RegistrationID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.blob(),
                    statusCode: result.status,
                    fileExtension: result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    UploadAIPCopy = async (params, RegistrationID) => {
        const formData = new FormData();
        formData.append("attachments", params.attachments);
        const result = await fetchWrapper.postwithFile(url + '/UploadAIPCopy/' + RegistrationID, formData);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: result.ok,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: result.ok,
                    data: await result.text(),
                    stautsCode: result.status && result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    DeleteAIPCopy = async (RegistrationID) => {
        const result = await fetchWrapper.delete(url + '/DeleteAIPCopy/' + RegistrationID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    DownloadAIPCopyThumbnail = async (RegistrationID) => {        
        const result = await fetchWrapper.get(url + '/DownloadAIPCopyThumbnail/' + RegistrationID);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.blob(),
                    statusCode: result.status,
                    fileExtension: result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    DownloadAIPCopiesByIDs = async (RegistrationIDs) => {
        var params = {
            registrationIds :RegistrationIDs
        };
        const result = await fetchWrapper.post(url + '/DownloadAIPCopies', params);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status,
                    fileExtension:result.headers.get('content-type')
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    // End AIP //

    UpdateStatus = async (params) => {
        const result = await fetchWrapper.put(url + '/UpdateStatus', params);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: result.ok,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: result.ok,
                    data: await result.text(),
                    stautsCode: result.status && result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    SendEmailOnStatusChange = async (regisID) =>{
        const result = await fetchWrapper.post(url + `/SendEmailOnStatusChange/${regisID}`);
        try {

            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                data: "Failed To Load Data",
                statusCode: result.status
            };
        }
    }

    SendRejectionEmail = async (regisID, params) => {
        const result = await fetchWrapper.post(this.apiUrl +  '/SendRejectionEmail/'+ regisID, params);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };

            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

}

export const eState = {
    None: 0,
    FormDesign: 1,
    FormCreated: 2
}

export const eFileUploadType = {
    None: 0,
    Photo: 1,
    PassportCopy: 2,
    QIDCopy: 3,
    AIPCopy: 4
}