import React, { useRef, useState } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/UserPanel";
import "./Header.scss";
import { Template } from "devextreme-react/core/template";
import { useScreenSize } from "../../utils/media-query";
import "../../utils/base-64-icons.scss";
import { DropDownButton } from "devextreme-react";
import { camelCase, eventStatusDropDown } from "../../utils/common-methods";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
import { SelectBox } from "devextreme-react";

const dropDownOptions = {
  width: "85px",
};

const Header = (props) => {
  const { isSmall, isXSmall, isXXSmall, isExSmall } = useScreenSize();
  const [userImage, setUserImage] = useState({
        uploadedFile: { fileData: localStorage.getItem("userImage") },
        attachedFile: localStorage.getItem("userImageData")
    });

  const HeaderToolbarRef = useRef(null);

  const DataGridRef = props.dataGridRef;

  const onAddButtonClick = () => {
    props.eventListEventID ? props.handleAdd(props.eventListEventID) : props.handleAdd();
  };

  const onRefreshButtonClick = async () => {
    props.refreshDataGrid && await props.refreshDataGrid();
    HeaderToolbarRef.current?.instance.repaint();
  };

  const onResetButtonClick = () => {
    DataGridRef.current?.instance.state(null);
    DataGridRef.current?.instance.clearFilter();
    HeaderToolbarRef.current?.instance.repaint();
    props.resetStatus && props.resetStatus(props.DefaultstatusValue)
    props.seteventListEventID && props.seteventListEventID();
    props.setHideFilterSelectBox && props.setHideFilterSelectBox(false);
  }

  
  const dropdownItems = [
    { id: 1, name: "Excel", icon: "exportxlsx" },
    { id: 2, name: "CSV", icon: "xlsfile" },
  ];

  const onItemClick = (e) => {
    var fileName = camelCase(props.title);
    if (props.exportFileName !== undefined && props.exportFileName !== null) {
      fileName = camelCase(props.exportFileName);
    }

    if (e.itemData.id === 1) {
      var workbook = new Workbook();
      var worksheet = workbook.addWorksheet("SheetName");
      exportDataGrid({
        component: DataGridRef.current?.instance,
        worksheet: worksheet,
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName + ".xlsx"
          );
        });
      });
    } else if (e.itemData.id === 2) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Report");
      exportDataGrid({
        component: DataGridRef.current?.instance,
        worksheet: worksheet,
      }).then(function () {
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName + ".csv"
          );
        });
      });
    }
  };

  const onDashBoardFilterChange = (e) =>{
    props.setfocusCardIndex(0)
    props.setEventStatus(e)
    props.setRowFocused(false)
  }

  return (
    <header className={"header-component"} id="Comman-Header">
      <Toolbar className={"header-toolbar"} ref={HeaderToolbarRef}>
        <Item
          location={"before"}
          cssClass={"header-title ps-2"}
          visible={!!props.title}
        >
          <span className={(isExSmall || isXXSmall || isXSmall || isSmall) ? "ps-4 ms-3" : "ps-2"}>{props.title}</span>
        </Item>
        {
          props.showEventDetails &&
          <Item
          location={"before"}
          cssClass={"event-title"}
        >
          <span className={(isExSmall || isXXSmall || isXSmall || isSmall) ? "ps-4 ms-3" : "d-flex"}>
            <span className="vertical-line ps-1 pe-2">|</span>
           {
            props.selectedEventDetails.eventLogo &&
            <span>
              <img src={props.selectedEventDetails.eventLogo} alt="event logo" width={18} className="mt-1 me-2"/>
            </span>
           }
            {props.selectedEventDetails.eventName}
           
          </span>
        </Item>
        }
        <Item location="before">
        {
          props.showEventStatusFilter &&
        <div className="status-filter-header d-flex">
           <span className="vertical-line ps-1 pe-2 mt-2 pt-1">|</span>
          <SelectBox
            label="Status"
            labelMode="floating"
            dataSource={eventStatusDropDown}
            displayExpr={"name"}
            valueExpr={"value"}
            width={170}
            value={props.eventStatus}
            onValueChange={onDashBoardFilterChange}
          />
          </div>
        }
        </Item>
        
        {!props.hideDefaultButtons &&(
          <Item location={"before"}>
            <span className="vertical-line px-1">|</span>
          </Item>
        )}
        {!props.hideDefaultButtons && (
          <Item location="before" locateInMenu="auto">
            <Button
              icon="plus"
              className="rounded-5 default-button"
              id="Add"
              onClick={onAddButtonClick}
              stylingMode="outlined"
              type="default"
              hint="Add"
            ></Button>
          </Item>
        )}
         {(!props.hideDefaultButtons || props.showRefreshButton) && (
          <Item location="after" locateInMenu="auto">
            <Button
              icon="refresh"
              className="mx-md-1 rounded-5 default-button"
              id="refresh"
              onClick={onRefreshButtonClick}
              disabled={props.disableRefreshButton && props.disableRefreshButton}
              stylingMode="outlined"
              type="default"
              hint="Refresh"
            ></Button>
          </Item>
        )}
       
        {!props.hideDefaultButtons && (
          <Item location="after" locateInMenu="auto">
            <DropDownButton
              displayExpr={"name"}
              items={dropdownItems}
              id={"export"}
              icon={"export"}
              hint="Export"
              dropDownOptions={dropDownOptions}
              stylingMode="outlined"
              type="default"
              className={"mx-md-1 rounded-5 export-dropdown-btn"}
              elementAttr={{ role: "button" }}
              showArrowIcon={false}
              onItemClick={onItemClick}
            ></DropDownButton>
          </Item>
        )}
         {!props.hideDefaultButtons && (
          <Item location="after" locateInMenu="auto">
            <Button
              icon="pulldown"
              className="mx-md-1 rounded-5 default-button ResetButton"
              id="reset"
              onClick={onResetButtonClick}
              stylingMode="outlined"
              type="default"
              hint="Reset"
            ></Button>
          </Item>
        )}
        {
          !isExSmall && !isXSmall && !isXXSmall && (!props.hideDefaultButtons  || props.showRefreshButton) &&
          <Item location={"after"}>
            <span className="vertical-line">|</span>
          </Item>
        }
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} userImage={userImage} setUserImage={setUserImage} setDropDownClick={props.setDropDownClick} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} userImage={userImage} setUserImage={setUserImage} setDropDownClick={props.setDropDownClick}/>
        </Template>
      </Toolbar>


    </header>
  );
}


export default Header;