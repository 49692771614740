import { RunAEMSAPIService } from "../RunAEMSAPIService";
import { fetchWrapper } from "../authHeader";

const baseURL = process.env.REACT_APP_BASEURL;

const url = `${baseURL}ApplicationSettings/LoginPageBanner`;

export class LoginPageBannerService extends RunAEMSAPIService {

    constructor() {
        // call the super class constructor and pass in the name parameter
        super(url);
    }

    GetLoginBanner = async () =>{
        const result = await fetchWrapper.get(url + '/Download');
    try {
       
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            data: "Failed To Load Data",
            statusCode: result.status
        };
    }
    }

    UploadLoginBanner = async (params) =>{
        const formData = new FormData();
        formData.append("attachments", params.attachments);
        const result = await fetchWrapper.postwithFile(url + '/Upload', formData);
    try {
        
        if (result.status && result.status === 200) {
            return {
                isOk: result.ok,
                data: await result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: result.ok,
                data: await result.text(),
                stautsCode: result.status && result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
    }


    DeleteLoginBanner = async () =>{
        const result = await fetchWrapper.delete(url + '/Delete');
    try {

        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: result.status
        };
    }
    }

}


