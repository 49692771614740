import { Button, HtmlEditor, LoadPanel, Popup, TagBox, TextBox, Validator } from "devextreme-react";
import { PatternRule, RequiredRule } from "devextreme-react/cjs/validator";
import React, { useRef, useState } from "react";
import { useScreenSize } from "../../utils/media-query";
import { Item, Toolbar } from "devextreme-react/cjs/html-editor";
import { PopupHeader } from "../../layouts";
import { EmailTemplateService } from "../../api/services/emailTemplateService";
import { ShowAlert } from "../../utils/common-methods";
import { RegistrationAuthorizedService } from "../../api/services/registrationAuthorizedService";

const htmlEditorAttributes = {
  class: "noteHtml emailhtml",
};

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];


const emailTemplateService = new EmailTemplateService();
const registrationServices = new RegistrationAuthorizedService();

const SingleRejectionEmailTemplate = (props) => {
    const emailRecord = {
        // referralID: props.ReferralID,
        recipients: [],
        subject: null,
        emailBody: null,
        replyto: null,
      };
  const tagBoxRef = useRef(null);
  const htmlEditorRef = useRef(null);
  const [newRecord, setNewRecord] = useState(emailRecord);
  const [isLoadPanelVisible, setIsLoadPanelVisible] = useState(false);
  const [LoaderText, setLoaderText] = useState("Loading...")
  const { isXSmall, isExSmall, isXXSmall, isExLarge, isMedium, isSmall } = useScreenSize();
  let EventID = props.eventId;
  


  const onEmailToChange = (e) => {
    setNewRecord({ ...newRecord, recipients: e });
  };


  const validationGroupName = "SendRejectionEmailValidationGroup";

  const onClosePopup = () =>{
    props.HideEmailTemplate();
}

  const PopupTitle = (e) => {
    return (
        <>
            <PopupHeader
                onClosePopup={onClosePopup}
                title={[
                    "Email Notification For Rejection",
                ]}
            />
        </>
    );
};
const shown = () => {
    tagBoxRef.current?.instance.focus();
  };

  const ClosePopup = () =>{
    props.HideEmailTemplate();
  }

  const SendEmailOnRejection = async () => {
    setLoaderText("Sending Email...")
    setIsLoadPanelVisible(true)
    var recipientsArray = newRecord.recipients;
    var recipientsString = recipientsArray.join(',');
    const dataToInsert = {
      recipients: recipientsString,
      subject: newRecord.subject, 
      emailBody: htmlEditorRef.current?.instance.option("value")
    }
    const result = await registrationServices.SendRejectionEmail(props.selectedRecordDetails.registrationID,dataToInsert)
    if(result.isOk){
      setIsLoadPanelVisible(false)
      ClosePopup();
    }else if(result.statusCode === 500){
      setIsLoadPanelVisible(false)
      ShowAlert("Something went wrong, contact your administrator.")
    }
    else{
      setIsLoadPanelVisible(false)
      ShowAlert(result.data)
    }
  }


  const onFormSubmit = (e) =>{
    e.preventDefault();
    SendEmailOnRejection();
  }

  const GetEmailTemplate = async () =>{
    setIsLoadPanelVisible(true)
    setLoaderText("Loading...")
    let EmailTemplateTypeID = 1; //rejection
    const result = await emailTemplateService.GetRejectionEmailTemplateRecord(EventID, EmailTemplateTypeID);
    if(result.statusCode === 400) {
      ShowAlert(result.data)
      setIsLoadPanelVisible(false)
    }else if(result.isOk){
      setIsLoadPanelVisible(false)
      setNewRecord({...newRecord, emailBody : result.data, recipients:props.selectedRecordDetails.emailID ?[ props.selectedRecordDetails.emailID] : [], subject: `Your registration has been rejected for ${props.eventName}` })
      htmlEditorRef.current?.instance.option("value", result.data);
    }
  }



  useState(()=>{
    if(props.showEmailTemplateOnSingleRejection){
      GetEmailTemplate()
    }
  },[props.showEmailTemplateOnSingleRejection])

  return (
    <>
    <LoadPanel
    visible={isLoadPanelVisible}
    message={LoaderText}
      />
    <Popup
            visible={props.showEmailTemplateOnSingleRejection}
            onHiding={ClosePopup}
            width={(isXSmall || isXXSmall || isExSmall) ? "95%" : isMedium || isSmall? "60%": "40%"}
            height={"auto"}
            maxHeight={"90%"}
            titleRender={PopupTitle}
            wrapperAttr={{ class: "CustomPopup" }}
            >
                <form onSubmit={onFormSubmit}>
    <div className="px-2">
      <TagBox
        ref={tagBoxRef}
        noDataText=""
        opened={false}
        openOnFieldClick={false}
        acceptCustomValue={true}
        value={newRecord.recipients}
        onValueChange={onEmailToChange}
        label="To"
        labelMode="floating"
        onShown={shown}
      >
        <Validator
          validationGroup={validationGroupName}
          elementAttr={{ class: "ForValidationAstrikMark" }}
        >
          <RequiredRule message={"Please enter recipients"} />
          <PatternRule
            message="Email is invalid"
            pattern={/^[\d\w+._-]+@[\d\w+._-]+\.[\w]+$/i}
          />
        </Validator>
      </TagBox>
      <TextBox
        name="subject"
        maxLength={50}
        value={newRecord.subject}
        onValueChange={(e) => setNewRecord({ ...newRecord, subject: e })}
        label="Subject"
        className="mt-3"
        labelMode="floating"
      >
        <Validator validationGroup={validationGroupName}
          elementAttr={{ class: "ForValidationAstrikMark" }}>
          <RequiredRule message={"Please enter subject"} />
        </Validator>
      </TextBox>

      <HtmlEditor
        tabIndex={-1}
        id="htmlEditor"
        ref={htmlEditorRef}
        elementAttr={htmlEditorAttributes}
        height={isXXSmall || isXSmall || isExSmall ? 280 : 350}
        hint="Add Details"
        // defaultValue={newRecord?.emailBody}
        className=" mt-3 CustomizeHtmlEditor"
      >
        <Toolbar className="itemTool" multiline={!isExLarge ? false : true}>
          <Item name="link"></Item>
          <Item name="font" acceptedValues={fontValues} />
          <Item name="size" acceptedValues={sizeValues}></Item>
          <Item name="bold" />
          <Item name="italic" />
          <Item name="underline" />
          <Item name="background" />
          <Item name="color" />
          <Item name="orderedList" />
          <Item name="bulletList" />
          <Item name="clear" />
        </Toolbar>
        <Validator>
          <RequiredRule message={"Please enter email body"} />
        </Validator>
      </HtmlEditor>
    </div>
    <div className="border-top mt-2 text-end">
        <Button
            text="Send"
            type="default"
            className="m-2 px-1 rounded-3 "
            useSubmitBehavior={true}
            validationGroup={validationGroupName}
            disabled={isLoadPanelVisible}
            />
    </div>
    </form>
    </Popup>
    </>
  );
};

export default SingleRejectionEmailTemplate;
