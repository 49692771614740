import { HomePage, TitleList, MediaFunctionList, MediaTypeList, NationalityList, EventGroupCategoryList, UserList , EventList, GroupsList, LogInPageBanner } from './pages';
import { withNavigationWatcher } from './contexts/navigation';


const routes = [
    {
        path: '/dashboard',
        element: HomePage
    },
    {
        path: '/Category/List',
        element :EventGroupCategoryList
    },
    {
        path:'/Users/List',
        element: UserList
    },
    {
        path: '/Titles/List',
        element: TitleList
    },
    {
        path: '/MediaFunctions/List',
        element: MediaFunctionList
    },
    {
        path: '/MediaTypes/List',
        element: MediaTypeList
    },
    {
        path: '/Nationalities/List',
        element: NationalityList
    },
    {
        path: '/Groups/List',
        element: GroupsList
    },
    {
        path: '/Event/List',
        element: EventList
    },
    {
        path: '/Login-Page-Banner',
        element: LogInPageBanner
    }
];


export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});


