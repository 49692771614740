import React, { useEffect, useRef, useState } from 'react';
import { Popup, ScrollView } from "devextreme-react";
import { PopupFooter, PopupHeader, PopupSkeleton } from '../../../layouts';
import HtmlEditor, {
    Toolbar as HtmlEditorToolbar,
    Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import DataGrid, { Scrolling, Column } from 'devextreme-react/data-grid';
import { ConflictPopup, HideDatagridLoader, ShowAlert } from '../../../utils/common-methods';
import { EmailTemplateService } from '../../../api/services/emailTemplateService';
import './Event.scss';
import TabPanel, { Item } from 'devextreme-react/tab-panel'
import { useScreenSize } from '../../../utils/media-query';
import { RegistrationFormDesignerService } from '../../../api/services/registrationFormDesignerService';


const emailTemplateService = new EmailTemplateService();
const registrationFormDesignerService = new RegistrationFormDesignerService();
const EmailTemplatePopup = (props) => {

    const htmlEditorSecondAttributes = {
        class: "noteSecondHtml",
    };

    const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
    const fontValues = [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
    ];

    const htmlEditorRef = useRef(null);
    let PrimaryKeyID = props.primaryKey;

    const HTMLEditorDefaultData = [
        {
            eventID: PrimaryKeyID,
            emailTemplateTypeID: 2,
            emailTemplatePlainText: null,
            emailTemplateFormattextText: null
        },
        {
            eventID: PrimaryKeyID,
            emailTemplateTypeID: 3,
            emailTemplatePlainText: null,
            emailTemplateFormattextText: null
        },
        {
            eventID: PrimaryKeyID,
            emailTemplateTypeID: 1,
            emailTemplatePlainText: null,
            emailTemplateFormattextText: null
        },
        {
            eventID: PrimaryKeyID,
            emailTemplateTypeID: 0,
            emailTemplatePlainText: null,
            emailTemplateFormattextText: null
        }
    ]

    const EmailTemplateStaticComponent = [
        {
            caption: "Event Name",
            componentID: 1
        },
        {
            caption: "Group Name",
            componentID: 2
        },
        {
            caption: "First Name",
            componentID: 3
        },
        {
            caption: "Last Name",
            componentID: 4
        },
        {
            caption: "Full Name",
            componentID: 5
        },

    ]

    const { isMedium, isLarge, isExLarge, isSmall } = useScreenSize();
    const [dsComponent, setDsComponent] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [htmlEditorData, setHTMLEditorData] = useState(HTMLEditorDefaultData);
    const [showSkeleton, setShowSkeleton] = useState(false);

    const GetComponentList = async () => {
        setShowSkeleton(true)
        const result = await registrationFormDesignerService.GetComponents();
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")
            setShowSkeleton(false);
        } else {
            const EmailTemplateData = result?.data?.filter(item => item.componentTypeID !== 7);
            EmailTemplateData.unshift({ caption: "Group Name", componentID: -2 })
            EmailTemplateData.unshift({ caption: "Event Name", componentID: -1 })
            setDsComponent(EmailTemplateData);
            setShowSkeleton(false);
        }
    }

    useEffect(() => {
        GetComponentList()
    }, [])

    const GetModalRecord = async () => {
        setShowSkeleton(true)
        const result = await emailTemplateService.GetEmailTemplateRecord(PrimaryKeyID);
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")
            setShowSkeleton(false);
        } else {
            if (result.statusCode === 200) {
                const response = result.data;
                const modifiedItems = htmlEditorData?.map((item) => {
                    const matchedItem = response?.find((selecteditem) => {
                        return item.emailTemplateTypeID === selecteditem.emailTemplateTypeID;
                    });
                    if (matchedItem) {
                        return {
                            eventID: PrimaryKeyID,
                            emailTemplateTypeID: item.emailTemplateTypeID,
                            emailTemplatePlainText: matchedItem.emailTemplatePlainText,
                            emailTemplateFormattextText: matchedItem.emailTemplateFormattextText
                        };
                    }
                    return item;
                });
                setHTMLEditorData(modifiedItems)
                setShowSkeleton(false);
            }
        }
    }

    useEffect(() => {
        GetModalRecord();
    }, [])

    useEffect(() => {
        if (!showSkeleton) {
            if (htmlEditorData) {
                let HTMLValue = htmlEditorData.find((item) => item.emailTemplateTypeID === selectedTabIndex);
                HTMLValue && htmlEditorRef.current?.instance.option("value", HTMLValue?.emailTemplateFormattextText);
            }
        }
    }, [selectedTabIndex, htmlEditorData, showSkeleton])


    const onClosePopup = () => {
        props.popupClose();
    };

    const onRowDbClick = (e) => {
        const selectedRowValue = `{{${e?.data.caption}}}`;
        const htmlEditorInstance = htmlEditorRef.current?.instance;
        const quill = htmlEditorInstance.getQuillInstance();
        const cursorPosition = quill.getSelection(true).index;
        quill?.insertText(cursorPosition, selectedRowValue);
    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        let modifiedTemplate = htmlEditorData;
        var descrText = htmlEditorRef.current?.instance.getQuillInstance();
        var FormattedText = htmlEditorRef.current?.instance.option("value");
        const descrPlainText = descrText && descrText.getText();
        modifiedTemplate = htmlEditorData?.map((item => {
            if (item.emailTemplateTypeID === selectedTabIndex) {
                return {
                    eventID: PrimaryKeyID,
                    emailTemplateTypeID: item.emailTemplateTypeID,
                    emailTemplatePlainText: descrPlainText,
                    emailTemplateFormattextText: FormattedText
                }
            }
            return item
        }))
        setHTMLEditorData(modifiedTemplate)
        UpdateData(modifiedTemplate);
    }

    const OnTabselectionChanged = (e) => {
        setSelectedTabIndex(e)
        var descrText = htmlEditorRef.current?.instance.getQuillInstance();
        var FormattedText = htmlEditorRef.current?.instance.option("value");
        const descrPlainText = descrText?.getText();
        const modifiedTemplate = htmlEditorData?.map((item => {
            if (item.emailTemplateTypeID === selectedTabIndex) {
                return {
                    eventID: PrimaryKeyID,
                    emailTemplateTypeID: item.emailTemplateTypeID,
                    emailTemplatePlainText: descrPlainText,
                    emailTemplateFormattextText: FormattedText
                }
            }
            return item
        }))
        setHTMLEditorData(modifiedTemplate)
    }

    const UpdateData = async (modifiedTemplate) => {
        setShowSkeleton(true);
        const dataToUpdate = {
            eventID: PrimaryKeyID,
            onApprovalTemplatePlainText: '',
            onApprovalTemplateFormattextText: '',
            onRejectionTemplatePlainText: '',
            onRejectionTemplateFormattextText: '',
            onPrintedTemplatePlainText: '',
            onPrintedTemplateFormattextText: '',
            onUserRegistrationTemplatePlainText: '',
            onUserRegistrationTemplateFormattextText: ''
        };
        modifiedTemplate.forEach(template => {
            switch (template.emailTemplateTypeID) {
                case 0:
                    dataToUpdate.onApprovalTemplatePlainText = template.emailTemplatePlainText;
                    dataToUpdate.onApprovalTemplateFormattextText = template.emailTemplateFormattextText;
                    break;
                case 1:
                    dataToUpdate.onRejectionTemplatePlainText = template.emailTemplatePlainText;
                    dataToUpdate.onRejectionTemplateFormattextText = template.emailTemplateFormattextText;
                    break;
                case 2:
                    dataToUpdate.onPrintedTemplatePlainText = template.emailTemplatePlainText;
                    dataToUpdate.onPrintedTemplateFormattextText = template.emailTemplateFormattextText;
                    break;
                case 3:
                    dataToUpdate.onUserRegistrationTemplatePlainText = template.emailTemplatePlainText;
                    dataToUpdate.onUserRegistrationTemplateFormattextText = template.emailTemplateFormattextText;
                    break;
                default:
                    break;
            }
        });

        if (dataToUpdate.onApprovalTemplateFormattextText === null || dataToUpdate.onApprovalTemplateFormattextText === undefined || dataToUpdate.onApprovalTemplateFormattextText === "" || dataToUpdate.onRejectionTemplateFormattextText === null || dataToUpdate.onRejectionTemplateFormattextText === undefined || dataToUpdate.onRejectionTemplateFormattextText === "" || dataToUpdate.onPrintedTemplateFormattextText === null || dataToUpdate.onPrintedTemplateFormattextText === undefined || dataToUpdate.onPrintedTemplateFormattextText === "" || dataToUpdate.onUserRegistrationTemplateFormattextText === null || dataToUpdate.onUserRegistrationTemplateFormattextText === undefined || dataToUpdate.onUserRegistrationTemplateFormattextText === "") {
            setShowSkeleton(false);
            ShowAlert("Email Templates cannot be blank", "Run");
        }
        else {
            const result = await emailTemplateService.UpdateEmailTemplateRecord(dataToUpdate);

            if (!result.isOk) {
                if (result.statusCode === 409) {
                    ConflictPopup(result.data).then((res) => {
                        if (res) {
                            GetModalRecord();
                        }
                    });
                    setShowSkeleton(false);
                } else if (result.statusCode === 400) {
                    ShowAlert(result.data, "Run");
                    setShowSkeleton(false);
                }
            } else {
                props.popupClose();
                setShowSkeleton(false);
            }
        }
    };

    const PopupTitle = (e) => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    title={[
                        "Email Templates",
                        <span key={"header_title"} className="base-accent-text">
                            {" for " + props.currentEventName}
                        </span>
                    ]}
                />
            </>
        );
    };

    return (
        <>
            {props.popupShow && (
                <Popup
                    visible={props.popupShow}
                    titleRender={PopupTitle}
                    width={`${isExLarge
                        ? "53%"
                        : isMedium
                            ? "97%" : isLarge ? "78%"
                                : isSmall ? "80%" : "95%"
                        }`}
                    height={"auto"}
                    maxHeight={"90%"}
                    deferRendering={false}
                    wrapperAttr={{ class: "CustomPopup" }}
                >
                    <ScrollView>
                        <form onSubmit={handleSubmit}>
                            <div className='row px-3 py-1'>
                                <div className='col-md-9'>
                                    <div className='py-1'>
                                        {showSkeleton ? (
                                            <div className="">
                                                <div className="d-flex rounded list-toolbar-skeleto-w_100"></div>
                                            </div>
                                        ) : (
                                            <TabPanel
                                                iconPosition='top'
                                                animationEnabled={true}
                                                selectedIndex={selectedTabIndex}
                                                onSelectedIndexChange={OnTabselectionChanged}
                                                defaultSelectedIndex={0}
                                            >
                                                <Item title="On Approval" icon="onApproval" ></Item>
                                                <Item title="On Rejection" icon="onRejectedIcon"></Item>
                                                <Item title="On Printed" icon="onPrintedIcon"></Item>
                                                <Item title="User registration" icon="onUserRegistrationIcon"></Item>
                                            </TabPanel>
                                        )}
                                    </div>
                                    <div className='py-1'>
                                        {showSkeleton ? (
                                            <div className="">
                                                <div className="d-flex rounded list-toolbar-skeleto-HTMLEditor"></div>
                                            </div>
                                        ) : (
                                            <HtmlEditor
                                                ref={htmlEditorRef}
                                                id={"EmailTemplateHtmlEditor"}
                                                elementAttr={htmlEditorSecondAttributes}
                                                valueType="html"
                                                height={400}
                                                className="CustomizeHtmlEditor"
                                            >
                                                <HtmlEditorToolbar multiline={false}>
                                                    <HtmlEditorItem name="link"></HtmlEditorItem>
                                                    <HtmlEditorItem
                                                        name="font"
                                                        acceptedValues={fontValues}
                                                    />
                                                    <HtmlEditorItem
                                                        name="size"
                                                        acceptedValues={sizeValues}
                                                    />
                                                    <HtmlEditorItem name="bold" />
                                                    <HtmlEditorItem name="italic" />
                                                    <HtmlEditorItem name="underline" />
                                                    <HtmlEditorItem name="background" />
                                                    <HtmlEditorItem name="color" />
                                                    <HtmlEditorItem name="orderedList" />
                                                    <HtmlEditorItem name="bulletList" />
                                                    <HtmlEditorItem name="image" />
                                                    <HtmlEditorItem name="clear" />
                                                </HtmlEditorToolbar>
                                            </HtmlEditor>
                                        )}
                                    </div>
                                </div>
                                <div className='col-md-3 ps-0 pe-1 list-section-email-templates'>
                                    {showSkeleton ? (
                                        <div className="">
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                        </div>
                                    ) : (
                                        <DataGrid
                                            // ref={DataGridRef}
                                            height={"100%"}
                                            width={"100%"}
                                            className='List_DataGrid py-1'
                                            dataSource={selectedTabIndex === 3 ? EmailTemplateStaticComponent : dsComponent}
                                            keyExpr="componentID"
                                            showBorders={true}
                                            showColumnLines={false}
                                            showRowLines={true}
                                            focusedRowEnabled={true}
                                            wordWrapEnabled={true}
                                            hoverStateEnabled={true}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            autoNavigateToFocusedRow={true}
                                            loadPanel={HideDatagridLoader}
                                            filterSyncEnabled={true}
                                            onRowDblClick={onRowDbClick}
                                            noDataText='No Record Found'
                                        >
                                            <Scrolling mode={'virtual'} />
                                            <Column
                                                caption='Fields'
                                                dataField='caption'
                                                minWidth={90}
                                                alignment='left'
                                                allowHiding={false}
                                            />
                                        </DataGrid>
                                    )}
                                </div>
                            </div>
                            {showSkeleton ?
                                <div className='d-flex justify-content-end pb-3 pe-2 mt-3'>
                                    <div className='skeleton-View Skeleton-popup-footer'></div>
                                </div>
                                :
                                <PopupFooter
                                    handleCancel={onClosePopup}
                                />
                            }
                        </form>
                    </ScrollView>
                </Popup >
            )}
        </>
    )
}

export default EmailTemplatePopup