import './RegistrationFormDesigner.scss'
import React, { createRef, useEffect, useState } from 'react';
import { LinkIcon as TextFieldIcon } from '../../utils/base-64-Icons';
import { Button, CheckBox, FileUploader, Popup, ScrollView, TextArea, TextBox } from 'devextreme-react';
import { ShowAlert, eCRUDStatus } from '../../utils/common-methods';
import { PopupFooter, PopupHeader, PopupSkeleton } from '../../layouts';
import { useScreenSize } from '../../utils/media-query';
import { DateEdit, DateTimeEdit, DragIcon, Dropdown, MemoEdit, SingleFileUploader, TextEdit, TimeEdit } from './group-base-64-Icons';
import { RegistrationFormDesignerService, eRegistrationType } from '../../api/services/registrationFormDesignerService';
import { SelectBox, Button as SelectBoxButton } from 'devextreme-react/select-box';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';

const registrationFormDesignerService = new RegistrationFormDesignerService();
const RegistrationFormDesigner = (props) => {
    const { isExSmall, isXSmall, isXXSmall, isExLarge, isLarge, isMedium, isSmall } = useScreenSize();
    const [draggedItem, setDraggedItem] = useState(null);
    const [droppedItems, setDroppedItems] = useState([]);
    const ValidationGroupName = "GroupRegistrationFormDesignerPopupValidation";
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [RefreshSection, setRefreshSection] = useState(false);
    const [formDesignerData, setFormDesignerData] = useState({});
    const [dsComponent, setDsComponent] = useState([]);

    const GetComponents = async () => {
        const result = await registrationFormDesignerService.GetComponents();
        if (result.statusCode === 400) {
            ShowAlert(result?.data, "Run")
            setShowSkeleton(false);
        } else {
            const ComponentData = result?.data?.map(item => {
                return {
                    ...item,
                    componentRef: createRef(),
                    isMandatory: false
                };
            });

            ComponentData.map((items) => {
                if (items.componentTypeID === 1) {
                    items.componentIcon = TextEdit
                }
                else if (items.componentTypeID === 2) {
                    items.componentIcon = MemoEdit
                }
                else if (items.componentTypeID === 3) {
                    items.componentIcon = Dropdown
                }
                else if (items.componentTypeID === 4) {
                    items.componentIcon = DateEdit
                }
                else if (items.componentTypeID === 5) {
                    items.componentIcon = TimeEdit
                }
                else if (items.componentTypeID === 6) {
                    items.componentIcon = DateTimeEdit
                }
                else if (items.componentTypeID === 7) {
                    items.componentIcon = SingleFileUploader
                }

            });

            setDsComponent(ComponentData);            
            if (props.EventDetail.RegistrationFormID) {
                GetFormDesignerModel(props.EventDetail.RegistrationFormID, ComponentData);
            } else {
                setShowSkeleton(false);
            }
        }
    }

    useEffect(() => {
        setShowSkeleton(true);
        GetComponents();
    }, []);

    const GetFormDesignerModel = async (RegistrationFormID, ComponentsData) => {
        const result = await registrationFormDesignerService.GetRecord(RegistrationFormID);
        if (result.statusCode === 200) {
            const response = result.data;
            setFormDesignerData(response);
            const Components = response.components?.map(item => {
                const captionName = ComponentsData.filter(r => r.componentID === item.componentID)[0]?.caption;
                return {
                    ...item,
                    componentRef: createRef(),
                    isMandatory: item.mandatory,
                    caption: captionName
                };
            }).sort(function (a, b) {
                return a.index - b.index;
            });
            setDroppedItems(Components);
            setShowSkeleton(false);
        }
        else if (result.statusCode === 400 || result.statusCode === 409) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false);
        }
    }

    const PopupTitle = (e) => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    title={[
                        `${props.EventDetail.RegistrationTypeID === 1 ? "Visitor" : props.EventDetail.RegistrationTypeID === 2 ? "Media" : "Group"} Registration Form Designer For `,
                        <span key={"header_title"} className="base-accent-text">
                            {props.EventDetail.EventName}
                        </span>
                    ]}

                />
            </>
        );
    };

    const handleDragStart = (e, item) => {
        setDraggedItem(item);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (draggedItem) {
            const newItems = [...droppedItems];
            const checkItemExist = newItems.filter(r => r.componentID === draggedItem.componentID)
            if (checkItemExist.length === 0) {
                newItems.push(draggedItem);
                setDroppedItems(newItems);
            }
            setDraggedItem(null);
        }
    };

    const handleItemDragStart = (e, item) => {
        e.dataTransfer.setData('text/plain', ''); // Required for Firefox
        setDraggedItem(item);
    };

    const handleItemDragEnd = () => {
        setDraggedItem(null);
    };

    const handleItemDrop = (index) => {
        if (draggedItem) {
            const newItems = [...droppedItems];
            newItems.splice(newItems.indexOf(draggedItem), 1);
            newItems.splice(index, 0, draggedItem);
            setDroppedItems(newItems);
            setDraggedItem(null);
        }
    };

    const onRemoveButtonClick = (e, item) => {
        if (item) {
            const newItems = droppedItems.filter(r => r.componentID !== item.componentID)
            setDroppedItems(newItems);
        }
    }

    const onMandatoryCheckClick = (e, item) => {
        setRefreshSection(true);
        droppedItems.map(r => {
            if (r.componentID === item.componentID) {
                r.isMandatory = e
            }
        })
        setTimeout(() => {
            setRefreshSection(false);
        }, 200)
    }

    const ClearUIFields = () => {
        setDroppedItems([]);
        setDsComponent([]);
        setRefreshSection(false);
    }

    const onClosePopup = () => {
        ClearUIFields();
        props.setStatus({ eStatus: eCRUDStatus.None })
        props.popupClose();
    }


    const onFormSubmit = async (e) => {
        e.preventDefault();
        if (droppedItems.length === 0) {
            ShowAlert("There are no control to save", "Run");
            return;
        }
        setShowSkeleton(true);
        let componentsList = [];
        droppedItems.map((r, index) => {
            componentsList.push({
                registrationFormID: props.EventDetail.RegistrationFormID,
                componentID: r.componentID,
                index: index,
                mandatory: r.isMandatory
            })
        })

        if (props.EventDetail.RegistrationFormID) {
            const dataToUpdate = {
                updatedOn: formDesignerData.updatedOn,
                registrationFormID: formDesignerData.registrationFormID,
                eventID: props.EventDetail.EventID,
                registrationTypeID: props.EventDetail.RegistrationTypeID,               
                components: componentsList
            }
            if(props.EventDetail.RegistrationTypeID === eRegistrationType.Group){
                dataToUpdate.eventGroupCategoryID = props.EventDetail.EventGroupCategoryID;
            }
            const result = await registrationFormDesignerService.UpdateRecord(dataToUpdate);
            if (!result.isOk) {
                if (result.statusCode === 400) {
                    ShowAlert(result.data, "Run");
                    setShowSkeleton(false);
                }
            } else {
                ClearUIFields();
                props.setStatus({
                    eStatus: eCRUDStatus.Updated
                });
                props.popupClose();
                setShowSkeleton(false);
            }
        }
        else {
            const dataToSave = {
                eventID: props.EventDetail.EventID,
                registrationTypeID: props.EventDetail.RegistrationTypeID,               
                components: componentsList
            }
            if(props.EventDetail.RegistrationTypeID === eRegistrationType.Group){
                dataToSave.eventGroupCategoryID = props.EventDetail.EventGroupCategoryID;
            }
            const result = await registrationFormDesignerService.InsertRecord(dataToSave);
            if (!result.isOk) {
                if (result.statusCode === 400) {
                    ShowAlert(result.data, "Run");
                    setShowSkeleton(false);
                }
            } else {
                ClearUIFields();
                props.setStatus({
                    eStatus: eCRUDStatus.Inserted
                });
                props.popupClose();
                setShowSkeleton(false);
            }
        }
    }

    return (
        <>
            {props.popupShow && (
                <Popup
                    visible={props.popupShow}
                    titleRender={PopupTitle}
                    width={(isXSmall || isXXSmall || isExSmall) ? "95%" :  isExLarge ? "75%" : "85%"}
                    height={"auto"}
                    maxHeight={"97vh"}
                    wrapperAttr={{ class: "CustomPopup" }}

                >
                    <ScrollView height="100%" width="100%">
                        <form onSubmit={onFormSubmit}>
                            {showSkeleton ?
                                <div className='p-2 px-3 pb-0'>
                                    <PopupSkeleton />
                                    <PopupSkeleton />
                                    <PopupSkeleton />
                                    <PopupSkeleton />
                                </div>
                                :
                                <>
                                    <div className='d-flex m-3 '>
                                        <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 componentsSection h-100'>
                                            <div className='componentsTitle'>Components</div>
                                            <ScrollView width={'100%'} className='py-3'>
                                                <div className={`d-flex flex-wrap w-100 px-2 ${isExLarge ? 'heightForExLarge' : 'heightForLarge'}`}>

                                                    {dsComponent.map(r => {
                                                        return (
                                                            <div
                                                                id={r.componentID}
                                                                className={`control-item ${isExLarge ? 'widthForLarge' : 'w-100'}`}
                                                                onDragStart={(e) => handleDragStart(e, r)}
                                                                draggable
                                                            >
                                                                <img src={r.componentIcon} alt='' className='rounded mx-auto d-block' width={24} height={24} />

                                                                <div className='ps-1 text-center text-break'> {r.caption} </div>
                                                            </div>
                                                        )
                                                    })}


                                                </div>
                                            </ScrollView>
                                        </div>
                                        &nbsp;
                                        <div className='center dropped-area col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 h-100' onDragOver={handleDragOver} onDrop={handleDrop}>
                                            <div className='componentsTitle'>Form Designer</div>
                                            <ScrollView width={'100%'} className='py-3'>
                                                <div className={`w-100 px-2  ${isExLarge ? 'heightForExLarge' : 'heightForLarge'}`}>
                                                    {RefreshSection && <div> </div>}
                                                    {droppedItems.map((item, index) => (
                                                        <div className="dropped-item">
                                                            <div className='row'>
                                                                <div
                                                                    className='d-inline-grid col-auto'
                                                                    key={index}
                                                                    draggable
                                                                    onDragStart={(e) => handleItemDragStart(e, item)}
                                                                    onDragEnd={handleItemDragEnd}
                                                                    onDragOver={(e) => e.preventDefault()} // Prevent default behavior to enable drop
                                                                    onDrop={() => handleItemDrop(index)}
                                                                >
                                                                    <Button
                                                                        icon={DragIcon}
                                                                        hint='Drag'
                                                                        className='dragControl'
                                                                    />
                                                                </div>

                                                                {item.componentTypeID === 1 &&
                                                                    <>

                                                                        <TextBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            readOnly={true}
                                                                            className='mt-0 col-10'
                                                                        >
                                                                        </TextBox>

                                                                    </>
                                                                }
                                                                {item.componentTypeID === 2 &&
                                                                    <>
                                                                        <TextArea
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            readOnly={true}
                                                                            className='mt-0 col-10'
                                                                        />

                                                                    </>
                                                                }
                                                                {item.componentTypeID === 3 &&
                                                                    <>
                                                                        <SelectBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            readOnly={true}
                                                                            className='mt-0 col-10'
                                                                            showDropDownButton={true}
                                                                            dataSource={[]}
                                                                        >
                                                                            <SelectBoxButton
                                                                                name="dropdown"
                                                                                location="after"
                                                                                options={{
                                                                                    icon: 'spindown',
                                                                                    stylingMode: 'text'
                                                                                }}
                                                                            />
                                                                        </SelectBox>

                                                                    </>
                                                                }
                                                                {item.componentTypeID === 4 &&
                                                                    <>
                                                                        <DateBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            readOnly={true}
                                                                            className='mt-0 col-10'
                                                                            type="date"
                                                                            displayFormat={"dd/MM/yyyy"}
                                                                        >
                                                                            <DateBoxButton name="dropDown" />
                                                                        </DateBox>

                                                                    </>
                                                                }
                                                                {item.componentTypeID === 5 &&
                                                                    <>
                                                                        <DateBox
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            label={item.caption}
                                                                            labelMode='floating'
                                                                            readOnly={true}
                                                                            className='mt-0 col-10'
                                                                            type="time"
                                                                        />

                                                                    </>
                                                                }
                                                                {item.componentTypeID === 6 &&
                                                                    <>
                                                                        <div className='col-10 d-flex'>
                                                                            <div className='col'>
                                                                            <DateBox
                                                                                id={item.componentID}
                                                                                name={item.caption}
                                                                                ref={item.componentRef}
                                                                                label={item.caption.split(" ")[0] + " Date"}
                                                                                labelMode='floating'
                                                                                readOnly={true}
                                                                                className='mt-0'
                                                                                type="date"
                                                                                displayFormat={"dd/MM/yyyy"}
                                                                                pickerType="calendar"
                                                                            /> </div> &nbsp;
                                                                              <div className='col'>
                                                                            <DateBox
                                                                                id={item.componentID}
                                                                                name={item.caption}
                                                                                ref={item.componentRef}
                                                                                label={item.caption.split(" ")[0] + " Time"}
                                                                                labelMode='floating'
                                                                                readOnly={true}
                                                                                className='mt-0'
                                                                                type="time"
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {item.componentTypeID === 7 &&
                                                                    <>
                                                                        <FileUploader
                                                                            id={item.componentID}
                                                                            name={item.caption}
                                                                            ref={item.componentRef}
                                                                            selectButtonText={`Select ${item.caption}`}
                                                                            readOnly={true}
                                                                            className='mt-0 col-10'
                                                                            accept="image/*"

                                                                        />

                                                                    </>
                                                                }
                                                                <div className='d-inline-grid col-auto'>
                                                                    <CheckBox             
                                                                    className='d-grid justify-content-center '                    
                                                                        hint="Mandatory"
                                                                        iconSize="18"
                                                                        value={item.isMandatory}
                                                                        onValueChange={(e) => onMandatoryCheckClick(e, item)}
                                                                    />
                                                                    <span className='mandatoryCheckbox'> Mandatory </span> 
                                                                </div>
                                                                <div className='d-inline-grid col-auto'>
                                                                    <Button
                                                                        icon='trash'
                                                                        hint='Remove'
                                                                        className='removeControl'
                                                                        onClick={(e) => onRemoveButtonClick(e, item)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ScrollView>
                                        </div>


                                    </div>
                                </>
                            }
                            {showSkeleton ?
                                <div className='d-flex justify-content-end pb-2 pe-3'>
                                    <div className='skeleton-View Skeleton-popup-footer'></div>
                                </div>
                                :
                                <PopupFooter
                                    ValidationGroupName={ValidationGroupName}
                                    handleCancel={onClosePopup}
                                    createUpdateStatus={null} />

                            }

                        </form>
                    </ScrollView>
                </Popup>
            )}
        </>
    );
}


export default RegistrationFormDesigner