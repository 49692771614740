import { RunAEMSAPIService } from "../RunAEMSAPIService";
import { fetchWrapper } from "../authHeader";

const baseURL = process.env.REACT_APP_BASEURL;

const url = `${baseURL}EventManagement/Event`;

export class EventService extends RunAEMSAPIService {

    constructor() {
        // call the super class constructor and pass in the name parameter
        super(url);
    }
 
    CheckDuplicateName = async (Name, ExcludeID) => {
        Name = encodeURIComponent(Name);
        const result = await fetchWrapper.get(url + `/CheckDuplicateName?Name=${Name}&ExcludeID=${ExcludeID}`);
        try {
            if (result.status && result.status === 200) {                
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    message: await result,
                    statusCode: result.status
                };
            }
        }
        catch (err) {            
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }
    CheckDuplicateEventCode = async (EventCode, ExcludeID) => {
        EventCode = encodeURIComponent(EventCode);
        const result = await fetchWrapper.get(url + `/CheckDuplicateEventCode?Code=${EventCode}&ExcludeID=${ExcludeID}`);
        try {
            if (result.status && result.status === 200) {                
                return {
                    isOk: true,
                    data: await result,
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    message: await result,
                    statusCode: result.status
                };
            }
        }
        catch (err) {            
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetEventLogo = async (ID) =>{
        const result = await fetchWrapper.get(url + '/DownloadEventLogoImage/' + ID);
    try {
       
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            data: "Failed To Load Data",
            statusCode: result.status
        };
    }
    }

    UploadEventLogo = async (params,ID) =>{
        const formData = new FormData();
        formData.append("EventID", params.eventID);
        formData.append("attachments", params.attachments);
        const result = await fetchWrapper.postwithFile(url + '/UploadEventLogoImage/' + ID, formData);
    try {
        
        if (result.status && result.status === 200) {
            return {
                isOk: result.ok,
                data: await result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: result.ok,
                data: await result.text(),
                stautsCode: result.status && result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
    }


    DeleteEventLogo = async (ID) =>{
        const result = await fetchWrapper.delete(url + '/DeleteEventLogoImage/' + ID);
    try {
        

        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: result.status
        };
    }
    }
    
    GetListRecordWithFilter = async (eventStatus) => {
        const result = await fetchWrapper.get(this.apiUrl + `/GetList?ID&eventStatus=${eventStatus}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }


    GetEventSummary = async (eventStatus) =>{
        var endUrl;
        if(eventStatus !== null && eventStatus !== undefined){
            endUrl = `/GetSummary/${eventStatus}`;
        }else{
            endUrl = `/GetSummary`
        }
        const result = await fetchWrapper.get(url + endUrl);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetEventSummaryByID = async (eventStatus, eventID) =>{
        var params = new URLSearchParams();
        if(eventStatus){
            params.append('EventStatus',eventStatus)
        }
        if(eventID){
            params.append('EventID', eventID)
        }
        
        const result = await fetchWrapper.get(url + `/GetSummary?${params}`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetEventLogoThumbnail = async (ID) =>{
        const result = await fetchWrapper.get(url + '/DownloadEventLogoImage/' + ID);
    try {
       
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            data: "Failed To Load Data",
            statusCode: result.status
        };
    }
    }

}


