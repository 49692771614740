import { Button, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import React from 'react';
import './PopupHeaderFooter.scss';
import { useScreenSize } from '../../utils/media-query';

const PopupHeader = ({ onClosePopup, title, showCloseButton }) => {

  const { isXSmall, isXXSmall, isExSmall, } = useScreenSize();


  return (
    <div className='popup_header shadow-sm mb-2 '>
      <div className='row mb-2 mx-1'>

        <Toolbar className='my-1'>
          {title &&
            <Item
              location="before"
            >
              <div className={`${(isXSmall || isXXSmall || isExSmall) ? 'font-semiBold-14' : 'font-semiBold-18'} fw-semibold`}>{title}</div>
            </Item>
          }
          {
            !showCloseButton &&
          <Item
            location="after"
          >
            <Button
              icon='close'
              type='default'
              hint='Close'
              stylingMode='outlined'
              className='rounded-5 remove-icon'
              hoverStateEnabled={false}
              activeStateEnabled={false}
              focusStateEnabled={false}
              onClick={onClosePopup}
            >
            </Button>
          </Item>
          }
        </Toolbar>
      </div>
    </div>
  )
}

export default PopupHeader;