import { Routes, Route, Navigate } from 'react-router-dom';
import groupRoutes from './group-app-routes';

export default function GroupContent() {

  return (
      <Routes>
        {groupRoutes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route
          path='*'
          element={<Navigate to='/group/dashboard' />}
        />
      </Routes>
  );
}

