import { Popup, TextBox } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { PopupHeader, PopupFooter, PopupSkeleton } from '../../../layouts';
import { useScreenSize } from "../../../utils/media-query";
import {
  ConflictPopup,
  ShowAlert,
  eCRUDStatus,
} from "../../../utils/common-methods";
import {
  Validator,
  RequiredRule,
  AsyncRule,
} from "devextreme-react/validator";
import { MediaFunctionService } from "../../../api/services/mediaFunctionService";

const mediaFunctionService = new MediaFunctionService();
const MediaFunctionPopup = (props) => {

  const ValidationGroupName = "MediaFunctionPopupValidation";

  const { isExSmall, isXSmall, isXXSmall, isSmall } = useScreenSize();

  const FocusedFeild = useRef(null);

  const onShown = () => FocusedFeild.current?.instance.focus();

  const MediaFunctionData = {
    mediaFunctionID: 0,
    mediaFunctionName: "",
    updatedOn: null
  };

  const [newRecord, setNewRecord] = useState(MediaFunctionData);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [createUpdateStatus, setCreateUpdateStatus] = useState(null)
  let PrimaryKeyID = props.primaryKey;
  const isAddMode = !PrimaryKeyID;


  useEffect(() => {
    if (PrimaryKeyID) {
      setShowSkeleton(true);
      GetModelRecord();
    }
    else {
      setNewRecord(MediaFunctionData);
    }
  }, [PrimaryKeyID]);

  useEffect(() => {
    !showSkeleton && FocusedFeild.current?.instance.focus()
  }, [showSkeleton])

  const GetModelRecord = async () => {
    const result = await mediaFunctionService.GetRecord(PrimaryKeyID);
    if (result.statusCode === 200) {
      const response = result.data;
      setNewRecord(response);
      setCreateUpdateStatus(
        {
          createdByUserName: result?.data.createdByUserName,
          createdOn: result?.data.createdOn,
          updatedByUserName: result?.data.updatedByUserName,
          updatedOn: result?.data.updatedOn
        }
      )
      setShowSkeleton(false);
    }
    else if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Run")
      setShowSkeleton(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isAddMode) {
      InsertData();
    } else {
      UpdateData();
    }
  };

  const InsertData = async () => {
    setShowSkeleton(true);
    let dataToInsert = {
      mediaFunctionName: newRecord.mediaFunctionName,
    };

    const result = await mediaFunctionService.InsertRecord(dataToInsert);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Run");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData,
      });
      ClearUIFields();
      props.popupClose();
      setShowSkeleton(false);

    }
  };

  const UpdateData = async () => {
    setShowSkeleton(true);
    var dataToUpdate = {
      updatedOn: newRecord.updatedOn,
      mediaFunctionID: newRecord.mediaFunctionID,
      mediaFunctionName: newRecord.mediaFunctionName,
    };
    const result = await mediaFunctionService.UpdateRecord(dataToUpdate);

    if (!result.isOk) {
      if (result.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
        setShowSkeleton(false);
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Run");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Updated,
        primaryKeyID: result.data.responseData,
      });
      ClearUIFields();
      props.popupClose();
      setShowSkeleton(false);
    }
  };

  const ClearUIFields = () => {
    setNewRecord(MediaFunctionData);
  }

  const onClosePopup = () => {
    ClearUIFields();
    props.setStatus({ eStatus: eCRUDStatus.None })
    props.popupClose();
  }


  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          title={[
            <span key={"header_title"} className="base-accent-text">
              {PrimaryKeyID ? "Edit" : "New"}
            </span>,
            " Media Function",
          ]}
        />
      </>
    );
  };


  const asyncmediaFunctionNameValidation = async (e) => {
    const Name = e?.value;
    const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
    const result = await mediaFunctionService.CheckDuplicateName(Name, ExcludeID);
    return new Promise((resolve) => {
      resolve(result.isOk !== false);
    });
  };

  return (
    <>
      {props.popupShow && (
        <Popup
          visible={props.popupShow}
          titleRender={PopupTitle}
          width={`${(isXSmall || isXXSmall || isExSmall) ? '95%' : '650px'}`}
          height={"auto"}
          maxHeight={"90%"}
          wrapperAttr={{ class: "CustomPopup" }}
          onShown={onShown}
        >
          <form onSubmit={handleSubmit}>
            {showSkeleton ?
              <div className='p-2 pb-0'>
                <PopupSkeleton />
              </div>
              :
              <div className="py-2 px-3">
                <TextBox
                  label="Media Function Name"
                  maxLength={100}
                  value={newRecord.mediaFunctionName}
                  onValueChange={(e) => {
                    setNewRecord({ ...newRecord, mediaFunctionName: e });
                  }}
                  labelMode="floating"
                  ref={FocusedFeild}
                  inputAttr={{
                    autocomplete: "new"
                  }}
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Media Function Name is required" />
                    <AsyncRule
                      reevaluate={false}
                      message="Can't accept duplicate Media Function Name"
                      validationCallback={asyncmediaFunctionNameValidation}
                    />
                  </Validator>
                </TextBox>
              </div>
            }
            {showSkeleton ?
              <div className='d-flex justify-content-end pb-2 pe-2'>
                <div className='skeleton-View Skeleton-popup-footer'></div>
              </div>
              :
              <PopupFooter
                ValidationGroupName={ValidationGroupName}
                handleCancel={onClosePopup}
                createUpdateStatus={createUpdateStatus}
              />
            }
          </form>
        </Popup>
      )}
    </>
  );
}

export default MediaFunctionPopup;