import { RunAEMSAPIService } from "../RunAEMSAPIService";
import { fetchWrapper } from "../authHeader";

const baseURL = process.env.REACT_APP_BASEURL;

const url = `${baseURL}UserManagement/CurrentUser`;

export class CurrentUserService extends RunAEMSAPIService {

    constructor() {
        // call the super class constructor and pass in the name parameter
        super(url);
    }

    GetCurrentUserRecord = async () =>{
        const result = await fetchWrapper.get(url + '/GetModel');
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetUserImage = async () =>{
        const result = await fetchWrapper.get(url + '/GetUserProfileImage');
    try {
       
        if (result.status && result.status === 200) {
            return {
                isOk: true,
                data: await result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: false,
                data: await result.text(),
                statusCode: result.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            data: "Failed To Load Data",
            statusCode: result.status
        };
    }
    }

    UploadUserProfileImage = async (dataObj) =>{
        const formData = new FormData();
        formData.append("attachments", dataObj.attachments);
        const result = await fetchWrapper.postwithFile(url + '/UpdateUserProfileImage', formData);
    try {
        
        if (result.status && result.status === 200) {
            return {
                isOk: result.ok,
                data: await result,
                statusCode: result.status
            };
        } else {
            return {
                isOk: result.ok,
                data: await result.text(),
                stautsCode: result.status && result.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            data: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: result.status
        };
    }
    }

}


