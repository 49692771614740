import { RunAEMSAPIService } from "../RunAEMSAPIService";
import { fetchWrapper } from "../authHeader";

const baseURL = process.env.REACT_APP_BASEURL;

const url = `${baseURL}RegistrationManagement/RegistrationFormDesigner`;

export class RegistrationFormDesignerService extends RunAEMSAPIService {

    constructor() {
        // call the super class constructor and pass in the name parameter
        super(url);
    }

    GetComponents = async () => {
        const result = await fetchWrapper.get(url + `/GetComponents`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetGroupUserFormDesign = async () => {
        const result = await fetchWrapper.get(url + `/GetGroupUserFormDesign`);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    GetAllGroupRegistrationFormDesign = async (ID) => {
        const result = await fetchWrapper.get(url + `/GetAllGroupRegistrationFormDesign/`+ ID);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }
}

export const eRegistrationType = {
    Visitor: 1,
    Media: 2,
    Group: 3
}

