import React, { useEffect, useRef, useState } from 'react';
import { CheckBox, NumberBox, Popup, ScrollView, TextArea, Validator } from 'devextreme-react';
import { CompareRule, EmailRule, RequiredRule, } from 'devextreme-react/data-grid';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import openeye from '../../../images/openeye.png'
import closeeyeIcon from '../../../images/closeeyeIcon .png'
import UserProfileUploader from './UserProfileUploader';
import { useScreenSize } from '../../../utils/media-query';
import { ConflictPopup, PasswordRegex, onKeyDown } from '../../../utils/common-methods';
import { ShowAlert, eCRUDStatus } from '../../../utils/common-methods';
import { AsyncRule, PatternRule, StringLengthRule } from 'devextreme-react/validator';
import { UserService } from '../../../api/services/userService';
import { PopupHeader, PopupFooter, PopupSkeleton, PopupSkeletonOneLine } from '../../../layouts';
import { CurrentUserService } from '../../../api/services/currentUserService';
import { userIcon } from '../../../utils/base-64-Icons';
import './User.scss';

const userService = new UserService();
const currentUserService = new CurrentUserService();

const UserPopup = (props) => {

    const { isXSmall, isXXSmall, isExSmall, isSmall } = useScreenSize();
    const ValidationGroupName = "userPopupValidation"

    const FocusedFeild = useRef(null);

    const UserData = {
        userID: 0,
        createdByUserName: "",
        createdOn: null,
        updatedByUserName: "",
        updatedOn: null,
        firstName: "",
        lastName: "",
        address: "",
        cityName: "",
        countryName: "",
        emailID: "",
        mobileNo: null,
        password: "",
        confirmPassword: "",
        updatePassword: false,
        uploadedFile: { fileData: null },
        attachedFile: null,
    };

    const [newRecord, setNewRecord] = useState(UserData);
    const [PasswordMode, setPasswordMode] = useState("password")
    const [showPasswordIcon, setShowpasswordIcon] = useState(openeye)
    const [confirmPasswordMode, setConfirmPasswordMode] = useState("password")
    const [showConfirmPasswordIcon, setShowConfirmpasswordIcon] = useState(openeye)
    const [showChangePaswordOption, setShowChangePasswordOption] = useState(false)
    const [dropDownClick, setDropDownClick] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [createUpdateStatus, setCreateUpdateStatus] = useState(null)
    let PrimaryKeyID = props.primaryKey;
    const isAddMode = !PrimaryKeyID;

    useEffect(() => {
        if (PrimaryKeyID) {
            setShowSkeleton(true);
            GetModelRecord();
        } else {
            setNewRecord(UserData);
        }
    }, [PrimaryKeyID])


    useEffect(() => {
        !showSkeleton && FocusedFeild.current?.instance.focus()
    }, [showSkeleton])


    const GetModelRecord = async () => {

        const result = await userService.GetRecord(PrimaryKeyID);
        if (result.statusCode === 400 || result.statusCode === 409) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false);
        }
        else {
            GetUserImage(PrimaryKeyID).then((res) => {
                setShowSkeleton(false);
                setNewRecord({
                    ...result.data,
                    uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
                    attachedFile: (res?.size > 0 ? res : null)
                })
                setCreateUpdateStatus(
                    {
                        createdByUserName: result?.data.createdByUserName,
                        createdOn: result?.data.createdOn,
                        updatedByUserName: result?.data.updatedByUserName,
                        updatedOn: result?.data.updatedOn
                    }
                )
            })
        }
    }



    const GetUserImage = async (primaryKeyID) => {
        const result = await userService.GetUserImage(primaryKeyID)
        let response = result.data
        if (response) {
            return response.blob()
        } else {
            return;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (dropDownClick !== true) {
            if (isAddMode) {
                InsertData()
            } else {
                UpdateData()
            }
        }
    }



    const InsertData = async () => {
        setShowSkeleton(true);
        const result = await userService.InsertRecord(newRecord)
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Run")
            }
            else if (result.statusCode === 409) {
                ShowAlert(result.data, "Run")
            }
            setShowSkeleton(false);
        }
        else {

            if (newRecord.attachedFile) {
                UploadUserProfile(result.data.responseData)
            }
            const currentUserID = result?.data.userID
            if (newRecord.userID === currentUserID) {
                document.getElementById("currentUserImage").src = (newRecord.uploadedFile.fileData ? newRecord.uploadedFile.fileData : userIcon)
                if (newRecord.attachedFile) {
                    localStorage.setItem("userImage", window.URL.createObjectURL(newRecord.attachedFile));
                    localStorage.setItem("userImageData", newRecord.attachedFile);
                }
                else {
                    localStorage.setItem("userImage", userIcon);
                    localStorage.setItem("userImageData", '');
                }
            }
            props.setStatus({
                eStatus: eCRUDStatus.Inserted,
                primaryKeyID: result.data.responseData,
            });
            ClearUIFields();
            props.popupClose();
            setShowSkeleton(false);
        }
    }
    const UpdateData = async () => {

        let dataObj = {
            updatedOn: newRecord.updatedOn,
            userID: newRecord.userID,
            firstName: newRecord.firstName,
            lastName: newRecord.lastName,
            address: newRecord.address,
            cityName: newRecord.cityName,
            countryName: newRecord.countryName,
            emailID: newRecord.emailID,
            mobileNo: newRecord.mobileNo,
            updatePassword: showChangePaswordOption,
            password: showChangePaswordOption === false ? undefined : newRecord.password,
            confirmPassword: showChangePaswordOption === false ? undefined : newRecord.confirmPassword,
            updateUserProfileImage: false
        }
        setShowSkeleton(true);
        const result = await userService.UpdateRecord(dataObj)
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Run")
            setShowSkeleton(false);
        }
        else if (result.statusCode === 409) {
            ConflictPopup(result.data).then((res) => {
                if (res) {
                    GetModelRecord();
                } else {
                    return;
                }
            });
            setShowSkeleton(false);
        }
        else {
            UploadUserProfile(newRecord.primaryKeyID)
            const result = await currentUserService.GetCurrentUserRecord()
            if (result.statusCode === 400 || result.statusCode === 409) {
                ShowAlert(result.data, "Run")
                setShowSkeleton(false);
            } else {
                const currentUserID = result?.data.userID
                if (newRecord.userID === currentUserID) {
                    document.getElementById("currentUserImage").src = (newRecord.uploadedFile.fileData ? newRecord.uploadedFile.fileData : userIcon)
                    if (newRecord.attachedFile) {
                        localStorage.setItem("userImage", window.URL.createObjectURL(newRecord.attachedFile));
                        localStorage.setItem("userImageData", newRecord.attachedFile);
                    }
                    else {
                        localStorage.setItem("userImage", userIcon);
                        localStorage.setItem("userImageData", "");
                    }
                }
            }
            props.setStatus({
                eStatus: eCRUDStatus.Updated,
                primaryKeyID: result.data.responseData,
            });
            ClearUIFields();
            props.popupClose();
            setShowSkeleton(false);
        }
    }

    const ClearUIFields = () => {
        setNewRecord(UserData);
    }

    const UploadUserProfile = async (primaryKeyID) => {
        var Datatoupload = { userID: primaryKeyID, attachments: newRecord.attachedFile }
        const result = await userService.UploadUserProfileImage(Datatoupload, primaryKeyID)
        if (result.isOk) {
            return;
        } else {
            ShowAlert(result.data)
        }
    }


    const PasswordOptions = {
        icon: showPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setPasswordMode(PasswordMode === 'text' ? 'password' : 'text');
            setShowpasswordIcon(showPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };
    const ConfirmPasswordOptions = {
        icon: showConfirmPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setConfirmPasswordMode(confirmPasswordMode === 'text' ? 'password' : 'text');
            setShowConfirmpasswordIcon(showConfirmPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };

    const passwordComparison = () => {
        return newRecord.password
    }


    useEffect(() => {
        if (!showChangePaswordOption && !isAddMode) {
            setNewRecord({
                ...newRecord,
                password: null,
                confirmPassword: null
            })
        }
    }, [showChangePaswordOption])



    const onClosePopup = () => {
        ClearUIFields();
        props.setStatus({ eStatus: eCRUDStatus.None })
        props.popupClose();
    }

    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    title={[<span key={"header_title"} className="base-accent-text">{PrimaryKeyID ? "Edit" : "New"}</span>, " User"]}
                />
            </>
        )
    }



    const asyncEmailIDValidation = async (e) => {

        const Name = e?.value;
        const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
        const result = await userService.CheckDuplicateEmailID(Name, ExcludeID);
        return new Promise((resolve) => {
            resolve(result.isOk !== false);
        });
    };

    const onShownUserPopup = () => {
        FocusedFeild.current?.instance.focus()
    }

    return (
        <>
            {props.popupShow && (
                <Popup
                    visible={props.popupShow}
                    titleRender={PopupTitle}
                    width={(isXSmall || isXXSmall || isExSmall || isSmall) ? "95%" : 700}
                    height={"auto"}
                    maxHeight={"90%"}
                    deferRendering={false}
                    wrapperAttr={{ class: "CustomPopup" }}
                    onShown={onShownUserPopup}
                >
                    <ScrollView >
                        <form onSubmit={handleSubmit}>
                            <div className='overflow-hidden'>
                                {(isXSmall || isXXSmall || isExSmall) &&
                                    <div className='pb-3 mx-auto top-profileSection'>
                                        {showSkeleton ?
                                            <div className="mt-1">
                                                <div className='skeleton-View image-Skeleton-Candidateprofile mt-2'></div>
                                            </div>
                                            :
                                            <UserProfileUploader newRecord={newRecord} setNewRecord={setNewRecord} />
                                        }
                                    </div>
                                }
                                <div className='row px-3 pt-2'>

                                    <div className='col-md'>
                                        {showSkeleton ?
                                            <div className=''>
                                                <PopupSkeleton />
                                                <PopupSkeleton />
                                            </div>
                                            :
                                            <>
                                                <div>
                                                    <TextBox
                                                        label='First Name'
                                                        maxLength={50}
                                                        labelMode='floating'
                                                        value={newRecord.firstName}
                                                        ref={FocusedFeild}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, firstName: e })}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                        }}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                            <RequiredRule message='First Name is required' />
                                                        </Validator>
                                                    </TextBox>
                                                </div>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        label='Last Name'
                                                        maxLength={50}
                                                        labelMode='floating'
                                                        value={newRecord.lastName}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, lastName: e })}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                        }}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                            <RequiredRule message='Last Name is required' />
                                                        </Validator>
                                                    </TextBox>
                                                </div>
                                                <div className='mt-4'>
                                                    <TextArea
                                                        label='Address'
                                                        maxLength={1000}
                                                        labelMode='floating'
                                                        value={newRecord.address}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, address: e })}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                        }}
                                                    >
                                                    </TextArea>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {(!isXSmall && !isXXSmall && !isExSmall) &&
                                        <div className='col-md-auto'>
                                            {showSkeleton ?
                                                <div className="pt-1">
                                                    <div className='skeleton-View image-Skeleton-Candidateprofile'></div>
                                                </div>
                                                :
                                                <UserProfileUploader newRecord={newRecord} setNewRecord={setNewRecord} />
                                            }
                                        </div>
                                    }
                                </div>
                                {showSkeleton ?
                                    <div className='px-3'>
                                        <PopupSkeleton />
                                        <PopupSkeleton />
                                        <PopupSkeletonOneLine />
                                        {PrimaryKeyID && showChangePaswordOption === true ? <PopupSkeleton /> : ""}
                                    </div>
                                    :
                                    <>

                                        <div className='row px-3'>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        label='Email ID'
                                                        labelMode='floating'
                                                        value={newRecord.emailID}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, emailID: e })}
                                                        inputAttr={{
                                                            autocomplete: "new"
                                                        }}
                                                        maxLength={50}

                                                    >
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                            <EmailRule message='Email ID is invalid' />
                                                            <RequiredRule message='Email ID is required' />
                                                            {

                                                            }
                                                            <AsyncRule
                                                                reevaluate={false}
                                                                message="Can't accept duplicate Email ID"
                                                                validationCallback={asyncEmailIDValidation}
                                                            />

                                                        </Validator>
                                                    </TextBox>
                                                </div>

                                            </div>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <NumberBox
                                                        label='Mobile Number'
                                                        labelMode='floating'
                                                        format="#"
                                                        step={0}
                                                        onKeyDown={onKeyDown}
                                                        maxLength={15}
                                                        value={newRecord.mobileNo && parseInt(newRecord.mobileNo)}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                            maxLength: 15
                                                        }}
                                                        onValueChange={(value) => setNewRecord({ ...newRecord, mobileNo: value })}
                                                    >
                                                        <Validator validationGroup={ValidationGroupName}>
                                                            <StringLengthRule message='Mobile Number must have 10 digits' min={10} ignoreEmptyValue={true} />
                                                        </Validator>
                                                    </NumberBox>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row px-3'>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        label='City'
                                                        labelMode='floating'
                                                        maxLength={50}
                                                        value={newRecord.cityName}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, cityName: e })}
                                                    >
                                                    </TextBox>
                                                </div>

                                            </div>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        id='CountryName'
                                                        name='CountryName'
                                                        label='Country'
                                                        labelMode='floating'
                                                        maxLength={50}
                                                        value={newRecord.countryName}
                                                        onValueChange={(e) => setNewRecord({ ...newRecord, countryName: e })}
                                                    >
                                                    </TextBox>
                                                </div>
                                            </div>
                                        </div>

                                        {PrimaryKeyID ?
                                            <div className='px-3'>
                                                <CheckBox
                                                    iconSize={15}
                                                    className='pe-2 mt-4'
                                                    value={showChangePaswordOption}
                                                    onValueChange={(e) => setShowChangePasswordOption(e)}
                                                >
                                                </CheckBox>
                                                <span className='font-regular-15 show_change_password fw-semibold'>Do you want to change password?</span>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                        {((PrimaryKeyID && showChangePaswordOption === true) || !PrimaryKeyID) &&
                                            <div className='row px-3'>
                                                <div className='col-md'>
                                                    <div className='mt-4'>
                                                        <TextBox
                                                            name="password"
                                                            label='Password'
                                                            mode={PasswordMode}
                                                            labelMode='floating'
                                                            maxLength={16}
                                                            value={newRecord.password}
                                                            onValueChange={(value) => setNewRecord({ ...newRecord, password: value?.replaceAll(" ", "") })}
                                                            inputAttr={{
                                                                autocomplete: "new-password"
                                                            }}
                                                        >
                                                            <TextBoxButton
                                                                name="password"
                                                                location="after"
                                                                options={PasswordOptions}
                                                            />
                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}  >
                                                                <RequiredRule message='Password is required' />
                                                                {/* <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} /> */}
                                                            </Validator>
                                                        </TextBox>
                                                    </div>
                                                </div>
                                                <div className='col-md'>
                                                    <div className='mt-4'>
                                                        <TextBox
                                                            label="Confirm Password"
                                                            mode={confirmPasswordMode}
                                                            labelMode='floating'
                                                            maxLength={16}
                                                            value={newRecord.confirmPassword}
                                                            onValueChange={(value) => setNewRecord({ ...newRecord, confirmPassword: value?.replaceAll(" ", "") })}
                                                            inputAttr={{
                                                                autocomplete: "new",
                                                            }}
                                                        >
                                                            <TextBoxButton
                                                                name="confirmPassword"
                                                                location="after"
                                                                options={ConfirmPasswordOptions}
                                                            />
                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                                <RequiredRule message='Confirm Password is required' />
                                                                {/* <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} /> */}
                                                                {newRecord.password && <CompareRule
                                                                    message="Password and Confirm Password does not match"
                                                                    comparisonTarget={passwordComparison}
                                                                />
                                                                }
                                                            </Validator>
                                                        </TextBox>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                {showSkeleton ?
                                    <div className='d-flex justify-content-end pb-2 pe-3'>
                                        <div className='skeleton-View Skeleton-popup-footer'></div>
                                    </div>
                                    :
                                    <PopupFooter
                                        ValidationGroupName={ValidationGroupName}
                                        handleCancel={onClosePopup}
                                        createUpdateStatus={createUpdateStatus}
                                    />
                                }
                            </div>
                        </form>
                    </ScrollView>
                </Popup>
            )}
        </>
    )
}

export default UserPopup;
